import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';

export default props => {
  const fill = props.fillColor || '#F27022';
  const average = (props.average || 0).toFixed(1);
  const roundedAverage = (Math.round(average * 2) / 2).toFixed(1);
  const split = roundedAverage.split('.');


  const stars = [];
  for(let i = 0; i < +split[0]; i++) {
    stars.push(<StarIcon key={`fullStart-${i}`} style={{ fontSize: (props.starSize || 20), color: fill, float: 'left' }}/>)
  }
  if (+split[1] === 5) {
    stars.push(<StarHalfIcon key='halfStar' style={{ fontSize: (props.starSize || 20), color: fill, float: 'left' }}/>)
  }
  for(let i = stars.length; i < 5; i++) {
    stars.push(<StarBorderIcon key={`emptyStar-${i}`} style={{fontSize: (props.starSize || 20), color: props.emptyColor || '#A0A0A0', float: 'left' }}/>)
  }

  return (
    <div style={{ width: props.width || 'fit-content', height: 20, marginLeft: 'auto', marginRight: 'auto' }}>
      { stars.map(s => s) }
    </div>
  );
}