import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Home from "Home/Home";
import AppProvider from "AppProvider";
import ReportViewer from "ReportViewer/ReportViewer";
import history from "./history";
import ReportBuilderProcess from "ReportBuilder/ReportBuilderProcess";
import segment from "segment.js";
import "./App.scss";


class App extends React.Component {

  componentDidMount = () => {
    segment.page();

    history.listen( location => {
      setTimeout(() => segment.page(), 500);
      return location;
    });
  }

  render(){
    return (
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Router history={history}>

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/build" component={ReportBuilderProcess} />
              <Route path="/view/:uuid" component={ReportViewer} />
            </Switch>
          </Router>
        </AppProvider>
      </ThemeProvider>
    );
  }

}

export default App;



const theme = createTheme({
  typography: {
    h1: {
      fontSize: 60,
      fontWeight: 700,
      lineHeight: 1.23,
      letterSpacing: 1.2,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.35,
      letterSpacing: 0.8,
    },
    h3: {
      fontSize: 26,
      fontWeight: 700,
      lineHeight: 1.35,
      letterSpacing: 0.5,
    },
    h4: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: 1.27,
      letterSpacing: 0.44,
    },
    // caption: {
    //   color: '#061432',
    //   fontSize: '1.67rem',
    //   fontWeight: 700,
    //   lineHeight: '120%',
    //   letterSpacing: 3.2,
    //   textTransform: 'uppercase',
    // },
    body1: {
      color: '#6E7191',
      fontSize: '1.125rem',
      lineHeight: 1.67,
      letterSpacing: 0.36,
    },
    body2: {
      color: '#6E7191',
      fontSize: '0.875rem',
      lineHeight: '120%',
    },
  },

  palette: {
    primary: {
      main: '#51C0A8',
      contrastText: 'white',
    },
    secondary: {
      main: '#6E7191',
    }
  },

  overrides: {
    // MuiButton: {
    //   contained: {
    //     backgroundColor: '#51C0A8',
    //     color: '#fff',
    //     boxShadow: '0px 8px 22px 0px rgba(44,48,125,0.23)',
    //     transition: 'box-shadow 300ms ease, -webkit-transform 300ms ease',
    //     transition: 'transform 300ms ease, box-shadow 300ms ease',
    //     transition: 'transform 300ms ease, box-shadow 300ms ease, -webkit-transform 300ms ease',
    //     '-webkit-transform-style': 'preserve-3d',
    //     transformStyle: 'preserve-3d',
    //     marginTop: '30px',
    //     marginBottom: '50px',

    //     '&:hover': {
    //       boxShadow: '0px 15px 32px 0px #d9dbe9',
    //       '-webkit-transform': 'translate3d(0px, -6px, 0.01px)',
    //       transform: 'translate3d(0px, -6px, 0.01px)',
    //       backgroundColor: '#51C0A8',
    //     },
    //   },
    //   root: {
    //     fontSize: '1.125rem',
    //     borderRadius: '1000px',
    //     textTransform: 'none',
    //     fontWeight: '700',
    //     letterSpacing: 0.36,
    //     lineHeight: 1.11,
    //     padding: '17.5px 31.5px',
    //     color: 'white'
    //   },
    // },
    MuiTypography: {
      paragraph: {
        marginBottom: '10px',

        '&:last-child': {
          marginBottom: '0px',
        }
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '0px',
      },
      stickyHeader: {
        color: '#9E9E9E',
        fontWeight: '500',
        fontSize: '16px',
        // backgroundColor: '#f9f9f9',
      },
      body: {
        color: '#2E3A5E',
        fontSize: '14px',
        // backgroundColor: '#fcfcfc',
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0px 6px 12px 0px rgba(81,192,168,0.29)'
      }
    },
    MuiCardActions: {
      root: {
        padding: 16,
        color: '#51C0A8',
        textDecoration: 'underline'
      }
    }
  },
});


