import React from "react";
import { log } from "kn-react";

const DEFAULT_STATE = {

}

export const AppContext = React.createContext(DEFAULT_STATE);


export default class AppProvider extends React.Component {
  state = DEFAULT_STATE;

  render(){
    return (
      <AppContext.Provider
        value={{
          ...this.state,
        }}
      >
        { this.props.children || [] }
      </AppContext.Provider>
    );
  }

}

