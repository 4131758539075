import React from "react";
import * as d3 from "d3";
import {
  withStyles,
  Link,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import { format, withContext } from "kn-react";
import {  RadioButtonUnchecked } from "@material-ui/icons";
import { HousingAndClimateContext } from 'HousingAndClimate/HousingAndClimateProvider';
import roundToNearest from "Lib/roundToNearest";


class Recap extends React.Component {
  render() {
    const { classes, otherPlaces, myPlace, totals, totalHeatingUnits } = this.props;
    const eph = this.props.myPlace;

    return (
      <>
         <List>
           <ListItem>
             <ListItemIcon>
               <RadioButtonUnchecked/>
              </ListItemIcon>
              <ListItemText>
                You are competing with at least {format.commas(otherPlaces.length - 1)} other companies for {format.commas((roundToNearest((totalHeatingUnits * 1) / 15, 500) + roundToNearest((totals.centralAcUnits * 1) / 15, 500)))} swap outs
              </ListItemText>
          </ListItem>

          <ListItem>
             <ListItemIcon>
               <RadioButtonUnchecked/>
              </ListItemIcon>
              <ListItemText>
                {
                  eph.userRatingsTotal ?
                    <>{myPlace?.name} has a review score of <strong>{eph.rating} ({eph.userRatingsTotal})</strong></>
                  :
                    <><strong>No reviews</strong> found for {myPlace?.name}.</>
                }

              </ListItemText>
          </ListItem>

          {

              eph.domain ?
                <ListItem>
                  <ListItemIcon>
                    <RadioButtonUnchecked/>
                  </ListItemIcon>
                  <ListItemText>
                    <Link className={classes.domain} href={eph.website} target="_blank">{eph.domain}</Link>{(eph.viewport && eph.contentWidth) ? " is" : "is not"} mobile friendly
                  </ListItemText>
                </ListItem>
            : null
          }
          {
            eph.domain ?
              <ListItem>
                <ListItemIcon>
                  <RadioButtonUnchecked/>
                </ListItemIcon>
                <ListItemText>
                  <Link className={classes.domain} href={eph.website} target="_blank">{eph.domain}</Link> {(eph.isOnHttps) ? "does" : "does not"} have an SSL certificate
                </ListItemText>
              </ListItem>

            : null
          }
          </List>

          <br />

          <div style={{ textAlign: 'center', marginTop: 40 }}>
            <Typography variant="h4">Considering updates to your website? Want to learn more about GoBoost?</Typography>

            <Button style={{ marginTop: 40 }} variant="contained" color="primary" component="a" size="large" href="https://www.goboost.com/#book-virtual-meeting" target="_blank">
              Book a Meeting
            </Button>
          </div>

          <br /><br />

          <Typography variant="body2" className="disclaimer" paragraph>
            Disclaimer: The free report was constructed using millions of data points and thousands of lines of code.
            The information contained within this report should be reviewed for accuracy prior to making business decisions.
         </Typography>

          <br />

      </>
    );
  }
}


const styles = (theme) => ({
  domain: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
});

export default withStyles(styles)(
  withContext(HousingAndClimateContext, Recap)
);
