import React from 'react';
import * as d3 from "d3";
import {
  Typography,
  Card,
  Grid,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { withContext, format } from 'kn-react';
import { HousingAndClimateContext } from 'HousingAndClimate/HousingAndClimateProvider';
import CompaniesMap from 'CompaniesMap/CompaniesMap';
import Section from './Section';
import AvatarHeader from 'AvatarHeader/AvatarHeader';
import { Business } from '@material-ui/icons';
import roundToNearest from 'Lib/roundToNearest';
import companyIcon from "Icons/company.svg";
import hvacIcon from "Icons/HVAC.svg";

const competition = props => {
  const ratio = format.commas(props.totals?.occupiedHousingUnits / props.reportPlaces.length);


  const reviewsTableData = props.otherPlaces.map((p) => ({
    placeId: p.placeId,
    name: p.name,
    rating: p.rating,
    userRatingsTotal: p.userRatingsTotal,
    userRatingsSum: p.userRatingsSum,
    website: p.website,
  }));

  reviewsTableData.sort((a, b) =>
    d3.descending(a.rating, b.rating)
  );

  return (
    <Section>
      <Grid container>

        <Grid container>
          <Typography variant="body1" paragraph>
            Here{"'"}s the competition
          </Typography>
        </Grid>

        <Grid container className="competition-info-container" alignItems="center">

          <Grid item className="info-block" xs={12} sm={5} style={{ padding: 25 }}>

            <Box style={{ marginBottom: "30px" }}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                  <Typography style={{ marginBottom: "0px", color: "#14142B" }} paragraph>
                    <strong>{props.reportPlaces.length - 1} Companies</strong>
                  </Typography>
                  <Typography variant="body2" paragraph>
                    in your service area
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                  <img style={iconCompetitionStyle} src={companyIcon} />
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={9}>
                  <Typography style={{ marginBottom: "0px" }} style={{ marginBottom: "0px", color: "#14142B" }} paragraph>
                    <strong>1:{ratio}</strong>
                  </Typography>
                  <Typography variant="body2" paragraph>
                    one HVAC company for every {ratio} homes
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                  <img style={iconCompetitionStyle} src={hvacIcon} />
                </Grid>
              </Grid>
            </Box>

          </Grid>

          <Grid item className="map-block" xs={12} sm={7} style={{ padding: 25 }}>

            {/* CHART */}
            <Card>
              <div style={{ position: "relative", width: "100%", height: 226 }}>
              {props.bounds ? (
                <CompaniesMap
                    bounds={props.bounds}
                    placeDetailHistories={props.reportPlaces}
                    onClick={(d) => console.log(d)}
                 />
              ) : null}
              </div>
            </Card>

          </Grid>
        </Grid>

        <Grid container>

          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <TableContainer style={{ maxHeight: 300 }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "50%" }}>Company</TableCell>
                    <TableCell style={{ width: "50%" }}>Website</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ maxHeight: 300, overflowY: "scroll" }}>
                  {reviewsTableData.map((p, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ width: "50%" }}>{p.name}</TableCell>
                      <TableCell style={{ width: "50%" }}><a href={p.website} target="_blank">{p.website}</a></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>

        </Grid>

      </Grid>

    </Section>
  );
}

export default withContext(HousingAndClimateContext, competition);

const iconCompetitionStyle = {
  height: 50,
  position: "relative",
};