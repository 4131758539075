import KnModel from "Lib/KnModel";

export default class EnrichedPlaceHistoryModel extends KnModel {
  constructor(props) {
    super(props);
    this.dominantColor = this.parseDominantColor();
  }

  parseDominantColor(){
    if(!this.dominantColor) {
      return null;
    }

    const color = JSON.parse(this.dominantColor);
    if (color === "null") {
      return null;
    }

    return (
      !color ?
        null :
        `rgb(${ color.slice(1, -1) })`
    )
  }


  get['hasScreenshot'](){
    return this.dominantColor && this.screenshotGcsUri;
  }

  get['userRatingsSum'](){
    return (this.userRatingsTotal || 0) * (this.rating || 0);
  }

  get['totalClicksPerMonth'](){
    return (this.organicClicksPerMonth || 0) + (this.paidClicksPerMonth || 0);
  }
}
