import history from 'history.js';
import { log } from 'kn-react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';



const DEFAULT_STATE = {};


export const TransitionContext = React.createContext(DEFAULT_STATE);


class TransitionProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.initialState();
  }

  initialState = () => {
    const { machineStates } = this.props;
    log('machineStates', machineStates);
    this.transitionTo('_first', { _first: machineStates[0].path })
  }

  transitionTo = (transitionKey, transitions, props = {}) => {
    const { machineStates } = this.props;

    props = { ...this.props, ...props };
    log('transition props',props)

    log('transitionTo', transitionKey, transitions, props);

    const transition = transitions[transitionKey];
    log('transition', transition);

    let routeData = this.convertToPath(
      typeof transition === 'function' ? transition(props) : transition
    );
    log('routeData', routeData);

    let nextState = machineStates.find(s => s.path === routeData.path);
    log('nextState', nextState);

    if (!nextState) {
      log('Next state', routeData.path, 'lies outside of machine. Redirecting...');
      return Promise.resolve( history.push(routeData.path) );
    }


    let guardRedirect = nextState.guard ? this.convertToPath( nextState.guard(props, transitionKey) ) : null;

    log('guardRedirect', guardRedirect);
    if (guardRedirect && (guardRedirect === 'continue' || guardRedirect.continue)) {
      const continueKey = guardRedirect.continue || transitionKey;
      const guardProps = guardRedirect.props || {};
      const mergedProps = { ...props, ...guardProps };
      return this.transitionTo(continueKey, nextState.transitions, mergedProps);
    } else if (guardRedirect) {
      routeData = guardRedirect;
    }

    const path = this.evaluateRouteData(routeData);
    log('Transitioning to', path);

    return Promise.resolve( history.push(path) );
  }


  convertToPath = route => {
    return (route && typeof route === 'string' && route !== 'continue') ? { path: route } : route;
  }


  evaluateRouteData = routeData => {
    log('evaluateRouteData', routeData);

    let path = routeData.path;

    const { params, query } = routeData;
    if (params) {
      Object.keys(params).forEach(k => {
        path = path.replace(new RegExp(`/:${k}`, 'g'), `/${params[k]}`);
      });
    }

    if (query) {
      const queries = Object.keys(query).map(k => `${k}=${encodeURIComponent(query[k])}`);
      path += `?${queries.join('&')}`;
    }

    return path;
  }


  render() {
    const { machineStates } = this.props;

    return (
      <TransitionContext.Provider
        value={{
          ...this.props,
          transitionTo: this.transitionTo,
        }}
      >
        <Switch>
          {
            machineStates.map(s => (
              <Route
                key={s.path}
                exact={s.exact}
                path={s.path}
                render={ routeProps => {
                const StateComponent = s.component;
                return <StateComponent {...({...this.props, ...routeProps})} transitions={s.transitions} />
                }}
              />
            ))
          }
        </Switch>
      </TransitionContext.Provider>
    );
  }
}


export default TransitionProvider;
