import React from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { Send } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { log, withContext } from "kn-react";
import formHelpers from "Lib/formHelpers";
import { ReportBuilderContext } from 'ReportBuilder/ReportBuilderProvider';
import segment from "segment";

class PayWall extends React.Component {
  state = {
    email: "",
    emailError: "",
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    didCreateReport: false,
  };

  onSubmit = (e) => {
    e.preventDefault();
    log("onSubmit");
    this.clearErrors();

    return this.validate()
      .then(() => {
        const { email, firstName, lastName } = this.state;
        segment.identify(email, { first_name: firstName, last_name: lastName });
        return this.props.createReport({ email, firstName, lastName });
      })
      .then((response) => {
        segment.track('created_report', { ...response.data.report });
        log("response", response);
        this.setState({ didCreateReport: true });
      })
      .catch((error) => {
        if (error.name === "ValidationError") {
          this.setState({ [error.validation.messageKey]: error.message });
        } else {
          console.log(error);
          if (error.response?.status === 500) {
            this.setState({ createError: true });
            return;
          }
          throw error;
        }
      });
  };

  clearErrors = () => {
    const newState = {};

    Object.keys(this.state).forEach((k) => {
      if (k.endsWith("Error")) {
        newState[k] = "";
      }
    });

    this.setState(newState);
  };

  validate = () => {
    const validations = {
      email: {
        messageKey: "emailError",
        email: { message: "Please enter a valid email address." },
      },
      firstName: {
        messageKey: "firstNameError",
        presence: { message: "Please enter your first name." },
      },
      lastName: {
        messageKey: "lastNameError",
        presence: { message: "Please enter your last name." },
      },
    };

    return formHelpers.validate(this.state, validations);
  };

  render() {
    const { didCreateReport } = this.state;

    return (
      <Dialog maxWidth="sm" fullWidth aria-labelledby="get-your-report" open>
        <form onSubmit={this.onSubmit}>
          {didCreateReport ? (
            <div
              style={{ textAlign: "center", marginTop: 50, marginBottom: 50 }}
            >
              <Typography variant="h2" paragraph>Sent!</Typography>
              <Typography variant="body2">
                Check your email for your report link.
              </Typography>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                component="a"
                href="https://www.goboost.com/report-sent"
                style={{ marginTop: 20, width: 150 }}
              >
                OK
              </Button>
            </div>
          ) : (
            <>
              <DialogContent>
                <Typography variant="h5" paragraph>
                  Report Complete. Where should we send it?
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="Email"
                      type="email"
                      variant="outlined"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      error={!!this.state.emailError}
                      helperText={
                        this.state.emailError ||
                        "Your report link will be emailed here."
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({ firstName: e.target.value })
                      }
                      error={!!this.state.firstNameError}
                      helperText={this.state.firstNameError || " "}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      onChange={(e) =>
                        this.setState({ lastName: e.target.value })
                      }
                      error={!!this.state.lastNameError}
                      helperText={this.state.lastNameError || " "}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {/* <Button
                  onClick={this.props.onClickClose}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button> */}
                <Button
                  type="submit"
                  onClick={this.onSubmit}
                  color="primary"
                  variant="contained"
                  disabled={didCreateReport}
                >
                  <Send style={{ marginRight: 5 }}/>
                  Send it
                </Button>
              </DialogActions>
            </>
          )}
        </form>
      </Dialog>
    );
  }
}

PayWall.defaultProps = {
  onClickClose: () => {},
};

export default withContext(ReportBuilderContext, PayWall);
