import React from 'react';
import { withContext, log } from 'kn-react';
import { Switch, Route } from 'react-router-dom';
import ReportBuilderProvider, { ReportBuilderContext } from './ReportBuilderProvider';
import SelectServiceArea from './Steps/SelectServiceArea/SelectServiceArea';
import FindYourCompany from './Steps/FindYourCompany/FindYourCompany';
import Processing from './Steps/Processing/Processing';
import ReportPreview from './Steps/ReportPreview/ReportPreview';
import history from 'history.js';
import ChooseYourCounty from './Steps/ChooseYourCounty/ChooseYourCounty';


export default class ReportBuilderProcess extends React.Component {
  componentDidMount = () => {
  }



  render() {
    return (
      <ReportBuilderProvider>
      <Switch>
        {
          machineStates.map((s,i) => {
            const StateComponent = withGuards(s.props?.guards || [], s.component);

            const WrappedComponent = props => {
              return <StateComponent {...{ ...props, ...( s.props || { }) }} />
            };

            const GuardedComponent = withContext(ReportBuilderContext, WrappedComponent);


            return (
              <Route
                exact
                key={i}
                path={s.path}
                component={GuardedComponent}
              />
            );
          })
        }
      </Switch>

    </ReportBuilderProvider>
    );
  }
}


const hasCounty = props => {
  return props.county ? null : machineStates[0].path;
}

const hasBounds = props => {
  return Object.keys(props.bounds).length ? null : machineStates[0].path;
}

const hasGeographies = props => {
  return props.includedGeographies.length ? null : machineStates[0].path;
}


const withGuards = (guards, Component) => {
  return props => {
    for (let i = 0; i < guards.length; i++) {
      const guardResult = guards[i](props);
      if (guardResult) {
        console.log(`Hit guard to ${guardResult}`)
        history.push(guardResult);
        return null;
      }
    }

    return <Component {...props} />
  }
};




export const machineStates = [
  {
    path: '/build/choose-your-county',
    component: ChooseYourCounty,
    props: {
      back: () => history.push('/'),
      next:  () => history.push(`/build/select-service-area`),
    }
  },
  {
    path: '/build/select-service-area',
    component: SelectServiceArea,
    props: {
      back: () => history.push('/build/choose-your-county'),
      next:  () => history.push('/build/find-your-company'),
      guards: [hasCounty]

    }
  },
  {
    path: '/build/find-your-company',
    component: FindYourCompany,
    props: {
      back:  () => history.push('/build/select-service-area'),
      next:  () => history.push('/build/processing'),
      guards: [hasCounty, hasGeographies]
    }
  },
  {
    path: '/build/processing',
    component: Processing,
    props: {
      back:  () => history.push('/build/find-your-company'),
      next:  () => history.push('/build/preview'),
    }
  },
  {
    path: '/build/preview',
    component: ReportPreview,
    props: {
      back:  () => history.push('/build/processing'),
    guards: [hasBounds]
    }
  }
];
