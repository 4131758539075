import React from "react";
import Axios from "axios";
import { log } from "kn-react";
import * as d3 from "d3";
import qs from 'qs';
import counties from 'ReportBuilder/Steps/ChooseYourCounty/counties';

import PlaceDetailHistoryModel from "Models/PlaceDetailHistoryModel";

const DEFAULT_STATE = {

  // county: 42001
  // bounds: {south_west_lat: 39.51039803578193, south_west_lng: -80.73852539062501, north_east_lat: 41.288126204331704, north_east_lng: -78.760986328125},
  // includedGeographies: ["county_42003", "county_42129"],

  county: null,
  bounds: {},
  includedGeographies: [],

  placeDetailHistories: [],
  selectedPlaceDetailHistory: null,
  enrichedPlaceHistory: null,
}

export const ReportBuilderContext = React.createContext(DEFAULT_STATE);


export default class ReportBuilderProvider extends React.Component {

  constructor(props){
    super(props);

    const params = qs.parse(window.location.search.slice(1));
    let county = counties.find(c => c.geoid == params.geoid) || null;
    let includedGeographies = county ? [`county_${params.geoid}`] : [];

    this.state = {
      ...DEFAULT_STATE,
      county,
      includedGeographies
    };
  }


  onSelectCounty = (county) => {
    log('onSelectCounty', county);
    this.setState({county, includedGeographies: [`county_${county.geoid}`]});
  }

  onChangeIncludedGeographies = includedGeographies => {
    this.setState({ includedGeographies });
    log("onChangeIncludedGeographies", includedGeographies);
  }

  onChangeBounds = bounds => {
    log('onChangeBounds', bounds);
    this.setState({ bounds });
  }


  getPlaceDetailHistories = () => {
    const { bounds } = this.state;

    return Axios.get("/api/place_detail_histories/in_bounds", {
      params: { ...bounds },
    }).then((response) => {
      log("response.data", response.data);
      const placeDetailHistories = response.data.place_detail_histories.map(
        (d) => PlaceDetailHistoryModel.fromJSON(d)
      );

      placeDetailHistories.sort((a, b) =>
        d3.descending(a.userRatingsTotal, b.userRatingsTotal)
      );

      this.setState({ placeDetailHistories });
    });
  };


  onSelectPlaceDetailHistory = ({selectedPlaceDetailHistory, enrichedPlaceHistory})=> {
    this.setState({
      selectedPlaceDetailHistory, enrichedPlaceHistory
    });
  }

  createReport = ({ email, firstName, lastName }) => {
    const { county, bounds, selectedPlaceDetailHistory, includedGeographies } = this.state;

    return Axios.post(
      '/api/reports',
      {
        county,
        bounds,
        place_id: selectedPlaceDetailHistory.placeId,
        included_geographies: includedGeographies,
        email: email.trim(),
        first_name: firstName.trim(),
        last_name: lastName.trim(),
      }
    );
  }


  render(){
    return (
      <ReportBuilderContext.Provider
        value={{
          ...this.state,
          onSelectCounty: this.onSelectCounty,
          onChangeBounds: this.onChangeBounds,
          onChangeIncludedGeographies: this.onChangeIncludedGeographies,
          getPlaceDetailHistories: this.getPlaceDetailHistories,
          onSelectPlaceDetailHistory: this.onSelectPlaceDetailHistory,
          createReport: this.createReport,
        }}
      >
        { this.props.children || [] }
      </ReportBuilderContext.Provider>
    );
  }

}

