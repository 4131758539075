import React from 'react';
import L from 'leaflet';
import * as d3 from 'd3';
import { Map as LeafletMap, TileLayer, CircleMarker, Tooltip } from 'react-leaflet';
import { log, format } from 'kn-react';

export default class ReviewsMap extends React.Component{

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();

    this.state = {
      placeDetailHistories: []
    }
  }

  componentDidMount = () => {
    this.getMap()?.on('zoomend', this.onChangeBounds);
    this.getMap()?.on('moveend', this.onChangeBounds);
    this.onChangeBounds();
  }




  onChangeBounds = () => {
    const bounds = this.getMap()?.getBounds();

    return this.props.onChangeBounds({
      south_west_lat: bounds._southWest.lat,
      south_west_lng: bounds._southWest.lng,
      north_east_lat: bounds._northEast.lat,
      north_east_lng: bounds._northEast.lng,
    });
  }

  getMap = () => {
    return this.mapRef.current?.leafletElement || null;
  }


  render(){
    const { bounds, placeDetailHistories } = this.props;

    const maxRatings = d3.max(placeDetailHistories, d => d.userRatingsTotal);
    this.radiusScale = d3.scaleSqrt().domain([0,maxRatings]).range([4, 15]);

    return (
      <LeafletMap
        tap={false}
        bounds={
          L.latLngBounds(
            L.latLng(bounds.south_west_lat, bounds.south_west_lng),
            L.latLng(bounds.north_east_lat, bounds.north_east_lng)
          )
        }
        style={{...defaultMapStyles, ...this.props.style }}
        ref={this.mapRef}>
          <TileLayer
            {...mapStyle}
            style={{ opacity: 0.9 }}
          />
          {
            placeDetailHistories.filter(p => (
              p.lat && p.lng
            )).map((c,i) => (
                  <CircleMarker
                    key={`CircleCenter-${i}`}
                    center={[c.lat, c.lng]}
                    radius={c.userRatingsTotal ? this.radiusScale(c.userRatingsTotal) : 4}
                    opacity={0.85}
                    weight={2}
                    color={c.rating ? ratingColors[ Math.round(c.rating) ] : '#777' }
                    fillOpacity={0.4}
                    fillColor={ c.rating ? ratingColors[ Math.round(c.rating) ] : '#777' }
                    onClick={() => this.props.onClick(c)}
                  >
                    <Tooltip>
                      <b>{ c.name }</b><br/>
                      { c.rating ? format.commas(c.rating, { decimals: 2 }) + ' Average Rating' : 'No Ratings' }
                      {
                        c.userRatingsTotal ?
                          <>
                            <br/>
                            { format.commas(c.userRatingsTotal || 0) } Total Ratings
                          </>
                          :
                        ''
                      }
                    </Tooltip>
                  </CircleMarker>
            ))
          }
      </LeafletMap>
    );
  }

}


ReviewsMap.defaultProps = {
  onChangeBounds: () => {},
  onClick: () => {},
}



export const ratingColors = {
  5: '#7dc8a2',
  4: '#afd889',
  3: '#ffd855',
  2: '#ffb24f',
  1: '#ff8c5f',
};



const mapStyle = {
  url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}{r}.png',
  attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http/://cartodb.com/attributions">CartoDB</a>',
  maxZoom: 19,
  subdomains: 'abcd',
};

const defaultMapStyles = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};