import React from "react";
import { Grid, Paper, Typography, withStyles } from "@material-ui/core";
import { log } from 'kn-react';



class Layout extends React.Component {

  render() {
    const {classes} = this.props;
    return (

      <div className={classes.root} style={{ top: 64 }}>
        {/* <Grid container spacing={0}> */}
          {/* <Grid item xs={12} sm={12} md={6}> */}
            <div className={classes.left}>
              { this.props.left }
            </div>
          {/* </Grid> */}
          {/* <Grid item xs={12} sm={12} md={6}> */}
            <div className={classes.right}>
              { this.props.right }
            </div>
          {/* </Grid> */}
        {/* </Grid> */}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("md")]: {
      flexDirection: 'row',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  left: {
    position: 'relative',
    height: 450,
    [theme.breakpoints.up("md")]: {
      flex: 1,
      height: 'calc(100vh - 64px)',
    }
  },
  right: {
    flex: 1,
    position: 'relative',
    [theme.breakpoints.up("md")]: {
      height: 'calc(100vh - 64px)',
      overflowY: 'scroll'
    }
  }
  // formWrapper: {
  //   textAlign: "center",
  //   padding: 15,
  //   maxWidth: 450,
  //   marginLeft: "auto",
  //   marginRight: "auto",
  // },
  // left: {
  //   position: "relative",
  //   [theme.breakpoints.down("md")]: {
  //     minHeight: 400,
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     minHeight: 650,
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     minHeight: 850,
  //   },
  //   [theme.breakpoints.up("xl")]: {
  //     minHeight: 1150,
  //   },
  // }
});

export default withStyles(styles)(Layout);
