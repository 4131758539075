export default [
	{
		geoid: "31039",
		name: "Cuming",
		state: "Nebraska",
		countyState: "Cuming County, Nebraska",
		centroidLng: -96.7874000,
		centroidLat: 41.9163000
	},
	{
		geoid: "53069",
		name: "Wahkiakum",
		state: "Washington",
		countyState: "Wahkiakum County, Washington",
		centroidLng: -123.4710000,
		centroidLat: 46.2651000
	},
	{
		geoid: "35011",
		name: "De Baca",
		state: "New Mexico",
		countyState: "De Baca County, New Mexico",
		centroidLng: -104.4190000,
		centroidLat: 34.3875000
	},
	{
		geoid: "31109",
		name: "Lancaster",
		state: "Nebraska",
		countyState: "Lancaster County, Nebraska",
		centroidLng: -96.6888000,
		centroidLat: 40.7846000
	},
	{
		geoid: "31129",
		name: "Nuckolls",
		state: "Nebraska",
		countyState: "Nuckolls County, Nebraska",
		centroidLng: -98.0473000,
		centroidLat: 40.1764000
	},
	{
		geoid: "72085",
		name: "Las Piedras",
		state: "Puerto Rico",
		countyState: "Las Piedras County, Puerto Rico",
		centroidLng: -65.8720000,
		centroidLat: 18.1965000
	},
	{
		geoid: "46099",
		name: "Minnehaha",
		state: "South Dakota",
		countyState: "Minnehaha County, South Dakota",
		centroidLng: -96.7912000,
		centroidLat: 43.6746000
	},
	{
		geoid: "48327",
		name: "Menard",
		state: "Texas",
		countyState: "Menard County, Texas",
		centroidLng: -99.8002000,
		centroidLat: 30.8992000
	},
	{
		geoid: "06091",
		name: "Sierra",
		state: "California",
		countyState: "Sierra County, California",
		centroidLng: -120.5300000,
		centroidLat: 39.5842000
	},
	{
		geoid: "21053",
		name: "Clinton",
		state: "Kentucky",
		countyState: "Clinton County, Kentucky",
		centroidLng: -85.1354000,
		centroidLat: 36.7517000
	},
	{
		geoid: "39063",
		name: "Hancock",
		state: "Ohio",
		countyState: "Hancock County, Ohio",
		centroidLng: -83.6507000,
		centroidLat: 40.9933000
	},
	{
		geoid: "48189",
		name: "Hale",
		state: "Texas",
		countyState: "Hale County, Texas",
		centroidLng: -101.8270000,
		centroidLat: 34.0689000
	},
	{
		geoid: "01027",
		name: "Clay",
		state: "Alabama",
		countyState: "Clay County, Alabama",
		centroidLng: -85.9078000,
		centroidLat: 33.2946000
	},
	{
		geoid: "48011",
		name: "Armstrong",
		state: "Texas",
		countyState: "Armstrong County, Texas",
		centroidLng: -101.3580000,
		centroidLat: 34.9653000
	},
	{
		geoid: "39003",
		name: "Allen",
		state: "Ohio",
		countyState: "Allen County, Ohio",
		centroidLng: -84.1386000,
		centroidLat: 40.7817000
	},
	{
		geoid: "13189",
		name: "McDuffie",
		state: "Georgia",
		countyState: "McDuffie County, Georgia",
		centroidLng: -82.4719000,
		centroidLat: 33.4854000
	},
	{
		geoid: "55111",
		name: "Sauk",
		state: "Wisconsin",
		countyState: "Sauk County, Wisconsin",
		centroidLng: -89.9559000,
		centroidLat: 43.3938000
	},
	{
		geoid: "05137",
		name: "Stone",
		state: "Arkansas",
		countyState: "Stone County, Arkansas",
		centroidLng: -92.1272000,
		centroidLat: 35.9189000
	},
	{
		geoid: "41063",
		name: "Wallowa",
		state: "Oregon",
		countyState: "Wallowa County, Oregon",
		centroidLng: -117.2210000,
		centroidLat: 45.5393000
	},
	{
		geoid: "42007",
		name: "Beaver",
		state: "Pennsylvania",
		countyState: "Beaver County, Pennsylvania",
		centroidLng: -80.3320000,
		centroidLat: 40.6662000
	},
	{
		geoid: "28061",
		name: "Jasper",
		state: "Mississippi",
		countyState: "Jasper County, Mississippi",
		centroidLng: -89.1166000,
		centroidLat: 32.0132000
	},
	{
		geoid: "29083",
		name: "Henry",
		state: "Missouri",
		countyState: "Henry County, Missouri",
		centroidLng: -93.7931000,
		centroidLat: 38.3842000
	},
	{
		geoid: "08109",
		name: "Saguache",
		state: "Colorado",
		countyState: "Saguache County, Colorado",
		centroidLng: -106.2150000,
		centroidLat: 38.1030000
	},
	{
		geoid: "37037",
		name: "Chatham",
		state: "North Carolina",
		countyState: "Chatham County, North Carolina",
		centroidLng: -79.2309000,
		centroidLat: 35.6959000
	},
	{
		geoid: "49033",
		name: "Rich",
		state: "Utah",
		countyState: "Rich County, Utah",
		centroidLng: -111.2790000,
		centroidLat: 41.5714000
	},
	{
		geoid: "40001",
		name: "Adair",
		state: "Oklahoma",
		countyState: "Adair County, Oklahoma",
		centroidLng: -94.6402000,
		centroidLat: 35.9001000
	},
	{
		geoid: "39085",
		name: "Lake",
		state: "Ohio",
		countyState: "Lake County, Ohio",
		centroidLng: -81.2486000,
		centroidLat: 41.9106000
	},
	{
		geoid: "51775",
		name: "Salem",
		state: "Virginia",
		countyState: "Salem County, Virginia",
		centroidLng: -80.0669000,
		centroidLat: 37.2882000
	},
	{
		geoid: "51121",
		name: "Montgomery",
		state: "Virginia",
		countyState: "Montgomery County, Virginia",
		centroidLng: -80.3968000,
		centroidLat: 37.1752000
	},
	{
		geoid: "47065",
		name: "Hamilton",
		state: "Tennessee",
		countyState: "Hamilton County, Tennessee",
		centroidLng: -85.2090000,
		centroidLat: 35.2211000
	},
	{
		geoid: "35035",
		name: "Otero",
		state: "New Mexico",
		countyState: "Otero County, New Mexico",
		centroidLng: -105.6130000,
		centroidLat: 32.6955000
	},
	{
		geoid: "35003",
		name: "Catron",
		state: "New Mexico",
		countyState: "Catron County, New Mexico",
		centroidLng: -108.3790000,
		centroidLat: 33.8906000
	},
	{
		geoid: "01091",
		name: "Marengo",
		state: "Alabama",
		countyState: "Marengo County, Alabama",
		centroidLng: -87.7953000,
		centroidLat: 32.2686000
	},
	{
		geoid: "56023",
		name: "Lincoln",
		state: "Wyoming",
		countyState: "Lincoln County, Wyoming",
		centroidLng: -110.5470000,
		centroidLat: 42.4468000
	},
	{
		geoid: "29011",
		name: "Barton",
		state: "Missouri",
		countyState: "Barton County, Missouri",
		centroidLng: -94.3456000,
		centroidLat: 37.5014000
	},
	{
		geoid: "48057",
		name: "Calhoun",
		state: "Texas",
		countyState: "Calhoun County, Texas",
		centroidLng: -96.6265000,
		centroidLat: 28.3766000
	},
	{
		geoid: "51735",
		name: "Poquoson",
		state: "Virginia",
		countyState: "Poquoson County, Virginia",
		centroidLng: -76.2743000,
		centroidLat: 37.1473000
	},
	{
		geoid: "54075",
		name: "Pocahontas",
		state: "West Virginia",
		countyState: "Pocahontas County, West Virginia",
		centroidLng: -79.9906000,
		centroidLat: 38.3880000
	},
	{
		geoid: "48077",
		name: "Clay",
		state: "Texas",
		countyState: "Clay County, Texas",
		centroidLng: -98.1848000,
		centroidLat: 33.8117000
	},
	{
		geoid: "51095",
		name: "James City",
		state: "Virginia",
		countyState: "James City County, Virginia",
		centroidLng: -76.7469000,
		centroidLat: 37.3105000
	},
	{
		geoid: "31101",
		name: "Keith",
		state: "Nebraska",
		countyState: "Keith County, Nebraska",
		centroidLng: -101.6530000,
		centroidLat: 41.1991000
	},
	{
		geoid: "31137",
		name: "Phelps",
		state: "Nebraska",
		countyState: "Phelps County, Nebraska",
		centroidLng: -99.4118000,
		centroidLat: 40.5183000
	},
	{
		geoid: "22019",
		name: "Calcasieu",
		state: "Louisiana",
		countyState: "Calcasieu Parish, Louisiana",
		centroidLng: -93.3265000,
		centroidLat: 30.2643000
	},
	{
		geoid: "22111",
		name: "Union",
		state: "Louisiana",
		countyState: "Union Parish, Louisiana",
		centroidLng: -92.3931000,
		centroidLat: 32.7977000
	},
	{
		geoid: "40135",
		name: "Sequoyah",
		state: "Oklahoma",
		countyState: "Sequoyah County, Oklahoma",
		centroidLng: -94.7818000,
		centroidLat: 35.4651000
	},
	{
		geoid: "01049",
		name: "DeKalb",
		state: "Alabama",
		countyState: "DeKalb County, Alabama",
		centroidLng: -85.8163000,
		centroidLat: 34.5298000
	},
	{
		geoid: "36101",
		name: "Steuben",
		state: "New York",
		countyState: "Steuben County, New York",
		centroidLng: -77.3575000,
		centroidLat: 42.2895000
	},
	{
		geoid: "42053",
		name: "Forest",
		state: "Pennsylvania",
		countyState: "Forest County, Pennsylvania",
		centroidLng: -79.2390000,
		centroidLat: 41.4755000
	},
	{
		geoid: "22105",
		name: "Tangipahoa",
		state: "Louisiana",
		countyState: "Tangipahoa Parish, Louisiana",
		centroidLng: -90.3641000,
		centroidLat: 30.6174000
	},
	{
		geoid: "51179",
		name: "Stafford",
		state: "Virginia",
		countyState: "Stafford County, Virginia",
		centroidLng: -77.4612000,
		centroidLat: 38.4174000
	},
	{
		geoid: "26109",
		name: "Menominee",
		state: "Michigan",
		countyState: "Menominee County, Michigan",
		centroidLng: -87.5080000,
		centroidLat: 45.5312000
	},
	{
		geoid: "18073",
		name: "Jasper",
		state: "Indiana",
		countyState: "Jasper County, Indiana",
		centroidLng: -87.1031000,
		centroidLat: 41.0115000
	},
	{
		geoid: "13025",
		name: "Brantley",
		state: "Georgia",
		countyState: "Brantley County, Georgia",
		centroidLng: -82.0081000,
		centroidLat: 31.1921000
	},
	{
		geoid: "13171",
		name: "Lamar",
		state: "Georgia",
		countyState: "Lamar County, Georgia",
		centroidLng: -84.1555000,
		centroidLat: 33.0672000
	},
	{
		geoid: "18151",
		name: "Steuben",
		state: "Indiana",
		countyState: "Steuben County, Indiana",
		centroidLng: -85.0007000,
		centroidLat: 41.6433000
	},
	{
		geoid: "48361",
		name: "Orange",
		state: "Texas",
		countyState: "Orange County, Texas",
		centroidLng: -93.9031000,
		centroidLat: 30.0547000
	},
	{
		geoid: "39047",
		name: "Fayette",
		state: "Ohio",
		countyState: "Fayette County, Ohio",
		centroidLng: -83.4613000,
		centroidLat: 39.5471000
	},
	{
		geoid: "25015",
		name: "Hampshire",
		state: "Massachusetts",
		countyState: "Hampshire County, Massachusetts",
		centroidLng: -72.6359000,
		centroidLat: 42.3697000
	},
	{
		geoid: "21003",
		name: "Allen",
		state: "Kentucky",
		countyState: "Allen County, Kentucky",
		centroidLng: -86.1841000,
		centroidLat: 36.7821000
	},
	{
		geoid: "37001",
		name: "Alamance",
		state: "North Carolina",
		countyState: "Alamance County, North Carolina",
		centroidLng: -79.3895000,
		centroidLat: 36.0465000
	},
	{
		geoid: "20073",
		name: "Greenwood",
		state: "Kansas",
		countyState: "Greenwood County, Kansas",
		centroidLng: -96.2421000,
		centroidLat: 37.8883000
	},
	{
		geoid: "25025",
		name: "Suffolk",
		state: "Massachusetts",
		countyState: "Suffolk County, Massachusetts",
		centroidLng: -70.9196000,
		centroidLat: 42.3390000
	},
	{
		geoid: "16051",
		name: "Jefferson",
		state: "Idaho",
		countyState: "Jefferson County, Idaho",
		centroidLng: -112.1620000,
		centroidLat: 43.8408000
	},
	{
		geoid: "48177",
		name: "Gonzales",
		state: "Texas",
		countyState: "Gonzales County, Texas",
		centroidLng: -97.4947000,
		centroidLat: 29.4472000
	},
	{
		geoid: "47115",
		name: "Marion",
		state: "Tennessee",
		countyState: "Marion County, Tennessee",
		centroidLng: -85.6175000,
		centroidLat: 35.1512000
	},
	{
		geoid: "26007",
		name: "Alpena",
		state: "Michigan",
		countyState: "Alpena County, Michigan",
		centroidLng: -83.1496000,
		centroidLat: 45.0342000
	},
	{
		geoid: "21231",
		name: "Wayne",
		state: "Kentucky",
		countyState: "Wayne County, Kentucky",
		centroidLng: -84.8184000,
		centroidLat: 36.8004000
	},
	{
		geoid: "47185",
		name: "White",
		state: "Tennessee",
		countyState: "White County, Tennessee",
		centroidLng: -85.4480000,
		centroidLat: 35.9363000
	},
	{
		geoid: "48147",
		name: "Fannin",
		state: "Texas",
		countyState: "Fannin County, Texas",
		centroidLng: -96.1156000,
		centroidLat: 33.6132000
	},
	{
		geoid: "20043",
		name: "Doniphan",
		state: "Kansas",
		countyState: "Doniphan County, Kansas",
		centroidLng: -95.0995000,
		centroidLat: 39.8075000
	},
	{
		geoid: "48265",
		name: "Kerr",
		state: "Texas",
		countyState: "Kerr County, Texas",
		centroidLng: -99.3377000,
		centroidLat: 30.0360000
	},
	{
		geoid: "12053",
		name: "Hernando",
		state: "Florida",
		countyState: "Hernando County, Florida",
		centroidLng: -82.4165000,
		centroidLat: 28.5641000
	},
	{
		geoid: "12129",
		name: "Wakulla",
		state: "Florida",
		countyState: "Wakulla County, Florida",
		centroidLng: -84.4066000,
		centroidLat: 30.1308000
	},
	{
		geoid: "12131",
		name: "Walton",
		state: "Florida",
		countyState: "Walton County, Florida",
		centroidLng: -86.1210000,
		centroidLat: 30.6038000
	},
	{
		geoid: "28017",
		name: "Chickasaw",
		state: "Mississippi",
		countyState: "Chickasaw County, Mississippi",
		centroidLng: -88.9553000,
		centroidLat: 33.9068000
	},
	{
		geoid: "02130",
		name: "Ketchikan Gateway",
		state: "Alaska",
		countyState: "Ketchikan Gateway Borough, Alaska",
		centroidLng: -131.1250000,
		centroidLat: 55.4359000
	},
	{
		geoid: "26035",
		name: "Clare",
		state: "Michigan",
		countyState: "Clare County, Michigan",
		centroidLng: -84.8474000,
		centroidLat: 43.9890000
	},
	{
		geoid: "20157",
		name: "Republic",
		state: "Kansas",
		countyState: "Republic County, Kansas",
		centroidLng: -97.6510000,
		centroidLat: 39.8278000
	},
	{
		geoid: "12127",
		name: "Volusia",
		state: "Florida",
		countyState: "Volusia County, Florida",
		centroidLng: -81.1722000,
		centroidLat: 29.0226000
	},
	{
		geoid: "40017",
		name: "Canadian",
		state: "Oklahoma",
		countyState: "Canadian County, Oklahoma",
		centroidLng: -97.9923000,
		centroidLat: 35.5249000
	},
	{
		geoid: "72115",
		name: "Quebradillas",
		state: "Puerto Rico",
		countyState: "Quebradillas County, Puerto Rico",
		centroidLng: -66.9337000,
		centroidLat: 18.4530000
	},
	{
		geoid: "78030",
		name: "St. Thomas",
		state: "United States Virgin Islands",
		countyState: "St. Thomas County, United States Virgin Islands",
		centroidLng: -64.9612000,
		centroidLat: 18.3223000
	},
	{
		geoid: "13115",
		name: "Floyd",
		state: "Georgia",
		countyState: "Floyd County, Georgia",
		centroidLng: -85.2337000,
		centroidLat: 34.3333000
	},
	{
		geoid: "32001",
		name: "Churchill",
		state: "Nevada",
		countyState: "Churchill County, Nevada",
		centroidLng: -118.3440000,
		centroidLat: 39.5380000
	},
	{
		geoid: "31181",
		name: "Webster",
		state: "Nebraska",
		countyState: "Webster County, Nebraska",
		centroidLng: -98.5001000,
		centroidLat: 40.1764000
	},
	{
		geoid: "17067",
		name: "Hancock",
		state: "Illinois",
		countyState: "Hancock County, Illinois",
		centroidLng: -91.2057000,
		centroidLat: 40.4161000
	},
	{
		geoid: "46067",
		name: "Hutchinson",
		state: "South Dakota",
		countyState: "Hutchinson County, South Dakota",
		centroidLng: -97.7570000,
		centroidLat: 43.3341000
	},
	{
		geoid: "41013",
		name: "Crook",
		state: "Oregon",
		countyState: "Crook County, Oregon",
		centroidLng: -120.3820000,
		centroidLat: 44.1307000
	},
	{
		geoid: "50011",
		name: "Franklin",
		state: "Vermont",
		countyState: "Franklin County, Vermont",
		centroidLng: -72.8959000,
		centroidLat: 44.8249000
	},
	{
		geoid: "26103",
		name: "Marquette",
		state: "Michigan",
		countyState: "Marquette County, Michigan",
		centroidLng: -87.6148000,
		centroidLat: 46.6042000
	},
	{
		geoid: "09007",
		name: "Middlesex",
		state: "Connecticut",
		countyState: "Middlesex County, Connecticut",
		centroidLng: -72.5292000,
		centroidLat: 41.4124000
	},
	{
		geoid: "37057",
		name: "Davidson",
		state: "North Carolina",
		countyState: "Davidson County, North Carolina",
		centroidLng: -80.2654000,
		centroidLat: 35.7655000
	},
	{
		geoid: "17025",
		name: "Clay",
		state: "Illinois",
		countyState: "Clay County, Illinois",
		centroidLng: -88.4736000,
		centroidLat: 38.7573000
	},
	{
		geoid: "29103",
		name: "Knox",
		state: "Missouri",
		countyState: "Knox County, Missouri",
		centroidLng: -92.1483000,
		centroidLat: 40.1268000
	},
	{
		geoid: "29117",
		name: "Livingston",
		state: "Missouri",
		countyState: "Livingston County, Missouri",
		centroidLng: -93.5112000,
		centroidLat: 39.7895000
	},
	{
		geoid: "42117",
		name: "Tioga",
		state: "Pennsylvania",
		countyState: "Tioga County, Pennsylvania",
		centroidLng: -77.2424000,
		centroidLat: 41.7720000
	},
	{
		geoid: "31043",
		name: "Dakota",
		state: "Nebraska",
		countyState: "Dakota County, Nebraska",
		centroidLng: -96.5415000,
		centroidLat: 42.4005000
	},
	{
		geoid: "26089",
		name: "Leelanau",
		state: "Michigan",
		countyState: "Leelanau County, Michigan",
		centroidLng: -86.0965000,
		centroidLat: 45.1788000
	},
	{
		geoid: "51181",
		name: "Surry",
		state: "Virginia",
		countyState: "Surry County, Virginia",
		centroidLng: -76.8988000,
		centroidLat: 37.0965000
	},
	{
		geoid: "48391",
		name: "Refugio",
		state: "Texas",
		countyState: "Refugio County, Texas",
		centroidLng: -97.1526000,
		centroidLat: 28.3081000
	},
	{
		geoid: "39017",
		name: "Butler",
		state: "Ohio",
		countyState: "Butler County, Ohio",
		centroidLng: -84.5757000,
		centroidLat: 39.4417000
	},
	{
		geoid: "13273",
		name: "Terrell",
		state: "Georgia",
		countyState: "Terrell County, Georgia",
		centroidLng: -84.4307000,
		centroidLat: 31.7942000
	},
	{
		geoid: "13063",
		name: "Clayton",
		state: "Georgia",
		countyState: "Clayton County, Georgia",
		centroidLng: -84.3514000,
		centroidLat: 33.5007000
	},
	{
		geoid: "56013",
		name: "Fremont",
		state: "Wyoming",
		countyState: "Fremont County, Wyoming",
		centroidLng: -108.7780000,
		centroidLat: 43.1353000
	},
	{
		geoid: "18115",
		name: "Ohio",
		state: "Indiana",
		countyState: "Ohio County, Indiana",
		centroidLng: -84.9851000,
		centroidLat: 38.9660000
	},
	{
		geoid: "13073",
		name: "Columbia",
		state: "Georgia",
		countyState: "Columbia County, Georgia",
		centroidLng: -82.2355000,
		centroidLat: 33.5276000
	},
	{
		geoid: "08115",
		name: "Sedgwick",
		state: "Colorado",
		countyState: "Sedgwick County, Colorado",
		centroidLng: -102.3520000,
		centroidLat: 40.8757000
	},
	{
		geoid: "30065",
		name: "Musselshell",
		state: "Montana",
		countyState: "Musselshell County, Montana",
		centroidLng: -108.3960000,
		centroidLat: 46.4442000
	},
	{
		geoid: "42043",
		name: "Dauphin",
		state: "Pennsylvania",
		countyState: "Dauphin County, Pennsylvania",
		centroidLng: -76.7824000,
		centroidLat: 40.3898000
	},
	{
		geoid: "46101",
		name: "Moody",
		state: "South Dakota",
		countyState: "Moody County, South Dakota",
		centroidLng: -96.6710000,
		centroidLat: 44.0221000
	},
	{
		geoid: "54079",
		name: "Putnam",
		state: "West Virginia",
		countyState: "Putnam County, West Virginia",
		centroidLng: -81.8805000,
		centroidLat: 38.4754000
	},
	{
		geoid: "34037",
		name: "Sussex",
		state: "New Jersey",
		countyState: "Sussex County, New Jersey",
		centroidLng: -74.6798000,
		centroidLat: 41.1283000
	},
	{
		geoid: "20039",
		name: "Decatur",
		state: "Kansas",
		countyState: "Decatur County, Kansas",
		centroidLng: -100.4590000,
		centroidLat: 39.7846000
	},
	{
		geoid: "18123",
		name: "Perry",
		state: "Indiana",
		countyState: "Perry County, Indiana",
		centroidLng: -86.6225000,
		centroidLat: 38.0539000
	},
	{
		geoid: "28145",
		name: "Union",
		state: "Mississippi",
		countyState: "Union County, Mississippi",
		centroidLng: -88.9909000,
		centroidLat: 34.4818000
	},
	{
		geoid: "31015",
		name: "Boyd",
		state: "Nebraska",
		countyState: "Boyd County, Nebraska",
		centroidLng: -98.7813000,
		centroidLat: 42.8799000
	},
	{
		geoid: "27095",
		name: "Mille Lacs",
		state: "Minnesota",
		countyState: "Mille Lacs County, Minnesota",
		centroidLng: -93.6203000,
		centroidLat: 45.9030000
	},
	{
		geoid: "38099",
		name: "Walsh",
		state: "North Dakota",
		countyState: "Walsh County, North Dakota",
		centroidLng: -97.7154000,
		centroidLat: 48.3687000
	},
	{
		geoid: "39115",
		name: "Morgan",
		state: "Ohio",
		countyState: "Morgan County, Ohio",
		centroidLng: -81.8332000,
		centroidLat: 39.6101000
	},
	{
		geoid: "39133",
		name: "Portage",
		state: "Ohio",
		countyState: "Portage County, Ohio",
		centroidLng: -81.1975000,
		centroidLat: 41.1680000
	},
	{
		geoid: "56041",
		name: "Uinta",
		state: "Wyoming",
		countyState: "Uinta County, Wyoming",
		centroidLng: -110.5470000,
		centroidLat: 41.2873000
	},
	{
		geoid: "32013",
		name: "Humboldt",
		state: "Nevada",
		countyState: "Humboldt County, Nevada",
		centroidLng: -118.1750000,
		centroidLat: 41.2633000
	},
	{
		geoid: "48247",
		name: "Jim Hogg",
		state: "Texas",
		countyState: "Jim Hogg County, Texas",
		centroidLng: -98.6863000,
		centroidLat: 27.0713000
	},
	{
		geoid: "08017",
		name: "Cheyenne",
		state: "Colorado",
		countyState: "Cheyenne County, Colorado",
		centroidLng: -102.6090000,
		centroidLat: 38.8298000
	},
	{
		geoid: "41005",
		name: "Clackamas",
		state: "Oregon",
		countyState: "Clackamas County, Oregon",
		centroidLng: -122.2600000,
		centroidLat: 45.1737000
	},
	{
		geoid: "05113",
		name: "Polk",
		state: "Arkansas",
		countyState: "Polk County, Arkansas",
		centroidLng: -94.2002000,
		centroidLat: 34.4594000
	},
	{
		geoid: "13193",
		name: "Macon",
		state: "Georgia",
		countyState: "Macon County, Georgia",
		centroidLng: -84.0269000,
		centroidLat: 32.5256000
	},
	{
		geoid: "18007",
		name: "Benton",
		state: "Indiana",
		countyState: "Benton County, Indiana",
		centroidLng: -87.3101000,
		centroidLat: 40.6063000
	},
	{
		geoid: "47129",
		name: "Morgan",
		state: "Tennessee",
		countyState: "Morgan County, Tennessee",
		centroidLng: -84.6280000,
		centroidLat: 36.1425000
	},
	{
		geoid: "46043",
		name: "Douglas",
		state: "South Dakota",
		countyState: "Douglas County, South Dakota",
		centroidLng: -98.4084000,
		centroidLat: 43.3481000
	},
	{
		geoid: "24047",
		name: "Worcester",
		state: "Maryland",
		countyState: "Worcester County, Maryland",
		centroidLng: -75.3241000,
		centroidLat: 38.2231000
	},
	{
		geoid: "17185",
		name: "Wabash",
		state: "Illinois",
		countyState: "Wabash County, Illinois",
		centroidLng: -87.8187000,
		centroidLat: 38.4032000
	},
	{
		geoid: "18069",
		name: "Huntington",
		state: "Indiana",
		countyState: "Huntington County, Indiana",
		centroidLng: -85.4892000,
		centroidLat: 40.8292000
	},
	{
		geoid: "21209",
		name: "Scott",
		state: "Kentucky",
		countyState: "Scott County, Kentucky",
		centroidLng: -84.5712000,
		centroidLat: 38.3018000
	},
	{
		geoid: "19053",
		name: "Decatur",
		state: "Iowa",
		countyState: "Decatur County, Iowa",
		centroidLng: -93.7858000,
		centroidLat: 40.7366000
	},
	{
		geoid: "18169",
		name: "Wabash",
		state: "Indiana",
		countyState: "Wabash County, Indiana",
		centroidLng: -85.7925000,
		centroidLat: 40.8489000
	},
	{
		geoid: "18003",
		name: "Allen",
		state: "Indiana",
		countyState: "Allen County, Indiana",
		centroidLng: -85.0707000,
		centroidLat: 41.0939000
	},
	{
		geoid: "18099",
		name: "Marshall",
		state: "Indiana",
		countyState: "Marshall County, Indiana",
		centroidLng: -86.2606000,
		centroidLat: 41.3254000
	},
	{
		geoid: "31171",
		name: "Thomas",
		state: "Nebraska",
		countyState: "Thomas County, Nebraska",
		centroidLng: -100.5560000,
		centroidLat: 41.9138000
	},
	{
		geoid: "38085",
		name: "Sioux",
		state: "North Dakota",
		countyState: "Sioux County, North Dakota",
		centroidLng: -101.2530000,
		centroidLat: 46.1864000
	},
	{
		geoid: "48127",
		name: "Dimmit",
		state: "Texas",
		countyState: "Dimmit County, Texas",
		centroidLng: -99.7543000,
		centroidLat: 28.4230000
	},
	{
		geoid: "27045",
		name: "Fillmore",
		state: "Minnesota",
		countyState: "Fillmore County, Minnesota",
		centroidLng: -92.0899000,
		centroidLat: 43.6744000
	},
	{
		geoid: "45023",
		name: "Chester",
		state: "South Carolina",
		countyState: "Chester County, South Carolina",
		centroidLng: -81.1739000,
		centroidLat: 34.6827000
	},
	{
		geoid: "27073",
		name: "Lac qui Parle",
		state: "Minnesota",
		countyState: "Lac qui Parle County, Minnesota",
		centroidLng: -96.0948000,
		centroidLat: 45.0370000
	},
	{
		geoid: "49005",
		name: "Cache",
		state: "Utah",
		countyState: "Cache County, Utah",
		centroidLng: -111.7840000,
		centroidLat: 41.6842000
	},
	{
		geoid: "55093",
		name: "Pierce",
		state: "Wisconsin",
		countyState: "Pierce County, Wisconsin",
		centroidLng: -92.4716000,
		centroidLat: 44.7011000
	},
	{
		geoid: "13049",
		name: "Charlton",
		state: "Georgia",
		countyState: "Charlton County, Georgia",
		centroidLng: -82.1562000,
		centroidLat: 30.7158000
	},
	{
		geoid: "13233",
		name: "Polk",
		state: "Georgia",
		countyState: "Polk County, Georgia",
		centroidLng: -85.1723000,
		centroidLat: 33.9999000
	},
	{
		geoid: "17113",
		name: "McLean",
		state: "Illinois",
		countyState: "McLean County, Illinois",
		centroidLng: -88.8643000,
		centroidLat: 40.5193000
	},
	{
		geoid: "23001",
		name: "Androscoggin",
		state: "Maine",
		countyState: "Androscoggin County, Maine",
		centroidLng: -70.2395000,
		centroidLat: 44.1970000
	},
	{
		geoid: "16055",
		name: "Kootenai",
		state: "Idaho",
		countyState: "Kootenai County, Idaho",
		centroidLng: -116.6860000,
		centroidLat: 47.6788000
	},
	{
		geoid: "32003",
		name: "Clark",
		state: "Nevada",
		countyState: "Clark County, Nevada",
		centroidLng: -114.9700000,
		centroidLat: 35.9278000
	},
	{
		geoid: "20147",
		name: "Phillips",
		state: "Kansas",
		countyState: "Phillips County, Kansas",
		centroidLng: -99.3472000,
		centroidLat: 39.7848000
	},
	{
		geoid: "31089",
		name: "Holt",
		state: "Nebraska",
		countyState: "Holt County, Nebraska",
		centroidLng: -98.7786000,
		centroidLat: 42.4923000
	},
	{
		geoid: "29101",
		name: "Johnson",
		state: "Missouri",
		countyState: "Johnson County, Missouri",
		centroidLng: -93.8112000,
		centroidLat: 38.7471000
	},
	{
		geoid: "55063",
		name: "La Crosse",
		state: "Wisconsin",
		countyState: "La Crosse County, Wisconsin",
		centroidLng: -91.1676000,
		centroidLat: 43.9077000
	},
	{
		geoid: "22021",
		name: "Caldwell",
		state: "Louisiana",
		countyState: "Caldwell Parish, Louisiana",
		centroidLng: -92.0902000,
		centroidLat: 32.1023000
	},
	{
		geoid: "21061",
		name: "Edmonson",
		state: "Kentucky",
		countyState: "Edmonson County, Kentucky",
		centroidLng: -86.2591000,
		centroidLat: 37.1970000
	},
	{
		geoid: "17005",
		name: "Bond",
		state: "Illinois",
		countyState: "Bond County, Illinois",
		centroidLng: -89.4448000,
		centroidLat: 38.8844000
	},
	{
		geoid: "17009",
		name: "Brown",
		state: "Illinois",
		countyState: "Brown County, Illinois",
		centroidLng: -90.7137000,
		centroidLat: 39.9725000
	},
	{
		geoid: "27085",
		name: "McLeod",
		state: "Minnesota",
		countyState: "McLeod County, Minnesota",
		centroidLng: -94.2560000,
		centroidLat: 44.8048000
	},
	{
		geoid: "55033",
		name: "Dunn",
		state: "Wisconsin",
		countyState: "Dunn County, Wisconsin",
		centroidLng: -91.9033000,
		centroidLat: 44.9466000
	},
	{
		geoid: "08027",
		name: "Custer",
		state: "Colorado",
		countyState: "Custer County, Colorado",
		centroidLng: -105.4230000,
		centroidLat: 38.0774000
	},
	{
		geoid: "29123",
		name: "Madison",
		state: "Missouri",
		countyState: "Madison County, Missouri",
		centroidLng: -90.3441000,
		centroidLat: 37.4779000
	},
	{
		geoid: "48353",
		name: "Nolan",
		state: "Texas",
		countyState: "Nolan County, Texas",
		centroidLng: -100.4060000,
		centroidLat: 32.3033000
	},
	{
		geoid: "42127",
		name: "Wayne",
		state: "Pennsylvania",
		countyState: "Wayne County, Pennsylvania",
		centroidLng: -75.2746000,
		centroidLat: 41.6160000
	},
	{
		geoid: "36091",
		name: "Saratoga",
		state: "New York",
		countyState: "Saratoga County, New York",
		centroidLng: -73.8667000,
		centroidLat: 43.0867000
	},
	{
		geoid: "48041",
		name: "Brazos",
		state: "Texas",
		countyState: "Brazos County, Texas",
		centroidLng: -96.3409000,
		centroidLat: 30.6520000
	},
	{
		geoid: "40095",
		name: "Marshall",
		state: "Oklahoma",
		countyState: "Marshall County, Oklahoma",
		centroidLng: -96.7587000,
		centroidLat: 33.9986000
	},
	{
		geoid: "24001",
		name: "Allegany",
		state: "Maryland",
		countyState: "Allegany County, Maryland",
		centroidLng: -78.6904000,
		centroidLat: 39.5807000
	},
	{
		geoid: "54103",
		name: "Wetzel",
		state: "West Virginia",
		countyState: "Wetzel County, West Virginia",
		centroidLng: -80.6667000,
		centroidLat: 39.5755000
	},
	{
		geoid: "55053",
		name: "Jackson",
		state: "Wisconsin",
		countyState: "Jackson County, Wisconsin",
		centroidLng: -90.7394000,
		centroidLat: 44.3339000
	},
	{
		geoid: "41007",
		name: "Clatsop",
		state: "Oregon",
		countyState: "Clatsop County, Oregon",
		centroidLng: -123.7590000,
		centroidLat: 46.0361000
	},
	{
		geoid: "53041",
		name: "Lewis",
		state: "Washington",
		countyState: "Lewis County, Washington",
		centroidLng: -122.3630000,
		centroidLat: 46.5889000
	},
	{
		geoid: "36003",
		name: "Allegany",
		state: "New York",
		countyState: "Allegany County, New York",
		centroidLng: -78.0161000,
		centroidLat: 42.2600000
	},
	{
		geoid: "19031",
		name: "Cedar",
		state: "Iowa",
		countyState: "Cedar County, Iowa",
		centroidLng: -91.1323000,
		centroidLat: 41.7723000
	},
	{
		geoid: "18117",
		name: "Orange",
		state: "Indiana",
		countyState: "Orange County, Indiana",
		centroidLng: -86.4954000,
		centroidLat: 38.5414000
	},
	{
		geoid: "13089",
		name: "DeKalb",
		state: "Georgia",
		countyState: "DeKalb County, Georgia",
		centroidLng: -84.1870000,
		centroidLat: 33.7928000
	},
	{
		geoid: "13227",
		name: "Pickens",
		state: "Georgia",
		countyState: "Pickens County, Georgia",
		centroidLng: -84.4556000,
		centroidLat: 34.4685000
	},
	{
		geoid: "30015",
		name: "Chouteau",
		state: "Montana",
		countyState: "Chouteau County, Montana",
		centroidLng: -110.4710000,
		centroidLat: 47.8611000
	},
	{
		geoid: "31017",
		name: "Brown",
		state: "Nebraska",
		countyState: "Brown County, Nebraska",
		centroidLng: -99.9305000,
		centroidLat: 42.4657000
	},
	{
		geoid: "47013",
		name: "Campbell",
		state: "Tennessee",
		countyState: "Campbell County, Tennessee",
		centroidLng: -84.1382000,
		centroidLat: 36.3848000
	},
	{
		geoid: "37069",
		name: "Franklin",
		state: "North Carolina",
		countyState: "Franklin County, North Carolina",
		centroidLng: -78.2765000,
		centroidLat: 36.0422000
	},
	{
		geoid: "24510",
		name: "Baltimore",
		state: "Maryland",
		countyState: "Baltimore County, Maryland",
		centroidLng: -76.6205000,
		centroidLat: 39.2847000
	},
	{
		geoid: "20111",
		name: "Lyon",
		state: "Kansas",
		countyState: "Lyon County, Kansas",
		centroidLng: -96.1515000,
		centroidLat: 38.4551000
	},
	{
		geoid: "19027",
		name: "Carroll",
		state: "Iowa",
		countyState: "Carroll County, Iowa",
		centroidLng: -94.8609000,
		centroidLat: 42.0364000
	},
	{
		geoid: "22107",
		name: "Tensas",
		state: "Louisiana",
		countyState: "Tensas Parish, Louisiana",
		centroidLng: -91.3051000,
		centroidLat: 31.9915000
	},
	{
		geoid: "47123",
		name: "Monroe",
		state: "Tennessee",
		countyState: "Monroe County, Tennessee",
		centroidLng: -84.2322000,
		centroidLat: 35.4383000
	},
	{
		geoid: "20075",
		name: "Hamilton",
		state: "Kansas",
		countyState: "Hamilton County, Kansas",
		centroidLng: -101.7860000,
		centroidLat: 37.9997000
	},
	{
		geoid: "19151",
		name: "Pocahontas",
		state: "Iowa",
		countyState: "Pocahontas County, Iowa",
		centroidLng: -94.6791000,
		centroidLat: 42.7342000
	},
	{
		geoid: "16045",
		name: "Gem",
		state: "Idaho",
		countyState: "Gem County, Idaho",
		centroidLng: -116.4330000,
		centroidLat: 44.1590000
	},
	{
		geoid: "05063",
		name: "Independence",
		state: "Arkansas",
		countyState: "Independence County, Arkansas",
		centroidLng: -91.5343000,
		centroidLat: 35.7346000
	},
	{
		geoid: "05085",
		name: "Lonoke",
		state: "Arkansas",
		countyState: "Lonoke County, Arkansas",
		centroidLng: -91.8771000,
		centroidLat: 34.7791000
	},
	{
		geoid: "18111",
		name: "Newton",
		state: "Indiana",
		countyState: "Newton County, Indiana",
		centroidLng: -87.3970000,
		centroidLat: 40.9776000
	},
	{
		geoid: "13119",
		name: "Franklin",
		state: "Georgia",
		countyState: "Franklin County, Georgia",
		centroidLng: -83.2249000,
		centroidLat: 34.3802000
	},
	{
		geoid: "12051",
		name: "Hendry",
		state: "Florida",
		countyState: "Hendry County, Florida",
		centroidLng: -81.2231000,
		centroidLat: 26.6060000
	},
	{
		geoid: "13265",
		name: "Taliaferro",
		state: "Georgia",
		countyState: "Taliaferro County, Georgia",
		centroidLng: -82.8470000,
		centroidLat: 33.5857000
	},
	{
		geoid: "48245",
		name: "Jefferson",
		state: "Texas",
		countyState: "Jefferson County, Texas",
		centroidLng: -94.1297000,
		centroidLat: 29.8477000
	},
	{
		geoid: "30059",
		name: "Meagher",
		state: "Montana",
		countyState: "Meagher County, Montana",
		centroidLng: -110.9660000,
		centroidLat: 46.6363000
	},
	{
		geoid: "08067",
		name: "La Plata",
		state: "Colorado",
		countyState: "La Plata County, Colorado",
		centroidLng: -107.9310000,
		centroidLat: 37.3200000
	},
	{
		geoid: "48027",
		name: "Bell",
		state: "Texas",
		countyState: "Bell County, Texas",
		centroidLng: -97.4920000,
		centroidLat: 31.0363000
	},
	{
		geoid: "28069",
		name: "Kemper",
		state: "Mississippi",
		countyState: "Kemper County, Mississippi",
		centroidLng: -88.6316000,
		centroidLat: 32.7529000
	},
	{
		geoid: "05019",
		name: "Clark",
		state: "Arkansas",
		countyState: "Clark County, Arkansas",
		centroidLng: -93.1893000,
		centroidLat: 34.0553000
	},
	{
		geoid: "41035",
		name: "Klamath",
		state: "Oregon",
		countyState: "Klamath County, Oregon",
		centroidLng: -121.5850000,
		centroidLat: 42.8048000
	},
	{
		geoid: "36075",
		name: "Oswego",
		state: "New York",
		countyState: "Oswego County, New York",
		centroidLng: -76.2005000,
		centroidLat: 43.4313000
	},
	{
		geoid: "72105",
		name: "Naranjito",
		state: "Puerto Rico",
		countyState: "Naranjito County, Puerto Rico",
		centroidLng: -66.2594000,
		centroidLat: 18.2876000
	},
	{
		geoid: "01019",
		name: "Cherokee",
		state: "Alabama",
		countyState: "Cherokee County, Alabama",
		centroidLng: -85.6214000,
		centroidLat: 34.2338000
	},
	{
		geoid: "48019",
		name: "Bandera",
		state: "Texas",
		countyState: "Bandera County, Texas",
		centroidLng: -99.1910000,
		centroidLat: 29.7297000
	},
	{
		geoid: "30087",
		name: "Rosebud",
		state: "Montana",
		countyState: "Rosebud County, Montana",
		centroidLng: -106.9920000,
		centroidLat: 46.0184000
	},
	{
		geoid: "35059",
		name: "Union",
		state: "New Mexico",
		countyState: "Union County, New Mexico",
		centroidLng: -103.5060000,
		centroidLat: 36.3698000
	},
	{
		geoid: "26153",
		name: "Schoolcraft",
		state: "Michigan",
		countyState: "Schoolcraft County, Michigan",
		centroidLng: -86.2357000,
		centroidLat: 46.0421000
	},
	{
		geoid: "36111",
		name: "Ulster",
		state: "New York",
		countyState: "Ulster County, New York",
		centroidLng: -74.3457000,
		centroidLat: 41.8788000
	},
	{
		geoid: "34007",
		name: "Camden",
		state: "New Jersey",
		countyState: "Camden County, New Jersey",
		centroidLng: -74.9380000,
		centroidLat: 39.8022000
	},
	{
		geoid: "17083",
		name: "Jersey",
		state: "Illinois",
		countyState: "Jersey County, Illinois",
		centroidLng: -90.3747000,
		centroidLat: 39.0928000
	},
	{
		geoid: "51085",
		name: "Hanover",
		state: "Virginia",
		countyState: "Hanover County, Virginia",
		centroidLng: -77.4526000,
		centroidLat: 37.7731000
	},
	{
		geoid: "20199",
		name: "Wallace",
		state: "Kansas",
		countyState: "Wallace County, Kansas",
		centroidLng: -101.7630000,
		centroidLat: 38.9162000
	},
	{
		geoid: "26003",
		name: "Alger",
		state: "Michigan",
		countyState: "Alger County, Michigan",
		centroidLng: -86.4856000,
		centroidLat: 46.9882000
	},
	{
		geoid: "05049",
		name: "Fulton",
		state: "Arkansas",
		countyState: "Fulton County, Arkansas",
		centroidLng: -91.8046000,
		centroidLat: 36.3748000
	},
	{
		geoid: "48215",
		name: "Hidalgo",
		state: "Texas",
		countyState: "Hidalgo County, Texas",
		centroidLng: -98.2242000,
		centroidLat: 26.4097000
	},
	{
		geoid: "37155",
		name: "Robeson",
		state: "North Carolina",
		countyState: "Robeson County, North Carolina",
		centroidLng: -79.1335000,
		centroidLat: 34.6260000
	},
	{
		geoid: "26123",
		name: "Newaygo",
		state: "Michigan",
		countyState: "Newaygo County, Michigan",
		centroidLng: -85.8010000,
		centroidLat: 43.5543000
	},
	{
		geoid: "41049",
		name: "Morrow",
		state: "Oregon",
		countyState: "Morrow County, Oregon",
		centroidLng: -119.5760000,
		centroidLat: 45.4600000
	},
	{
		geoid: "27153",
		name: "Todd",
		state: "Minnesota",
		countyState: "Todd County, Minnesota",
		centroidLng: -94.8995000,
		centroidLat: 46.0712000
	},
	{
		geoid: "05083",
		name: "Logan",
		state: "Arkansas",
		countyState: "Logan County, Arkansas",
		centroidLng: -93.7101000,
		centroidLat: 35.2249000
	},
	{
		geoid: "37109",
		name: "Lincoln",
		state: "North Carolina",
		countyState: "Lincoln County, North Carolina",
		centroidLng: -81.2386000,
		centroidLat: 35.4841000
	},
	{
		geoid: "05091",
		name: "Miller",
		state: "Arkansas",
		countyState: "Miller County, Arkansas",
		centroidLng: -93.8545000,
		centroidLat: 33.3166000
	},
	{
		geoid: "12095",
		name: "Orange",
		state: "Florida",
		countyState: "Orange County, Florida",
		centroidLng: -81.2607000,
		centroidLat: 28.5665000
	},
	{
		geoid: "08111",
		name: "San Juan",
		state: "Colorado",
		countyState: "San Juan County, Colorado",
		centroidLng: -107.7170000,
		centroidLat: 37.8022000
	},
	{
		geoid: "16041",
		name: "Franklin",
		state: "Idaho",
		countyState: "Franklin County, Idaho",
		centroidLng: -111.8170000,
		centroidLat: 42.2142000
	},
	{
		geoid: "13045",
		name: "Carroll",
		state: "Georgia",
		countyState: "Carroll County, Georgia",
		centroidLng: -85.0736000,
		centroidLat: 33.6190000
	},
	{
		geoid: "20161",
		name: "Riley",
		state: "Kansas",
		countyState: "Riley County, Kansas",
		centroidLng: -96.6754000,
		centroidLat: 39.3049000
	},
	{
		geoid: "48413",
		name: "Schleicher",
		state: "Texas",
		countyState: "Schleicher County, Texas",
		centroidLng: -100.5390000,
		centroidLat: 30.8974000
	},
	{
		geoid: "51167",
		name: "Russell",
		state: "Virginia",
		countyState: "Russell County, Virginia",
		centroidLng: -82.0936000,
		centroidLat: 36.9258000
	},
	{
		geoid: "37027",
		name: "Caldwell",
		state: "North Carolina",
		countyState: "Caldwell County, North Carolina",
		centroidLng: -81.5694000,
		centroidLat: 35.9398000
	},
	{
		geoid: "16065",
		name: "Madison",
		state: "Idaho",
		countyState: "Madison County, Idaho",
		centroidLng: -111.6910000,
		centroidLat: 43.7760000
	},
	{
		geoid: "18173",
		name: "Warrick",
		state: "Indiana",
		countyState: "Warrick County, Indiana",
		centroidLng: -87.2453000,
		centroidLat: 38.0621000
	},
	{
		geoid: "21001",
		name: "Adair",
		state: "Kentucky",
		countyState: "Adair County, Kentucky",
		centroidLng: -85.2851000,
		centroidLat: 37.1187000
	},
	{
		geoid: "21077",
		name: "Gallatin",
		state: "Kentucky",
		countyState: "Gallatin County, Kentucky",
		centroidLng: -84.8418000,
		centroidLat: 38.7609000
	},
	{
		geoid: "21097",
		name: "Harrison",
		state: "Kentucky",
		countyState: "Harrison County, Kentucky",
		centroidLng: -84.3297000,
		centroidLat: 38.4299000
	},
	{
		geoid: "23023",
		name: "Sagadahoc",
		state: "Maine",
		countyState: "Sagadahoc County, Maine",
		centroidLng: -69.8583000,
		centroidLat: 43.9022000
	},
	{
		geoid: "30097",
		name: "Sweet Grass",
		state: "Montana",
		countyState: "Sweet Grass County, Montana",
		centroidLng: -109.8550000,
		centroidLat: 45.6965000
	},
	{
		geoid: "48181",
		name: "Grayson",
		state: "Texas",
		countyState: "Grayson County, Texas",
		centroidLng: -96.6623000,
		centroidLat: 33.6787000
	},
	{
		geoid: "42059",
		name: "Greene",
		state: "Pennsylvania",
		countyState: "Greene County, Pennsylvania",
		centroidLng: -80.2113000,
		centroidLat: 39.8706000
	},
	{
		geoid: "02188",
		name: "Northwest Arctic",
		state: "Alaska",
		countyState: "Northwest Arctic Borough, Alaska",
		centroidLng: -159.8710000,
		centroidLat: 66.8659000
	},
	{
		geoid: "20051",
		name: "Ellis",
		state: "Kansas",
		countyState: "Ellis County, Kansas",
		centroidLng: -99.3179000,
		centroidLat: 38.9149000
	},
	{
		geoid: "01065",
		name: "Hale",
		state: "Alabama",
		countyState: "Hale County, Alabama",
		centroidLng: -87.6453000,
		centroidLat: 32.7438000
	},
	{
		geoid: "01105",
		name: "Perry",
		state: "Alabama",
		countyState: "Perry County, Alabama",
		centroidLng: -87.2717000,
		centroidLat: 32.5912000
	},
	{
		geoid: "01033",
		name: "Colbert",
		state: "Alabama",
		countyState: "Colbert County, Alabama",
		centroidLng: -87.7779000,
		centroidLat: 34.7365000
	},
	{
		geoid: "20151",
		name: "Pratt",
		state: "Kansas",
		countyState: "Pratt County, Kansas",
		centroidLng: -98.7400000,
		centroidLat: 37.6477000
	},
	{
		geoid: "21017",
		name: "Bourbon",
		state: "Kentucky",
		countyState: "Bourbon County, Kentucky",
		centroidLng: -84.2060000,
		centroidLat: 38.2194000
	},
	{
		geoid: "47149",
		name: "Rutherford",
		state: "Tennessee",
		countyState: "Rutherford County, Tennessee",
		centroidLng: -86.4210000,
		centroidLat: 35.8596000
	},
	{
		geoid: "13075",
		name: "Cook",
		state: "Georgia",
		countyState: "Cook County, Georgia",
		centroidLng: -83.4284000,
		centroidLat: 31.1888000
	},
	{
		geoid: "20101",
		name: "Lane",
		state: "Kansas",
		countyState: "Lane County, Kansas",
		centroidLng: -100.4660000,
		centroidLat: 38.4812000
	},
	{
		geoid: "40059",
		name: "Harper",
		state: "Oklahoma",
		countyState: "Harper County, Oklahoma",
		centroidLng: -99.6494000,
		centroidLat: 36.7972000
	},
	{
		geoid: "17147",
		name: "Piatt",
		state: "Illinois",
		countyState: "Piatt County, Illinois",
		centroidLng: -88.6032000,
		centroidLat: 40.0369000
	},
	{
		geoid: "48107",
		name: "Crosby",
		state: "Texas",
		countyState: "Crosby County, Texas",
		centroidLng: -101.3010000,
		centroidLat: 33.6142000
	},
	{
		geoid: "72053",
		name: "Fajardo",
		state: "Puerto Rico",
		countyState: "Fajardo County, Puerto Rico",
		centroidLng: -65.6114000,
		centroidLat: 18.3594000
	},
	{
		geoid: "01063",
		name: "Greene",
		state: "Alabama",
		countyState: "Greene County, Alabama",
		centroidLng: -87.9569000,
		centroidLat: 32.8357000
	},
	{
		geoid: "16033",
		name: "Clark",
		state: "Idaho",
		countyState: "Clark County, Idaho",
		centroidLng: -112.3060000,
		centroidLat: 44.2718000
	},
	{
		geoid: "19109",
		name: "Kossuth",
		state: "Iowa",
		countyState: "Kossuth County, Iowa",
		centroidLng: -94.2067000,
		centroidLat: 43.2041000
	},
	{
		geoid: "72151",
		name: "Yabucoa",
		state: "Puerto Rico",
		countyState: "Yabucoa County, Puerto Rico",
		centroidLng: -65.8750000,
		centroidLat: 18.0458000
	},
	{
		geoid: "38013",
		name: "Burke",
		state: "North Dakota",
		countyState: "Burke County, North Dakota",
		centroidLng: -102.4810000,
		centroidLat: 48.7728000
	},
	{
		geoid: "37063",
		name: "Durham",
		state: "North Carolina",
		countyState: "Durham County, North Carolina",
		centroidLng: -78.8578000,
		centroidLat: 36.0513000
	},
	{
		geoid: "19085",
		name: "Harrison",
		state: "Iowa",
		countyState: "Harrison County, Iowa",
		centroidLng: -95.8184000,
		centroidLat: 41.6861000
	},
	{
		geoid: "31103",
		name: "Keya Paha",
		state: "Nebraska",
		countyState: "Keya Paha County, Nebraska",
		centroidLng: -99.7262000,
		centroidLat: 42.8582000
	},
	{
		geoid: "19187",
		name: "Webster",
		state: "Iowa",
		countyState: "Webster County, Iowa",
		centroidLng: -94.1876000,
		centroidLat: 42.4271000
	},
	{
		geoid: "17151",
		name: "Pope",
		state: "Illinois",
		countyState: "Pope County, Illinois",
		centroidLng: -88.5614000,
		centroidLat: 37.3337000
	},
	{
		geoid: "47159",
		name: "Smith",
		state: "Tennessee",
		countyState: "Smith County, Tennessee",
		centroidLng: -85.9577000,
		centroidLat: 36.2540000
	},
	{
		geoid: "51089",
		name: "Henry",
		state: "Virginia",
		countyState: "Henry County, Virginia",
		centroidLng: -79.8679000,
		centroidLat: 36.6991000
	},
	{
		geoid: "51510",
		name: "Alexandria",
		state: "Virginia",
		countyState: "Alexandria County, Virginia",
		centroidLng: -77.0908000,
		centroidLat: 38.8151000
	},
	{
		geoid: "19101",
		name: "Jefferson",
		state: "Iowa",
		countyState: "Jefferson County, Iowa",
		centroidLng: -91.9480000,
		centroidLat: 41.0318000
	},
	{
		geoid: "30049",
		name: "Lewis and Clark",
		state: "Montana",
		countyState: "Lewis and Clark County, Montana",
		centroidLng: -112.3300000,
		centroidLat: 47.1844000
	},
	{
		geoid: "49013",
		name: "Duchesne",
		state: "Utah",
		countyState: "Duchesne County, Utah",
		centroidLng: -110.4400000,
		centroidLat: 40.3205000
	},
	{
		geoid: "40003",
		name: "Alfalfa",
		state: "Oklahoma",
		countyState: "Alfalfa County, Oklahoma",
		centroidLng: -98.3246000,
		centroidLat: 36.7310000
	},
	{
		geoid: "48119",
		name: "Delta",
		state: "Texas",
		countyState: "Delta County, Texas",
		centroidLng: -95.5842000,
		centroidLat: 33.3569000
	},
	{
		geoid: "47169",
		name: "Trousdale",
		state: "Tennessee",
		countyState: "Trousdale County, Tennessee",
		centroidLng: -86.1282000,
		centroidLat: 36.3916000
	},
	{
		geoid: "21193",
		name: "Perry",
		state: "Kentucky",
		countyState: "Perry County, Kentucky",
		centroidLng: -83.2770000,
		centroidLat: 37.2177000
	},
	{
		geoid: "46039",
		name: "Deuel",
		state: "South Dakota",
		countyState: "Deuel County, South Dakota",
		centroidLng: -96.6684000,
		centroidLat: 44.7606000
	},
	{
		geoid: "41029",
		name: "Jackson",
		state: "Oregon",
		countyState: "Jackson County, Oregon",
		centroidLng: -122.7570000,
		centroidLat: 42.4999000
	},
	{
		geoid: "72067",
		name: "Hormigueros",
		state: "Puerto Rico",
		countyState: "Hormigueros County, Puerto Rico",
		centroidLng: -67.1188000,
		centroidLat: 18.1321000
	},
	{
		geoid: "48507",
		name: "Zavala",
		state: "Texas",
		countyState: "Zavala County, Texas",
		centroidLng: -99.7616000,
		centroidLat: 28.8659000
	},
	{
		geoid: "31007",
		name: "Banner",
		state: "Nebraska",
		countyState: "Banner County, Nebraska",
		centroidLng: -103.7100000,
		centroidLat: 41.5464000
	},
	{
		geoid: "19121",
		name: "Madison",
		state: "Iowa",
		countyState: "Madison County, Iowa",
		centroidLng: -94.0155000,
		centroidLat: 41.3342000
	},
	{
		geoid: "12011",
		name: "Broward",
		state: "Florida",
		countyState: "Broward County, Florida",
		centroidLng: -80.4483000,
		centroidLat: 26.1561000
	},
	{
		geoid: "13279",
		name: "Toombs",
		state: "Georgia",
		countyState: "Toombs County, Georgia",
		centroidLng: -82.3325000,
		centroidLat: 32.1328000
	},
	{
		geoid: "26151",
		name: "Sanilac",
		state: "Michigan",
		countyState: "Sanilac County, Michigan",
		centroidLng: -82.6217000,
		centroidLat: 43.4248000
	},
	{
		geoid: "48397",
		name: "Rockwall",
		state: "Texas",
		countyState: "Rockwall County, Texas",
		centroidLng: -96.4080000,
		centroidLat: 32.8978000
	},
	{
		geoid: "26163",
		name: "Wayne",
		state: "Michigan",
		countyState: "Wayne County, Michigan",
		centroidLng: -83.1509000,
		centroidLat: 42.2396000
	},
	{
		geoid: "27105",
		name: "Nobles",
		state: "Minnesota",
		countyState: "Nobles County, Minnesota",
		centroidLng: -95.7529000,
		centroidLat: 43.6745000
	},
	{
		geoid: "27001",
		name: "Aitkin",
		state: "Minnesota",
		countyState: "Aitkin County, Minnesota",
		centroidLng: -93.4330000,
		centroidLat: 46.5922000
	},
	{
		geoid: "38071",
		name: "Ramsey",
		state: "North Dakota",
		countyState: "Ramsey County, North Dakota",
		centroidLng: -98.7462000,
		centroidLat: 48.2296000
	},
	{
		geoid: "37145",
		name: "Person",
		state: "North Carolina",
		countyState: "Person County, North Carolina",
		centroidLng: -78.9744000,
		centroidLat: 36.3890000
	},
	{
		geoid: "20195",
		name: "Trego",
		state: "Kansas",
		countyState: "Trego County, Kansas",
		centroidLng: -99.8728000,
		centroidLat: 38.9144000
	},
	{
		geoid: "28151",
		name: "Washington",
		state: "Mississippi",
		countyState: "Washington County, Mississippi",
		centroidLng: -90.9573000,
		centroidLat: 33.2691000
	},
	{
		geoid: "48133",
		name: "Eastland",
		state: "Texas",
		countyState: "Eastland County, Texas",
		centroidLng: -98.7967000,
		centroidLat: 32.2968000
	},
	{
		geoid: "28055",
		name: "Issaquena",
		state: "Mississippi",
		countyState: "Issaquena County, Mississippi",
		centroidLng: -90.9527000,
		centroidLat: 32.7113000
	},
	{
		geoid: "48123",
		name: "DeWitt",
		state: "Texas",
		countyState: "DeWitt County, Texas",
		centroidLng: -97.3657000,
		centroidLat: 29.0989000
	},
	{
		geoid: "48005",
		name: "Angelina",
		state: "Texas",
		countyState: "Angelina County, Texas",
		centroidLng: -94.5676000,
		centroidLat: 31.2765000
	},
	{
		geoid: "45081",
		name: "Saluda",
		state: "South Carolina",
		countyState: "Saluda County, South Carolina",
		centroidLng: -81.7411000,
		centroidLat: 34.0011000
	},
	{
		geoid: "29125",
		name: "Maries",
		state: "Missouri",
		countyState: "Maries County, Missouri",
		centroidLng: -91.9142000,
		centroidLat: 38.1516000
	},
	{
		geoid: "05001",
		name: "Arkansas",
		state: "Arkansas",
		countyState: "Arkansas County, Arkansas",
		centroidLng: -91.3819000,
		centroidLat: 34.2528000
	},
	{
		geoid: "29109",
		name: "Lawrence",
		state: "Missouri",
		countyState: "Lawrence County, Missouri",
		centroidLng: -93.8340000,
		centroidLat: 37.1065000
	},
	{
		geoid: "51540",
		name: "Charlottesville",
		state: "Virginia",
		countyState: "Charlottesville County, Virginia",
		centroidLng: -78.4850000,
		centroidLat: 38.0400000
	},
	{
		geoid: "17011",
		name: "Bureau",
		state: "Illinois",
		countyState: "Bureau County, Illinois",
		centroidLng: -89.5130000,
		centroidLat: 41.3669000
	},
	{
		geoid: "39145",
		name: "Scioto",
		state: "Ohio",
		countyState: "Scioto County, Ohio",
		centroidLng: -82.9604000,
		centroidLat: 38.7933000
	},
	{
		geoid: "37115",
		name: "Madison",
		state: "North Carolina",
		countyState: "Madison County, North Carolina",
		centroidLng: -82.6858000,
		centroidLat: 35.8720000
	},
	{
		geoid: "16053",
		name: "Jerome",
		state: "Idaho",
		countyState: "Jerome County, Idaho",
		centroidLng: -114.2750000,
		centroidLat: 42.6730000
	},
	{
		geoid: "39163",
		name: "Vinton",
		state: "Ohio",
		countyState: "Vinton County, Ohio",
		centroidLng: -82.5116000,
		centroidLat: 39.2117000
	},
	{
		geoid: "48309",
		name: "McLennan",
		state: "Texas",
		countyState: "McLennan County, Texas",
		centroidLng: -97.2032000,
		centroidLat: 31.5536000
	},
	{
		geoid: "16037",
		name: "Custer",
		state: "Idaho",
		countyState: "Custer County, Idaho",
		centroidLng: -114.3130000,
		centroidLat: 44.2241000
	},
	{
		geoid: "19089",
		name: "Howard",
		state: "Iowa",
		countyState: "Howard County, Iowa",
		centroidLng: -92.3171000,
		centroidLat: 43.3568000
	},
	{
		geoid: "12099",
		name: "Palm Beach",
		state: "Florida",
		countyState: "Palm Beach County, Florida",
		centroidLng: -80.4303000,
		centroidLat: 26.6458000
	},
	{
		geoid: "13181",
		name: "Lincoln",
		state: "Georgia",
		countyState: "Lincoln County, Georgia",
		centroidLng: -82.4321000,
		centroidLat: 33.8106000
	},
	{
		geoid: "01013",
		name: "Butler",
		state: "Alabama",
		countyState: "Butler County, Alabama",
		centroidLng: -86.6736000,
		centroidLat: 31.7442000
	},
	{
		geoid: "47165",
		name: "Sumner",
		state: "Tennessee",
		countyState: "Sumner County, Tennessee",
		centroidLng: -86.4801000,
		centroidLat: 36.4483000
	},
	{
		geoid: "51097",
		name: "King and Queen",
		state: "Virginia",
		countyState: "King and Queen County, Virginia",
		centroidLng: -76.9162000,
		centroidLat: 37.7017000
	},
	{
		geoid: "05015",
		name: "Carroll",
		state: "Arkansas",
		countyState: "Carroll County, Arkansas",
		centroidLng: -93.5917000,
		centroidLat: 36.3104000
	},
	{
		geoid: "51820",
		name: "Waynesboro",
		state: "Virginia",
		countyState: "Waynesboro County, Virginia",
		centroidLng: -78.9033000,
		centroidLat: 38.0656000
	},
	{
		geoid: "02220",
		name: "Sitka",
		state: "Alaska",
		countyState: "Sitka Borough, Alaska",
		centroidLng: -135.4410000,
		centroidLat: 57.0745000
	},
	{
		geoid: "41051",
		name: "Multnomah",
		state: "Oregon",
		countyState: "Multnomah County, Oregon",
		centroidLng: -122.3740000,
		centroidLat: 45.5807000
	},
	{
		geoid: "47061",
		name: "Grundy",
		state: "Tennessee",
		countyState: "Grundy County, Tennessee",
		centroidLng: -85.6996000,
		centroidLat: 35.3735000
	},
	{
		geoid: "27057",
		name: "Hubbard",
		state: "Minnesota",
		countyState: "Hubbard County, Minnesota",
		centroidLng: -94.9208000,
		centroidLat: 47.1082000
	},
	{
		geoid: "38081",
		name: "Sargent",
		state: "North Dakota",
		countyState: "Sargent County, North Dakota",
		centroidLng: -97.6182000,
		centroidLat: 46.1089000
	},
	{
		geoid: "36089",
		name: "St. Lawrence",
		state: "New York",
		countyState: "St. Lawrence County, New York",
		centroidLng: -75.1929000,
		centroidLat: 44.5331000
	},
	{
		geoid: "29179",
		name: "Reynolds",
		state: "Missouri",
		countyState: "Reynolds County, Missouri",
		centroidLng: -91.0243000,
		centroidLat: 37.3272000
	},
	{
		geoid: "06067",
		name: "Sacramento",
		state: "California",
		countyState: "Sacramento County, California",
		centroidLng: -121.4450000,
		centroidLat: 38.3774000
	},
	{
		geoid: "39125",
		name: "Paulding",
		state: "Ohio",
		countyState: "Paulding County, Ohio",
		centroidLng: -84.5726000,
		centroidLat: 41.1214000
	},
	{
		geoid: "18179",
		name: "Wells",
		state: "Indiana",
		countyState: "Wells County, Indiana",
		centroidLng: -85.2587000,
		centroidLat: 40.7423000
	},
	{
		geoid: "51063",
		name: "Floyd",
		state: "Virginia",
		countyState: "Floyd County, Virginia",
		centroidLng: -80.3692000,
		centroidLat: 36.9160000
	},
	{
		geoid: "06083",
		name: "Santa Barbara",
		state: "California",
		countyState: "Santa Barbara County, California",
		centroidLng: -120.0870000,
		centroidLat: 34.7208000
	},
	{
		geoid: "54005",
		name: "Boone",
		state: "West Virginia",
		countyState: "Boone County, West Virginia",
		centroidLng: -81.7170000,
		centroidLat: 37.9971000
	},
	{
		geoid: "28009",
		name: "Benton",
		state: "Mississippi",
		countyState: "Benton County, Mississippi",
		centroidLng: -89.1848000,
		centroidLat: 34.7889000
	},
	{
		geoid: "21055",
		name: "Crittenden",
		state: "Kentucky",
		countyState: "Crittenden County, Kentucky",
		centroidLng: -88.0795000,
		centroidLat: 37.3367000
	},
	{
		geoid: "19147",
		name: "Palo Alto",
		state: "Iowa",
		countyState: "Palo Alto County, Iowa",
		centroidLng: -94.6782000,
		centroidLat: 43.0818000
	},
	{
		geoid: "19159",
		name: "Ringgold",
		state: "Iowa",
		countyState: "Ringgold County, Iowa",
		centroidLng: -94.2432000,
		centroidLat: 40.7352000
	},
	{
		geoid: "37151",
		name: "Randolph",
		state: "North Carolina",
		countyState: "Randolph County, North Carolina",
		centroidLng: -79.8043000,
		centroidLat: 35.7132000
	},
	{
		geoid: "24015",
		name: "Cecil",
		state: "Maryland",
		countyState: "Cecil County, Maryland",
		centroidLng: -75.9999000,
		centroidLat: 39.5415000
	},
	{
		geoid: "18171",
		name: "Warren",
		state: "Indiana",
		countyState: "Warren County, Indiana",
		centroidLng: -87.3116000,
		centroidLat: 40.3021000
	},
	{
		geoid: "31077",
		name: "Greeley",
		state: "Nebraska",
		countyState: "Greeley County, Nebraska",
		centroidLng: -98.5215000,
		centroidLat: 41.5674000
	},
	{
		geoid: "31095",
		name: "Jefferson",
		state: "Nebraska",
		countyState: "Jefferson County, Nebraska",
		centroidLng: -97.1426000,
		centroidLat: 40.1758000
	},
	{
		geoid: "31151",
		name: "Saline",
		state: "Nebraska",
		countyState: "Saline County, Nebraska",
		centroidLng: -97.1405000,
		centroidLat: 40.5241000
	},
	{
		geoid: "21119",
		name: "Knott",
		state: "Kentucky",
		countyState: "Knott County, Kentucky",
		centroidLng: -82.9227000,
		centroidLat: 37.3494000
	},
	{
		geoid: "19045",
		name: "Clinton",
		state: "Iowa",
		countyState: "Clinton County, Iowa",
		centroidLng: -90.5195000,
		centroidLat: 41.8806000
	},
	{
		geoid: "27063",
		name: "Jackson",
		state: "Minnesota",
		countyState: "Jackson County, Minnesota",
		centroidLng: -95.1546000,
		centroidLat: 43.6741000
	},
	{
		geoid: "27039",
		name: "Dodge",
		state: "Minnesota",
		countyState: "Dodge County, Minnesota",
		centroidLng: -92.8619000,
		centroidLat: 44.0227000
	},
	{
		geoid: "27047",
		name: "Freeborn",
		state: "Minnesota",
		countyState: "Freeborn County, Minnesota",
		centroidLng: -93.3488000,
		centroidLat: 43.6739000
	},
	{
		geoid: "06009",
		name: "Calaveras",
		state: "California",
		countyState: "Calaveras County, California",
		centroidLng: -120.5080000,
		centroidLat: 38.1709000
	},
	{
		geoid: "25001",
		name: "Barnstable",
		state: "Massachusetts",
		countyState: "Barnstable County, Massachusetts",
		centroidLng: -70.3087000,
		centroidLat: 41.7993000
	},
	{
		geoid: "51119",
		name: "Middlesex",
		state: "Virginia",
		countyState: "Middlesex County, Virginia",
		centroidLng: -76.4460000,
		centroidLat: 37.6443000
	},
	{
		geoid: "42065",
		name: "Jefferson",
		state: "Pennsylvania",
		countyState: "Jefferson County, Pennsylvania",
		centroidLng: -78.9629000,
		centroidLat: 41.1413000
	},
	{
		geoid: "18089",
		name: "Lake",
		state: "Indiana",
		countyState: "Lake County, Indiana",
		centroidLng: -87.3728000,
		centroidLat: 41.4615000
	},
	{
		geoid: "47003",
		name: "Bedford",
		state: "Tennessee",
		countyState: "Bedford County, Tennessee",
		centroidLng: -86.4500000,
		centroidLat: 35.5108000
	},
	{
		geoid: "47027",
		name: "Clay",
		state: "Tennessee",
		countyState: "Clay County, Tennessee",
		centroidLng: -85.5405000,
		centroidLat: 36.5149000
	},
	{
		geoid: "17029",
		name: "Coles",
		state: "Illinois",
		countyState: "Coles County, Illinois",
		centroidLng: -88.2161000,
		centroidLat: 39.5298000
	},
	{
		geoid: "47097",
		name: "Lauderdale",
		state: "Tennessee",
		countyState: "Lauderdale County, Tennessee",
		centroidLng: -89.6491000,
		centroidLat: 35.7426000
	},
	{
		geoid: "41001",
		name: "Baker",
		state: "Oregon",
		countyState: "Baker County, Oregon",
		centroidLng: -117.6520000,
		centroidLat: 44.6679000
	},
	{
		geoid: "36097",
		name: "Schuyler",
		state: "New York",
		countyState: "Schuyler County, New York",
		centroidLng: -76.8632000,
		centroidLat: 42.3903000
	},
	{
		geoid: "26159",
		name: "Van Buren",
		state: "Michigan",
		countyState: "Van Buren County, Michigan",
		centroidLng: -86.4256000,
		centroidLat: 42.2450000
	},
	{
		geoid: "17131",
		name: "Mercer",
		state: "Illinois",
		countyState: "Mercer County, Illinois",
		centroidLng: -90.7740000,
		centroidLat: 41.1985000
	},
	{
		geoid: "21127",
		name: "Lawrence",
		state: "Kentucky",
		countyState: "Lawrence County, Kentucky",
		centroidLng: -82.7447000,
		centroidLat: 38.0741000
	},
	{
		geoid: "21175",
		name: "Morgan",
		state: "Kentucky",
		countyState: "Morgan County, Kentucky",
		centroidLng: -83.2338000,
		centroidLat: 37.9141000
	},
	{
		geoid: "21177",
		name: "Muhlenberg",
		state: "Kentucky",
		countyState: "Muhlenberg County, Kentucky",
		centroidLng: -87.1351000,
		centroidLat: 37.2283000
	},
	{
		geoid: "17049",
		name: "Effingham",
		state: "Illinois",
		countyState: "Effingham County, Illinois",
		centroidLng: -88.5836000,
		centroidLat: 39.0636000
	},
	{
		geoid: "47105",
		name: "Loudon",
		state: "Tennessee",
		countyState: "Loudon County, Tennessee",
		centroidLng: -84.3588000,
		centroidLat: 35.7568000
	},
	{
		geoid: "46025",
		name: "Clark",
		state: "South Dakota",
		countyState: "Clark County, South Dakota",
		centroidLng: -97.7371000,
		centroidLat: 44.8486000
	},
	{
		geoid: "15003",
		name: "Honolulu",
		state: "Hawaii",
		countyState: "Honolulu County, Hawaii",
		centroidLng: -157.9710000,
		centroidLat: 21.4811000
	},
	{
		geoid: "12041",
		name: "Gilchrist",
		state: "Florida",
		countyState: "Gilchrist County, Florida",
		centroidLng: -82.8147000,
		centroidLat: 29.7490000
	},
	{
		geoid: "18157",
		name: "Tippecanoe",
		state: "Indiana",
		countyState: "Tippecanoe County, Indiana",
		centroidLng: -86.8950000,
		centroidLat: 40.3887000
	},
	{
		geoid: "42109",
		name: "Snyder",
		state: "Pennsylvania",
		countyState: "Snyder County, Pennsylvania",
		centroidLng: -77.0777000,
		centroidLat: 40.7627000
	},
	{
		geoid: "48277",
		name: "Lamar",
		state: "Texas",
		countyState: "Lamar County, Texas",
		centroidLng: -95.5829000,
		centroidLat: 33.6604000
	},
	{
		geoid: "12086",
		name: "Miami-Dade",
		state: "Florida",
		countyState: "Miami-Dade County, Florida",
		centroidLng: -80.4582000,
		centroidLat: 25.5584000
	},
	{
		geoid: "31057",
		name: "Dundy",
		state: "Nebraska",
		countyState: "Dundy County, Nebraska",
		centroidLng: -101.6880000,
		centroidLat: 40.1765000
	},
	{
		geoid: "48289",
		name: "Leon",
		state: "Texas",
		countyState: "Leon County, Texas",
		centroidLng: -95.9933000,
		centroidLat: 31.3139000
	},
	{
		geoid: "08097",
		name: "Pitkin",
		state: "Colorado",
		countyState: "Pitkin County, Colorado",
		centroidLng: -106.9460000,
		centroidLat: 39.1720000
	},
	{
		geoid: "38035",
		name: "Grand Forks",
		state: "North Dakota",
		countyState: "Grand Forks County, North Dakota",
		centroidLng: -97.3944000,
		centroidLat: 47.9335000
	},
	{
		geoid: "18091",
		name: "LaPorte",
		state: "Indiana",
		countyState: "LaPorte County, Indiana",
		centroidLng: -86.7098000,
		centroidLat: 41.4989000
	},
	{
		geoid: "18057",
		name: "Hamilton",
		state: "Indiana",
		countyState: "Hamilton County, Indiana",
		centroidLng: -86.0523000,
		centroidLat: 40.0725000
	},
	{
		geoid: "51595",
		name: "Emporia",
		state: "Virginia",
		countyState: "Emporia County, Virginia",
		centroidLng: -77.5353000,
		centroidLat: 36.6961000
	},
	{
		geoid: "40081",
		name: "Lincoln",
		state: "Oklahoma",
		countyState: "Lincoln County, Oklahoma",
		centroidLng: -96.8804000,
		centroidLat: 35.7025000
	},
	{
		geoid: "20071",
		name: "Greeley",
		state: "Kansas",
		countyState: "Greeley County, Kansas",
		centroidLng: -101.8060000,
		centroidLat: 38.4810000
	},
	{
		geoid: "13145",
		name: "Harris",
		state: "Georgia",
		countyState: "Harris County, Georgia",
		centroidLng: -84.9339000,
		centroidLat: 32.7278000
	},
	{
		geoid: "13251",
		name: "Screven",
		state: "Georgia",
		countyState: "Screven County, Georgia",
		centroidLng: -81.6289000,
		centroidLat: 32.7675000
	},
	{
		geoid: "72127",
		name: "San Juan",
		state: "Puerto Rico",
		countyState: "San Juan County, Puerto Rico",
		centroidLng: -66.0604000,
		centroidLat: 18.4105000
	},
	{
		geoid: "48433",
		name: "Stonewall",
		state: "Texas",
		countyState: "Stonewall County, Texas",
		centroidLng: -100.2540000,
		centroidLat: 33.1789000
	},
	{
		geoid: "39069",
		name: "Henry",
		state: "Ohio",
		countyState: "Henry County, Ohio",
		centroidLng: -84.1115000,
		centroidLat: 41.3267000
	},
	{
		geoid: "33009",
		name: "Grafton",
		state: "New Hampshire",
		countyState: "Grafton County, New Hampshire",
		centroidLng: -71.8409000,
		centroidLat: 43.9676000
	},
	{
		geoid: "29145",
		name: "Newton",
		state: "Missouri",
		countyState: "Newton County, Missouri",
		centroidLng: -94.3389000,
		centroidLat: 36.9023000
	},
	{
		geoid: "18129",
		name: "Posey",
		state: "Indiana",
		countyState: "Posey County, Indiana",
		centroidLng: -87.8930000,
		centroidLat: 38.0010000
	},
	{
		geoid: "48311",
		name: "McMullen",
		state: "Texas",
		countyState: "McMullen County, Texas",
		centroidLng: -98.5688000,
		centroidLat: 28.3528000
	},
	{
		geoid: "17035",
		name: "Cumberland",
		state: "Illinois",
		countyState: "Cumberland County, Illinois",
		centroidLng: -88.2395000,
		centroidLat: 39.2749000
	},
	{
		geoid: "13297",
		name: "Walton",
		state: "Georgia",
		countyState: "Walton County, Georgia",
		centroidLng: -83.7441000,
		centroidLat: 33.7634000
	},
	{
		geoid: "27121",
		name: "Pope",
		state: "Minnesota",
		countyState: "Pope County, Minnesota",
		centroidLng: -95.4451000,
		centroidLat: 45.5859000
	},
	{
		geoid: "51157",
		name: "Rappahannock",
		state: "Virginia",
		countyState: "Rappahannock County, Virginia",
		centroidLng: -78.1368000,
		centroidLat: 38.6931000
	},
	{
		geoid: "51009",
		name: "Amherst",
		state: "Virginia",
		countyState: "Amherst County, Virginia",
		centroidLng: -79.1523000,
		centroidLat: 37.6000000
	},
	{
		geoid: "06111",
		name: "Ventura",
		state: "California",
		countyState: "Ventura County, California",
		centroidLng: -119.0670000,
		centroidLat: 34.4471000
	},
	{
		geoid: "17033",
		name: "Crawford",
		state: "Illinois",
		countyState: "Crawford County, Illinois",
		centroidLng: -87.7313000,
		centroidLat: 39.0140000
	},
	{
		geoid: "08093",
		name: "Park",
		state: "Colorado",
		countyState: "Park County, Colorado",
		centroidLng: -105.7690000,
		centroidLat: 39.1306000
	},
	{
		geoid: "06037",
		name: "Los Angeles",
		state: "California",
		countyState: "Los Angeles County, California",
		centroidLng: -118.2990000,
		centroidLat: 34.2414000
	},
	{
		geoid: "04027",
		name: "Yuma",
		state: "Arizona",
		countyState: "Yuma County, Arizona",
		centroidLng: -114.0750000,
		centroidLat: 32.7516000
	},
	{
		geoid: "13129",
		name: "Gordon",
		state: "Georgia",
		countyState: "Gordon County, Georgia",
		centroidLng: -84.8784000,
		centroidLat: 34.5092000
	},
	{
		geoid: "19025",
		name: "Calhoun",
		state: "Iowa",
		countyState: "Calhoun County, Iowa",
		centroidLng: -94.6559000,
		centroidLat: 42.3847000
	},
	{
		geoid: "12055",
		name: "Highlands",
		state: "Florida",
		countyState: "Highlands County, Florida",
		centroidLng: -81.2529000,
		centroidLat: 27.3395000
	},
	{
		geoid: "51580",
		name: "Covington",
		state: "Virginia",
		countyState: "Covington County, Virginia",
		centroidLng: -79.9875000,
		centroidLat: 37.7809000
	},
	{
		geoid: "50017",
		name: "Orange",
		state: "Vermont",
		countyState: "Orange County, Vermont",
		centroidLng: -72.4114000,
		centroidLat: 43.9960000
	},
	{
		geoid: "20113",
		name: "McPherson",
		state: "Kansas",
		countyState: "McPherson County, Kansas",
		centroidLng: -97.6484000,
		centroidLat: 38.3917000
	},
	{
		geoid: "04021",
		name: "Pinal",
		state: "Arizona",
		countyState: "Pinal County, Arizona",
		centroidLng: -111.3260000,
		centroidLat: 32.9836000
	},
	{
		geoid: "39051",
		name: "Fulton",
		state: "Ohio",
		countyState: "Fulton County, Ohio",
		centroidLng: -84.1385000,
		centroidLat: 41.6029000
	},
	{
		geoid: "08003",
		name: "Alamosa",
		state: "Colorado",
		countyState: "Alamosa County, Colorado",
		centroidLng: -105.7460000,
		centroidLat: 37.5543000
	},
	{
		geoid: "12017",
		name: "Citrus",
		state: "Florida",
		countyState: "Citrus County, Florida",
		centroidLng: -82.5087000,
		centroidLat: 28.8591000
	},
	{
		geoid: "72049",
		name: "Culebra",
		state: "Puerto Rico",
		countyState: "Culebra County, Puerto Rico",
		centroidLng: -65.2965000,
		centroidLat: 18.3237000
	},
	{
		geoid: "72051",
		name: "Dorado",
		state: "Puerto Rico",
		countyState: "Dorado County, Puerto Rico",
		centroidLng: -66.2523000,
		centroidLat: 18.4564000
	},
	{
		geoid: "47041",
		name: "DeKalb",
		state: "Tennessee",
		countyState: "DeKalb County, Tennessee",
		centroidLng: -85.8469000,
		centroidLat: 35.9786000
	},
	{
		geoid: "42031",
		name: "Clarion",
		state: "Pennsylvania",
		countyState: "Clarion County, Pennsylvania",
		centroidLng: -79.4538000,
		centroidLat: 41.2023000
	},
	{
		geoid: "46097",
		name: "Miner",
		state: "South Dakota",
		countyState: "Miner County, South Dakota",
		centroidLng: -97.6104000,
		centroidLat: 44.0220000
	},
	{
		geoid: "46073",
		name: "Jerauld",
		state: "South Dakota",
		countyState: "Jerauld County, South Dakota",
		centroidLng: -98.6293000,
		centroidLat: 44.0659000
	},
	{
		geoid: "18031",
		name: "Decatur",
		state: "Indiana",
		countyState: "Decatur County, Indiana",
		centroidLng: -85.4917000,
		centroidLat: 39.2921000
	},
	{
		geoid: "18037",
		name: "Dubois",
		state: "Indiana",
		countyState: "Dubois County, Indiana",
		centroidLng: -86.8761000,
		centroidLat: 38.3647000
	},
	{
		geoid: "51057",
		name: "Essex",
		state: "Virginia",
		countyState: "Essex County, Virginia",
		centroidLng: -76.9191000,
		centroidLat: 37.9453000
	},
	{
		geoid: "45089",
		name: "Williamsburg",
		state: "South Carolina",
		countyState: "Williamsburg County, South Carolina",
		centroidLng: -79.7094000,
		centroidLat: 33.5983000
	},
	{
		geoid: "36031",
		name: "Essex",
		state: "New York",
		countyState: "Essex County, New York",
		centroidLng: -73.8152000,
		centroidLat: 44.1457000
	},
	{
		geoid: "19195",
		name: "Worth",
		state: "Iowa",
		countyState: "Worth County, Iowa",
		centroidLng: -93.2609000,
		centroidLat: 43.3774000
	},
	{
		geoid: "40063",
		name: "Hughes",
		state: "Oklahoma",
		countyState: "Hughes County, Oklahoma",
		centroidLng: -96.2360000,
		centroidLat: 35.0285000
	},
	{
		geoid: "51027",
		name: "Buchanan",
		state: "Virginia",
		countyState: "Buchanan County, Virginia",
		centroidLng: -82.0271000,
		centroidLat: 37.2881000
	},
	{
		geoid: "51005",
		name: "Alleghany",
		state: "Virginia",
		countyState: "Alleghany County, Virginia",
		centroidLng: -79.9512000,
		centroidLat: 37.7785000
	},
	{
		geoid: "28087",
		name: "Lowndes",
		state: "Mississippi",
		countyState: "Lowndes County, Mississippi",
		centroidLng: -88.4601000,
		centroidLat: 33.5164000
	},
	{
		geoid: "18143",
		name: "Scott",
		state: "Indiana",
		countyState: "Scott County, Indiana",
		centroidLng: -85.7292000,
		centroidLat: 38.6965000
	},
	{
		geoid: "08099",
		name: "Prowers",
		state: "Colorado",
		countyState: "Prowers County, Colorado",
		centroidLng: -102.3950000,
		centroidLat: 37.9561000
	},
	{
		geoid: "48435",
		name: "Sutton",
		state: "Texas",
		countyState: "Sutton County, Texas",
		centroidLng: -100.5380000,
		centroidLat: 30.4989000
	},
	{
		geoid: "72121",
		name: "Sabana Grande",
		state: "Puerto Rico",
		countyState: "Sabana Grande County, Puerto Rico",
		centroidLng: -66.9349000,
		centroidLat: 18.0873000
	},
	{
		geoid: "40101",
		name: "Muskogee",
		state: "Oklahoma",
		countyState: "Muskogee County, Oklahoma",
		centroidLng: -95.4081000,
		centroidLat: 35.5592000
	},
	{
		geoid: "29043",
		name: "Christian",
		state: "Missouri",
		countyState: "Christian County, Missouri",
		centroidLng: -93.2565000,
		centroidLat: 36.9537000
	},
	{
		geoid: "72039",
		name: "Ciales",
		state: "Puerto Rico",
		countyState: "Ciales County, Puerto Rico",
		centroidLng: -66.5322000,
		centroidLat: 18.2637000
	},
	{
		geoid: "28073",
		name: "Lamar",
		state: "Mississippi",
		countyState: "Lamar County, Mississippi",
		centroidLng: -89.5011000,
		centroidLat: 31.2083000
	},
	{
		geoid: "18137",
		name: "Ripley",
		state: "Indiana",
		countyState: "Ripley County, Indiana",
		centroidLng: -85.2550000,
		centroidLat: 39.1117000
	},
	{
		geoid: "38057",
		name: "Mercer",
		state: "North Dakota",
		countyState: "Mercer County, North Dakota",
		centroidLng: -101.7310000,
		centroidLat: 47.2778000
	},
	{
		geoid: "13147",
		name: "Hart",
		state: "Georgia",
		countyState: "Hart County, Georgia",
		centroidLng: -82.9446000,
		centroidLat: 34.3519000
	},
	{
		geoid: "22049",
		name: "Jackson",
		state: "Louisiana",
		countyState: "Jackson Parish, Louisiana",
		centroidLng: -92.5669000,
		centroidLat: 32.3210000
	},
	{
		geoid: "51133",
		name: "Northumberland",
		state: "Virginia",
		countyState: "Northumberland County, Virginia",
		centroidLng: -76.4051000,
		centroidLat: 37.8523000
	},
	{
		geoid: "48157",
		name: "Fort Bend",
		state: "Texas",
		countyState: "Fort Bend County, Texas",
		centroidLng: -95.7565000,
		centroidLat: 29.5254000
	},
	{
		geoid: "55047",
		name: "Green Lake",
		state: "Wisconsin",
		countyState: "Green Lake County, Wisconsin",
		centroidLng: -89.0654000,
		centroidLat: 43.8076000
	},
	{
		geoid: "30073",
		name: "Pondera",
		state: "Montana",
		countyState: "Pondera County, Montana",
		centroidLng: -112.3790000,
		centroidLat: 48.2339000
	},
	{
		geoid: "55127",
		name: "Walworth",
		state: "Wisconsin",
		countyState: "Walworth County, Wisconsin",
		centroidLng: -88.5418000,
		centroidLat: 42.6675000
	},
	{
		geoid: "32021",
		name: "Mineral",
		state: "Nevada",
		countyState: "Mineral County, Nevada",
		centroidLng: -118.4240000,
		centroidLat: 38.4842000
	},
	{
		geoid: "17057",
		name: "Fulton",
		state: "Illinois",
		countyState: "Fulton County, Illinois",
		centroidLng: -90.1618000,
		centroidLat: 40.4493000
	},
	{
		geoid: "36103",
		name: "Suffolk",
		state: "New York",
		countyState: "Suffolk County, New York",
		centroidLng: -72.6375000,
		centroidLat: 40.9223000
	},
	{
		geoid: "18161",
		name: "Union",
		state: "Indiana",
		countyState: "Union County, Indiana",
		centroidLng: -84.9253000,
		centroidLat: 39.6256000
	},
	{
		geoid: "29119",
		name: "McDonald",
		state: "Missouri",
		countyState: "McDonald County, Missouri",
		centroidLng: -94.3428000,
		centroidLat: 36.6326000
	},
	{
		geoid: "31157",
		name: "Scotts Bluff",
		state: "Nebraska",
		countyState: "Scotts Bluff County, Nebraska",
		centroidLng: -103.7070000,
		centroidLat: 41.8510000
	},
	{
		geoid: "35047",
		name: "San Miguel",
		state: "New Mexico",
		countyState: "San Miguel County, New Mexico",
		centroidLng: -104.6810000,
		centroidLat: 35.4564000
	},
	{
		geoid: "01081",
		name: "Lee",
		state: "Alabama",
		countyState: "Lee County, Alabama",
		centroidLng: -85.3490000,
		centroidLat: 32.5782000
	},
	{
		geoid: "48367",
		name: "Parker",
		state: "Texas",
		countyState: "Parker County, Texas",
		centroidLng: -97.8055000,
		centroidLat: 32.7793000
	},
	{
		geoid: "38021",
		name: "Dickey",
		state: "North Dakota",
		countyState: "Dickey County, North Dakota",
		centroidLng: -98.5062000,
		centroidLat: 46.1095000
	},
	{
		geoid: "38003",
		name: "Barnes",
		state: "North Dakota",
		countyState: "Barnes County, North Dakota",
		centroidLng: -98.0745000,
		centroidLat: 46.9356000
	},
	{
		geoid: "27143",
		name: "Sibley",
		state: "Minnesota",
		countyState: "Sibley County, Minnesota",
		centroidLng: -94.1966000,
		centroidLat: 44.5869000
	},
	{
		geoid: "13293",
		name: "Upson",
		state: "Georgia",
		countyState: "Upson County, Georgia",
		centroidLng: -84.3007000,
		centroidLat: 33.0019000
	},
	{
		geoid: "06097",
		name: "Sonoma",
		state: "California",
		countyState: "Sonoma County, California",
		centroidLng: -122.9900000,
		centroidLat: 38.4631000
	},
	{
		geoid: "26033",
		name: "Chippewa",
		state: "Michigan",
		countyState: "Chippewa County, Michigan",
		centroidLng: -84.3368000,
		centroidLat: 46.4108000
	},
	{
		geoid: "16019",
		name: "Bonneville",
		state: "Idaho",
		countyState: "Bonneville County, Idaho",
		centroidLng: -111.7820000,
		centroidLat: 43.3232000
	},
	{
		geoid: "13013",
		name: "Barrow",
		state: "Georgia",
		countyState: "Barrow County, Georgia",
		centroidLng: -83.7032000,
		centroidLat: 34.0110000
	},
	{
		geoid: "13183",
		name: "Long",
		state: "Georgia",
		countyState: "Long County, Georgia",
		centroidLng: -81.7367000,
		centroidLat: 31.7768000
	},
	{
		geoid: "48271",
		name: "Kinney",
		state: "Texas",
		countyState: "Kinney County, Texas",
		centroidLng: -100.4540000,
		centroidLat: 29.3541000
	},
	{
		geoid: "40115",
		name: "Ottawa",
		state: "Oklahoma",
		countyState: "Ottawa County, Oklahoma",
		centroidLng: -94.8278000,
		centroidLat: 36.8335000
	},
	{
		geoid: "42033",
		name: "Clearfield",
		state: "Pennsylvania",
		countyState: "Clearfield County, Pennsylvania",
		centroidLng: -78.4224000,
		centroidLat: 40.9890000
	},
	{
		geoid: "26037",
		name: "Clinton",
		state: "Michigan",
		countyState: "Clinton County, Michigan",
		centroidLng: -84.6006000,
		centroidLat: 42.9442000
	},
	{
		geoid: "72133",
		name: "Santa Isabel",
		state: "Puerto Rico",
		countyState: "Santa Isabel County, Puerto Rico",
		centroidLng: -66.3893000,
		centroidLat: 17.9559000
	},
	{
		geoid: "17135",
		name: "Montgomery",
		state: "Illinois",
		countyState: "Montgomery County, Illinois",
		centroidLng: -89.4216000,
		centroidLat: 39.2618000
	},
	{
		geoid: "28003",
		name: "Alcorn",
		state: "Mississippi",
		countyState: "Alcorn County, Mississippi",
		centroidLng: -88.5935000,
		centroidLat: 34.8758000
	},
	{
		geoid: "28071",
		name: "Lafayette",
		state: "Mississippi",
		countyState: "Lafayette County, Mississippi",
		centroidLng: -89.4839000,
		centroidLat: 34.3582000
	},
	{
		geoid: "22043",
		name: "Grant",
		state: "Louisiana",
		countyState: "Grant Parish, Louisiana",
		centroidLng: -92.5848000,
		centroidLat: 31.5925000
	},
	{
		geoid: "29005",
		name: "Atchison",
		state: "Missouri",
		countyState: "Atchison County, Missouri",
		centroidLng: -95.4760000,
		centroidLat: 40.4235000
	},
	{
		geoid: "17115",
		name: "Macon",
		state: "Illinois",
		countyState: "Macon County, Illinois",
		centroidLng: -88.9815000,
		centroidLat: 39.8540000
	},
	{
		geoid: "26149",
		name: "St. Joseph",
		state: "Michigan",
		countyState: "St. Joseph County, Michigan",
		centroidLng: -85.5419000,
		centroidLat: 41.9153000
	},
	{
		geoid: "17055",
		name: "Franklin",
		state: "Illinois",
		countyState: "Franklin County, Illinois",
		centroidLng: -88.9415000,
		centroidLat: 37.9943000
	},
	{
		geoid: "30033",
		name: "Garfield",
		state: "Montana",
		countyState: "Garfield County, Montana",
		centroidLng: -107.0280000,
		centroidLat: 47.4140000
	},
	{
		geoid: "31001",
		name: "Adams",
		state: "Nebraska",
		countyState: "Adams County, Nebraska",
		centroidLng: -98.5011000,
		centroidLat: 40.5245000
	},
	{
		geoid: "27109",
		name: "Olmsted",
		state: "Minnesota",
		countyState: "Olmsted County, Minnesota",
		centroidLng: -92.3839000,
		centroidLat: 44.0146000
	},
	{
		geoid: "34039",
		name: "Union",
		state: "New Jersey",
		countyState: "Union County, New Jersey",
		centroidLng: -74.2995000,
		centroidLat: 40.6658000
	},
	{
		geoid: "72139",
		name: "Trujillo Alto",
		state: "Puerto Rico",
		countyState: "Trujillo Alto County, Puerto Rico",
		centroidLng: -65.9902000,
		centroidLat: 18.3370000
	},
	{
		geoid: "01097",
		name: "Mobile",
		state: "Alabama",
		countyState: "Mobile County, Alabama",
		centroidLng: -88.1780000,
		centroidLat: 30.6589000
	},
	{
		geoid: "46087",
		name: "McCook",
		state: "South Dakota",
		countyState: "McCook County, South Dakota",
		centroidLng: -97.3690000,
		centroidLat: 43.6744000
	},
	{
		geoid: "01057",
		name: "Fayette",
		state: "Alabama",
		countyState: "Fayette County, Alabama",
		centroidLng: -87.6879000,
		centroidLat: 33.7197000
	},
	{
		geoid: "01017",
		name: "Chambers",
		state: "Alabama",
		countyState: "Chambers County, Alabama",
		centroidLng: -85.3579000,
		centroidLat: 32.9188000
	},
	{
		geoid: "37131",
		name: "Northampton",
		state: "North Carolina",
		countyState: "Northampton County, North Carolina",
		centroidLng: -77.4831000,
		centroidLat: 36.3561000
	},
	{
		geoid: "08081",
		name: "Moffat",
		state: "Colorado",
		countyState: "Moffat County, Colorado",
		centroidLng: -108.1820000,
		centroidLat: 40.6115000
	},
	{
		geoid: "21087",
		name: "Green",
		state: "Kentucky",
		countyState: "Green County, Kentucky",
		centroidLng: -85.5250000,
		centroidLat: 37.2906000
	},
	{
		geoid: "18047",
		name: "Franklin",
		state: "Indiana",
		countyState: "Franklin County, Indiana",
		centroidLng: -85.0570000,
		centroidLat: 39.3976000
	},
	{
		geoid: "37013",
		name: "Beaufort",
		state: "North Carolina",
		countyState: "Beaufort County, North Carolina",
		centroidLng: -76.8318000,
		centroidLat: 35.4778000
	},
	{
		geoid: "72073",
		name: "Jayuya",
		state: "Puerto Rico",
		countyState: "Jayuya County, Puerto Rico",
		centroidLng: -66.5928000,
		centroidLat: 18.2250000
	},
	{
		geoid: "38015",
		name: "Burleigh",
		state: "North Dakota",
		countyState: "Burleigh County, North Dakota",
		centroidLng: -100.5200000,
		centroidLat: 46.9805000
	},
	{
		geoid: "37159",
		name: "Rowan",
		state: "North Carolina",
		countyState: "Rowan County, North Carolina",
		centroidLng: -80.4771000,
		centroidLat: 35.6827000
	},
	{
		geoid: "26071",
		name: "Iron",
		state: "Michigan",
		countyState: "Iron County, Michigan",
		centroidLng: -88.5539000,
		centroidLat: 46.1705000
	},
	{
		geoid: "27067",
		name: "Kandiyohi",
		state: "Minnesota",
		countyState: "Kandiyohi County, Minnesota",
		centroidLng: -95.0061000,
		centroidLat: 45.1521000
	},
	{
		geoid: "21237",
		name: "Wolfe",
		state: "Kentucky",
		countyState: "Wolfe County, Kentucky",
		centroidLng: -83.4754000,
		centroidLat: 37.7405000
	},
	{
		geoid: "48149",
		name: "Fayette",
		state: "Texas",
		countyState: "Fayette County, Texas",
		centroidLng: -96.9439000,
		centroidLat: 29.8962000
	},
	{
		geoid: "46081",
		name: "Lawrence",
		state: "South Dakota",
		countyState: "Lawrence County, South Dakota",
		centroidLng: -103.7540000,
		centroidLat: 44.3727000
	},
	{
		geoid: "23029",
		name: "Washington",
		state: "Maine",
		countyState: "Washington County, Maine",
		centroidLng: -67.4952000,
		centroidLat: 45.0031000
	},
	{
		geoid: "48045",
		name: "Briscoe",
		state: "Texas",
		countyState: "Briscoe County, Texas",
		centroidLng: -101.2080000,
		centroidLat: 34.5303000
	},
	{
		geoid: "26131",
		name: "Ontonagon",
		state: "Michigan",
		countyState: "Ontonagon County, Michigan",
		centroidLng: -89.4878000,
		centroidLat: 47.0234000
	},
	{
		geoid: "28015",
		name: "Carroll",
		state: "Mississippi",
		countyState: "Carroll County, Mississippi",
		centroidLng: -89.9101000,
		centroidLat: 33.4457000
	},
	{
		geoid: "41003",
		name: "Benton",
		state: "Oregon",
		countyState: "Benton County, Oregon",
		centroidLng: -123.4450000,
		centroidLat: 44.4990000
	},
	{
		geoid: "27133",
		name: "Rock",
		state: "Minnesota",
		countyState: "Rock County, Minnesota",
		centroidLng: -96.2529000,
		centroidLat: 43.6748000
	},
	{
		geoid: "42063",
		name: "Indiana",
		state: "Pennsylvania",
		countyState: "Indiana County, Pennsylvania",
		centroidLng: -79.1294000,
		centroidLat: 40.6400000
	},
	{
		geoid: "22061",
		name: "Lincoln",
		state: "Louisiana",
		countyState: "Lincoln Parish, Louisiana",
		centroidLng: -92.6482000,
		centroidLat: 32.6064000
	},
	{
		geoid: "42099",
		name: "Perry",
		state: "Pennsylvania",
		countyState: "Perry County, Pennsylvania",
		centroidLng: -77.2934000,
		centroidLat: 40.4068000
	},
	{
		geoid: "46137",
		name: "Ziebach",
		state: "South Dakota",
		countyState: "Ziebach County, South Dakota",
		centroidLng: -101.5680000,
		centroidLat: 44.9920000
	},
	{
		geoid: "48203",
		name: "Harrison",
		state: "Texas",
		countyState: "Harrison County, Texas",
		centroidLng: -94.3725000,
		centroidLat: 32.5598000
	},
	{
		geoid: "26013",
		name: "Baraga",
		state: "Michigan",
		countyState: "Baraga County, Michigan",
		centroidLng: -88.3353000,
		centroidLat: 46.7319000
	},
	{
		geoid: "40131",
		name: "Rogers",
		state: "Oklahoma",
		countyState: "Rogers County, Oklahoma",
		centroidLng: -95.5718000,
		centroidLat: 36.3365000
	},
	{
		geoid: "29019",
		name: "Boone",
		state: "Missouri",
		countyState: "Boone County, Missouri",
		centroidLng: -92.3373000,
		centroidLat: 38.9460000
	},
	{
		geoid: "72097",
		name: "Mayag?ez",
		state: "Puerto Rico",
		countyState: "Mayag?ez County, Puerto Rico",
		centroidLng: -67.1358000,
		centroidLat: 18.2115000
	},
	{
		geoid: "40015",
		name: "Caddo",
		state: "Oklahoma",
		countyState: "Caddo County, Oklahoma",
		centroidLng: -98.3575000,
		centroidLat: 35.2033000
	},
	{
		geoid: "08059",
		name: "Jefferson",
		state: "Colorado",
		countyState: "Jefferson County, Colorado",
		centroidLng: -105.2240000,
		centroidLat: 39.5220000
	},
	{
		geoid: "54011",
		name: "Cabell",
		state: "West Virginia",
		countyState: "Cabell County, West Virginia",
		centroidLng: -82.2777000,
		centroidLat: 38.4134000
	},
	{
		geoid: "48383",
		name: "Reagan",
		state: "Texas",
		countyState: "Reagan County, Texas",
		centroidLng: -101.5220000,
		centroidLat: 31.3654000
	},
	{
		geoid: "05141",
		name: "Van Buren",
		state: "Arkansas",
		countyState: "Van Buren County, Arkansas",
		centroidLng: -92.5456000,
		centroidLat: 35.5773000
	},
	{
		geoid: "08117",
		name: "Summit",
		state: "Colorado",
		countyState: "Summit County, Colorado",
		centroidLng: -106.1080000,
		centroidLat: 39.6411000
	},
	{
		geoid: "20035",
		name: "Cowley",
		state: "Kansas",
		countyState: "Cowley County, Kansas",
		centroidLng: -96.8390000,
		centroidLat: 37.2377000
	},
	{
		geoid: "05071",
		name: "Johnson",
		state: "Arkansas",
		countyState: "Johnson County, Arkansas",
		centroidLng: -93.4373000,
		centroidLat: 35.5477000
	},
	{
		geoid: "36041",
		name: "Hamilton",
		state: "New York",
		countyState: "Hamilton County, New York",
		centroidLng: -74.4569000,
		centroidLat: 43.6676000
	},
	{
		geoid: "51045",
		name: "Craig",
		state: "Virginia",
		countyState: "Craig County, Virginia",
		centroidLng: -80.2209000,
		centroidLat: 37.4883000
	},
	{
		geoid: "48083",
		name: "Coleman",
		state: "Texas",
		countyState: "Coleman County, Texas",
		centroidLng: -99.4591000,
		centroidLat: 31.7459000
	},
	{
		geoid: "02290",
		name: "Yukon-Koyukuk",
		state: "Alaska",
		countyState: "Yukon-Koyukuk Borough, Alaska",
		centroidLng: -151.0250000,
		centroidLat: 65.2230000
	},
	{
		geoid: "04017",
		name: "Navajo",
		state: "Arizona",
		countyState: "Navajo County, Arizona",
		centroidLng: -110.2890000,
		centroidLat: 35.2854000
	},
	{
		geoid: "28085",
		name: "Lincoln",
		state: "Mississippi",
		countyState: "Lincoln County, Mississippi",
		centroidLng: -90.4907000,
		centroidLat: 31.5334000
	},
	{
		geoid: "48357",
		name: "Ochiltree",
		state: "Texas",
		countyState: "Ochiltree County, Texas",
		centroidLng: -100.8160000,
		centroidLat: 36.2782000
	},
	{
		geoid: "48103",
		name: "Crane",
		state: "Texas",
		countyState: "Crane County, Texas",
		centroidLng: -102.5340000,
		centroidLat: 31.3690000
	},
	{
		geoid: "56003",
		name: "Big Horn",
		state: "Wyoming",
		countyState: "Big Horn County, Wyoming",
		centroidLng: -107.8840000,
		centroidLat: 44.5832000
	},
	{
		geoid: "47081",
		name: "Hickman",
		state: "Tennessee",
		countyState: "Hickman County, Tennessee",
		centroidLng: -87.4726000,
		centroidLat: 35.8010000
	},
	{
		geoid: "28143",
		name: "Tunica",
		state: "Mississippi",
		countyState: "Tunica County, Mississippi",
		centroidLng: -90.3935000,
		centroidLat: 34.6579000
	},
	{
		geoid: "29081",
		name: "Harrison",
		state: "Missouri",
		countyState: "Harrison County, Missouri",
		centroidLng: -93.9978000,
		centroidLat: 40.3552000
	},
	{
		geoid: "72029",
		name: "Can?vanas",
		state: "Puerto Rico",
		countyState: "Can?vanas County, Puerto Rico",
		centroidLng: -65.8839000,
		centroidLat: 18.3332000
	},
	{
		geoid: "18005",
		name: "Bartholomew",
		state: "Indiana",
		countyState: "Bartholomew County, Indiana",
		centroidLng: -85.8851000,
		centroidLat: 39.1931000
	},
	{
		geoid: "13223",
		name: "Paulding",
		state: "Georgia",
		countyState: "Paulding County, Georgia",
		centroidLng: -84.8868000,
		centroidLat: 33.9287000
	},
	{
		geoid: "39015",
		name: "Brown",
		state: "Ohio",
		countyState: "Brown County, Ohio",
		centroidLng: -83.8629000,
		centroidLat: 38.9470000
	},
	{
		geoid: "47141",
		name: "Putnam",
		state: "Tennessee",
		countyState: "Putnam County, Tennessee",
		centroidLng: -85.4548000,
		centroidLat: 36.1418000
	},
	{
		geoid: "38101",
		name: "Ward",
		state: "North Dakota",
		countyState: "Ward County, North Dakota",
		centroidLng: -101.6020000,
		centroidLat: 48.3274000
	},
	{
		geoid: "25027",
		name: "Worcester",
		state: "Massachusetts",
		countyState: "Worcester County, Massachusetts",
		centroidLng: -71.8970000,
		centroidLat: 42.3648000
	},
	{
		geoid: "39091",
		name: "Logan",
		state: "Ohio",
		countyState: "Logan County, Ohio",
		centroidLng: -83.7674000,
		centroidLat: 40.3840000
	},
	{
		geoid: "06031",
		name: "Kings",
		state: "California",
		countyState: "Kings County, California",
		centroidLng: -119.8950000,
		centroidLat: 36.1388000
	},
	{
		geoid: "06073",
		name: "San Diego",
		state: "California",
		countyState: "San Diego County, California",
		centroidLng: -116.8460000,
		centroidLat: 33.0169000
	},
	{
		geoid: "72123",
		name: "Salinas",
		state: "Puerto Rico",
		countyState: "Salinas County, Puerto Rico",
		centroidLng: -66.2544000,
		centroidLat: 17.9791000
	},
	{
		geoid: "13079",
		name: "Crawford",
		state: "Georgia",
		countyState: "Crawford County, Georgia",
		centroidLng: -83.9519000,
		centroidLat: 32.6935000
	},
	{
		geoid: "72103",
		name: "Naguabo",
		state: "Puerto Rico",
		countyState: "Naguabo County, Puerto Rico",
		centroidLng: -65.7431000,
		centroidLat: 18.2063000
	},
	{
		geoid: "29037",
		name: "Cass",
		state: "Missouri",
		countyState: "Cass County, Missouri",
		centroidLng: -94.3386000,
		centroidLat: 38.6462000
	},
	{
		geoid: "36027",
		name: "Dutchess",
		state: "New York",
		countyState: "Dutchess County, New York",
		centroidLng: -73.7437000,
		centroidLat: 41.7599000
	},
	{
		geoid: "48477",
		name: "Washington",
		state: "Texas",
		countyState: "Washington County, Texas",
		centroidLng: -96.4377000,
		centroidLat: 30.2221000
	},
	{
		geoid: "08101",
		name: "Pueblo",
		state: "Colorado",
		countyState: "Pueblo County, Colorado",
		centroidLng: -104.5520000,
		centroidLat: 38.1286000
	},
	{
		geoid: "29067",
		name: "Douglas",
		state: "Missouri",
		countyState: "Douglas County, Missouri",
		centroidLng: -92.4990000,
		centroidLat: 36.9323000
	},
	{
		geoid: "38009",
		name: "Bottineau",
		state: "North Dakota",
		countyState: "Bottineau County, North Dakota",
		centroidLng: -100.8210000,
		centroidLat: 48.7722000
	},
	{
		geoid: "37051",
		name: "Cumberland",
		state: "North Carolina",
		countyState: "Cumberland County, North Carolina",
		centroidLng: -78.8038000,
		centroidLat: 35.0509000
	},
	{
		geoid: "56017",
		name: "Hot Springs",
		state: "Wyoming",
		countyState: "Hot Springs County, Wyoming",
		centroidLng: -108.4540000,
		centroidLat: 43.7698000
	},
	{
		geoid: "26001",
		name: "Alcona",
		state: "Michigan",
		countyState: "Alcona County, Michigan",
		centroidLng: -83.1091000,
		centroidLat: 44.6865000
	},
	{
		geoid: "06061",
		name: "Placer",
		state: "California",
		countyState: "Placer County, California",
		centroidLng: -120.7430000,
		centroidLat: 39.0140000
	},
	{
		geoid: "05129",
		name: "Searcy",
		state: "Arkansas",
		countyState: "Searcy County, Arkansas",
		centroidLng: -92.6814000,
		centroidLat: 35.9167000
	},
	{
		geoid: "48305",
		name: "Lynn",
		state: "Texas",
		countyState: "Lynn County, Texas",
		centroidLng: -101.8160000,
		centroidLat: 33.1772000
	},
	{
		geoid: "36077",
		name: "Otsego",
		state: "New York",
		countyState: "Otsego County, New York",
		centroidLng: -75.0238000,
		centroidLat: 42.6112000
	},
	{
		geoid: "37153",
		name: "Richmond",
		state: "North Carolina",
		countyState: "Richmond County, North Carolina",
		centroidLng: -79.7672000,
		centroidLat: 34.9945000
	},
	{
		geoid: "37093",
		name: "Hoke",
		state: "North Carolina",
		countyState: "Hoke County, North Carolina",
		centroidLng: -79.2468000,
		centroidLat: 35.0238000
	},
	{
		geoid: "31131",
		name: "Otoe",
		state: "Nebraska",
		countyState: "Otoe County, Nebraska",
		centroidLng: -96.0869000,
		centroidLat: 40.6535000
	},
	{
		geoid: "42023",
		name: "Cameron",
		state: "Pennsylvania",
		countyState: "Cameron County, Pennsylvania",
		centroidLng: -78.2048000,
		centroidLat: 41.4176000
	},
	{
		geoid: "45035",
		name: "Dorchester",
		state: "South Carolina",
		countyState: "Dorchester County, South Carolina",
		centroidLng: -80.4355000,
		centroidLat: 33.0763000
	},
	{
		geoid: "27161",
		name: "Waseca",
		state: "Minnesota",
		countyState: "Waseca County, Minnesota",
		centroidLng: -93.5872000,
		centroidLat: 44.0221000
	},
	{
		geoid: "46015",
		name: "Brule",
		state: "South Dakota",
		countyState: "Brule County, South Dakota",
		centroidLng: -99.1318000,
		centroidLat: 43.7172000
	},
	{
		geoid: "47177",
		name: "Warren",
		state: "Tennessee",
		countyState: "Warren County, Tennessee",
		centroidLng: -85.7776000,
		centroidLat: 35.6784000
	},
	{
		geoid: "17023",
		name: "Clark",
		state: "Illinois",
		countyState: "Clark County, Illinois",
		centroidLng: -87.7728000,
		centroidLat: 39.3224000
	},
	{
		geoid: "21201",
		name: "Robertson",
		state: "Kentucky",
		countyState: "Robertson County, Kentucky",
		centroidLng: -84.0571000,
		centroidLat: 38.5155000
	},
	{
		geoid: "47095",
		name: "Lake",
		state: "Tennessee",
		countyState: "Lake County, Tennessee",
		centroidLng: -89.5229000,
		centroidLat: 36.3443000
	},
	{
		geoid: "45015",
		name: "Berkeley",
		state: "South Carolina",
		countyState: "Berkeley County, South Carolina",
		centroidLng: -79.9042000,
		centroidLat: 33.1630000
	},
	{
		geoid: "05051",
		name: "Garland",
		state: "Arkansas",
		countyState: "Garland County, Arkansas",
		centroidLng: -93.0994000,
		centroidLat: 34.5813000
	},
	{
		geoid: "20139",
		name: "Osage",
		state: "Kansas",
		countyState: "Osage County, Kansas",
		centroidLng: -95.7254000,
		centroidLat: 38.6518000
	},
	{
		geoid: "39155",
		name: "Trumbull",
		state: "Ohio",
		countyState: "Trumbull County, Ohio",
		centroidLng: -80.7610000,
		centroidLat: 41.3174000
	},
	{
		geoid: "48333",
		name: "Mills",
		state: "Texas",
		countyState: "Mills County, Texas",
		centroidLng: -98.6309000,
		centroidLat: 31.4771000
	},
	{
		geoid: "48359",
		name: "Oldham",
		state: "Texas",
		countyState: "Oldham County, Texas",
		centroidLng: -102.6030000,
		centroidLat: 35.4053000
	},
	{
		geoid: "42019",
		name: "Butler",
		state: "Pennsylvania",
		countyState: "Butler County, Pennsylvania",
		centroidLng: -79.9276000,
		centroidLat: 40.9212000
	},
	{
		geoid: "16049",
		name: "Idaho",
		state: "Idaho",
		countyState: "Idaho County, Idaho",
		centroidLng: -115.5570000,
		centroidLat: 45.8894000
	},
	{
		geoid: "20115",
		name: "Marion",
		state: "Kansas",
		countyState: "Marion County, Kansas",
		centroidLng: -97.0958000,
		centroidLat: 38.3476000
	},
	{
		geoid: "51029",
		name: "Buckingham",
		state: "Virginia",
		countyState: "Buckingham County, Virginia",
		centroidLng: -78.5365000,
		centroidLat: 37.5633000
	},
	{
		geoid: "37025",
		name: "Cabarrus",
		state: "North Carolina",
		countyState: "Cabarrus County, North Carolina",
		centroidLng: -80.5413000,
		centroidLat: 35.3458000
	},
	{
		geoid: "29225",
		name: "Webster",
		state: "Missouri",
		countyState: "Webster County, Missouri",
		centroidLng: -92.8779000,
		centroidLat: 37.2772000
	},
	{
		geoid: "49011",
		name: "Davis",
		state: "Utah",
		countyState: "Davis County, Utah",
		centroidLng: -112.1160000,
		centroidLat: 40.9611000
	},
	{
		geoid: "27033",
		name: "Cottonwood",
		state: "Minnesota",
		countyState: "Cottonwood County, Minnesota",
		centroidLng: -95.1610000,
		centroidLat: 44.0218000
	},
	{
		geoid: "12093",
		name: "Okeechobee",
		state: "Florida",
		countyState: "Okeechobee County, Florida",
		centroidLng: -80.9455000,
		centroidLat: 27.3011000
	},
	{
		geoid: "13291",
		name: "Union",
		state: "Georgia",
		countyState: "Union County, Georgia",
		centroidLng: -83.9798000,
		centroidLat: 34.8141000
	},
	{
		geoid: "19013",
		name: "Black Hawk",
		state: "Iowa",
		countyState: "Black Hawk County, Iowa",
		centroidLng: -92.3094000,
		centroidLat: 42.4697000
	},
	{
		geoid: "48403",
		name: "Sabine",
		state: "Texas",
		countyState: "Sabine County, Texas",
		centroidLng: -93.8219000,
		centroidLat: 31.3730000
	},
	{
		geoid: "39131",
		name: "Pike",
		state: "Ohio",
		countyState: "Pike County, Ohio",
		centroidLng: -83.0858000,
		centroidLat: 39.0728000
	},
	{
		geoid: "01125",
		name: "Tuscaloosa",
		state: "Alabama",
		countyState: "Tuscaloosa County, Alabama",
		centroidLng: -87.4532000,
		centroidLat: 33.3066000
	},
	{
		geoid: "01131",
		name: "Wilcox",
		state: "Alabama",
		countyState: "Wilcox County, Alabama",
		centroidLng: -87.2623000,
		centroidLat: 32.0481000
	},
	{
		geoid: "28097",
		name: "Montgomery",
		state: "Mississippi",
		countyState: "Montgomery County, Mississippi",
		centroidLng: -89.5838000,
		centroidLat: 33.4817000
	},
	{
		geoid: "31099",
		name: "Kearney",
		state: "Nebraska",
		countyState: "Kearney County, Nebraska",
		centroidLng: -98.9517000,
		centroidLat: 40.5200000
	},
	{
		geoid: "31087",
		name: "Hitchcock",
		state: "Nebraska",
		countyState: "Hitchcock County, Nebraska",
		centroidLng: -101.0420000,
		centroidLat: 40.1766000
	},
	{
		geoid: "10003",
		name: "New Castle",
		state: "Delaware",
		countyState: "New Castle County, Delaware",
		centroidLng: -75.5972000,
		centroidLat: 39.5649000
	},
	{
		geoid: "16081",
		name: "Teton",
		state: "Idaho",
		countyState: "Teton County, Idaho",
		centroidLng: -111.2230000,
		centroidLat: 43.7427000
	},
	{
		geoid: "17161",
		name: "Rock Island",
		state: "Illinois",
		countyState: "Rock Island County, Illinois",
		centroidLng: -90.6160000,
		centroidLat: 41.5549000
	},
	{
		geoid: "51073",
		name: "Gloucester",
		state: "Virginia",
		countyState: "Gloucester County, Virginia",
		centroidLng: -76.5084000,
		centroidLat: 37.4182000
	},
	{
		geoid: "05133",
		name: "Sevier",
		state: "Arkansas",
		countyState: "Sevier County, Arkansas",
		centroidLng: -94.2180000,
		centroidLat: 33.9718000
	},
	{
		geoid: "18017",
		name: "Cass",
		state: "Indiana",
		countyState: "Cass County, Indiana",
		centroidLng: -86.3734000,
		centroidLat: 40.7361000
	},
	{
		geoid: "16077",
		name: "Power",
		state: "Idaho",
		countyState: "Power County, Idaho",
		centroidLng: -112.8120000,
		centroidLat: 42.7192000
	},
	{
		geoid: "18015",
		name: "Carroll",
		state: "Indiana",
		countyState: "Carroll County, Indiana",
		centroidLng: -86.5738000,
		centroidLat: 40.5843000
	},
	{
		geoid: "42101",
		name: "Philadelphia",
		state: "Pennsylvania",
		countyState: "Philadelphia County, Pennsylvania",
		centroidLng: -75.1180000,
		centroidLat: 40.0025000
	},
	{
		geoid: "56043",
		name: "Washakie",
		state: "Wyoming",
		countyState: "Washakie County, Wyoming",
		centroidLng: -107.8310000,
		centroidLat: 43.8343000
	},
	{
		geoid: "37029",
		name: "Camden",
		state: "North Carolina",
		countyState: "Camden County, North Carolina",
		centroidLng: -76.2145000,
		centroidLat: 36.3183000
	},
	{
		geoid: "36109",
		name: "Tompkins",
		state: "New York",
		countyState: "Tompkins County, New York",
		centroidLng: -76.4672000,
		centroidLat: 42.4452000
	},
	{
		geoid: "05069",
		name: "Jefferson",
		state: "Arkansas",
		countyState: "Jefferson County, Arkansas",
		centroidLng: -91.8324000,
		centroidLat: 34.2775000
	},
	{
		geoid: "05079",
		name: "Lincoln",
		state: "Arkansas",
		countyState: "Lincoln County, Arkansas",
		centroidLng: -91.6991000,
		centroidLat: 33.9787000
	},
	{
		geoid: "55123",
		name: "Vernon",
		state: "Wisconsin",
		countyState: "Vernon County, Wisconsin",
		centroidLng: -90.7921000,
		centroidLat: 43.5769000
	},
	{
		geoid: "29093",
		name: "Iron",
		state: "Missouri",
		countyState: "Iron County, Missouri",
		centroidLng: -90.8450000,
		centroidLat: 37.5055000
	},
	{
		geoid: "48223",
		name: "Hopkins",
		state: "Texas",
		countyState: "Hopkins County, Texas",
		centroidLng: -95.5855000,
		centroidLat: 33.1688000
	},
	{
		geoid: "36001",
		name: "Albany",
		state: "New York",
		countyState: "Albany County, New York",
		centroidLng: -73.9708000,
		centroidLat: 42.6149000
	},
	{
		geoid: "48225",
		name: "Houston",
		state: "Texas",
		countyState: "Houston County, Texas",
		centroidLng: -95.3676000,
		centroidLat: 31.2594000
	},
	{
		geoid: "39111",
		name: "Monroe",
		state: "Ohio",
		countyState: "Monroe County, Ohio",
		centroidLng: -81.0702000,
		centroidLat: 39.7047000
	},
	{
		geoid: "20165",
		name: "Rush",
		state: "Kansas",
		countyState: "Rush County, Kansas",
		centroidLng: -99.3095000,
		centroidLat: 38.5227000
	},
	{
		geoid: "18107",
		name: "Montgomery",
		state: "Indiana",
		countyState: "Montgomery County, Indiana",
		centroidLng: -86.8938000,
		centroidLat: 40.0399000
	},
	{
		geoid: "20187",
		name: "Stanton",
		state: "Kansas",
		countyState: "Stanton County, Kansas",
		centroidLng: -101.7840000,
		centroidLat: 37.5635000
	},
	{
		geoid: "21129",
		name: "Lee",
		state: "Kentucky",
		countyState: "Lee County, Kentucky",
		centroidLng: -83.7139000,
		centroidLat: 37.6035000
	},
	{
		geoid: "27071",
		name: "Koochiching",
		state: "Minnesota",
		countyState: "Koochiching County, Minnesota",
		centroidLng: -93.7555000,
		centroidLat: 48.2789000
	},
	{
		geoid: "48029",
		name: "Bexar",
		state: "Texas",
		countyState: "Bexar County, Texas",
		centroidLng: -98.4617000,
		centroidLat: 29.4376000
	},
	{
		geoid: "08013",
		name: "Boulder",
		state: "Colorado",
		countyState: "Boulder County, Colorado",
		centroidLng: -105.3740000,
		centroidLat: 40.0878000
	},
	{
		geoid: "47089",
		name: "Jefferson",
		state: "Tennessee",
		countyState: "Jefferson County, Tennessee",
		centroidLng: -83.4724000,
		centroidLat: 36.0439000
	},
	{
		geoid: "40097",
		name: "Mayes",
		state: "Oklahoma",
		countyState: "Mayes County, Oklahoma",
		centroidLng: -95.2228000,
		centroidLat: 36.2926000
	},
	{
		geoid: "27165",
		name: "Watonwan",
		state: "Minnesota",
		countyState: "Watonwan County, Minnesota",
		centroidLng: -94.6143000,
		centroidLat: 43.9785000
	},
	{
		geoid: "16013",
		name: "Blaine",
		state: "Idaho",
		countyState: "Blaine County, Idaho",
		centroidLng: -114.0020000,
		centroidLat: 43.3044000
	},
	{
		geoid: "22053",
		name: "Jefferson Davis",
		state: "Louisiana",
		countyState: "Jefferson Davis Parish, Louisiana",
		centroidLng: -92.8571000,
		centroidLat: 30.2628000
	},
	{
		geoid: "46119",
		name: "Sully",
		state: "South Dakota",
		countyState: "Sully County, South Dakota",
		centroidLng: -100.2010000,
		centroidLat: 44.7229000
	},
	{
		geoid: "08031",
		name: "Denver",
		state: "Colorado",
		countyState: "Denver County, Colorado",
		centroidLng: -104.9330000,
		centroidLat: 39.6993000
	},
	{
		geoid: "40029",
		name: "Coal",
		state: "Oklahoma",
		countyState: "Coal County, Oklahoma",
		centroidLng: -96.3031000,
		centroidLat: 34.5929000
	},
	{
		geoid: "30011",
		name: "Carter",
		state: "Montana",
		countyState: "Carter County, Montana",
		centroidLng: -104.5390000,
		centroidLat: 45.5671000
	},
	{
		geoid: "48461",
		name: "Upton",
		state: "Texas",
		countyState: "Upton County, Texas",
		centroidLng: -102.0470000,
		centroidLat: 31.3657000
	},
	{
		geoid: "18051",
		name: "Gibson",
		state: "Indiana",
		countyState: "Gibson County, Indiana",
		centroidLng: -87.6519000,
		centroidLat: 38.3494000
	},
	{
		geoid: "31105",
		name: "Kimball",
		state: "Nebraska",
		countyState: "Kimball County, Nebraska",
		centroidLng: -103.7150000,
		centroidLat: 41.1980000
	},
	{
		geoid: "17093",
		name: "Kendall",
		state: "Illinois",
		countyState: "Kendall County, Illinois",
		centroidLng: -88.4279000,
		centroidLat: 41.5908000
	},
	{
		geoid: "11001",
		name: "District of Columbia",
		state: "District of Columbia",
		countyState: "District of Columbia County, District of Columbia",
		centroidLng: -77.0146000,
		centroidLat: 38.8937000
	},
	{
		geoid: "08085",
		name: "Montrose",
		state: "Colorado",
		countyState: "Montrose County, Colorado",
		centroidLng: -108.2800000,
		centroidLat: 38.4104000
	},
	{
		geoid: "27171",
		name: "Wright",
		state: "Minnesota",
		countyState: "Wright County, Minnesota",
		centroidLng: -93.8881000,
		centroidLat: 45.2008000
	},
	{
		geoid: "55059",
		name: "Kenosha",
		state: "Wisconsin",
		countyState: "Kenosha County, Wisconsin",
		centroidLng: -87.6629000,
		centroidLat: 42.5808000
	},
	{
		geoid: "08037",
		name: "Eagle",
		state: "Colorado",
		countyState: "Eagle County, Colorado",
		centroidLng: -106.6450000,
		centroidLat: 39.6374000
	},
	{
		geoid: "06075",
		name: "San Francisco",
		state: "California",
		countyState: "San Francisco County, California",
		centroidLng: -123.0550000,
		centroidLat: 37.7314000
	},
	{
		geoid: "08113",
		name: "San Miguel",
		state: "Colorado",
		countyState: "San Miguel County, Colorado",
		centroidLng: -108.3880000,
		centroidLat: 37.9643000
	},
	{
		geoid: "02020",
		name: "Anchorage",
		state: "Alaska",
		countyState: "Anchorage Borough, Alaska",
		centroidLng: -149.4430000,
		centroidLat: 61.1085000
	},
	{
		geoid: "12109",
		name: "St. Johns",
		state: "Florida",
		countyState: "St. Johns County, Florida",
		centroidLng: -81.4206000,
		centroidLat: 29.9377000
	},
	{
		geoid: "28019",
		name: "Choctaw",
		state: "Mississippi",
		countyState: "Choctaw County, Mississippi",
		centroidLng: -89.2711000,
		centroidLat: 33.3210000
	},
	{
		geoid: "29079",
		name: "Grundy",
		state: "Missouri",
		countyState: "Grundy County, Missouri",
		centroidLng: -93.5633000,
		centroidLat: 40.1129000
	},
	{
		geoid: "26085",
		name: "Lake",
		state: "Michigan",
		countyState: "Lake County, Michigan",
		centroidLng: -85.8029000,
		centroidLat: 43.9906000
	},
	{
		geoid: "26139",
		name: "Ottawa",
		state: "Michigan",
		countyState: "Ottawa County, Michigan",
		centroidLng: -86.4449000,
		centroidLat: 42.9856000
	},
	{
		geoid: "17031",
		name: "Cook",
		state: "Illinois",
		countyState: "Cook County, Illinois",
		centroidLng: -87.6873000,
		centroidLat: 41.8120000
	},
	{
		geoid: "17059",
		name: "Gallatin",
		state: "Illinois",
		countyState: "Gallatin County, Illinois",
		centroidLng: -88.2006000,
		centroidLat: 37.7445000
	},
	{
		geoid: "45047",
		name: "Greenwood",
		state: "South Carolina",
		countyState: "Greenwood County, South Carolina",
		centroidLng: -82.1013000,
		centroidLat: 34.1814000
	},
	{
		geoid: "20059",
		name: "Franklin",
		state: "Kansas",
		countyState: "Franklin County, Kansas",
		centroidLng: -95.2827000,
		centroidLat: 38.5642000
	},
	{
		geoid: "27027",
		name: "Clay",
		state: "Minnesota",
		countyState: "Clay County, Minnesota",
		centroidLng: -96.5062000,
		centroidLat: 46.8900000
	},
	{
		geoid: "47075",
		name: "Haywood",
		state: "Tennessee",
		countyState: "Haywood County, Tennessee",
		centroidLng: -89.2858000,
		centroidLat: 35.6099000
	},
	{
		geoid: "49003",
		name: "Box Elder",
		state: "Utah",
		countyState: "Box Elder County, Utah",
		centroidLng: -112.9580000,
		centroidLat: 41.5007000
	},
	{
		geoid: "28139",
		name: "Tippah",
		state: "Mississippi",
		countyState: "Tippah County, Mississippi",
		centroidLng: -88.9032000,
		centroidLat: 34.7961000
	},
	{
		geoid: "54051",
		name: "Marshall",
		state: "West Virginia",
		countyState: "Marshall County, West Virginia",
		centroidLng: -80.6946000,
		centroidLat: 39.8771000
	},
	{
		geoid: "54035",
		name: "Jackson",
		state: "West Virginia",
		countyState: "Jackson County, West Virginia",
		centroidLng: -81.7066000,
		centroidLat: 38.8249000
	},
	{
		geoid: "16039",
		name: "Elmore",
		state: "Idaho",
		countyState: "Elmore County, Idaho",
		centroidLng: -115.6200000,
		centroidLat: 43.4332000
	},
	{
		geoid: "24005",
		name: "Baltimore",
		state: "Maryland",
		countyState: "Baltimore County, Maryland",
		centroidLng: -76.5916000,
		centroidLat: 39.4390000
	},
	{
		geoid: "38005",
		name: "Benson",
		state: "North Dakota",
		countyState: "Benson County, North Dakota",
		centroidLng: -99.1869000,
		centroidLat: 48.1087000
	},
	{
		geoid: "29163",
		name: "Pike",
		state: "Missouri",
		countyState: "Pike County, Missouri",
		centroidLng: -91.0908000,
		centroidLat: 39.3687000
	},
	{
		geoid: "39087",
		name: "Lawrence",
		state: "Ohio",
		countyState: "Lawrence County, Ohio",
		centroidLng: -82.5516000,
		centroidLat: 38.6262000
	},
	{
		geoid: "29201",
		name: "Scott",
		state: "Missouri",
		countyState: "Scott County, Missouri",
		centroidLng: -89.5498000,
		centroidLat: 37.0564000
	},
	{
		geoid: "39001",
		name: "Adams",
		state: "Ohio",
		countyState: "Adams County, Ohio",
		centroidLng: -83.4865000,
		centroidLat: 38.8259000
	},
	{
		geoid: "48143",
		name: "Erath",
		state: "Texas",
		countyState: "Erath County, Texas",
		centroidLng: -98.2080000,
		centroidLat: 32.2153000
	},
	{
		geoid: "13137",
		name: "Habersham",
		state: "Georgia",
		countyState: "Habersham County, Georgia",
		centroidLng: -83.5099000,
		centroidLat: 34.6297000
	},
	{
		geoid: "13319",
		name: "Wilkinson",
		state: "Georgia",
		countyState: "Wilkinson County, Georgia",
		centroidLng: -83.1760000,
		centroidLat: 32.7965000
	},
	{
		geoid: "54061",
		name: "Monongalia",
		state: "West Virginia",
		countyState: "Monongalia County, West Virginia",
		centroidLng: -80.0930000,
		centroidLat: 39.5785000
	},
	{
		geoid: "39033",
		name: "Crawford",
		state: "Ohio",
		countyState: "Crawford County, Ohio",
		centroidLng: -82.9185000,
		centroidLat: 40.8497000
	},
	{
		geoid: "12027",
		name: "DeSoto",
		state: "Florida",
		countyState: "DeSoto County, Florida",
		centroidLng: -81.8099000,
		centroidLat: 27.1862000
	},
	{
		geoid: "09011",
		name: "New London",
		state: "Connecticut",
		countyState: "New London County, Connecticut",
		centroidLng: -72.1272000,
		centroidLat: 41.4647000
	},
	{
		geoid: "39059",
		name: "Guernsey",
		state: "Ohio",
		countyState: "Guernsey County, Ohio",
		centroidLng: -81.4773000,
		centroidLat: 40.0308000
	},
	{
		geoid: "26015",
		name: "Barry",
		state: "Michigan",
		countyState: "Barry County, Michigan",
		centroidLng: -85.3086000,
		centroidLat: 42.5953000
	},
	{
		geoid: "29209",
		name: "Stone",
		state: "Missouri",
		countyState: "Stone County, Missouri",
		centroidLng: -93.4586000,
		centroidLat: 36.7470000
	},
	{
		geoid: "51760",
		name: "Richmond",
		state: "Virginia",
		countyState: "Richmond County, Virginia",
		centroidLng: -77.4933000,
		centroidLat: 37.5247000
	},
	{
		geoid: "37169",
		name: "Stokes",
		state: "North Carolina",
		countyState: "Stokes County, North Carolina",
		centroidLng: -80.2395000,
		centroidLat: 36.4039000
	},
	{
		geoid: "19039",
		name: "Clarke",
		state: "Iowa",
		countyState: "Clarke County, Iowa",
		centroidLng: -93.7856000,
		centroidLat: 41.0294000
	},
	{
		geoid: "19065",
		name: "Fayette",
		state: "Iowa",
		countyState: "Fayette County, Iowa",
		centroidLng: -91.8436000,
		centroidLat: 42.8623000
	},
	{
		geoid: "06041",
		name: "Marin",
		state: "California",
		countyState: "Marin County, California",
		centroidLng: -122.7410000,
		centroidLat: 38.0681000
	},
	{
		geoid: "48381",
		name: "Randall",
		state: "Texas",
		countyState: "Randall County, Texas",
		centroidLng: -101.8960000,
		centroidLat: 34.9654000
	},
	{
		geoid: "48401",
		name: "Rusk",
		state: "Texas",
		countyState: "Rusk County, Texas",
		centroidLng: -94.7192000,
		centroidLat: 32.1257000
	},
	{
		geoid: "19181",
		name: "Warren",
		state: "Iowa",
		countyState: "Warren County, Iowa",
		centroidLng: -93.5594000,
		centroidLat: 41.3363000
	},
	{
		geoid: "39161",
		name: "Van Wert",
		state: "Ohio",
		countyState: "Van Wert County, Ohio",
		centroidLng: -84.5717000,
		centroidLat: 40.8375000
	},
	{
		geoid: "12043",
		name: "Glades",
		state: "Florida",
		countyState: "Glades County, Florida",
		centroidLng: -81.2181000,
		centroidLat: 26.9895000
	},
	{
		geoid: "13199",
		name: "Meriwether",
		state: "Georgia",
		countyState: "Meriwether County, Georgia",
		centroidLng: -84.6776000,
		centroidLat: 33.0381000
	},
	{
		geoid: "08023",
		name: "Costilla",
		state: "Colorado",
		countyState: "Costilla County, Colorado",
		centroidLng: -105.4510000,
		centroidLat: 37.3266000
	},
	{
		geoid: "48099",
		name: "Coryell",
		state: "Texas",
		countyState: "Coryell County, Texas",
		centroidLng: -97.7993000,
		centroidLat: 31.3902000
	},
	{
		geoid: "19001",
		name: "Adair",
		state: "Iowa",
		countyState: "Adair County, Iowa",
		centroidLng: -94.4710000,
		centroidLat: 41.3306000
	},
	{
		geoid: "13053",
		name: "Chattahoochee",
		state: "Georgia",
		countyState: "Chattahoochee County, Georgia",
		centroidLng: -84.8227000,
		centroidLat: 32.3780000
	},
	{
		geoid: "06043",
		name: "Mariposa",
		state: "California",
		countyState: "Mariposa County, California",
		centroidLng: -119.8510000,
		centroidLat: 37.5436000
	},
	{
		geoid: "29089",
		name: "Howard",
		state: "Missouri",
		countyState: "Howard County, Missouri",
		centroidLng: -92.6878000,
		centroidLat: 39.1556000
	},
	{
		geoid: "41047",
		name: "Marion",
		state: "Oregon",
		countyState: "Marion County, Oregon",
		centroidLng: -122.4570000,
		centroidLat: 44.9843000
	},
	{
		geoid: "55079",
		name: "Milwaukee",
		state: "Wisconsin",
		countyState: "Milwaukee County, Wisconsin",
		centroidLng: -87.5697000,
		centroidLat: 43.0173000
	},
	{
		geoid: "27139",
		name: "Scott",
		state: "Minnesota",
		countyState: "Scott County, Minnesota",
		centroidLng: -93.5947000,
		centroidLat: 44.6780000
	},
	{
		geoid: "39129",
		name: "Pickaway",
		state: "Ohio",
		countyState: "Pickaway County, Ohio",
		centroidLng: -82.9991000,
		centroidLat: 39.6403000
	},
	{
		geoid: "51145",
		name: "Powhatan",
		state: "Virginia",
		countyState: "Powhatan County, Virginia",
		centroidLng: -77.8937000,
		centroidLat: 37.5533000
	},
	{
		geoid: "08014",
		name: "Broomfield",
		state: "Colorado",
		countyState: "Broomfield County, Colorado",
		centroidLng: -105.1000000,
		centroidLat: 39.9576000
	},
	{
		geoid: "47099",
		name: "Lawrence",
		state: "Tennessee",
		countyState: "Lawrence County, Tennessee",
		centroidLng: -87.4046000,
		centroidLat: 35.2286000
	},
	{
		geoid: "26009",
		name: "Antrim",
		state: "Michigan",
		countyState: "Antrim County, Michigan",
		centroidLng: -85.1640000,
		centroidLat: 45.0101000
	},
	{
		geoid: "45069",
		name: "Marlboro",
		state: "South Carolina",
		countyState: "Marlboro County, South Carolina",
		centroidLng: -79.6923000,
		centroidLat: 34.5524000
	},
	{
		geoid: "16009",
		name: "Benewah",
		state: "Idaho",
		countyState: "Benewah County, Idaho",
		centroidLng: -116.6850000,
		centroidLat: 47.2182000
	},
	{
		geoid: "17103",
		name: "Lee",
		state: "Illinois",
		countyState: "Lee County, Illinois",
		centroidLng: -89.2855000,
		centroidLat: 41.7487000
	},
	{
		geoid: "31127",
		name: "Nemaha",
		state: "Nebraska",
		countyState: "Nemaha County, Nebraska",
		centroidLng: -95.8090000,
		centroidLat: 40.4119000
	},
	{
		geoid: "37031",
		name: "Carteret",
		state: "North Carolina",
		countyState: "Carteret County, North Carolina",
		centroidLng: -76.5872000,
		centroidLat: 34.8666000
	},
	{
		geoid: "20149",
		name: "Pottawatomie",
		state: "Kansas",
		countyState: "Pottawatomie County, Kansas",
		centroidLng: -96.3815000,
		centroidLat: 39.3464000
	},
	{
		geoid: "26075",
		name: "Jackson",
		state: "Michigan",
		countyState: "Jackson County, Michigan",
		centroidLng: -84.4247000,
		centroidLat: 42.2476000
	},
	{
		geoid: "48091",
		name: "Comal",
		state: "Texas",
		countyState: "Comal County, Texas",
		centroidLng: -98.3228000,
		centroidLat: 29.8162000
	},
	{
		geoid: "25019",
		name: "Nantucket",
		state: "Massachusetts",
		countyState: "Nantucket County, Massachusetts",
		centroidLng: -70.1928000,
		centroidLat: 41.3154000
	},
	{
		geoid: "45009",
		name: "Bamberg",
		state: "South Carolina",
		countyState: "Bamberg County, South Carolina",
		centroidLng: -81.0129000,
		centroidLat: 33.2333000
	},
	{
		geoid: "48007",
		name: "Aransas",
		state: "Texas",
		countyState: "Aransas County, Texas",
		centroidLng: -97.0256000,
		centroidLat: 28.0615000
	},
	{
		geoid: "13019",
		name: "Berrien",
		state: "Georgia",
		countyState: "Berrien County, Georgia",
		centroidLng: -83.2362000,
		centroidLat: 31.2507000
	},
	{
		geoid: "18165",
		name: "Vermillion",
		state: "Indiana",
		countyState: "Vermillion County, Indiana",
		centroidLng: -87.4436000,
		centroidLat: 39.8779000
	},
	{
		geoid: "26143",
		name: "Roscommon",
		state: "Michigan",
		countyState: "Roscommon County, Michigan",
		centroidLng: -84.6111000,
		centroidLat: 44.3360000
	},
	{
		geoid: "37005",
		name: "Alleghany",
		state: "North Carolina",
		countyState: "Alleghany County, North Carolina",
		centroidLng: -81.1274000,
		centroidLat: 36.4705000
	},
	{
		geoid: "06035",
		name: "Lassen",
		state: "California",
		countyState: "Lassen County, California",
		centroidLng: -120.6640000,
		centroidLat: 40.4461000
	},
	{
		geoid: "51610",
		name: "Falls Church",
		state: "Virginia",
		countyState: "Falls Church County, Virginia",
		centroidLng: -77.1723000,
		centroidLat: 38.8862000
	},
	{
		geoid: "12081",
		name: "Manatee",
		state: "Florida",
		countyState: "Manatee County, Florida",
		centroidLng: -82.4387000,
		centroidLat: 27.4272000
	},
	{
		geoid: "39119",
		name: "Muskingum",
		state: "Ohio",
		countyState: "Muskingum County, Ohio",
		centroidLng: -81.9625000,
		centroidLat: 39.9612000
	},
	{
		geoid: "08015",
		name: "Chaffee",
		state: "Colorado",
		countyState: "Chaffee County, Colorado",
		centroidLng: -106.2390000,
		centroidLat: 38.7400000
	},
	{
		geoid: "08025",
		name: "Crowley",
		state: "Colorado",
		countyState: "Crowley County, Colorado",
		centroidLng: -103.7800000,
		centroidLat: 38.3179000
	},
	{
		geoid: "08043",
		name: "Fremont",
		state: "Colorado",
		countyState: "Fremont County, Colorado",
		centroidLng: -105.4770000,
		centroidLat: 38.4776000
	},
	{
		geoid: "28109",
		name: "Pearl River",
		state: "Mississippi",
		countyState: "Pearl River County, Mississippi",
		centroidLng: -89.5965000,
		centroidLat: 30.7365000
	},
	{
		geoid: "28111",
		name: "Perry",
		state: "Mississippi",
		countyState: "Perry County, Mississippi",
		centroidLng: -88.9903000,
		centroidLat: 31.1719000
	},
	{
		geoid: "31041",
		name: "Custer",
		state: "Nebraska",
		countyState: "Custer County, Nebraska",
		centroidLng: -99.7281000,
		centroidLat: 41.3939000
	},
	{
		geoid: "12013",
		name: "Calhoun",
		state: "Florida",
		countyState: "Calhoun County, Florida",
		centroidLng: -85.1611000,
		centroidLat: 30.4040000
	},
	{
		geoid: "21117",
		name: "Kenton",
		state: "Kentucky",
		countyState: "Kenton County, Kentucky",
		centroidLng: -84.5205000,
		centroidLat: 38.9450000
	},
	{
		geoid: "19037",
		name: "Chickasaw",
		state: "Iowa",
		countyState: "Chickasaw County, Iowa",
		centroidLng: -92.3177000,
		centroidLat: 43.0602000
	},
	{
		geoid: "17037",
		name: "DeKalb",
		state: "Illinois",
		countyState: "DeKalb County, Illinois",
		centroidLng: -88.7653000,
		centroidLat: 41.8910000
	},
	{
		geoid: "19081",
		name: "Hancock",
		state: "Iowa",
		countyState: "Hancock County, Iowa",
		centroidLng: -93.7345000,
		centroidLat: 43.0817000
	},
	{
		geoid: "38089",
		name: "Stark",
		state: "North Dakota",
		countyState: "Stark County, North Dakota",
		centroidLng: -102.6640000,
		centroidLat: 46.8201000
	},
	{
		geoid: "26095",
		name: "Luce",
		state: "Michigan",
		countyState: "Luce County, Michigan",
		centroidLng: -85.5497000,
		centroidLat: 46.7751000
	},
	{
		geoid: "40019",
		name: "Carter",
		state: "Oklahoma",
		countyState: "Carter County, Oklahoma",
		centroidLng: -97.2480000,
		centroidLat: 34.2889000
	},
	{
		geoid: "37139",
		name: "Pasquotank",
		state: "North Carolina",
		countyState: "Pasquotank County, North Carolina",
		centroidLng: -76.2570000,
		centroidLat: 36.2851000
	},
	{
		geoid: "35055",
		name: "Taos",
		state: "New Mexico",
		countyState: "Taos County, New Mexico",
		centroidLng: -105.6290000,
		centroidLat: 36.5045000
	},
	{
		geoid: "28011",
		name: "Bolivar",
		state: "Mississippi",
		countyState: "Bolivar County, Mississippi",
		centroidLng: -90.9448000,
		centroidLat: 33.8250000
	},
	{
		geoid: "32023",
		name: "Nye",
		state: "Nevada",
		countyState: "Nye County, Nevada",
		centroidLng: -116.5990000,
		centroidLat: 37.5636000
	},
	{
		geoid: "47073",
		name: "Hawkins",
		state: "Tennessee",
		countyState: "Hawkins County, Tennessee",
		centroidLng: -82.9444000,
		centroidLat: 36.4180000
	},
	{
		geoid: "38011",
		name: "Bowman",
		state: "North Dakota",
		countyState: "Bowman County, North Dakota",
		centroidLng: -103.5210000,
		centroidLat: 46.1128000
	},
	{
		geoid: "42057",
		name: "Fulton",
		state: "Pennsylvania",
		countyState: "Fulton County, Pennsylvania",
		centroidLng: -78.1225000,
		centroidLat: 39.9436000
	},
	{
		geoid: "22055",
		name: "Lafayette",
		state: "Louisiana",
		countyState: "Lafayette Parish, Louisiana",
		centroidLng: -92.0951000,
		centroidLat: 30.2092000
	},
	{
		geoid: "48237",
		name: "Jack",
		state: "Texas",
		countyState: "Jack County, Texas",
		centroidLng: -98.1723000,
		centroidLat: 33.2342000
	},
	{
		geoid: "17175",
		name: "Stark",
		state: "Illinois",
		countyState: "Stark County, Illinois",
		centroidLng: -89.8120000,
		centroidLat: 41.1041000
	},
	{
		geoid: "40055",
		name: "Greer",
		state: "Oklahoma",
		countyState: "Greer County, Oklahoma",
		centroidLng: -99.5675000,
		centroidLat: 34.9208000
	},
	{
		geoid: "48211",
		name: "Hemphill",
		state: "Texas",
		countyState: "Hemphill County, Texas",
		centroidLng: -100.2710000,
		centroidLat: 35.8378000
	},
	{
		geoid: "16069",
		name: "Nez Perce",
		state: "Idaho",
		countyState: "Nez Perce County, Idaho",
		centroidLng: -116.7120000,
		centroidLat: 46.2432000
	},
	{
		geoid: "46129",
		name: "Walworth",
		state: "South Dakota",
		countyState: "Walworth County, South Dakota",
		centroidLng: -100.0970000,
		centroidLat: 45.4203000
	},
	{
		geoid: "06055",
		name: "Napa",
		state: "California",
		countyState: "Napa County, California",
		centroidLng: -122.3540000,
		centroidLat: 38.5096000
	},
	{
		geoid: "40033",
		name: "Cotton",
		state: "Oklahoma",
		countyState: "Cotton County, Oklahoma",
		centroidLng: -98.4005000,
		centroidLat: 34.2847000
	},
	{
		geoid: "29221",
		name: "Washington",
		state: "Missouri",
		countyState: "Washington County, Missouri",
		centroidLng: -90.8700000,
		centroidLat: 37.9704000
	},
	{
		geoid: "36011",
		name: "Cayuga",
		state: "New York",
		countyState: "Cayuga County, New York",
		centroidLng: -76.5096000,
		centroidLat: 43.1634000
	},
	{
		geoid: "48417",
		name: "Shackelford",
		state: "Texas",
		countyState: "Shackelford County, Texas",
		centroidLng: -99.3540000,
		centroidLat: 32.7359000
	},
	{
		geoid: "48407",
		name: "San Jacinto",
		state: "Texas",
		countyState: "San Jacinto County, Texas",
		centroidLng: -95.0944000,
		centroidLat: 30.6131000
	},
	{
		geoid: "19061",
		name: "Dubuque",
		state: "Iowa",
		countyState: "Dubuque County, Iowa",
		centroidLng: -90.8041000,
		centroidLat: 42.4849000
	},
	{
		geoid: "51075",
		name: "Goochland",
		state: "Virginia",
		countyState: "Goochland County, Virginia",
		centroidLng: -77.8878000,
		centroidLat: 37.7343000
	},
	{
		geoid: "20181",
		name: "Sherman",
		state: "Kansas",
		countyState: "Sherman County, Kansas",
		centroidLng: -101.7190000,
		centroidLat: 39.3508000
	},
	{
		geoid: "39139",
		name: "Richland",
		state: "Ohio",
		countyState: "Richland County, Ohio",
		centroidLng: -82.5320000,
		centroidLat: 40.7728000
	},
	{
		geoid: "17001",
		name: "Adams",
		state: "Illinois",
		countyState: "Adams County, Illinois",
		centroidLng: -91.2125000,
		centroidLat: 39.9787000
	},
	{
		geoid: "39021",
		name: "Champaign",
		state: "Ohio",
		countyState: "Champaign County, Ohio",
		centroidLng: -83.7653000,
		centroidLat: 40.1418000
	},
	{
		geoid: "33019",
		name: "Sullivan",
		state: "New Hampshire",
		countyState: "Sullivan County, New Hampshire",
		centroidLng: -72.1941000,
		centroidLat: 43.3645000
	},
	{
		geoid: "17075",
		name: "Iroquois",
		state: "Illinois",
		countyState: "Iroquois County, Illinois",
		centroidLng: -87.8288000,
		centroidLat: 40.7480000
	},
	{
		geoid: "19157",
		name: "Poweshiek",
		state: "Iowa",
		countyState: "Poweshiek County, Iowa",
		centroidLng: -92.5321000,
		centroidLat: 41.6857000
	},
	{
		geoid: "39169",
		name: "Wayne",
		state: "Ohio",
		countyState: "Wayne County, Ohio",
		centroidLng: -81.8885000,
		centroidLat: 40.8292000
	},
	{
		geoid: "13113",
		name: "Fayette",
		state: "Georgia",
		countyState: "Fayette County, Georgia",
		centroidLng: -84.5044000,
		centroidLat: 33.4037000
	},
	{
		geoid: "13141",
		name: "Hancock",
		state: "Georgia",
		countyState: "Hancock County, Georgia",
		centroidLng: -83.0107000,
		centroidLat: 33.2595000
	},
	{
		geoid: "48259",
		name: "Kendall",
		state: "Texas",
		countyState: "Kendall County, Texas",
		centroidLng: -98.6674000,
		centroidLat: 29.9277000
	},
	{
		geoid: "36039",
		name: "Greene",
		state: "New York",
		countyState: "Greene County, New York",
		centroidLng: -74.1555000,
		centroidLat: 42.2804000
	},
	{
		geoid: "51137",
		name: "Orange",
		state: "Virginia",
		countyState: "Orange County, Virginia",
		centroidLng: -78.0358000,
		centroidLat: 38.2555000
	},
	{
		geoid: "40089",
		name: "McCurtain",
		state: "Oklahoma",
		countyState: "McCurtain County, Oklahoma",
		centroidLng: -94.8089000,
		centroidLat: 34.0617000
	},
	{
		geoid: "13277",
		name: "Tift",
		state: "Georgia",
		countyState: "Tift County, Georgia",
		centroidLng: -83.5027000,
		centroidLat: 31.4621000
	},
	{
		geoid: "31025",
		name: "Cass",
		state: "Nebraska",
		countyState: "Cass County, Nebraska",
		centroidLng: -96.1366000,
		centroidLat: 40.9247000
	},
	{
		geoid: "46077",
		name: "Kingsbury",
		state: "South Dakota",
		countyState: "Kingsbury County, South Dakota",
		centroidLng: -97.4907000,
		centroidLat: 44.3696000
	},
	{
		geoid: "56009",
		name: "Converse",
		state: "Wyoming",
		countyState: "Converse County, Wyoming",
		centroidLng: -105.4850000,
		centroidLat: 42.8942000
	},
	{
		geoid: "36043",
		name: "Herkimer",
		state: "New York",
		countyState: "Herkimer County, New York",
		centroidLng: -74.9575000,
		centroidLat: 43.4610000
	},
	{
		geoid: "38047",
		name: "Logan",
		state: "North Dakota",
		countyState: "Logan County, North Dakota",
		centroidLng: -99.4770000,
		centroidLat: 46.4577000
	},
	{
		geoid: "26053",
		name: "Gogebic",
		state: "Michigan",
		countyState: "Gogebic County, Michigan",
		centroidLng: -89.7045000,
		centroidLat: 46.5697000
	},
	{
		geoid: "27005",
		name: "Becker",
		state: "Minnesota",
		countyState: "Becker County, Minnesota",
		centroidLng: -95.6784000,
		centroidLat: 46.9340000
	},
	{
		geoid: "01095",
		name: "Marshall",
		state: "Alabama",
		countyState: "Marshall County, Alabama",
		centroidLng: -86.3198000,
		centroidLat: 34.3496000
	},
	{
		geoid: "47173",
		name: "Union",
		state: "Tennessee",
		countyState: "Union County, Tennessee",
		centroidLng: -83.8386000,
		centroidLat: 36.2984000
	},
	{
		geoid: "54057",
		name: "Mineral",
		state: "West Virginia",
		countyState: "Mineral County, West Virginia",
		centroidLng: -78.9644000,
		centroidLat: 39.4430000
	},
	{
		geoid: "55003",
		name: "Ashland",
		state: "Wisconsin",
		countyState: "Ashland County, Wisconsin",
		centroidLng: -90.4427000,
		centroidLat: 46.6452000
	},
	{
		geoid: "17053",
		name: "Ford",
		state: "Illinois",
		countyState: "Ford County, Illinois",
		centroidLng: -88.1958000,
		centroidLat: 40.6982000
	},
	{
		geoid: "38029",
		name: "Emmons",
		state: "North Dakota",
		countyState: "Emmons County, North Dakota",
		centroidLng: -100.2700000,
		centroidLat: 46.2882000
	},
	{
		geoid: "06089",
		name: "Shasta",
		state: "California",
		countyState: "Shasta County, California",
		centroidLng: -122.1940000,
		centroidLat: 40.7352000
	},
	{
		geoid: "22113",
		name: "Vermilion",
		state: "Louisiana",
		countyState: "Vermilion Parish, Louisiana",
		centroidLng: -92.3515000,
		centroidLat: 29.8155000
	},
	{
		geoid: "05121",
		name: "Randolph",
		state: "Arkansas",
		countyState: "Randolph County, Arkansas",
		centroidLng: -91.0780000,
		centroidLat: 36.3067000
	},
	{
		geoid: "55085",
		name: "Oneida",
		state: "Wisconsin",
		countyState: "Oneida County, Wisconsin",
		centroidLng: -89.5458000,
		centroidLat: 45.6830000
	},
	{
		geoid: "22035",
		name: "East Carroll",
		state: "Louisiana",
		countyState: "East Carroll Parish, Louisiana",
		centroidLng: -91.2302000,
		centroidLat: 32.7713000
	},
	{
		geoid: "22037",
		name: "East Feliciana",
		state: "Louisiana",
		countyState: "East Feliciana Parish, Louisiana",
		centroidLng: -91.0733000,
		centroidLat: 30.8246000
	},
	{
		geoid: "22087",
		name: "St. Bernard",
		state: "Louisiana",
		countyState: "St. Bernard Parish, Louisiana",
		centroidLng: -89.3852000,
		centroidLat: 29.9149000
	},
	{
		geoid: "48481",
		name: "Wharton",
		state: "Texas",
		countyState: "Wharton County, Texas",
		centroidLng: -96.2411000,
		centroidLat: 29.2985000
	},
	{
		geoid: "02198",
		name: "Prince of Wales-Hyder",
		state: "Alaska",
		countyState: "Prince of Wales-Hyder Borough, Alaska",
		centroidLng: -130.1960000,
		centroidLat: 55.9451000
	},
	{
		geoid: "02195",
		name: "Petersburg",
		state: "Alaska",
		countyState: "Petersburg Borough, Alaska",
		centroidLng: -132.8920000,
		centroidLat: 57.0899000
	},
	{
		geoid: "48153",
		name: "Floyd",
		state: "Texas",
		countyState: "Floyd County, Texas",
		centroidLng: -101.3030000,
		centroidLat: 34.0715000
	},
	{
		geoid: "02275",
		name: "Wrangell",
		state: "Alaska",
		countyState: "Wrangell Borough, Alaska",
		centroidLng: -132.0830000,
		centroidLat: 56.2686000
	},
	{
		geoid: "21151",
		name: "Madison",
		state: "Kentucky",
		countyState: "Madison County, Kentucky",
		centroidLng: -84.2997000,
		centroidLat: 37.7164000
	},
	{
		geoid: "47009",
		name: "Blount",
		state: "Tennessee",
		countyState: "Blount County, Tennessee",
		centroidLng: -83.9255000,
		centroidLat: 35.6738000
	},
	{
		geoid: "30031",
		name: "Gallatin",
		state: "Montana",
		countyState: "Gallatin County, Montana",
		centroidLng: -111.2950000,
		centroidLat: 45.3348000
	},
	{
		geoid: "45031",
		name: "Darlington",
		state: "South Carolina",
		countyState: "Darlington County, South Carolina",
		centroidLng: -79.9728000,
		centroidLat: 34.3106000
	},
	{
		geoid: "01053",
		name: "Escambia",
		state: "Alabama",
		countyState: "Escambia County, Alabama",
		centroidLng: -87.1521000,
		centroidLat: 31.1282000
	},
	{
		geoid: "13143",
		name: "Haralson",
		state: "Georgia",
		countyState: "Haralson County, Georgia",
		centroidLng: -85.2116000,
		centroidLat: 33.7792000
	},
	{
		geoid: "20001",
		name: "Allen",
		state: "Kansas",
		countyState: "Allen County, Kansas",
		centroidLng: -95.3017000,
		centroidLat: 37.8855000
	},
	{
		geoid: "17041",
		name: "Douglas",
		state: "Illinois",
		countyState: "Douglas County, Illinois",
		centroidLng: -88.2051000,
		centroidLat: 39.7659000
	},
	{
		geoid: "51079",
		name: "Greene",
		state: "Virginia",
		countyState: "Greene County, Virginia",
		centroidLng: -78.4758000,
		centroidLat: 38.3298000
	},
	{
		geoid: "66010",
		name: "Guam",
		state: "Guam",
		countyState: "Guam County, Guam",
		centroidLng: 144.7860000,
		centroidLat: 13.4443000
	},
	{
		geoid: "55137",
		name: "Waushara",
		state: "Wisconsin",
		countyState: "Waushara County, Wisconsin",
		centroidLng: -89.2419000,
		centroidLat: 44.1139000
	},
	{
		geoid: "05065",
		name: "Izard",
		state: "Arkansas",
		countyState: "Izard County, Arkansas",
		centroidLng: -91.9447000,
		centroidLat: 36.0643000
	},
	{
		geoid: "06053",
		name: "Monterey",
		state: "California",
		countyState: "Monterey County, California",
		centroidLng: -121.1330000,
		centroidLat: 36.3543000
	},
	{
		geoid: "35017",
		name: "Grant",
		state: "New Mexico",
		countyState: "Grant County, New Mexico",
		centroidLng: -108.3280000,
		centroidLat: 32.5365000
	},
	{
		geoid: "29131",
		name: "Miller",
		state: "Missouri",
		countyState: "Miller County, Missouri",
		centroidLng: -92.4386000,
		centroidLat: 38.2236000
	},
	{
		geoid: "37193",
		name: "Wilkes",
		state: "North Carolina",
		countyState: "Wilkes County, North Carolina",
		centroidLng: -81.2071000,
		centroidLat: 36.2178000
	},
	{
		geoid: "02110",
		name: "Juneau",
		state: "Alaska",
		countyState: "Juneau Borough, Alaska",
		centroidLng: -134.1940000,
		centroidLat: 58.3807000
	},
	{
		geoid: "29165",
		name: "Platte",
		state: "Missouri",
		countyState: "Platte County, Missouri",
		centroidLng: -94.8516000,
		centroidLat: 39.3435000
	},
	{
		geoid: "29021",
		name: "Buchanan",
		state: "Missouri",
		countyState: "Buchanan County, Missouri",
		centroidLng: -94.8574000,
		centroidLat: 39.6759000
	},
	{
		geoid: "01083",
		name: "Limestone",
		state: "Alabama",
		countyState: "Limestone County, Alabama",
		centroidLng: -87.0311000,
		centroidLat: 34.7749000
	},
	{
		geoid: "18065",
		name: "Henry",
		state: "Indiana",
		countyState: "Henry County, Indiana",
		centroidLng: -85.3986000,
		centroidLat: 39.9318000
	},
	{
		geoid: "31143",
		name: "Polk",
		state: "Nebraska",
		countyState: "Polk County, Nebraska",
		centroidLng: -97.5982000,
		centroidLat: 41.2214000
	},
	{
		geoid: "39123",
		name: "Ottawa",
		state: "Ohio",
		countyState: "Ottawa County, Ohio",
		centroidLng: -83.0160000,
		centroidLat: 41.6431000
	},
	{
		geoid: "27107",
		name: "Norman",
		state: "Minnesota",
		countyState: "Norman County, Minnesota",
		centroidLng: -96.4692000,
		centroidLat: 47.3251000
	},
	{
		geoid: "19165",
		name: "Shelby",
		state: "Iowa",
		countyState: "Shelby County, Iowa",
		centroidLng: -95.2994000,
		centroidLat: 41.6844000
	},
	{
		geoid: "24013",
		name: "Carroll",
		state: "Maryland",
		countyState: "Carroll County, Maryland",
		centroidLng: -77.0493000,
		centroidLat: 39.5346000
	},
	{
		geoid: "19011",
		name: "Benton",
		state: "Iowa",
		countyState: "Benton County, Iowa",
		centroidLng: -92.0652000,
		centroidLat: 42.0803000
	},
	{
		geoid: "21159",
		name: "Martin",
		state: "Kentucky",
		countyState: "Martin County, Kentucky",
		centroidLng: -82.4926000,
		centroidLat: 37.8157000
	},
	{
		geoid: "27025",
		name: "Chisago",
		state: "Minnesota",
		countyState: "Chisago County, Minnesota",
		centroidLng: -92.8943000,
		centroidLat: 45.5135000
	},
	{
		geoid: "21189",
		name: "Owsley",
		state: "Kentucky",
		countyState: "Owsley County, Kentucky",
		centroidLng: -83.7048000,
		centroidLat: 37.3985000
	},
	{
		geoid: "42015",
		name: "Bradford",
		state: "Pennsylvania",
		countyState: "Bradford County, Pennsylvania",
		centroidLng: -76.5211000,
		centroidLat: 41.7717000
	},
	{
		geoid: "54029",
		name: "Hancock",
		state: "West Virginia",
		countyState: "Hancock County, West Virginia",
		centroidLng: -80.5928000,
		centroidLat: 40.5169000
	},
	{
		geoid: "29051",
		name: "Cole",
		state: "Missouri",
		countyState: "Cole County, Missouri",
		centroidLng: -92.2493000,
		centroidLat: 38.5309000
	},
	{
		geoid: "26021",
		name: "Berrien",
		state: "Michigan",
		countyState: "Berrien County, Michigan",
		centroidLng: -86.7153000,
		centroidLat: 42.0015000
	},
	{
		geoid: "42061",
		name: "Huntingdon",
		state: "Pennsylvania",
		countyState: "Huntingdon County, Pennsylvania",
		centroidLng: -77.9670000,
		centroidLat: 40.4025000
	},
	{
		geoid: "31045",
		name: "Dawes",
		state: "Nebraska",
		countyState: "Dawes County, Nebraska",
		centroidLng: -103.1390000,
		centroidLat: 42.7191000
	},
	{
		geoid: "51141",
		name: "Patrick",
		state: "Virginia",
		countyState: "Patrick County, Virginia",
		centroidLng: -80.3336000,
		centroidLat: 36.7073000
	},
	{
		geoid: "26119",
		name: "Montmorency",
		state: "Michigan",
		countyState: "Montmorency County, Michigan",
		centroidLng: -84.1262000,
		centroidLat: 45.0284000
	},
	{
		geoid: "26135",
		name: "Oscoda",
		state: "Michigan",
		countyState: "Oscoda County, Michigan",
		centroidLng: -84.1294000,
		centroidLat: 44.6819000
	},
	{
		geoid: "35007",
		name: "Colfax",
		state: "New Mexico",
		countyState: "Colfax County, New Mexico",
		centroidLng: -104.6930000,
		centroidLat: 36.6070000
	},
	{
		geoid: "17163",
		name: "St. Clair",
		state: "Illinois",
		countyState: "St. Clair County, Illinois",
		centroidLng: -89.9837000,
		centroidLat: 38.4400000
	},
	{
		geoid: "17189",
		name: "Washington",
		state: "Illinois",
		countyState: "Washington County, Illinois",
		centroidLng: -89.4242000,
		centroidLat: 38.3657000
	},
	{
		geoid: "42009",
		name: "Bedford",
		state: "Pennsylvania",
		countyState: "Bedford County, Pennsylvania",
		centroidLng: -78.4715000,
		centroidLat: 40.0247000
	},
	{
		geoid: "05127",
		name: "Scott",
		state: "Arkansas",
		countyState: "Scott County, Arkansas",
		centroidLng: -94.0797000,
		centroidLat: 34.8826000
	},
	{
		geoid: "20099",
		name: "Labette",
		state: "Kansas",
		countyState: "Labette County, Kansas",
		centroidLng: -95.2982000,
		centroidLat: 37.1918000
	},
	{
		geoid: "42073",
		name: "Lawrence",
		state: "Pennsylvania",
		countyState: "Lawrence County, Pennsylvania",
		centroidLng: -80.3081000,
		centroidLat: 40.9897000
	},
	{
		geoid: "39067",
		name: "Harrison",
		state: "Ohio",
		countyState: "Harrison County, Ohio",
		centroidLng: -81.1025000,
		centroidLat: 40.2965000
	},
	{
		geoid: "37003",
		name: "Alexander",
		state: "North Carolina",
		countyState: "Alexander County, North Carolina",
		centroidLng: -81.1737000,
		centroidLat: 35.9111000
	},
	{
		geoid: "34009",
		name: "Cape May",
		state: "New Jersey",
		countyState: "Cape May County, New Jersey",
		centroidLng: -74.8167000,
		centroidLat: 39.0565000
	},
	{
		geoid: "33005",
		name: "Cheshire",
		state: "New Hampshire",
		countyState: "Cheshire County, New Hampshire",
		centroidLng: -72.2431000,
		centroidLat: 42.9470000
	},
	{
		geoid: "27089",
		name: "Marshall",
		state: "Minnesota",
		countyState: "Marshall County, Minnesota",
		centroidLng: -96.3782000,
		centroidLat: 48.3583000
	},
	{
		geoid: "51101",
		name: "King William",
		state: "Virginia",
		countyState: "King William County, Virginia",
		centroidLng: -77.0614000,
		centroidLat: 37.7139000
	},
	{
		geoid: "08055",
		name: "Huerfano",
		state: "Colorado",
		countyState: "Huerfano County, Colorado",
		centroidLng: -104.9260000,
		centroidLat: 37.6540000
	},
	{
		geoid: "48255",
		name: "Karnes",
		state: "Texas",
		countyState: "Karnes County, Texas",
		centroidLng: -97.8828000,
		centroidLat: 28.9449000
	},
	{
		geoid: "47023",
		name: "Chester",
		state: "Tennessee",
		countyState: "Chester County, Tennessee",
		centroidLng: -88.5969000,
		centroidLat: 35.4199000
	},
	{
		geoid: "17171",
		name: "Scott",
		state: "Illinois",
		countyState: "Scott County, Illinois",
		centroidLng: -90.4734000,
		centroidLat: 39.6551000
	},
	{
		geoid: "49027",
		name: "Millard",
		state: "Utah",
		countyState: "Millard County, Utah",
		centroidLng: -113.0320000,
		centroidLat: 39.0627000
	},
	{
		geoid: "49029",
		name: "Morgan",
		state: "Utah",
		countyState: "Morgan County, Utah",
		centroidLng: -111.5490000,
		centroidLat: 41.0770000
	},
	{
		geoid: "48257",
		name: "Kaufman",
		state: "Texas",
		countyState: "Kaufman County, Texas",
		centroidLng: -96.3028000,
		centroidLat: 32.5982000
	},
	{
		geoid: "04011",
		name: "Greenlee",
		state: "Arizona",
		countyState: "Greenlee County, Arizona",
		centroidLng: -109.2710000,
		centroidLat: 33.1025000
	},
	{
		geoid: "04013",
		name: "Maricopa",
		state: "Arizona",
		countyState: "Maricopa County, Arizona",
		centroidLng: -112.1870000,
		centroidLat: 33.2766000
	},
	{
		geoid: "26129",
		name: "Ogemaw",
		state: "Michigan",
		countyState: "Ogemaw County, Michigan",
		centroidLng: -84.1269000,
		centroidLat: 44.3349000
	},
	{
		geoid: "47087",
		name: "Jackson",
		state: "Tennessee",
		countyState: "Jackson County, Tennessee",
		centroidLng: -85.6684000,
		centroidLat: 36.3676000
	},
	{
		geoid: "17159",
		name: "Richland",
		state: "Illinois",
		countyState: "Richland County, Illinois",
		centroidLng: -88.1028000,
		centroidLat: 38.7096000
	},
	{
		geoid: "01009",
		name: "Blount",
		state: "Alabama",
		countyState: "Blount County, Alabama",
		centroidLng: -86.6334000,
		centroidLat: 34.0128000
	},
	{
		geoid: "30051",
		name: "Liberty",
		state: "Montana",
		countyState: "Liberty County, Montana",
		centroidLng: -111.0760000,
		centroidLat: 48.5654000
	},
	{
		geoid: "48131",
		name: "Duval",
		state: "Texas",
		countyState: "Duval County, Texas",
		centroidLng: -98.5179000,
		centroidLat: 27.6602000
	},
	{
		geoid: "29173",
		name: "Ralls",
		state: "Missouri",
		countyState: "Ralls County, Missouri",
		centroidLng: -91.4482000,
		centroidLat: 39.5026000
	},
	{
		geoid: "48251",
		name: "Johnson",
		state: "Texas",
		countyState: "Johnson County, Texas",
		centroidLng: -97.3517000,
		centroidLat: 32.3446000
	},
	{
		geoid: "13061",
		name: "Clay",
		state: "Georgia",
		countyState: "Clay County, Georgia",
		centroidLng: -84.9701000,
		centroidLat: 31.6332000
	},
	{
		geoid: "41023",
		name: "Grant",
		state: "Oregon",
		countyState: "Grant County, Oregon",
		centroidLng: -118.9480000,
		centroidLat: 44.4784000
	},
	{
		geoid: "41011",
		name: "Coos",
		state: "Oregon",
		countyState: "Coos County, Oregon",
		centroidLng: -124.1270000,
		centroidLat: 43.1391000
	},
	{
		geoid: "48159",
		name: "Franklin",
		state: "Texas",
		countyState: "Franklin County, Texas",
		centroidLng: -95.2167000,
		centroidLat: 33.1756000
	},
	{
		geoid: "36113",
		name: "Warren",
		state: "New York",
		countyState: "Warren County, New York",
		centroidLng: -73.8256000,
		centroidLat: 43.5129000
	},
	{
		geoid: "51113",
		name: "Madison",
		state: "Virginia",
		countyState: "Madison County, Virginia",
		centroidLng: -78.2677000,
		centroidLat: 38.4287000
	},
	{
		geoid: "40005",
		name: "Atoka",
		state: "Oklahoma",
		countyState: "Atoka County, Oklahoma",
		centroidLng: -96.0397000,
		centroidLat: 34.4187000
	},
	{
		geoid: "17129",
		name: "Menard",
		state: "Illinois",
		countyState: "Menard County, Illinois",
		centroidLng: -89.7867000,
		centroidLat: 40.0311000
	},
	{
		geoid: "13015",
		name: "Bartow",
		state: "Georgia",
		countyState: "Bartow County, Georgia",
		centroidLng: -84.8457000,
		centroidLat: 34.2442000
	},
	{
		geoid: "12069",
		name: "Lake",
		state: "Florida",
		countyState: "Lake County, Florida",
		centroidLng: -81.6536000,
		centroidLat: 28.8110000
	},
	{
		geoid: "40111",
		name: "Okmulgee",
		state: "Oklahoma",
		countyState: "Okmulgee County, Oklahoma",
		centroidLng: -95.9527000,
		centroidLat: 35.6170000
	},
	{
		geoid: "38083",
		name: "Sheridan",
		state: "North Dakota",
		countyState: "Sheridan County, North Dakota",
		centroidLng: -100.3530000,
		centroidLat: 47.5874000
	},
	{
		geoid: "18049",
		name: "Fulton",
		state: "Indiana",
		countyState: "Fulton County, Indiana",
		centroidLng: -86.2069000,
		centroidLat: 41.0410000
	},
	{
		geoid: "20091",
		name: "Johnson",
		state: "Kansas",
		countyState: "Johnson County, Kansas",
		centroidLng: -94.8319000,
		centroidLat: 38.8997000
	},
	{
		geoid: "24009",
		name: "Calvert",
		state: "Maryland",
		countyState: "Calvert County, Maryland",
		centroidLng: -76.5119000,
		centroidLat: 38.5372000
	},
	{
		geoid: "55129",
		name: "Washburn",
		state: "Wisconsin",
		countyState: "Washburn County, Wisconsin",
		centroidLng: -91.7954000,
		centroidLat: 45.8977000
	},
	{
		geoid: "53031",
		name: "Jefferson",
		state: "Washington",
		countyState: "Jefferson County, Washington",
		centroidLng: -123.6560000,
		centroidLat: 47.9440000
	},
	{
		geoid: "72003",
		name: "Aguada",
		state: "Puerto Rico",
		countyState: "Aguada County, Puerto Rico",
		centroidLng: -67.1879000,
		centroidLat: 18.3775000
	},
	{
		geoid: "27169",
		name: "Winona",
		state: "Minnesota",
		countyState: "Winona County, Minnesota",
		centroidLng: -91.6818000,
		centroidLat: 44.0200000
	},
	{
		geoid: "28149",
		name: "Warren",
		state: "Mississippi",
		countyState: "Warren County, Mississippi",
		centroidLng: -90.8642000,
		centroidLat: 32.3486000
	},
	{
		geoid: "29003",
		name: "Andrew",
		state: "Missouri",
		countyState: "Andrew County, Missouri",
		centroidLng: -94.8318000,
		centroidLat: 39.9748000
	},
	{
		geoid: "29139",
		name: "Montgomery",
		state: "Missouri",
		countyState: "Montgomery County, Missouri",
		centroidLng: -91.4537000,
		centroidLat: 38.9121000
	},
	{
		geoid: "20021",
		name: "Cherokee",
		state: "Kansas",
		countyState: "Cherokee County, Kansas",
		centroidLng: -94.8465000,
		centroidLat: 37.1693000
	},
	{
		geoid: "37083",
		name: "Halifax",
		state: "North Carolina",
		countyState: "Halifax County, North Carolina",
		centroidLng: -77.6183000,
		centroidLat: 36.2578000
	},
	{
		geoid: "35043",
		name: "Sandoval",
		state: "New Mexico",
		countyState: "Sandoval County, New Mexico",
		centroidLng: -106.2630000,
		centroidLat: 35.8474000
	},
	{
		geoid: "21051",
		name: "Clay",
		state: "Kentucky",
		countyState: "Clay County, Kentucky",
		centroidLng: -83.7373000,
		centroidLat: 37.1477000
	},
	{
		geoid: "42035",
		name: "Clinton",
		state: "Pennsylvania",
		countyState: "Clinton County, Pennsylvania",
		centroidLng: -77.6185000,
		centroidLat: 41.2208000
	},
	{
		geoid: "41043",
		name: "Linn",
		state: "Oregon",
		countyState: "Linn County, Oregon",
		centroidLng: -122.5270000,
		centroidLat: 44.4972000
	},
	{
		geoid: "55065",
		name: "Lafayette",
		state: "Wisconsin",
		countyState: "Lafayette County, Wisconsin",
		centroidLng: -90.1321000,
		centroidLat: 42.6602000
	},
	{
		geoid: "40147",
		name: "Washington",
		state: "Oklahoma",
		countyState: "Washington County, Oklahoma",
		centroidLng: -95.8939000,
		centroidLat: 36.7115000
	},
	{
		geoid: "54077",
		name: "Preston",
		state: "West Virginia",
		countyState: "Preston County, West Virginia",
		centroidLng: -79.6881000,
		centroidLat: 39.4581000
	},
	{
		geoid: "69100",
		name: "Rota",
		state: "Commonwealth of the Northern Mariana Islands",
		countyState: "Rota County, Commonwealth of the Northern Mariana Islands",
		centroidLng: 145.2160000,
		centroidLat: 14.1517000
	},
	{
		geoid: "55135",
		name: "Waupaca",
		state: "Wisconsin",
		countyState: "Waupaca County, Wisconsin",
		centroidLng: -88.9149000,
		centroidLat: 44.4620000
	},
	{
		geoid: "48137",
		name: "Edwards",
		state: "Texas",
		countyState: "Edwards County, Texas",
		centroidLng: -100.2270000,
		centroidLat: 29.9570000
	},
	{
		geoid: "41037",
		name: "Lake",
		state: "Oregon",
		countyState: "Lake County, Oregon",
		centroidLng: -120.3550000,
		centroidLat: 42.8050000
	},
	{
		geoid: "29153",
		name: "Ozark",
		state: "Missouri",
		countyState: "Ozark County, Missouri",
		centroidLng: -92.4423000,
		centroidLat: 36.6516000
	},
	{
		geoid: "48473",
		name: "Waller",
		state: "Texas",
		countyState: "Waller County, Texas",
		centroidLng: -95.9978000,
		centroidLat: 29.9868000
	},
	{
		geoid: "01099",
		name: "Monroe",
		state: "Alabama",
		countyState: "Monroe County, Alabama",
		centroidLng: -87.3453000,
		centroidLat: 31.5295000
	},
	{
		geoid: "50009",
		name: "Essex",
		state: "Vermont",
		countyState: "Essex County, Vermont",
		centroidLng: -71.7197000,
		centroidLat: 44.6808000
	},
	{
		geoid: "51111",
		name: "Lunenburg",
		state: "Virginia",
		countyState: "Lunenburg County, Virginia",
		centroidLng: -78.2483000,
		centroidLat: 36.9481000
	},
	{
		geoid: "38097",
		name: "Traill",
		state: "North Dakota",
		countyState: "Traill County, North Dakota",
		centroidLng: -97.1516000,
		centroidLat: 47.4550000
	},
	{
		geoid: "13139",
		name: "Hall",
		state: "Georgia",
		countyState: "Hall County, Georgia",
		centroidLng: -83.8390000,
		centroidLat: 34.3062000
	},
	{
		geoid: "13283",
		name: "Treutlen",
		state: "Georgia",
		countyState: "Treutlen County, Georgia",
		centroidLng: -82.5653000,
		centroidLat: 32.4030000
	},
	{
		geoid: "13037",
		name: "Calhoun",
		state: "Georgia",
		countyState: "Calhoun County, Georgia",
		centroidLng: -84.6203000,
		centroidLat: 31.5358000
	},
	{
		geoid: "55125",
		name: "Vilas",
		state: "Wisconsin",
		countyState: "Vilas County, Wisconsin",
		centroidLng: -89.4884000,
		centroidLat: 46.0782000
	},
	{
		geoid: "27007",
		name: "Beltrami",
		state: "Minnesota",
		countyState: "Beltrami County, Minnesota",
		centroidLng: -95.0086000,
		centroidLat: 47.9750000
	},
	{
		geoid: "72043",
		name: "Coamo",
		state: "Puerto Rico",
		countyState: "Coamo County, Puerto Rico",
		centroidLng: -66.3510000,
		centroidLat: 18.0961000
	},
	{
		geoid: "72055",
		name: "Gu?nica",
		state: "Puerto Rico",
		countyState: "Gu?nica County, Puerto Rico",
		centroidLng: -66.9198000,
		centroidLat: 17.9544000
	},
	{
		geoid: "24019",
		name: "Dorchester",
		state: "Maryland",
		countyState: "Dorchester County, Maryland",
		centroidLng: -76.0702000,
		centroidLat: 38.3813000
	},
	{
		geoid: "42037",
		name: "Columbia",
		state: "Pennsylvania",
		countyState: "Columbia County, Pennsylvania",
		centroidLng: -76.4243000,
		centroidLat: 41.0429000
	},
	{
		geoid: "39023",
		name: "Clark",
		state: "Ohio",
		countyState: "Clark County, Ohio",
		centroidLng: -83.7818000,
		centroidLat: 39.9045000
	},
	{
		geoid: "48323",
		name: "Maverick",
		state: "Texas",
		countyState: "Maverick County, Texas",
		centroidLng: -100.3890000,
		centroidLat: 28.6416000
	},
	{
		geoid: "04019",
		name: "Pima",
		state: "Arizona",
		countyState: "Pima County, Arizona",
		centroidLng: -111.8910000,
		centroidLat: 31.9701000
	},
	{
		geoid: "35006",
		name: "Cibola",
		state: "New Mexico",
		countyState: "Cibola County, New Mexico",
		centroidLng: -108.0560000,
		centroidLat: 34.9631000
	},
	{
		geoid: "34005",
		name: "Burlington",
		state: "New Jersey",
		countyState: "Burlington County, New Jersey",
		centroidLng: -74.7248000,
		centroidLat: 39.8624000
	},
	{
		geoid: "50021",
		name: "Rutland",
		state: "Vermont",
		countyState: "Rutland County, Vermont",
		centroidLng: -73.0661000,
		centroidLat: 43.5749000
	},
	{
		geoid: "13099",
		name: "Early",
		state: "Georgia",
		countyState: "Early County, Georgia",
		centroidLng: -84.8711000,
		centroidLat: 31.2956000
	},
	{
		geoid: "25007",
		name: "Dukes",
		state: "Massachusetts",
		countyState: "Dukes County, Massachusetts",
		centroidLng: -70.6431000,
		centroidLat: 41.3798000
	},
	{
		geoid: "38049",
		name: "McHenry",
		state: "North Dakota",
		countyState: "McHenry County, North Dakota",
		centroidLng: -100.6290000,
		centroidLat: 48.2401000
	},
	{
		geoid: "31179",
		name: "Wayne",
		state: "Nebraska",
		countyState: "Wayne County, Nebraska",
		centroidLng: -97.0954000,
		centroidLat: 42.2211000
	},
	{
		geoid: "38045",
		name: "LaMoure",
		state: "North Dakota",
		countyState: "LaMoure County, North Dakota",
		centroidLng: -98.5355000,
		centroidLat: 46.4569000
	},
	{
		geoid: "16023",
		name: "Butte",
		state: "Idaho",
		countyState: "Butte County, Idaho",
		centroidLng: -113.2470000,
		centroidLat: 43.7594000
	},
	{
		geoid: "33007",
		name: "Coos",
		state: "New Hampshire",
		countyState: "Coos County, New Hampshire",
		centroidLng: -71.3878000,
		centroidLat: 44.6958000
	},
	{
		geoid: "38043",
		name: "Kidder",
		state: "North Dakota",
		countyState: "Kidder County, North Dakota",
		centroidLng: -99.7807000,
		centroidLat: 46.9795000
	},
	{
		geoid: "48273",
		name: "Kleberg",
		state: "Texas",
		countyState: "Kleberg County, Texas",
		centroidLng: -97.6124000,
		centroidLat: 27.4226000
	},
	{
		geoid: "18127",
		name: "Porter",
		state: "Indiana",
		countyState: "Porter County, Indiana",
		centroidLng: -87.0761000,
		centroidLat: 41.4905000
	},
	{
		geoid: "28101",
		name: "Newton",
		state: "Mississippi",
		countyState: "Newton County, Mississippi",
		centroidLng: -89.1183000,
		centroidLat: 32.4004000
	},
	{
		geoid: "37163",
		name: "Sampson",
		state: "North Carolina",
		countyState: "Sampson County, North Carolina",
		centroidLng: -78.3928000,
		centroidLat: 34.9350000
	},
	{
		geoid: "47127",
		name: "Moore",
		state: "Tennessee",
		countyState: "Moore County, Tennessee",
		centroidLng: -86.3919000,
		centroidLat: 35.2674000
	},
	{
		geoid: "12103",
		name: "Pinellas",
		state: "Florida",
		countyState: "Pinellas County, Florida",
		centroidLng: -82.7248000,
		centroidLat: 27.8918000
	},
	{
		geoid: "72019",
		name: "Barranquitas",
		state: "Puerto Rico",
		countyState: "Barranquitas County, Puerto Rico",
		centroidLng: -66.3071000,
		centroidLat: 18.2009000
	},
	{
		geoid: "17065",
		name: "Hamilton",
		state: "Illinois",
		countyState: "Hamilton County, Illinois",
		centroidLng: -88.5385000,
		centroidLat: 38.0815000
	},
	{
		geoid: "05105",
		name: "Perry",
		state: "Arkansas",
		countyState: "Perry County, Arkansas",
		centroidLng: -92.9232000,
		centroidLat: 34.9438000
	},
	{
		geoid: "20023",
		name: "Cheyenne",
		state: "Kansas",
		countyState: "Cheyenne County, Kansas",
		centroidLng: -101.7310000,
		centroidLat: 39.7857000
	},
	{
		geoid: "08029",
		name: "Delta",
		state: "Colorado",
		countyState: "Delta County, Colorado",
		centroidLng: -107.9400000,
		centroidLat: 38.9430000
	},
	{
		geoid: "19185",
		name: "Wayne",
		state: "Iowa",
		countyState: "Wayne County, Iowa",
		centroidLng: -93.3271000,
		centroidLat: 40.7392000
	},
	{
		geoid: "48165",
		name: "Gaines",
		state: "Texas",
		countyState: "Gaines County, Texas",
		centroidLng: -102.6340000,
		centroidLat: 32.7409000
	},
	{
		geoid: "01093",
		name: "Marion",
		state: "Alabama",
		countyState: "Marion County, Alabama",
		centroidLng: -87.9208000,
		centroidLat: 34.1179000
	},
	{
		geoid: "37189",
		name: "Watauga",
		state: "North Carolina",
		countyState: "Watauga County, North Carolina",
		centroidLng: -81.6870000,
		centroidLat: 36.2514000
	},
	{
		geoid: "51770",
		name: "Roanoke",
		state: "Virginia",
		countyState: "Roanoke County, Virginia",
		centroidLng: -79.9581000,
		centroidLat: 37.2748000
	},
	{
		geoid: "48317",
		name: "Martin",
		state: "Texas",
		countyState: "Martin County, Texas",
		centroidLng: -101.9500000,
		centroidLat: 32.3060000
	},
	{
		geoid: "48037",
		name: "Bowie",
		state: "Texas",
		countyState: "Bowie County, Texas",
		centroidLng: -94.3950000,
		centroidLat: 33.4766000
	},
	{
		geoid: "49057",
		name: "Weber",
		state: "Utah",
		countyState: "Weber County, Utah",
		centroidLng: -111.9570000,
		centroidLat: 41.2554000
	},
	{
		geoid: "29127",
		name: "Marion",
		state: "Missouri",
		countyState: "Marion County, Missouri",
		centroidLng: -91.5767000,
		centroidLat: 39.8028000
	},
	{
		geoid: "36045",
		name: "Jefferson",
		state: "New York",
		countyState: "Jefferson County, New York",
		centroidLng: -76.0904000,
		centroidLat: 44.0357000
	},
	{
		geoid: "19097",
		name: "Jackson",
		state: "Iowa",
		countyState: "Jackson County, Iowa",
		centroidLng: -90.5264000,
		centroidLat: 42.2075000
	},
	{
		geoid: "38063",
		name: "Nelson",
		state: "North Dakota",
		countyState: "Nelson County, North Dakota",
		centroidLng: -98.2039000,
		centroidLat: 47.9337000
	},
	{
		geoid: "36019",
		name: "Clinton",
		state: "New York",
		countyState: "Clinton County, New York",
		centroidLng: -73.6803000,
		centroidLat: 44.7203000
	},
	{
		geoid: "08045",
		name: "Garfield",
		state: "Colorado",
		countyState: "Garfield County, Colorado",
		centroidLng: -108.0420000,
		centroidLat: 39.7287000
	},
	{
		geoid: "29177",
		name: "Ray",
		state: "Missouri",
		countyState: "Ray County, Missouri",
		centroidLng: -93.9855000,
		centroidLat: 39.3306000
	},
	{
		geoid: "39073",
		name: "Hocking",
		state: "Ohio",
		countyState: "Hocking County, Ohio",
		centroidLng: -82.4539000,
		centroidLat: 39.5116000
	},
	{
		geoid: "01085",
		name: "Lowndes",
		state: "Alabama",
		countyState: "Lowndes County, Alabama",
		centroidLng: -86.6525000,
		centroidLat: 32.1841000
	},
	{
		geoid: "13043",
		name: "Candler",
		state: "Georgia",
		countyState: "Candler County, Georgia",
		centroidLng: -82.0866000,
		centroidLat: 32.4144000
	},
	{
		geoid: "19113",
		name: "Linn",
		state: "Iowa",
		countyState: "Linn County, Iowa",
		centroidLng: -91.5993000,
		centroidLat: 42.0794000
	},
	{
		geoid: "06105",
		name: "Trinity",
		state: "California",
		countyState: "Trinity County, California",
		centroidLng: -123.0350000,
		centroidLat: 40.6726000
	},
	{
		geoid: "13215",
		name: "Muscogee",
		state: "Georgia",
		countyState: "Muscogee County, Georgia",
		centroidLng: -84.8741000,
		centroidLat: 32.4910000
	},
	{
		geoid: "28135",
		name: "Tallahatchie",
		state: "Mississippi",
		countyState: "Tallahatchie County, Mississippi",
		centroidLng: -90.1885000,
		centroidLat: 33.9292000
	},
	{
		geoid: "20005",
		name: "Atchison",
		state: "Kansas",
		countyState: "Atchison County, Kansas",
		centroidLng: -95.2697000,
		centroidLat: 39.5359000
	},
	{
		geoid: "72081",
		name: "Lares",
		state: "Puerto Rico",
		countyState: "Lares County, Puerto Rico",
		centroidLng: -66.8650000,
		centroidLat: 18.2693000
	},
	{
		geoid: "48003",
		name: "Andrews",
		state: "Texas",
		countyState: "Andrews County, Texas",
		centroidLng: -102.6380000,
		centroidLat: 32.3046000
	},
	{
		geoid: "39109",
		name: "Miami",
		state: "Ohio",
		countyState: "Miami County, Ohio",
		centroidLng: -84.2296000,
		centroidLat: 40.0399000
	},
	{
		geoid: "48375",
		name: "Potter",
		state: "Texas",
		countyState: "Potter County, Texas",
		centroidLng: -101.8950000,
		centroidLat: 35.4015000
	},
	{
		geoid: "40133",
		name: "Seminole",
		state: "Oklahoma",
		countyState: "Seminole County, Oklahoma",
		centroidLng: -96.6091000,
		centroidLat: 35.1622000
	},
	{
		geoid: "45003",
		name: "Aiken",
		state: "South Carolina",
		countyState: "Aiken County, South Carolina",
		centroidLng: -81.5999000,
		centroidLat: 33.5377000
	},
	{
		geoid: "60020",
		name: "Manu'a",
		state: "American Samoa",
		countyState: "Manu'a County, American Samoa",
		centroidLng: -169.5460000,
		centroidLat: -14.2113000
	},
	{
		geoid: "06045",
		name: "Mendocino",
		state: "California",
		countyState: "Mendocino County, California",
		centroidLng: -123.4780000,
		centroidLat: 39.3803000
	},
	{
		geoid: "51550",
		name: "Chesapeake",
		state: "Virginia",
		countyState: "Chesapeake County, Virginia",
		centroidLng: -76.2785000,
		centroidLat: 36.7085000
	},
	{
		geoid: "69120",
		name: "Tinian",
		state: "Commonwealth of the Northern Mariana Islands",
		countyState: "Tinian County, Commonwealth of the Northern Mariana Islands",
		centroidLng: 145.6080000,
		centroidLat: 14.9489000
	},
	{
		geoid: "72153",
		name: "Yauco",
		state: "Puerto Rico",
		countyState: "Yauco County, Puerto Rico",
		centroidLng: -66.8613000,
		centroidLat: 18.0610000
	},
	{
		geoid: "56037",
		name: "Sweetwater",
		state: "Wyoming",
		countyState: "Sweetwater County, Wyoming",
		centroidLng: -108.7790000,
		centroidLat: 41.6340000
	},
	{
		geoid: "48169",
		name: "Garza",
		state: "Texas",
		countyState: "Garza County, Texas",
		centroidLng: -101.2980000,
		centroidLat: 33.1791000
	},
	{
		geoid: "54031",
		name: "Hardy",
		state: "West Virginia",
		countyState: "Hardy County, West Virginia",
		centroidLng: -78.8212000,
		centroidLat: 38.9995000
	},
	{
		geoid: "72001",
		name: "Adjuntas",
		state: "Puerto Rico",
		countyState: "Adjuntas County, Puerto Rico",
		centroidLng: -66.7510000,
		centroidLat: 18.1811000
	},
	{
		geoid: "55089",
		name: "Ozaukee",
		state: "Wisconsin",
		countyState: "Ozaukee County, Wisconsin",
		centroidLng: -87.5915000,
		centroidLat: 43.3671000
	},
	{
		geoid: "47163",
		name: "Sullivan",
		state: "Tennessee",
		countyState: "Sullivan County, Tennessee",
		centroidLng: -82.2645000,
		centroidLat: 36.5042000
	},
	{
		geoid: "45059",
		name: "Laurens",
		state: "South Carolina",
		countyState: "Laurens County, South Carolina",
		centroidLng: -81.9792000,
		centroidLat: 34.4944000
	},
	{
		geoid: "56011",
		name: "Crook",
		state: "Wyoming",
		countyState: "Crook County, Wyoming",
		centroidLng: -104.5700000,
		centroidLat: 44.5881000
	},
	{
		geoid: "55117",
		name: "Sheboygan",
		state: "Wisconsin",
		countyState: "Sheboygan County, Wisconsin",
		centroidLng: -87.6188000,
		centroidLat: 43.7183000
	},
	{
		geoid: "25013",
		name: "Hampden",
		state: "Massachusetts",
		countyState: "Hampden County, Massachusetts",
		centroidLng: -72.6051000,
		centroidLat: 42.1708000
	},
	{
		geoid: "48055",
		name: "Caldwell",
		state: "Texas",
		countyState: "Caldwell County, Texas",
		centroidLng: -97.6075000,
		centroidLat: 29.8518000
	},
	{
		geoid: "20029",
		name: "Cloud",
		state: "Kansas",
		countyState: "Cloud County, Kansas",
		centroidLng: -97.6502000,
		centroidLat: 39.4800000
	},
	{
		geoid: "06027",
		name: "Inyo",
		state: "California",
		countyState: "Inyo County, California",
		centroidLng: -117.2190000,
		centroidLat: 36.6260000
	},
	{
		geoid: "01037",
		name: "Coosa",
		state: "Alabama",
		countyState: "Coosa County, Alabama",
		centroidLng: -86.2610000,
		centroidLat: 32.9291000
	},
	{
		geoid: "19059",
		name: "Dickinson",
		state: "Iowa",
		countyState: "Dickinson County, Iowa",
		centroidLng: -95.1509000,
		centroidLat: 43.3781000
	},
	{
		geoid: "21095",
		name: "Harlan",
		state: "Kentucky",
		countyState: "Harlan County, Kentucky",
		centroidLng: -83.1837000,
		centroidLat: 36.8430000
	},
	{
		geoid: "20061",
		name: "Geary",
		state: "Kansas",
		countyState: "Geary County, Kansas",
		centroidLng: -96.7323000,
		centroidLat: 39.0449000
	},
	{
		geoid: "42011",
		name: "Berks",
		state: "Pennsylvania",
		countyState: "Berks County, Pennsylvania",
		centroidLng: -75.9849000,
		centroidLat: 40.4072000
	},
	{
		geoid: "47019",
		name: "Carter",
		state: "Tennessee",
		countyState: "Carter County, Tennessee",
		centroidLng: -82.1378000,
		centroidLat: 36.3069000
	},
	{
		geoid: "27083",
		name: "Lyon",
		state: "Minnesota",
		countyState: "Lyon County, Minnesota",
		centroidLng: -95.8434000,
		centroidLat: 44.4129000
	},
	{
		geoid: "51037",
		name: "Charlotte",
		state: "Virginia",
		countyState: "Charlotte County, Virginia",
		centroidLng: -78.6741000,
		centroidLat: 36.9733000
	},
	{
		geoid: "47179",
		name: "Washington",
		state: "Tennessee",
		countyState: "Washington County, Tennessee",
		centroidLng: -82.5012000,
		centroidLat: 36.2717000
	},
	{
		geoid: "45071",
		name: "Newberry",
		state: "South Carolina",
		countyState: "Newberry County, South Carolina",
		centroidLng: -81.6310000,
		centroidLat: 34.3022000
	},
	{
		geoid: "51093",
		name: "Isle of Wight",
		state: "Virginia",
		countyState: "Isle of Wight County, Virginia",
		centroidLng: -76.6878000,
		centroidLat: 36.8989000
	},
	{
		geoid: "41069",
		name: "Wheeler",
		state: "Oregon",
		countyState: "Wheeler County, Oregon",
		centroidLng: -120.0740000,
		centroidLat: 44.6875000
	},
	{
		geoid: "29023",
		name: "Butler",
		state: "Missouri",
		countyState: "Butler County, Missouri",
		centroidLng: -90.4070000,
		centroidLat: 36.7125000
	},
	{
		geoid: "22047",
		name: "Iberville",
		state: "Louisiana",
		countyState: "Iberville Parish, Louisiana",
		centroidLng: -91.3602000,
		centroidLat: 30.2614000
	},
	{
		geoid: "28125",
		name: "Sharkey",
		state: "Mississippi",
		countyState: "Sharkey County, Mississippi",
		centroidLng: -90.8108000,
		centroidLat: 32.8798000
	},
	{
		geoid: "51061",
		name: "Fauquier",
		state: "Virginia",
		countyState: "Fauquier County, Virginia",
		centroidLng: -77.8313000,
		centroidLat: 38.7116000
	},
	{
		geoid: "51750",
		name: "Radford",
		state: "Virginia",
		countyState: "Radford County, Virginia",
		centroidLng: -80.5522000,
		centroidLat: 37.1203000
	},
	{
		geoid: "32005",
		name: "Douglas",
		state: "Nevada",
		countyState: "Douglas County, Nevada",
		centroidLng: -119.6570000,
		centroidLat: 38.8244000
	},
	{
		geoid: "12037",
		name: "Franklin",
		state: "Florida",
		countyState: "Franklin County, Florida",
		centroidLng: -84.7511000,
		centroidLat: 29.7749000
	},
	{
		geoid: "18055",
		name: "Greene",
		state: "Indiana",
		countyState: "Greene County, Indiana",
		centroidLng: -86.9616000,
		centroidLat: 39.0371000
	},
	{
		geoid: "18139",
		name: "Rush",
		state: "Indiana",
		countyState: "Rush County, Indiana",
		centroidLng: -85.4655000,
		centroidLat: 39.6199000
	},
	{
		geoid: "55131",
		name: "Washington",
		state: "Wisconsin",
		countyState: "Washington County, Wisconsin",
		centroidLng: -88.2296000,
		centroidLat: 43.3676000
	},
	{
		geoid: "29105",
		name: "Laclede",
		state: "Missouri",
		countyState: "Laclede County, Missouri",
		centroidLng: -92.5544000,
		centroidLat: 37.6843000
	},
	{
		geoid: "42085",
		name: "Mercer",
		state: "Pennsylvania",
		countyState: "Mercer County, Pennsylvania",
		centroidLng: -80.2593000,
		centroidLat: 41.2810000
	},
	{
		geoid: "05031",
		name: "Craighead",
		state: "Arkansas",
		countyState: "Craighead County, Arkansas",
		centroidLng: -90.6618000,
		centroidLat: 35.8482000
	},
	{
		geoid: "29195",
		name: "Saline",
		state: "Missouri",
		countyState: "Saline County, Missouri",
		centroidLng: -93.1726000,
		centroidLat: 39.1700000
	},
	{
		geoid: "17193",
		name: "White",
		state: "Illinois",
		countyState: "White County, Illinois",
		centroidLng: -88.1427000,
		centroidLat: 38.0739000
	},
	{
		geoid: "17039",
		name: "De Witt",
		state: "Illinois",
		countyState: "De Witt County, Illinois",
		centroidLng: -88.8618000,
		centroidLat: 40.1660000
	},
	{
		geoid: "32033",
		name: "White Pine",
		state: "Nevada",
		countyState: "White Pine County, Nevada",
		centroidLng: -114.9770000,
		centroidLat: 39.4029000
	},
	{
		geoid: "27049",
		name: "Goodhue",
		state: "Minnesota",
		countyState: "Goodhue County, Minnesota",
		centroidLng: -92.6416000,
		centroidLat: 44.4546000
	},
	{
		geoid: "37173",
		name: "Swain",
		state: "North Carolina",
		countyState: "Swain County, North Carolina",
		centroidLng: -83.5546000,
		centroidLat: 35.4875000
	},
	{
		geoid: "12101",
		name: "Pasco",
		state: "Florida",
		countyState: "Pasco County, Florida",
		centroidLng: -82.4815000,
		centroidLat: 28.3249000
	},
	{
		geoid: "12119",
		name: "Sumter",
		state: "Florida",
		countyState: "Sumter County, Florida",
		centroidLng: -82.1325000,
		centroidLat: 28.6329000
	},
	{
		geoid: "36059",
		name: "Nassau",
		state: "New York",
		countyState: "Nassau County, New York",
		centroidLng: -73.6017000,
		centroidLat: 40.7548000
	},
	{
		geoid: "38091",
		name: "Steele",
		state: "North Dakota",
		countyState: "Steele County, North Dakota",
		centroidLng: -97.7189000,
		centroidLat: 47.4557000
	},
	{
		geoid: "18163",
		name: "Vanderburgh",
		state: "Indiana",
		countyState: "Vanderburgh County, Indiana",
		centroidLng: -87.5751000,
		centroidLat: 37.9972000
	},
	{
		geoid: "29071",
		name: "Franklin",
		state: "Missouri",
		countyState: "Franklin County, Missouri",
		centroidLng: -91.0507000,
		centroidLat: 38.4562000
	},
	{
		geoid: "37011",
		name: "Avery",
		state: "North Carolina",
		countyState: "Avery County, North Carolina",
		centroidLng: -81.9084000,
		centroidLat: 36.0992000
	},
	{
		geoid: "54107",
		name: "Wood",
		state: "West Virginia",
		countyState: "Wood County, West Virginia",
		centroidLng: -81.4979000,
		centroidLat: 39.2183000
	},
	{
		geoid: "55007",
		name: "Bayfield",
		state: "Wisconsin",
		countyState: "Bayfield County, Wisconsin",
		centroidLng: -91.1434000,
		centroidLat: 46.6828000
	},
	{
		geoid: "47161",
		name: "Stewart",
		state: "Tennessee",
		countyState: "Stewart County, Tennessee",
		centroidLng: -87.8315000,
		centroidLat: 36.5035000
	},
	{
		geoid: "55097",
		name: "Portage",
		state: "Wisconsin",
		countyState: "Portage County, Wisconsin",
		centroidLng: -89.5342000,
		centroidLat: 44.4646000
	},
	{
		geoid: "45063",
		name: "Lexington",
		state: "South Carolina",
		countyState: "Lexington County, South Carolina",
		centroidLng: -81.2483000,
		centroidLat: 33.9253000
	},
	{
		geoid: "24003",
		name: "Anne Arundel",
		state: "Maryland",
		countyState: "Anne Arundel County, Maryland",
		centroidLng: -76.5949000,
		centroidLat: 38.9742000
	},
	{
		geoid: "55039",
		name: "Fond du Lac",
		state: "Wisconsin",
		countyState: "Fond du Lac County, Wisconsin",
		centroidLng: -88.5230000,
		centroidLat: 43.7405000
	},
	{
		geoid: "54101",
		name: "Webster",
		state: "West Virginia",
		countyState: "Webster County, West Virginia",
		centroidLng: -80.4311000,
		centroidLat: 38.4825000
	},
	{
		geoid: "55061",
		name: "Kewaunee",
		state: "Wisconsin",
		countyState: "Kewaunee County, Wisconsin",
		centroidLng: -87.2722000,
		centroidLat: 44.5021000
	},
	{
		geoid: "48369",
		name: "Parmer",
		state: "Texas",
		countyState: "Parmer County, Texas",
		centroidLng: -102.7840000,
		centroidLat: 34.5300000
	},
	{
		geoid: "40151",
		name: "Woods",
		state: "Oklahoma",
		countyState: "Woods County, Oklahoma",
		centroidLng: -98.9948000,
		centroidLat: 36.6955000
	},
	{
		geoid: "21063",
		name: "Elliott",
		state: "Kentucky",
		countyState: "Elliott County, Kentucky",
		centroidLng: -83.0797000,
		centroidLat: 38.1317000
	},
	{
		geoid: "25003",
		name: "Berkshire",
		state: "Massachusetts",
		countyState: "Berkshire County, Massachusetts",
		centroidLng: -73.2285000,
		centroidLat: 42.3929000
	},
	{
		geoid: "29041",
		name: "Chariton",
		state: "Missouri",
		countyState: "Chariton County, Missouri",
		centroidLng: -92.9885000,
		centroidLat: 39.4643000
	},
	{
		geoid: "08103",
		name: "Rio Blanco",
		state: "Colorado",
		countyState: "Rio Blanco County, Colorado",
		centroidLng: -108.0440000,
		centroidLat: 39.9379000
	},
	{
		geoid: "08087",
		name: "Morgan",
		state: "Colorado",
		countyState: "Morgan County, Colorado",
		centroidLng: -103.8070000,
		centroidLat: 40.2626000
	},
	{
		geoid: "41045",
		name: "Malheur",
		state: "Oregon",
		countyState: "Malheur County, Oregon",
		centroidLng: -117.5640000,
		centroidLat: 43.2208000
	},
	{
		geoid: "34023",
		name: "Middlesex",
		state: "New Jersey",
		countyState: "Middlesex County, New Jersey",
		centroidLng: -74.4174000,
		centroidLat: 40.4299000
	},
	{
		geoid: "20167",
		name: "Russell",
		state: "Kansas",
		countyState: "Russell County, Kansas",
		centroidLng: -98.7632000,
		centroidLat: 38.9151000
	},
	{
		geoid: "17155",
		name: "Putnam",
		state: "Illinois",
		countyState: "Putnam County, Illinois",
		centroidLng: -89.3144000,
		centroidLat: 41.2131000
	},
	{
		geoid: "46055",
		name: "Haakon",
		state: "South Dakota",
		countyState: "Haakon County, South Dakota",
		centroidLng: -101.5270000,
		centroidLat: 44.3708000
	},
	{
		geoid: "47111",
		name: "Macon",
		state: "Tennessee",
		countyState: "Macon County, Tennessee",
		centroidLng: -86.0084000,
		centroidLat: 36.5260000
	},
	{
		geoid: "05023",
		name: "Cleburne",
		state: "Arkansas",
		countyState: "Cleburne County, Arkansas",
		centroidLng: -92.0212000,
		centroidLat: 35.5352000
	},
	{
		geoid: "01109",
		name: "Pike",
		state: "Alabama",
		countyState: "Pike County, Alabama",
		centroidLng: -85.9285000,
		centroidLat: 31.8392000
	},
	{
		geoid: "16003",
		name: "Adams",
		state: "Idaho",
		countyState: "Adams County, Idaho",
		centroidLng: -116.4940000,
		centroidLat: 44.8562000
	},
	{
		geoid: "25005",
		name: "Bristol",
		state: "Massachusetts",
		countyState: "Bristol County, Massachusetts",
		centroidLng: -71.0669000,
		centroidLat: 41.7561000
	},
	{
		geoid: "42107",
		name: "Schuylkill",
		state: "Pennsylvania",
		countyState: "Schuylkill County, Pennsylvania",
		centroidLng: -76.2297000,
		centroidLat: 40.7226000
	},
	{
		geoid: "38103",
		name: "Wells",
		state: "North Dakota",
		countyState: "Wells County, North Dakota",
		centroidLng: -99.6677000,
		centroidLat: 47.5874000
	},
	{
		geoid: "37045",
		name: "Cleveland",
		state: "North Carolina",
		countyState: "Cleveland County, North Carolina",
		centroidLng: -81.5449000,
		centroidLat: 35.3740000
	},
	{
		geoid: "05059",
		name: "Hot Spring",
		state: "Arkansas",
		countyState: "Hot Spring County, Arkansas",
		centroidLng: -93.0384000,
		centroidLat: 34.3279000
	},
	{
		geoid: "24021",
		name: "Frederick",
		state: "Maryland",
		countyState: "Frederick County, Maryland",
		centroidLng: -77.3919000,
		centroidLat: 39.4702000
	},
	{
		geoid: "17169",
		name: "Schuyler",
		state: "Illinois",
		countyState: "Schuyler County, Illinois",
		centroidLng: -90.5566000,
		centroidLat: 40.1316000
	},
	{
		geoid: "06051",
		name: "Mono",
		state: "California",
		countyState: "Mono County, California",
		centroidLng: -118.7420000,
		centroidLat: 38.0879000
	},
	{
		geoid: "06109",
		name: "Tuolumne",
		state: "California",
		countyState: "Tuolumne County, California",
		centroidLng: -119.9250000,
		centroidLat: 38.0336000
	},
	{
		geoid: "35013",
		name: "Do?a Ana",
		state: "New Mexico",
		countyState: "Do?a Ana County, New Mexico",
		centroidLng: -106.8200000,
		centroidLat: 32.4180000
	},
	{
		geoid: "13081",
		name: "Crisp",
		state: "Georgia",
		countyState: "Crisp County, Georgia",
		centroidLng: -83.7854000,
		centroidLat: 31.9170000
	},
	{
		geoid: "05027",
		name: "Columbia",
		state: "Arkansas",
		countyState: "Columbia County, Arkansas",
		centroidLng: -93.2345000,
		centroidLat: 33.2362000
	},
	{
		geoid: "38039",
		name: "Griggs",
		state: "North Dakota",
		countyState: "Griggs County, North Dakota",
		centroidLng: -98.2304000,
		centroidLat: 47.4565000
	},
	{
		geoid: "06095",
		name: "Solano",
		state: "California",
		countyState: "Solano County, California",
		centroidLng: -122.0000000,
		centroidLat: 38.2854000
	},
	{
		geoid: "19043",
		name: "Clayton",
		state: "Iowa",
		countyState: "Clayton County, Iowa",
		centroidLng: -91.2527000,
		centroidLat: 42.8628000
	},
	{
		geoid: "48449",
		name: "Titus",
		state: "Texas",
		countyState: "Titus County, Texas",
		centroidLng: -94.9675000,
		centroidLat: 33.1905000
	},
	{
		geoid: "54027",
		name: "Hampshire",
		state: "West Virginia",
		countyState: "Hampshire County, West Virginia",
		centroidLng: -78.6594000,
		centroidLat: 39.3142000
	},
	{
		geoid: "37125",
		name: "Moore",
		state: "North Carolina",
		countyState: "Moore County, North Carolina",
		centroidLng: -79.4319000,
		centroidLat: 35.2805000
	},
	{
		geoid: "20009",
		name: "Barton",
		state: "Kansas",
		countyState: "Barton County, Kansas",
		centroidLng: -98.7565000,
		centroidLat: 38.4789000
	},
	{
		geoid: "38087",
		name: "Slope",
		state: "North Dakota",
		countyState: "Slope County, North Dakota",
		centroidLng: -103.4850000,
		centroidLat: 46.4549000
	},
	{
		geoid: "22083",
		name: "Richland",
		state: "Louisiana",
		countyState: "Richland Parish, Louisiana",
		centroidLng: -91.7328000,
		centroidLat: 32.4094000
	},
	{
		geoid: "31071",
		name: "Garfield",
		state: "Nebraska",
		countyState: "Garfield County, Nebraska",
		centroidLng: -98.9909000,
		centroidLat: 41.9144000
	},
	{
		geoid: "22099",
		name: "St. Martin",
		state: "Louisiana",
		countyState: "St. Martin Parish, Louisiana",
		centroidLng: -91.6788000,
		centroidLat: 30.2654000
	},
	{
		geoid: "01119",
		name: "Sumter",
		state: "Alabama",
		countyState: "Sumter County, Alabama",
		centroidLng: -88.1312000,
		centroidLat: 32.6516000
	},
	{
		geoid: "01133",
		name: "Winston",
		state: "Alabama",
		countyState: "Winston County, Alabama",
		centroidLng: -87.3732000,
		centroidLat: 34.1459000
	},
	{
		geoid: "12117",
		name: "Seminole",
		state: "Florida",
		countyState: "Seminole County, Florida",
		centroidLng: -81.2233000,
		centroidLat: 28.7448000
	},
	{
		geoid: "28035",
		name: "Forrest",
		state: "Mississippi",
		countyState: "Forrest County, Mississippi",
		centroidLng: -89.2948000,
		centroidLat: 31.1721000
	},
	{
		geoid: "26065",
		name: "Ingham",
		state: "Michigan",
		countyState: "Ingham County, Michigan",
		centroidLng: -84.3719000,
		centroidLat: 42.5993000
	},
	{
		geoid: "20177",
		name: "Shawnee",
		state: "Kansas",
		countyState: "Shawnee County, Kansas",
		centroidLng: -95.7696000,
		centroidLat: 39.0429000
	},
	{
		geoid: "46061",
		name: "Hanson",
		state: "South Dakota",
		countyState: "Hanson County, South Dakota",
		centroidLng: -97.7874000,
		centroidLat: 43.6749000
	},
	{
		geoid: "27061",
		name: "Itasca",
		state: "Minnesota",
		countyState: "Itasca County, Minnesota",
		centroidLng: -93.7375000,
		centroidLat: 47.4621000
	},
	{
		geoid: "08073",
		name: "Lincoln",
		state: "Colorado",
		countyState: "Lincoln County, Colorado",
		centroidLng: -103.6050000,
		centroidLat: 39.0415000
	},
	{
		geoid: "36053",
		name: "Madison",
		state: "New York",
		countyState: "Madison County, New York",
		centroidLng: -75.6165000,
		centroidLat: 42.9545000
	},
	{
		geoid: "36115",
		name: "Washington",
		state: "New York",
		countyState: "Washington County, New York",
		centroidLng: -73.4391000,
		centroidLat: 43.3749000
	},
	{
		geoid: "18081",
		name: "Johnson",
		state: "Indiana",
		countyState: "Johnson County, Indiana",
		centroidLng: -86.1012000,
		centroidLat: 39.4900000
	},
	{
		geoid: "48445",
		name: "Terry",
		state: "Texas",
		countyState: "Terry County, Texas",
		centroidLng: -102.3350000,
		centroidLat: 33.1741000
	},
	{
		geoid: "35021",
		name: "Harding",
		state: "New Mexico",
		countyState: "Harding County, New Mexico",
		centroidLng: -103.9020000,
		centroidLat: 35.8041000
	},
	{
		geoid: "01121",
		name: "Talladega",
		state: "Alabama",
		countyState: "Talladega County, Alabama",
		centroidLng: -86.1491000,
		centroidLat: 33.4012000
	},
	{
		geoid: "55067",
		name: "Langlade",
		state: "Wisconsin",
		countyState: "Langlade County, Wisconsin",
		centroidLng: -89.0321000,
		centroidLat: 45.2495000
	},
	{
		geoid: "48155",
		name: "Foard",
		state: "Texas",
		countyState: "Foard County, Texas",
		centroidLng: -99.7615000,
		centroidLat: 33.9879000
	},
	{
		geoid: "19021",
		name: "Buena Vista",
		state: "Iowa",
		countyState: "Buena Vista County, Iowa",
		centroidLng: -95.1510000,
		centroidLat: 42.7352000
	},
	{
		geoid: "19129",
		name: "Mills",
		state: "Iowa",
		countyState: "Mills County, Iowa",
		centroidLng: -95.6338000,
		centroidLat: 41.0307000
	},
	{
		geoid: "48385",
		name: "Real",
		state: "Texas",
		countyState: "Real County, Texas",
		centroidLng: -99.8332000,
		centroidLat: 29.8529000
	},
	{
		geoid: "39041",
		name: "Delaware",
		state: "Ohio",
		countyState: "Delaware County, Ohio",
		centroidLng: -82.9973000,
		centroidLat: 40.2849000
	},
	{
		geoid: "37067",
		name: "Forsyth",
		state: "North Carolina",
		countyState: "Forsyth County, North Carolina",
		centroidLng: -80.2759000,
		centroidLat: 36.1171000
	},
	{
		geoid: "32510",
		name: "Carson City",
		state: "Nevada",
		countyState: "Carson City County, Nevada",
		centroidLng: -119.7760000,
		centroidLat: 39.1678000
	},
	{
		geoid: "19049",
		name: "Dallas",
		state: "Iowa",
		countyState: "Dallas County, Iowa",
		centroidLng: -94.0356000,
		centroidLat: 41.6833000
	},
	{
		geoid: "48065",
		name: "Carson",
		state: "Texas",
		countyState: "Carson County, Texas",
		centroidLng: -101.3550000,
		centroidLat: 35.4035000
	},
	{
		geoid: "13261",
		name: "Sumter",
		state: "Georgia",
		countyState: "Sumter County, Georgia",
		centroidLng: -84.1815000,
		centroidLat: 32.0512000
	},
	{
		geoid: "29223",
		name: "Wayne",
		state: "Missouri",
		countyState: "Wayne County, Missouri",
		centroidLng: -90.4450000,
		centroidLat: 37.1195000
	},
	{
		geoid: "45007",
		name: "Anderson",
		state: "South Carolina",
		countyState: "Anderson County, South Carolina",
		centroidLng: -82.6531000,
		centroidLat: 34.5142000
	},
	{
		geoid: "56007",
		name: "Carbon",
		state: "Wyoming",
		countyState: "Carbon County, Wyoming",
		centroidLng: -106.9990000,
		centroidLat: 41.7169000
	},
	{
		geoid: "30093",
		name: "Silver Bow",
		state: "Montana",
		countyState: "Silver Bow County, Montana",
		centroidLng: -112.6380000,
		centroidLat: 45.9051000
	},
	{
		geoid: "17177",
		name: "Stephenson",
		state: "Illinois",
		countyState: "Stephenson County, Illinois",
		centroidLng: -89.6616000,
		centroidLat: 42.3510000
	},
	{
		geoid: "26087",
		name: "Lapeer",
		state: "Michigan",
		countyState: "Lapeer County, Michigan",
		centroidLng: -83.2222000,
		centroidLat: 43.1037000
	},
	{
		geoid: "22027",
		name: "Claiborne",
		state: "Louisiana",
		countyState: "Claiborne Parish, Louisiana",
		centroidLng: -92.9816000,
		centroidLat: 32.8012000
	},
	{
		geoid: "28161",
		name: "Yalobusha",
		state: "Mississippi",
		countyState: "Yalobusha County, Mississippi",
		centroidLng: -89.7195000,
		centroidLat: 34.0297000
	},
	{
		geoid: "72093",
		name: "Maricao",
		state: "Puerto Rico",
		countyState: "Maricao County, Puerto Rico",
		centroidLng: -66.9441000,
		centroidLat: 18.1729000
	},
	{
		geoid: "26079",
		name: "Kalkaska",
		state: "Michigan",
		countyState: "Kalkaska County, Michigan",
		centroidLng: -85.0906000,
		centroidLat: 44.6850000
	},
	{
		geoid: "72005",
		name: "Aguadilla",
		state: "Puerto Rico",
		countyState: "Aguadilla County, Puerto Rico",
		centroidLng: -67.1396000,
		centroidLat: 18.4762000
	},
	{
		geoid: "22039",
		name: "Evangeline",
		state: "Louisiana",
		countyState: "Evangeline Parish, Louisiana",
		centroidLng: -92.4031000,
		centroidLat: 30.7410000
	},
	{
		geoid: "47069",
		name: "Hardeman",
		state: "Tennessee",
		countyState: "Hardeman County, Tennessee",
		centroidLng: -88.9900000,
		centroidLat: 35.2134000
	},
	{
		geoid: "04003",
		name: "Cochise",
		state: "Arizona",
		countyState: "Cochise County, Arizona",
		centroidLng: -109.7540000,
		centroidLat: 31.8801000
	},
	{
		geoid: "35023",
		name: "Hidalgo",
		state: "New Mexico",
		countyState: "Hidalgo County, New Mexico",
		centroidLng: -108.6290000,
		centroidLat: 32.0550000
	},
	{
		geoid: "38023",
		name: "Divide",
		state: "North Dakota",
		countyState: "Divide County, North Dakota",
		centroidLng: -103.4670000,
		centroidLat: 48.8164000
	},
	{
		geoid: "06071",
		name: "San Bernardino",
		state: "California",
		countyState: "San Bernardino County, California",
		centroidLng: -115.9670000,
		centroidLat: 34.8400000
	},
	{
		geoid: "08083",
		name: "Montezuma",
		state: "Colorado",
		countyState: "Montezuma County, Colorado",
		centroidLng: -108.5080000,
		centroidLat: 37.3192000
	},
	{
		geoid: "28023",
		name: "Clarke",
		state: "Mississippi",
		countyState: "Clarke County, Mississippi",
		centroidLng: -88.6728000,
		centroidLat: 32.0272000
	},
	{
		geoid: "19125",
		name: "Marion",
		state: "Iowa",
		countyState: "Marion County, Iowa",
		centroidLng: -93.0992000,
		centroidLat: 41.3346000
	},
	{
		geoid: "31055",
		name: "Douglas",
		state: "Nebraska",
		countyState: "Douglas County, Nebraska",
		centroidLng: -96.1709000,
		centroidLat: 41.2917000
	},
	{
		geoid: "51600",
		name: "Fairfax",
		state: "Virginia",
		countyState: "Fairfax County, Virginia",
		centroidLng: -77.3018000,
		centroidLat: 38.8523000
	},
	{
		geoid: "40057",
		name: "Harmon",
		state: "Oklahoma",
		countyState: "Harmon County, Oklahoma",
		centroidLng: -99.8333000,
		centroidLat: 34.7687000
	},
	{
		geoid: "29039",
		name: "Cedar",
		state: "Missouri",
		countyState: "Cedar County, Missouri",
		centroidLng: -93.8424000,
		centroidLat: 37.7368000
	},
	{
		geoid: "13059",
		name: "Clarke",
		state: "Georgia",
		countyState: "Clarke County, Georgia",
		centroidLng: -83.3891000,
		centroidLat: 33.9443000
	},
	{
		geoid: "16085",
		name: "Valley",
		state: "Idaho",
		countyState: "Valley County, Idaho",
		centroidLng: -115.4540000,
		centroidLat: 44.6837000
	},
	{
		geoid: "48275",
		name: "Knox",
		state: "Texas",
		countyState: "Knox County, Texas",
		centroidLng: -99.7344000,
		centroidLat: 33.6167000
	},
	{
		geoid: "05103",
		name: "Ouachita",
		state: "Arkansas",
		countyState: "Ouachita County, Arkansas",
		centroidLng: -92.8427000,
		centroidLat: 33.5891000
	},
	{
		geoid: "39037",
		name: "Darke",
		state: "Ohio",
		countyState: "Darke County, Ohio",
		centroidLng: -84.6192000,
		centroidLat: 40.1354000
	},
	{
		geoid: "40071",
		name: "Kay",
		state: "Oklahoma",
		countyState: "Kay County, Oklahoma",
		centroidLng: -97.1062000,
		centroidLat: 36.7964000
	},
	{
		geoid: "46041",
		name: "Dewey",
		state: "South Dakota",
		countyState: "Dewey County, South Dakota",
		centroidLng: -100.8790000,
		centroidLat: 45.0986000
	},
	{
		geoid: "41057",
		name: "Tillamook",
		state: "Oregon",
		countyState: "Tillamook County, Oregon",
		centroidLng: -123.6980000,
		centroidLat: 45.4138000
	},
	{
		geoid: "13077",
		name: "Coweta",
		state: "Georgia",
		countyState: "Coweta County, Georgia",
		centroidLng: -84.7562000,
		centroidLat: 33.3514000
	},
	{
		geoid: "28127",
		name: "Simpson",
		state: "Mississippi",
		countyState: "Simpson County, Mississippi",
		centroidLng: -89.9505000,
		centroidLat: 31.9012000
	},
	{
		geoid: "28131",
		name: "Stone",
		state: "Mississippi",
		countyState: "Stone County, Mississippi",
		centroidLng: -89.1130000,
		centroidLat: 30.7790000
	},
	{
		geoid: "06013",
		name: "Contra Costa",
		state: "California",
		countyState: "Contra Costa County, California",
		centroidLng: -121.9880000,
		centroidLat: 37.9093000
	},
	{
		geoid: "47017",
		name: "Carroll",
		state: "Tennessee",
		countyState: "Carroll County, Tennessee",
		centroidLng: -88.4423000,
		centroidLat: 35.9706000
	},
	{
		geoid: "51087",
		name: "Henrico",
		state: "Virginia",
		countyState: "Henrico County, Virginia",
		centroidLng: -77.4162000,
		centroidLat: 37.5312000
	},
	{
		geoid: "18119",
		name: "Owen",
		state: "Indiana",
		countyState: "Owen County, Indiana",
		centroidLng: -86.8428000,
		centroidLat: 39.3195000
	},
	{
		geoid: "36119",
		name: "Westchester",
		state: "New York",
		countyState: "Westchester County, New York",
		centroidLng: -73.7330000,
		centroidLat: 41.1190000
	},
	{
		geoid: "29017",
		name: "Bollinger",
		state: "Missouri",
		countyState: "Bollinger County, Missouri",
		centroidLng: -90.0422000,
		centroidLat: 37.3197000
	},
	{
		geoid: "54047",
		name: "McDowell",
		state: "West Virginia",
		countyState: "McDowell County, West Virginia",
		centroidLng: -81.6539000,
		centroidLat: 37.3753000
	},
	{
		geoid: "54033",
		name: "Harrison",
		state: "West Virginia",
		countyState: "Harrison County, West Virginia",
		centroidLng: -80.3853000,
		centroidLat: 39.2852000
	},
	{
		geoid: "37077",
		name: "Granville",
		state: "North Carolina",
		countyState: "Granville County, North Carolina",
		centroidLng: -78.6305000,
		centroidLat: 36.2822000
	},
	{
		geoid: "09009",
		name: "New Haven",
		state: "Connecticut",
		countyState: "New Haven County, Connecticut",
		centroidLng: -72.9275000,
		centroidLat: 41.3657000
	},
	{
		geoid: "31139",
		name: "Pierce",
		state: "Nebraska",
		countyState: "Pierce County, Nebraska",
		centroidLng: -97.6013000,
		centroidLat: 42.2643000
	},
	{
		geoid: "13023",
		name: "Bleckley",
		state: "Georgia",
		countyState: "Bleckley County, Georgia",
		centroidLng: -83.3185000,
		centroidLat: 32.4283000
	},
	{
		geoid: "13033",
		name: "Burke",
		state: "Georgia",
		countyState: "Burke County, Georgia",
		centroidLng: -81.9292000,
		centroidLat: 33.0504000
	},
	{
		geoid: "17181",
		name: "Union",
		state: "Illinois",
		countyState: "Union County, Illinois",
		centroidLng: -89.2817000,
		centroidLat: 37.4652000
	},
	{
		geoid: "17091",
		name: "Kankakee",
		state: "Illinois",
		countyState: "Kankakee County, Illinois",
		centroidLng: -87.8891000,
		centroidLat: 41.1463000
	},
	{
		geoid: "38051",
		name: "McIntosh",
		state: "North Dakota",
		countyState: "McIntosh County, North Dakota",
		centroidLng: -99.4416000,
		centroidLat: 46.1116000
	},
	{
		geoid: "31141",
		name: "Platte",
		state: "Nebraska",
		countyState: "Platte County, Nebraska",
		centroidLng: -97.5418000,
		centroidLat: 41.5382000
	},
	{
		geoid: "37185",
		name: "Warren",
		state: "North Carolina",
		countyState: "Warren County, North Carolina",
		centroidLng: -78.1118000,
		centroidLat: 36.3737000
	},
	{
		geoid: "72009",
		name: "Aibonito",
		state: "Puerto Rico",
		countyState: "Aibonito County, Puerto Rico",
		centroidLng: -66.2687000,
		centroidLat: 18.1281000
	},
	{
		geoid: "13197",
		name: "Marion",
		state: "Georgia",
		countyState: "Marion County, Georgia",
		centroidLng: -84.5245000,
		centroidLat: 32.3481000
	},
	{
		geoid: "46027",
		name: "Clay",
		state: "South Dakota",
		countyState: "Clay County, South Dakota",
		centroidLng: -96.9831000,
		centroidLat: 42.8939000
	},
	{
		geoid: "10005",
		name: "Sussex",
		state: "Delaware",
		countyState: "Sussex County, Delaware",
		centroidLng: -75.3535000,
		centroidLat: 38.7269000
	},
	{
		geoid: "13159",
		name: "Jasper",
		state: "Georgia",
		countyState: "Jasper County, Georgia",
		centroidLng: -83.6985000,
		centroidLat: 33.3290000
	},
	{
		geoid: "29175",
		name: "Randolph",
		state: "Missouri",
		countyState: "Randolph County, Missouri",
		centroidLng: -92.5041000,
		centroidLat: 39.4284000
	},
	{
		geoid: "20015",
		name: "Butler",
		state: "Kansas",
		countyState: "Butler County, Kansas",
		centroidLng: -96.8381000,
		centroidLat: 37.7815000
	},
	{
		geoid: "48209",
		name: "Hays",
		state: "Texas",
		countyState: "Hays County, Texas",
		centroidLng: -98.0032000,
		centroidLat: 30.0544000
	},
	{
		geoid: "48325",
		name: "Medina",
		state: "Texas",
		countyState: "Medina County, Texas",
		centroidLng: -99.1092000,
		centroidLat: 29.3905000
	},
	{
		geoid: "26105",
		name: "Mason",
		state: "Michigan",
		countyState: "Mason County, Michigan",
		centroidLng: -86.5655000,
		centroidLat: 43.9991000
	},
	{
		geoid: "45013",
		name: "Beaufort",
		state: "South Carolina",
		countyState: "Beaufort County, South Carolina",
		centroidLng: -80.6610000,
		centroidLat: 32.3906000
	},
	{
		geoid: "46135",
		name: "Yankton",
		state: "South Dakota",
		countyState: "Yankton County, South Dakota",
		centroidLng: -97.3988000,
		centroidLat: 42.9848000
	},
	{
		geoid: "55105",
		name: "Rock",
		state: "Wisconsin",
		countyState: "Rock County, Wisconsin",
		centroidLng: -89.0728000,
		centroidLat: 42.6698000
	},
	{
		geoid: "45077",
		name: "Pickens",
		state: "South Carolina",
		countyState: "Pickens County, South Carolina",
		centroidLng: -82.7100000,
		centroidLat: 34.8531000
	},
	{
		geoid: "53013",
		name: "Columbia",
		state: "Washington",
		countyState: "Columbia County, Washington",
		centroidLng: -117.9230000,
		centroidLat: 46.3119000
	},
	{
		geoid: "18023",
		name: "Clinton",
		state: "Indiana",
		countyState: "Clinton County, Indiana",
		centroidLng: -86.4690000,
		centroidLat: 40.3046000
	},
	{
		geoid: "17027",
		name: "Clinton",
		state: "Illinois",
		countyState: "Clinton County, Illinois",
		centroidLng: -89.4227000,
		centroidLat: 38.5795000
	},
	{
		geoid: "16067",
		name: "Minidoka",
		state: "Idaho",
		countyState: "Minidoka County, Idaho",
		centroidLng: -113.6730000,
		centroidLat: 42.8592000
	},
	{
		geoid: "42113",
		name: "Sullivan",
		state: "Pennsylvania",
		countyState: "Sullivan County, Pennsylvania",
		centroidLng: -76.5169000,
		centroidLat: 41.4328000
	},
	{
		geoid: "28121",
		name: "Rankin",
		state: "Mississippi",
		countyState: "Rankin County, Mississippi",
		centroidLng: -89.9923000,
		centroidLat: 32.3202000
	},
	{
		geoid: "48345",
		name: "Motley",
		state: "Texas",
		countyState: "Motley County, Texas",
		centroidLng: -100.7800000,
		centroidLat: 34.0739000
	},
	{
		geoid: "41039",
		name: "Lane",
		state: "Oregon",
		countyState: "Lane County, Oregon",
		centroidLng: -122.9990000,
		centroidLat: 43.8640000
	},
	{
		geoid: "48485",
		name: "Wichita",
		state: "Texas",
		countyState: "Wichita County, Texas",
		centroidLng: -98.6880000,
		centroidLat: 34.0232000
	},
	{
		geoid: "26155",
		name: "Shiawassee",
		state: "Michigan",
		countyState: "Shiawassee County, Michigan",
		centroidLng: -84.1452000,
		centroidLat: 42.9543000
	},
	{
		geoid: "20089",
		name: "Jewell",
		state: "Kansas",
		countyState: "Jewell County, Kansas",
		centroidLng: -98.2184000,
		centroidLat: 39.7848000
	},
	{
		geoid: "20123",
		name: "Mitchell",
		state: "Kansas",
		countyState: "Mitchell County, Kansas",
		centroidLng: -98.2093000,
		centroidLat: 39.3931000
	},
	{
		geoid: "12007",
		name: "Bradford",
		state: "Florida",
		countyState: "Bradford County, Florida",
		centroidLng: -82.2324000,
		centroidLat: 29.9307000
	},
	{
		geoid: "28091",
		name: "Marion",
		state: "Mississippi",
		countyState: "Marion County, Mississippi",
		centroidLng: -89.8472000,
		centroidLat: 31.2168000
	},
	{
		geoid: "28039",
		name: "George",
		state: "Mississippi",
		countyState: "George County, Mississippi",
		centroidLng: -88.6488000,
		centroidLat: 30.8665000
	},
	{
		geoid: "17085",
		name: "Jo Daviess",
		state: "Illinois",
		countyState: "Jo Daviess County, Illinois",
		centroidLng: -90.2882000,
		centroidLat: 42.3512000
	},
	{
		geoid: "18059",
		name: "Hancock",
		state: "Indiana",
		countyState: "Hancock County, Indiana",
		centroidLng: -85.7651000,
		centroidLat: 39.8215000
	},
	{
		geoid: "51620",
		name: "Franklin",
		state: "Virginia",
		countyState: "Franklin County, Virginia",
		centroidLng: -76.9396000,
		centroidLat: 36.6779000
	},
	{
		geoid: "48329",
		name: "Midland",
		state: "Texas",
		countyState: "Midland County, Texas",
		centroidLng: -102.0320000,
		centroidLat: 31.8691000
	},
	{
		geoid: "20171",
		name: "Scott",
		state: "Kansas",
		countyState: "Scott County, Kansas",
		centroidLng: -100.9060000,
		centroidLat: 38.4824000
	},
	{
		geoid: "30013",
		name: "Cascade",
		state: "Montana",
		countyState: "Cascade County, Montana",
		centroidLng: -111.3420000,
		centroidLat: 47.2584000
	},
	{
		geoid: "51660",
		name: "Harrisonburg",
		state: "Virginia",
		countyState: "Harrisonburg County, Virginia",
		centroidLng: -78.8711000,
		centroidLat: 38.4396000
	},
	{
		geoid: "17199",
		name: "Williamson",
		state: "Illinois",
		countyState: "Williamson County, Illinois",
		centroidLng: -88.9302000,
		centroidLat: 37.7299000
	},
	{
		geoid: "30029",
		name: "Flathead",
		state: "Montana",
		countyState: "Flathead County, Montana",
		centroidLng: -113.9490000,
		centroidLat: 48.2983000
	},
	{
		geoid: "72111",
		name: "Pe?uelas",
		state: "Puerto Rico",
		countyState: "Pe?uelas County, Puerto Rico",
		centroidLng: -66.7262000,
		centroidLat: 18.0235000
	},
	{
		geoid: "20189",
		name: "Stevens",
		state: "Kansas",
		countyState: "Stevens County, Kansas",
		centroidLng: -101.3120000,
		centroidLat: 37.1921000
	},
	{
		geoid: "21067",
		name: "Fayette",
		state: "Kentucky",
		countyState: "Fayette County, Kentucky",
		centroidLng: -84.4715000,
		centroidLat: 38.0284000
	},
	{
		geoid: "21091",
		name: "Hancock",
		state: "Kentucky",
		countyState: "Hancock County, Kentucky",
		centroidLng: -86.8048000,
		centroidLat: 37.8272000
	},
	{
		geoid: "46013",
		name: "Brown",
		state: "South Dakota",
		countyState: "Brown County, South Dakota",
		centroidLng: -98.3520000,
		centroidLat: 45.5895000
	},
	{
		geoid: "54001",
		name: "Barbour",
		state: "West Virginia",
		countyState: "Barbour County, West Virginia",
		centroidLng: -80.0180000,
		centroidLat: 39.1253000
	},
	{
		geoid: "47181",
		name: "Wayne",
		state: "Tennessee",
		countyState: "Wayne County, Tennessee",
		centroidLng: -87.7987000,
		centroidLat: 35.2476000
	},
	{
		geoid: "31013",
		name: "Box Butte",
		state: "Nebraska",
		countyState: "Box Butte County, Nebraska",
		centroidLng: -103.0710000,
		centroidLat: 42.2204000
	},
	{
		geoid: "42097",
		name: "Northumberland",
		state: "Pennsylvania",
		countyState: "Northumberland County, Pennsylvania",
		centroidLng: -76.6648000,
		centroidLat: 40.8896000
	},
	{
		geoid: "48469",
		name: "Victoria",
		state: "Texas",
		countyState: "Victoria County, Texas",
		centroidLng: -96.9741000,
		centroidLat: 28.7949000
	},
	{
		geoid: "51530",
		name: "Buena Vista",
		state: "Virginia",
		countyState: "Buena Vista County, Virginia",
		centroidLng: -79.3538000,
		centroidLat: 37.7289000
	},
	{
		geoid: "26147",
		name: "St. Clair",
		state: "Michigan",
		countyState: "St. Clair County, Michigan",
		centroidLng: -82.6652000,
		centroidLat: 42.8241000
	},
	{
		geoid: "31173",
		name: "Thurston",
		state: "Nebraska",
		countyState: "Thurston County, Nebraska",
		centroidLng: -96.5453000,
		centroidLat: 42.1466000
	},
	{
		geoid: "50015",
		name: "Lamoille",
		state: "Vermont",
		countyState: "Lamoille County, Vermont",
		centroidLng: -72.6477000,
		centroidLat: 44.5999000
	},
	{
		geoid: "51067",
		name: "Franklin",
		state: "Virginia",
		countyState: "Franklin County, Virginia",
		centroidLng: -79.9139000,
		centroidLat: 37.0069000
	},
	{
		geoid: "40011",
		name: "Blaine",
		state: "Oklahoma",
		countyState: "Blaine County, Oklahoma",
		centroidLng: -98.4218000,
		centroidLat: 35.8586000
	},
	{
		geoid: "53037",
		name: "Kittitas",
		state: "Washington",
		countyState: "Kittitas County, Washington",
		centroidLng: -120.6960000,
		centroidLat: 47.1670000
	},
	{
		geoid: "18135",
		name: "Randolph",
		state: "Indiana",
		countyState: "Randolph County, Indiana",
		centroidLng: -85.0112000,
		centroidLat: 40.1571000
	},
	{
		geoid: "49025",
		name: "Kane",
		state: "Utah",
		countyState: "Kane County, Utah",
		centroidLng: -111.7700000,
		centroidLat: 37.2720000
	},
	{
		geoid: "54093",
		name: "Tucker",
		state: "West Virginia",
		countyState: "Tucker County, West Virginia",
		centroidLng: -79.5637000,
		centroidLat: 39.1179000
	},
	{
		geoid: "72113",
		name: "Ponce",
		state: "Puerto Rico",
		countyState: "Ponce County, Puerto Rico",
		centroidLng: -66.6074000,
		centroidLat: 18.0020000
	},
	{
		geoid: "55023",
		name: "Crawford",
		state: "Wisconsin",
		countyState: "Crawford County, Wisconsin",
		centroidLng: -90.9404000,
		centroidLat: 43.2064000
	},
	{
		geoid: "01071",
		name: "Jackson",
		state: "Alabama",
		countyState: "Jackson County, Alabama",
		centroidLng: -85.9711000,
		centroidLat: 34.7278000
	},
	{
		geoid: "19123",
		name: "Mahaska",
		state: "Iowa",
		countyState: "Mahaska County, Iowa",
		centroidLng: -92.6408000,
		centroidLat: 41.3353000
	},
	{
		geoid: "20045",
		name: "Douglas",
		state: "Kansas",
		countyState: "Douglas County, Kansas",
		centroidLng: -95.2789000,
		centroidLat: 38.9040000
	},
	{
		geoid: "21059",
		name: "Daviess",
		state: "Kentucky",
		countyState: "Daviess County, Kentucky",
		centroidLng: -87.1128000,
		centroidLat: 37.7455000
	},
	{
		geoid: "21197",
		name: "Powell",
		state: "Kentucky",
		countyState: "Powell County, Kentucky",
		centroidLng: -83.8292000,
		centroidLat: 37.8251000
	},
	{
		geoid: "29097",
		name: "Jasper",
		state: "Missouri",
		countyState: "Jasper County, Missouri",
		centroidLng: -94.3353000,
		centroidLat: 37.2062000
	},
	{
		geoid: "41061",
		name: "Union",
		state: "Oregon",
		countyState: "Union County, Oregon",
		centroidLng: -117.9820000,
		centroidLat: 45.4087000
	},
	{
		geoid: "13151",
		name: "Henry",
		state: "Georgia",
		countyState: "Henry County, Georgia",
		centroidLng: -84.1385000,
		centroidLat: 33.4725000
	},
	{
		geoid: "38001",
		name: "Adams",
		state: "North Dakota",
		countyState: "Adams County, North Dakota",
		centroidLng: -102.4970000,
		centroidLat: 46.1139000
	},
	{
		geoid: "37137",
		name: "Pamlico",
		state: "North Carolina",
		countyState: "Pamlico County, North Carolina",
		centroidLng: -76.6459000,
		centroidLat: 35.1561000
	},
	{
		geoid: "21083",
		name: "Graves",
		state: "Kentucky",
		countyState: "Graves County, Kentucky",
		centroidLng: -88.6518000,
		centroidLat: 36.7240000
	},
	{
		geoid: "42021",
		name: "Cambria",
		state: "Pennsylvania",
		countyState: "Cambria County, Pennsylvania",
		centroidLng: -78.7022000,
		centroidLat: 40.4843000
	},
	{
		geoid: "47107",
		name: "McMinn",
		state: "Tennessee",
		countyState: "McMinn County, Tennessee",
		centroidLng: -84.6304000,
		centroidLat: 35.4429000
	},
	{
		geoid: "26093",
		name: "Livingston",
		state: "Michigan",
		countyState: "Livingston County, Michigan",
		centroidLng: -83.9115000,
		centroidLat: 42.6036000
	},
	{
		geoid: "08001",
		name: "Adams",
		state: "Colorado",
		countyState: "Adams County, Colorado",
		centroidLng: -104.3800000,
		centroidLat: 39.8697000
	},
	{
		geoid: "42069",
		name: "Lackawanna",
		state: "Pennsylvania",
		countyState: "Lackawanna County, Pennsylvania",
		centroidLng: -75.6375000,
		centroidLat: 41.4018000
	},
	{
		geoid: "40123",
		name: "Pontotoc",
		state: "Oklahoma",
		countyState: "Pontotoc County, Oklahoma",
		centroidLng: -96.6693000,
		centroidLat: 34.7353000
	},
	{
		geoid: "40037",
		name: "Creek",
		state: "Oklahoma",
		countyState: "Creek County, Oklahoma",
		centroidLng: -96.3262000,
		centroidLat: 35.9012000
	},
	{
		geoid: "48457",
		name: "Tyler",
		state: "Texas",
		countyState: "Tyler County, Texas",
		centroidLng: -94.3545000,
		centroidLat: 30.7927000
	},
	{
		geoid: "47175",
		name: "Van Buren",
		state: "Tennessee",
		countyState: "Van Buren County, Tennessee",
		centroidLng: -85.4343000,
		centroidLat: 35.6778000
	},
	{
		geoid: "40125",
		name: "Pottawatomie",
		state: "Oklahoma",
		countyState: "Pottawatomie County, Oklahoma",
		centroidLng: -96.8830000,
		centroidLat: 35.1814000
	},
	{
		geoid: "09013",
		name: "Tolland",
		state: "Connecticut",
		countyState: "Tolland County, Connecticut",
		centroidLng: -72.3088000,
		centroidLat: 41.8115000
	},
	{
		geoid: "35053",
		name: "Socorro",
		state: "New Mexico",
		countyState: "Socorro County, New Mexico",
		centroidLng: -106.8260000,
		centroidLat: 34.0280000
	},
	{
		geoid: "13003",
		name: "Atkinson",
		state: "Georgia",
		countyState: "Atkinson County, Georgia",
		centroidLng: -82.8853000,
		centroidLat: 31.3017000
	},
	{
		geoid: "12031",
		name: "Duval",
		state: "Florida",
		countyState: "Duval County, Florida",
		centroidLng: -81.6831000,
		centroidLat: 30.3450000
	},
	{
		geoid: "48145",
		name: "Falls",
		state: "Texas",
		countyState: "Falls County, Texas",
		centroidLng: -96.9375000,
		centroidLat: 31.2542000
	},
	{
		geoid: "05149",
		name: "Yell",
		state: "Arkansas",
		countyState: "Yell County, Arkansas",
		centroidLng: -93.3032000,
		centroidLat: 35.0304000
	},
	{
		geoid: "20033",
		name: "Comanche",
		state: "Kansas",
		countyState: "Comanche County, Kansas",
		centroidLng: -99.2725000,
		centroidLat: 37.1918000
	},
	{
		geoid: "21113",
		name: "Jessamine",
		state: "Kentucky",
		countyState: "Jessamine County, Kentucky",
		centroidLng: -84.5795000,
		centroidLat: 37.8662000
	},
	{
		geoid: "21171",
		name: "Monroe",
		state: "Kentucky",
		countyState: "Monroe County, Kentucky",
		centroidLng: -85.7069000,
		centroidLat: 36.7285000
	},
	{
		geoid: "40075",
		name: "Kiowa",
		state: "Oklahoma",
		countyState: "Kiowa County, Oklahoma",
		centroidLng: -99.0145000,
		centroidLat: 34.8593000
	},
	{
		geoid: "46079",
		name: "Lake",
		state: "South Dakota",
		countyState: "Lake County, South Dakota",
		centroidLng: -97.1295000,
		centroidLat: 44.0220000
	},
	{
		geoid: "37033",
		name: "Caswell",
		state: "North Carolina",
		countyState: "Caswell County, North Carolina",
		centroidLng: -79.3351000,
		centroidLat: 36.3919000
	},
	{
		geoid: "48377",
		name: "Presidio",
		state: "Texas",
		countyState: "Presidio County, Texas",
		centroidLng: -104.3870000,
		centroidLat: 29.9438000
	},
	{
		geoid: "39057",
		name: "Greene",
		state: "Ohio",
		countyState: "Greene County, Ohio",
		centroidLng: -83.8804000,
		centroidLat: 39.7004000
	},
	{
		geoid: "48489",
		name: "Willacy",
		state: "Texas",
		countyState: "Willacy County, Texas",
		centroidLng: -97.5858000,
		centroidLat: 26.4555000
	},
	{
		geoid: "72021",
		name: "Bayam?n",
		state: "Puerto Rico",
		countyState: "Bayam?n County, Puerto Rico",
		centroidLng: -66.1659000,
		centroidLat: 18.3449000
	},
	{
		geoid: "35028",
		name: "Los Alamos",
		state: "New Mexico",
		countyState: "Los Alamos County, New Mexico",
		centroidLng: -106.2950000,
		centroidLat: 35.8638000
	},
	{
		geoid: "02122",
		name: "Kenai Peninsula",
		state: "Alaska",
		countyState: "Kenai Peninsula Borough, Alaska",
		centroidLng: -151.6560000,
		centroidLat: 60.0395000
	},
	{
		geoid: "01003",
		name: "Baldwin",
		state: "Alabama",
		countyState: "Baldwin County, Alabama",
		centroidLng: -87.7020000,
		centroidLat: 30.7328000
	},
	{
		geoid: "19007",
		name: "Appanoose",
		state: "Iowa",
		countyState: "Appanoose County, Iowa",
		centroidLng: -92.8679000,
		centroidLat: 40.7415000
	},
	{
		geoid: "78010",
		name: "St. Croix",
		state: "United States Virgin Islands",
		countyState: "St. Croix County, United States Virgin Islands",
		centroidLng: -64.7347000,
		centroidLat: 17.7342000
	},
	{
		geoid: "51127",
		name: "New Kent",
		state: "Virginia",
		countyState: "New Kent County, Virginia",
		centroidLng: -76.9915000,
		centroidLat: 37.5023000
	},
	{
		geoid: "13263",
		name: "Talbot",
		state: "Georgia",
		countyState: "Talbot County, Georgia",
		centroidLng: -84.4947000,
		centroidLat: 32.7011000
	},
	{
		geoid: "36049",
		name: "Lewis",
		state: "New York",
		countyState: "Lewis County, New York",
		centroidLng: -75.4803000,
		centroidLat: 43.8187000
	},
	{
		geoid: "54015",
		name: "Clay",
		state: "West Virginia",
		countyState: "Clay County, West Virginia",
		centroidLng: -81.0507000,
		centroidLat: 38.4658000
	},
	{
		geoid: "18077",
		name: "Jefferson",
		state: "Indiana",
		countyState: "Jefferson County, Indiana",
		centroidLng: -85.4428000,
		centroidLat: 38.7500000
	},
	{
		geoid: "51019",
		name: "Bedford",
		state: "Virginia",
		countyState: "Bedford County, Virginia",
		centroidLng: -79.5219000,
		centroidLat: 37.3120000
	},
	{
		geoid: "35033",
		name: "Mora",
		state: "New Mexico",
		countyState: "Mora County, New Mexico",
		centroidLng: -105.0260000,
		centroidLat: 36.0082000
	},
	{
		geoid: "31011",
		name: "Boone",
		state: "Nebraska",
		countyState: "Boone County, Nebraska",
		centroidLng: -98.0629000,
		centroidLat: 41.6984000
	},
	{
		geoid: "29149",
		name: "Oregon",
		state: "Missouri",
		countyState: "Oregon County, Missouri",
		centroidLng: -91.3971000,
		centroidLat: 36.6929000
	},
	{
		geoid: "47083",
		name: "Houston",
		state: "Tennessee",
		countyState: "Houston County, Tennessee",
		centroidLng: -87.7471000,
		centroidLat: 36.2730000
	},
	{
		geoid: "42131",
		name: "Wyoming",
		state: "Pennsylvania",
		countyState: "Wyoming County, Pennsylvania",
		centroidLng: -76.0008000,
		centroidLat: 41.5142000
	},
	{
		geoid: "37107",
		name: "Lenoir",
		state: "North Carolina",
		countyState: "Lenoir County, North Carolina",
		centroidLng: -77.6125000,
		centroidLat: 35.2175000
	},
	{
		geoid: "18167",
		name: "Vigo",
		state: "Indiana",
		countyState: "Vigo County, Indiana",
		centroidLng: -87.4101000,
		centroidLat: 39.4336000
	},
	{
		geoid: "18039",
		name: "Elkhart",
		state: "Indiana",
		countyState: "Elkhart County, Indiana",
		centroidLng: -85.8580000,
		centroidLat: 41.5977000
	},
	{
		geoid: "51077",
		name: "Grayson",
		state: "Virginia",
		countyState: "Grayson County, Virginia",
		centroidLng: -81.2577000,
		centroidLat: 36.6828000
	},
	{
		geoid: "48303",
		name: "Lubbock",
		state: "Texas",
		countyState: "Lubbock County, Texas",
		centroidLng: -101.8210000,
		centroidLat: 33.6100000
	},
	{
		geoid: "27093",
		name: "Meeker",
		state: "Minnesota",
		countyState: "Meeker County, Minnesota",
		centroidLng: -94.5096000,
		centroidLat: 45.1093000
	},
	{
		geoid: "39005",
		name: "Ashland",
		state: "Ohio",
		countyState: "Ashland County, Ohio",
		centroidLng: -82.2810000,
		centroidLat: 40.8104000
	},
	{
		geoid: "31119",
		name: "Madison",
		state: "Nebraska",
		countyState: "Madison County, Nebraska",
		centroidLng: -97.6013000,
		centroidLat: 41.9166000
	},
	{
		geoid: "12085",
		name: "Martin",
		state: "Florida",
		countyState: "Martin County, Florida",
		centroidLng: -80.4542000,
		centroidLat: 27.1102000
	},
	{
		geoid: "08079",
		name: "Mineral",
		state: "Colorado",
		countyState: "Mineral County, Colorado",
		centroidLng: -106.9190000,
		centroidLat: 37.6837000
	},
	{
		geoid: "13085",
		name: "Dawson",
		state: "Georgia",
		countyState: "Dawson County, Georgia",
		centroidLng: -84.1518000,
		centroidLat: 34.4760000
	},
	{
		geoid: "13167",
		name: "Johnson",
		state: "Georgia",
		countyState: "Johnson County, Georgia",
		centroidLng: -82.6956000,
		centroidLat: 32.6659000
	},
	{
		geoid: "05095",
		name: "Monroe",
		state: "Arkansas",
		countyState: "Monroe County, Arkansas",
		centroidLng: -91.2241000,
		centroidLat: 34.6703000
	},
	{
		geoid: "29059",
		name: "Dallas",
		state: "Missouri",
		countyState: "Dallas County, Missouri",
		centroidLng: -93.0188000,
		centroidLat: 37.6595000
	},
	{
		geoid: "51143",
		name: "Pittsylvania",
		state: "Virginia",
		countyState: "Pittsylvania County, Virginia",
		centroidLng: -79.4036000,
		centroidLat: 36.8392000
	},
	{
		geoid: "02068",
		name: "Denali",
		state: "Alaska",
		countyState: "Denali Borough, Alaska",
		centroidLng: -150.0000000,
		centroidLat: 63.5420000
	},
	{
		geoid: "20145",
		name: "Pawnee",
		state: "Kansas",
		countyState: "Pawnee County, Kansas",
		centroidLng: -99.2486000,
		centroidLat: 38.1753000
	},
	{
		geoid: "20209",
		name: "Wyandotte",
		state: "Kansas",
		countyState: "Wyandotte County, Kansas",
		centroidLng: -94.7486000,
		centroidLat: 39.0972000
	},
	{
		geoid: "12029",
		name: "Dixie",
		state: "Florida",
		countyState: "Dixie County, Florida",
		centroidLng: -82.9113000,
		centroidLat: 29.8225000
	},
	{
		geoid: "26157",
		name: "Tuscola",
		state: "Michigan",
		countyState: "Tuscola County, Michigan",
		centroidLng: -83.4061000,
		centroidLat: 43.5251000
	},
	{
		geoid: "02240",
		name: "Southeast Fairbanks",
		state: "Alaska",
		countyState: "Southeast Fairbanks Borough, Alaska",
		centroidLng: -144.0010000,
		centroidLat: 63.8717000
	},
	{
		geoid: "20065",
		name: "Graham",
		state: "Kansas",
		countyState: "Graham County, Kansas",
		centroidLng: -99.8830000,
		centroidLat: 39.3496000
	},
	{
		geoid: "22097",
		name: "St. Landry",
		state: "Louisiana",
		countyState: "St. Landry Parish, Louisiana",
		centroidLng: -92.0861000,
		centroidLat: 30.5744000
	},
	{
		geoid: "47055",
		name: "Giles",
		state: "Tennessee",
		countyState: "Giles County, Tennessee",
		centroidLng: -87.0304000,
		centroidLat: 35.2240000
	},
	{
		geoid: "39095",
		name: "Lucas",
		state: "Ohio",
		countyState: "Lucas County, Ohio",
		centroidLng: -83.4394000,
		centroidLat: 41.6868000
	},
	{
		geoid: "05093",
		name: "Mississippi",
		state: "Arkansas",
		countyState: "Mississippi County, Arkansas",
		centroidLng: -89.9667000,
		centroidLat: 35.6951000
	},
	{
		geoid: "72117",
		name: "Rinc?n",
		state: "Puerto Rico",
		countyState: "Rinc?n County, Puerto Rico",
		centroidLng: -67.2526000,
		centroidLat: 18.3358000
	},
	{
		geoid: "18043",
		name: "Floyd",
		state: "Indiana",
		countyState: "Floyd County, Indiana",
		centroidLng: -85.8970000,
		centroidLat: 38.2998000
	},
	{
		geoid: "20129",
		name: "Morton",
		state: "Kansas",
		countyState: "Morton County, Kansas",
		centroidLng: -101.7990000,
		centroidLat: 37.1912000
	},
	{
		geoid: "21081",
		name: "Grant",
		state: "Kentucky",
		countyState: "Grant County, Kentucky",
		centroidLng: -84.6311000,
		centroidLat: 38.6383000
	},
	{
		geoid: "21217",
		name: "Taylor",
		state: "Kentucky",
		countyState: "Taylor County, Kentucky",
		centroidLng: -85.3304000,
		centroidLat: 37.3377000
	},
	{
		geoid: "37075",
		name: "Graham",
		state: "North Carolina",
		countyState: "Graham County, North Carolina",
		centroidLng: -83.8106000,
		centroidLat: 35.3417000
	},
	{
		geoid: "54045",
		name: "Logan",
		state: "West Virginia",
		countyState: "Logan County, West Virginia",
		centroidLng: -81.9024000,
		centroidLat: 37.8332000
	},
	{
		geoid: "54073",
		name: "Pleasants",
		state: "West Virginia",
		countyState: "Pleasants County, West Virginia",
		centroidLng: -81.1897000,
		centroidLat: 39.3724000
	},
	{
		geoid: "51071",
		name: "Giles",
		state: "Virginia",
		countyState: "Giles County, Virginia",
		centroidLng: -80.7227000,
		centroidLat: 37.3146000
	},
	{
		geoid: "54019",
		name: "Fayette",
		state: "West Virginia",
		countyState: "Fayette County, West Virginia",
		centroidLng: -81.0648000,
		centroidLat: 38.0413000
	},
	{
		geoid: "53061",
		name: "Snohomish",
		state: "Washington",
		countyState: "Snohomish County, Washington",
		centroidLng: -121.6830000,
		centroidLat: 48.0372000
	},
	{
		geoid: "35015",
		name: "Eddy",
		state: "New Mexico",
		countyState: "Eddy County, New Mexico",
		centroidLng: -104.2870000,
		centroidLat: 32.4815000
	},
	{
		geoid: "31117",
		name: "McPherson",
		state: "Nebraska",
		countyState: "McPherson County, Nebraska",
		centroidLng: -101.0590000,
		centroidLat: 41.5686000
	},
	{
		geoid: "31145",
		name: "Red Willow",
		state: "Nebraska",
		countyState: "Red Willow County, Nebraska",
		centroidLng: -100.4770000,
		centroidLat: 40.1766000
	},
	{
		geoid: "31149",
		name: "Rock",
		state: "Nebraska",
		countyState: "Rock County, Nebraska",
		centroidLng: -99.4559000,
		centroidLat: 42.4452000
	},
	{
		geoid: "47085",
		name: "Humphreys",
		state: "Tennessee",
		countyState: "Humphreys County, Tennessee",
		centroidLng: -87.7762000,
		centroidLat: 36.0243000
	},
	{
		geoid: "17125",
		name: "Mason",
		state: "Illinois",
		countyState: "Mason County, Illinois",
		centroidLng: -89.9783000,
		centroidLat: 40.2434000
	},
	{
		geoid: "51197",
		name: "Wythe",
		state: "Virginia",
		countyState: "Wythe County, Virginia",
		centroidLng: -81.0599000,
		centroidLat: 36.9155000
	},
	{
		geoid: "13303",
		name: "Washington",
		state: "Georgia",
		countyState: "Washington County, Georgia",
		centroidLng: -82.7923000,
		centroidLat: 32.9973000
	},
	{
		geoid: "53077",
		name: "Yakima",
		state: "Washington",
		countyState: "Yakima County, Washington",
		centroidLng: -120.6940000,
		centroidLat: 46.5645000
	},
	{
		geoid: "05143",
		name: "Washington",
		state: "Arkansas",
		countyState: "Washington County, Arkansas",
		centroidLng: -94.2200000,
		centroidLat: 35.9933000
	},
	{
		geoid: "48213",
		name: "Henderson",
		state: "Texas",
		countyState: "Henderson County, Texas",
		centroidLng: -95.9411000,
		centroidLat: 32.1824000
	},
	{
		geoid: "55035",
		name: "Eau Claire",
		state: "Wisconsin",
		countyState: "Eau Claire County, Wisconsin",
		centroidLng: -91.2860000,
		centroidLat: 44.7267000
	},
	{
		geoid: "47153",
		name: "Sequatchie",
		state: "Tennessee",
		countyState: "Sequatchie County, Tennessee",
		centroidLng: -85.4166000,
		centroidLat: 35.3573000
	},
	{
		geoid: "48471",
		name: "Walker",
		state: "Texas",
		countyState: "Walker County, Texas",
		centroidLng: -95.5953000,
		centroidLat: 30.7812000
	},
	{
		geoid: "04005",
		name: "Coconino",
		state: "Arizona",
		countyState: "Coconino County, Arizona",
		centroidLng: -112.0520000,
		centroidLat: 35.6308000
	},
	{
		geoid: "20191",
		name: "Sumner",
		state: "Kansas",
		countyState: "Sumner County, Kansas",
		centroidLng: -97.4777000,
		centroidLat: 37.2379000
	},
	{
		geoid: "26127",
		name: "Oceana",
		state: "Michigan",
		countyState: "Oceana County, Michigan",
		centroidLng: -86.5891000,
		centroidLat: 43.6455000
	},
	{
		geoid: "27065",
		name: "Kanabec",
		state: "Minnesota",
		countyState: "Kanabec County, Minnesota",
		centroidLng: -93.2864000,
		centroidLat: 45.9450000
	},
	{
		geoid: "39007",
		name: "Ashtabula",
		state: "Ohio",
		countyState: "Ashtabula County, Ohio",
		centroidLng: -80.7645000,
		centroidLat: 41.9132000
	},
	{
		geoid: "06003",
		name: "Alpine",
		state: "California",
		countyState: "Alpine County, California",
		centroidLng: -119.8080000,
		centroidLat: 38.6301000
	},
	{
		geoid: "48167",
		name: "Galveston",
		state: "Texas",
		countyState: "Galveston County, Texas",
		centroidLng: -94.8012000,
		centroidLat: 29.3305000
	},
	{
		geoid: "01011",
		name: "Bullock",
		state: "Alabama",
		countyState: "Bullock County, Alabama",
		centroidLng: -85.7047000,
		centroidLat: 32.0928000
	},
	{
		geoid: "17105",
		name: "Livingston",
		state: "Illinois",
		countyState: "Livingston County, Illinois",
		centroidLng: -88.5832000,
		centroidLat: 40.8654000
	},
	{
		geoid: "39135",
		name: "Preble",
		state: "Ohio",
		countyState: "Preble County, Ohio",
		centroidLng: -84.6470000,
		centroidLat: 39.7425000
	},
	{
		geoid: "39079",
		name: "Jackson",
		state: "Ohio",
		countyState: "Jackson County, Ohio",
		centroidLng: -82.6159000,
		centroidLat: 39.0262000
	},
	{
		geoid: "48201",
		name: "Harris",
		state: "Texas",
		countyState: "Harris County, Texas",
		centroidLng: -95.4346000,
		centroidLat: 29.8340000
	},
	{
		geoid: "25017",
		name: "Middlesex",
		state: "Massachusetts",
		countyState: "Middlesex County, Massachusetts",
		centroidLng: -71.4596000,
		centroidLat: 42.4467000
	},
	{
		geoid: "72145",
		name: "Vega Baja",
		state: "Puerto Rico",
		countyState: "Vega Baja County, Puerto Rico",
		centroidLng: -66.3983000,
		centroidLat: 18.4431000
	},
	{
		geoid: "48115",
		name: "Dawson",
		state: "Texas",
		countyState: "Dawson County, Texas",
		centroidLng: -101.9490000,
		centroidLat: 32.7426000
	},
	{
		geoid: "36069",
		name: "Ontario",
		state: "New York",
		countyState: "Ontario County, New York",
		centroidLng: -77.2875000,
		centroidLat: 42.8081000
	},
	{
		geoid: "02261",
		name: "Valdez-Cordova",
		state: "Alaska",
		countyState: "Valdez-Cordova Borough, Alaska",
		centroidLng: -144.9750000,
		centroidLat: 61.4686000
	},
	{
		geoid: "01001",
		name: "Autauga",
		state: "Alabama",
		countyState: "Autauga County, Alabama",
		centroidLng: -86.6662000,
		centroidLat: 32.5079000
	},
	{
		geoid: "20121",
		name: "Miami",
		state: "Kansas",
		countyState: "Miami County, Kansas",
		centroidLng: -94.8376000,
		centroidLat: 38.5637000
	},
	{
		geoid: "13091",
		name: "Dodge",
		state: "Georgia",
		countyState: "Dodge County, Georgia",
		centroidLng: -83.1229000,
		centroidLat: 32.1764000
	},
	{
		geoid: "36023",
		name: "Cortland",
		state: "New York",
		countyState: "Cortland County, New York",
		centroidLng: -76.0693000,
		centroidLat: 42.5993000
	},
	{
		geoid: "13039",
		name: "Camden",
		state: "Georgia",
		countyState: "Camden County, Georgia",
		centroidLng: -81.6207000,
		centroidLat: 30.9391000
	},
	{
		geoid: "26161",
		name: "Washtenaw",
		state: "Michigan",
		countyState: "Washtenaw County, Michigan",
		centroidLng: -83.8367000,
		centroidLat: 42.2534000
	},
	{
		geoid: "18087",
		name: "LaGrange",
		state: "Indiana",
		countyState: "LaGrange County, Indiana",
		centroidLng: -85.4268000,
		centroidLat: 41.6416000
	},
	{
		geoid: "48371",
		name: "Pecos",
		state: "Texas",
		countyState: "Pecos County, Texas",
		centroidLng: -102.6770000,
		centroidLat: 30.7121000
	},
	{
		geoid: "13225",
		name: "Peach",
		state: "Georgia",
		countyState: "Peach County, Georgia",
		centroidLng: -83.8596000,
		centroidLat: 32.5660000
	},
	{
		geoid: "13311",
		name: "White",
		state: "Georgia",
		countyState: "White County, Georgia",
		centroidLng: -83.7465000,
		centroidLat: 34.6525000
	},
	{
		geoid: "13163",
		name: "Jefferson",
		state: "Georgia",
		countyState: "Jefferson County, Georgia",
		centroidLng: -82.4473000,
		centroidLat: 33.0374000
	},
	{
		geoid: "55083",
		name: "Oconto",
		state: "Wisconsin",
		countyState: "Oconto County, Wisconsin",
		centroidLng: -88.2201000,
		centroidLat: 45.0256000
	},
	{
		geoid: "48347",
		name: "Nacogdoches",
		state: "Texas",
		countyState: "Nacogdoches County, Texas",
		centroidLng: -94.6391000,
		centroidLat: 31.5342000
	},
	{
		geoid: "44009",
		name: "Washington",
		state: "Rhode Island",
		countyState: "Washington County, Rhode Island",
		centroidLng: -71.6439000,
		centroidLat: 41.4195000
	},
	{
		geoid: "53047",
		name: "Okanogan",
		state: "Washington",
		countyState: "Okanogan County, Washington",
		centroidLng: -119.8610000,
		centroidLat: 48.4707000
	},
	{
		geoid: "53063",
		name: "Spokane",
		state: "Washington",
		countyState: "Spokane County, Washington",
		centroidLng: -117.4320000,
		centroidLat: 47.6535000
	},
	{
		geoid: "55041",
		name: "Forest",
		state: "Wisconsin",
		countyState: "Forest County, Wisconsin",
		centroidLng: -88.7366000,
		centroidLat: 45.7253000
	},
	{
		geoid: "55113",
		name: "Sawyer",
		state: "Wisconsin",
		countyState: "Sawyer County, Wisconsin",
		centroidLng: -91.1144000,
		centroidLat: 45.8977000
	},
	{
		geoid: "34017",
		name: "Hudson",
		state: "New Jersey",
		countyState: "Hudson County, New Jersey",
		centroidLng: -74.0754000,
		centroidLat: 40.7329000
	},
	{
		geoid: "39151",
		name: "Stark",
		state: "Ohio",
		countyState: "Stark County, Ohio",
		centroidLng: -81.3682000,
		centroidLat: 40.8119000
	},
	{
		geoid: "48187",
		name: "Guadalupe",
		state: "Texas",
		countyState: "Guadalupe County, Texas",
		centroidLng: -97.9715000,
		centroidLat: 29.6118000
	},
	{
		geoid: "53029",
		name: "Island",
		state: "Washington",
		countyState: "Island County, Washington",
		centroidLng: -122.5960000,
		centroidLat: 48.1230000
	},
	{
		geoid: "54037",
		name: "Jefferson",
		state: "West Virginia",
		countyState: "Jefferson County, West Virginia",
		centroidLng: -77.8775000,
		centroidLat: 39.3159000
	},
	{
		geoid: "51153",
		name: "Prince William",
		state: "Virginia",
		countyState: "Prince William County, Virginia",
		centroidLng: -77.4785000,
		centroidLat: 38.7533000
	},
	{
		geoid: "42029",
		name: "Chester",
		state: "Pennsylvania",
		countyState: "Chester County, Pennsylvania",
		centroidLng: -75.7489000,
		centroidLat: 39.9831000
	},
	{
		geoid: "54055",
		name: "Mercer",
		state: "West Virginia",
		countyState: "Mercer County, West Virginia",
		centroidLng: -81.1058000,
		centroidLat: 37.4164000
	},
	{
		geoid: "47145",
		name: "Roane",
		state: "Tennessee",
		countyState: "Roane County, Tennessee",
		centroidLng: -84.5265000,
		centroidLat: 35.8469000
	},
	{
		geoid: "53009",
		name: "Clallam",
		state: "Washington",
		countyState: "Clallam County, Washington",
		centroidLng: -123.8730000,
		centroidLat: 48.1832000
	},
	{
		geoid: "53065",
		name: "Stevens",
		state: "Washington",
		countyState: "Stevens County, Washington",
		centroidLng: -117.9160000,
		centroidLat: 48.3965000
	},
	{
		geoid: "19177",
		name: "Van Buren",
		state: "Iowa",
		countyState: "Van Buren County, Iowa",
		centroidLng: -91.9481000,
		centroidLat: 40.7499000
	},
	{
		geoid: "31073",
		name: "Gosper",
		state: "Nebraska",
		countyState: "Gosper County, Nebraska",
		centroidLng: -99.8687000,
		centroidLat: 40.5256000
	},
	{
		geoid: "28095",
		name: "Monroe",
		state: "Mississippi",
		countyState: "Monroe County, Mississippi",
		centroidLng: -88.4621000,
		centroidLat: 33.8698000
	},
	{
		geoid: "12033",
		name: "Escambia",
		state: "Florida",
		countyState: "Escambia County, Florida",
		centroidLng: -87.2757000,
		centroidLat: 30.6143000
	},
	{
		geoid: "48439",
		name: "Tarrant",
		state: "Texas",
		countyState: "Tarrant County, Texas",
		centroidLng: -97.2914000,
		centroidLat: 32.7714000
	},
	{
		geoid: "24033",
		name: "Prince George's",
		state: "Maryland",
		countyState: "Prince George's County, Maryland",
		centroidLng: -76.8780000,
		centroidLat: 38.8334000
	},
	{
		geoid: "51169",
		name: "Scott",
		state: "Virginia",
		countyState: "Scott County, Virginia",
		centroidLng: -82.6396000,
		centroidLat: 36.7395000
	},
	{
		geoid: "48239",
		name: "Jackson",
		state: "Texas",
		countyState: "Jackson County, Texas",
		centroidLng: -96.6239000,
		centroidLat: 28.9691000
	},
	{
		geoid: "40085",
		name: "Love",
		state: "Oklahoma",
		countyState: "Love County, Oklahoma",
		centroidLng: -97.2479000,
		centroidLat: 33.8942000
	},
	{
		geoid: "13289",
		name: "Twiggs",
		state: "Georgia",
		countyState: "Twiggs County, Georgia",
		centroidLng: -83.4191000,
		centroidLat: 32.6752000
	},
	{
		geoid: "39099",
		name: "Mahoning",
		state: "Ohio",
		countyState: "Mahoning County, Ohio",
		centroidLng: -80.8028000,
		centroidLat: 41.0171000
	},
	{
		geoid: "01087",
		name: "Macon",
		state: "Alabama",
		countyState: "Macon County, Alabama",
		centroidLng: -85.7284000,
		centroidLat: 32.4140000
	},
	{
		geoid: "17191",
		name: "Wayne",
		state: "Illinois",
		countyState: "Wayne County, Illinois",
		centroidLng: -88.4250000,
		centroidLat: 38.4314000
	},
	{
		geoid: "48437",
		name: "Swisher",
		state: "Texas",
		countyState: "Swisher County, Texas",
		centroidLng: -101.7350000,
		centroidLat: 34.5302000
	},
	{
		geoid: "29151",
		name: "Osage",
		state: "Missouri",
		countyState: "Osage County, Missouri",
		centroidLng: -91.9180000,
		centroidLat: 38.4980000
	},
	{
		geoid: "17089",
		name: "Kane",
		state: "Illinois",
		countyState: "Kane County, Illinois",
		centroidLng: -88.4202000,
		centroidLat: 41.9370000
	},
	{
		geoid: "06017",
		name: "El Dorado",
		state: "California",
		countyState: "El Dorado County, California",
		centroidLng: -120.5090000,
		centroidLat: 38.7849000
	},
	{
		geoid: "45055",
		name: "Kershaw",
		state: "South Carolina",
		countyState: "Kershaw County, South Carolina",
		centroidLng: -80.5816000,
		centroidLat: 34.3419000
	},
	{
		geoid: "37073",
		name: "Gates",
		state: "North Carolina",
		countyState: "Gates County, North Carolina",
		centroidLng: -76.7022000,
		centroidLat: 36.4241000
	},
	{
		geoid: "08089",
		name: "Otero",
		state: "Colorado",
		countyState: "Otero County, Colorado",
		centroidLng: -103.7300000,
		centroidLat: 37.9542000
	},
	{
		geoid: "41019",
		name: "Douglas",
		state: "Oregon",
		countyState: "Douglas County, Oregon",
		centroidLng: -123.1320000,
		centroidLat: 43.3221000
	},
	{
		geoid: "42089",
		name: "Monroe",
		state: "Pennsylvania",
		countyState: "Monroe County, Pennsylvania",
		centroidLng: -75.3082000,
		centroidLat: 41.0334000
	},
	{
		geoid: "05101",
		name: "Newton",
		state: "Arkansas",
		countyState: "Newton County, Arkansas",
		centroidLng: -93.2323000,
		centroidLat: 35.9249000
	},
	{
		geoid: "38019",
		name: "Cavalier",
		state: "North Dakota",
		countyState: "Cavalier County, North Dakota",
		centroidLng: -98.4639000,
		centroidLat: 48.7718000
	},
	{
		geoid: "40103",
		name: "Noble",
		state: "Oklahoma",
		countyState: "Noble County, Oklahoma",
		centroidLng: -97.1748000,
		centroidLat: 36.3799000
	},
	{
		geoid: "20063",
		name: "Gove",
		state: "Kansas",
		countyState: "Gove County, Kansas",
		centroidLng: -100.4830000,
		centroidLat: 38.9154000
	},
	{
		geoid: "22077",
		name: "Pointe Coupee",
		state: "Louisiana",
		countyState: "Pointe Coupee Parish, Louisiana",
		centroidLng: -91.5663000,
		centroidLat: 30.7513000
	},
	{
		geoid: "31067",
		name: "Gage",
		state: "Nebraska",
		countyState: "Gage County, Nebraska",
		centroidLng: -96.6901000,
		centroidLat: 40.2623000
	},
	{
		geoid: "01115",
		name: "St. Clair",
		state: "Alabama",
		countyState: "St. Clair County, Alabama",
		centroidLng: -86.3108000,
		centroidLat: 33.6902000
	},
	{
		geoid: "28123",
		name: "Scott",
		state: "Mississippi",
		countyState: "Scott County, Mississippi",
		centroidLng: -89.5532000,
		centroidLat: 32.4280000
	},
	{
		geoid: "13095",
		name: "Dougherty",
		state: "Georgia",
		countyState: "Dougherty County, Georgia",
		centroidLng: -84.2196000,
		centroidLat: 31.5431000
	},
	{
		geoid: "18061",
		name: "Harrison",
		state: "Indiana",
		countyState: "Harrison County, Indiana",
		centroidLng: -86.1147000,
		centroidLat: 38.1904000
	},
	{
		geoid: "16017",
		name: "Bonner",
		state: "Idaho",
		countyState: "Bonner County, Idaho",
		centroidLng: -116.5450000,
		centroidLat: 48.3688000
	},
	{
		geoid: "13047",
		name: "Catoosa",
		state: "Georgia",
		countyState: "Catoosa County, Georgia",
		centroidLng: -85.1224000,
		centroidLat: 34.8776000
	},
	{
		geoid: "22059",
		name: "LaSalle",
		state: "Louisiana",
		countyState: "LaSalle Parish, Louisiana",
		centroidLng: -92.1950000,
		centroidLat: 31.6255000
	},
	{
		geoid: "23027",
		name: "Waldo",
		state: "Maine",
		countyState: "Waldo County, Maine",
		centroidLng: -69.1494000,
		centroidLat: 44.4787000
	},
	{
		geoid: "24027",
		name: "Howard",
		state: "Maryland",
		countyState: "Howard County, Maryland",
		centroidLng: -76.9418000,
		centroidLat: 39.2362000
	},
	{
		geoid: "49035",
		name: "Salt Lake",
		state: "Utah",
		countyState: "Salt Lake County, Utah",
		centroidLng: -111.9070000,
		centroidLat: 40.6680000
	},
	{
		geoid: "09003",
		name: "Hartford",
		state: "Connecticut",
		countyState: "Hartford County, Connecticut",
		centroidLng: -72.7187000,
		centroidLat: 41.7917000
	},
	{
		geoid: "09015",
		name: "Windham",
		state: "Connecticut",
		countyState: "Windham County, Connecticut",
		centroidLng: -72.0202000,
		centroidLat: 41.8316000
	},
	{
		geoid: "12087",
		name: "Monroe",
		state: "Florida",
		countyState: "Monroe County, Florida",
		centroidLng: -81.1856000,
		centroidLat: 25.1008000
	},
	{
		geoid: "39157",
		name: "Tuscarawas",
		state: "Ohio",
		countyState: "Tuscarawas County, Ohio",
		centroidLng: -81.4885000,
		centroidLat: 40.4406000
	},
	{
		geoid: "36085",
		name: "Richmond",
		state: "New York",
		countyState: "Richmond County, New York",
		centroidLng: -74.1468000,
		centroidLat: 40.5646000
	},
	{
		geoid: "39071",
		name: "Highland",
		state: "Ohio",
		countyState: "Highland County, Ohio",
		centroidLng: -83.6078000,
		centroidLat: 39.1988000
	},
	{
		geoid: "27077",
		name: "Lake of the Woods",
		state: "Minnesota",
		countyState: "Lake of the Woods County, Minnesota",
		centroidLng: -94.8857000,
		centroidLat: 48.8752000
	},
	{
		geoid: "16011",
		name: "Bingham",
		state: "Idaho",
		countyState: "Bingham County, Idaho",
		centroidLng: -112.2980000,
		centroidLat: 43.2448000
	},
	{
		geoid: "72023",
		name: "Cabo Rojo",
		state: "Puerto Rico",
		countyState: "Cabo Rojo County, Puerto Rico",
		centroidLng: -67.1830000,
		centroidLat: 18.0388000
	},
	{
		geoid: "72069",
		name: "Humacao",
		state: "Puerto Rico",
		countyState: "Humacao County, Puerto Rico",
		centroidLng: -65.7885000,
		centroidLat: 18.1380000
	},
	{
		geoid: "72077",
		name: "Juncos",
		state: "Puerto Rico",
		countyState: "Juncos County, Puerto Rico",
		centroidLng: -65.9017000,
		centroidLat: 18.2065000
	},
	{
		geoid: "45045",
		name: "Greenville",
		state: "South Carolina",
		countyState: "Greenville County, South Carolina",
		centroidLng: -82.4556000,
		centroidLat: 34.8498000
	},
	{
		geoid: "46085",
		name: "Lyman",
		state: "South Dakota",
		countyState: "Lyman County, South Dakota",
		centroidLng: -99.8243000,
		centroidLat: 43.8586000
	},
	{
		geoid: "55121",
		name: "Trempealeau",
		state: "Wisconsin",
		countyState: "Trempealeau County, Wisconsin",
		centroidLng: -91.3825000,
		centroidLat: 44.2906000
	},
	{
		geoid: "06113",
		name: "Yolo",
		state: "California",
		countyState: "Yolo County, California",
		centroidLng: -121.9620000,
		centroidLat: 38.6195000
	},
	{
		geoid: "48431",
		name: "Sterling",
		state: "Texas",
		countyState: "Sterling County, Texas",
		centroidLng: -101.0450000,
		centroidLat: 31.8219000
	},
	{
		geoid: "48495",
		name: "Winkler",
		state: "Texas",
		countyState: "Winkler County, Texas",
		centroidLng: -103.0630000,
		centroidLat: 31.8693000
	},
	{
		geoid: "46071",
		name: "Jackson",
		state: "South Dakota",
		countyState: "Jackson County, South Dakota",
		centroidLng: -101.6020000,
		centroidLat: 43.6920000
	},
	{
		geoid: "49041",
		name: "Sevier",
		state: "Utah",
		countyState: "Sevier County, Utah",
		centroidLng: -111.9100000,
		centroidLat: 38.7737000
	},
	{
		geoid: "51036",
		name: "Charles City",
		state: "Virginia",
		countyState: "Charles City County, Virginia",
		centroidLng: -77.0716000,
		centroidLat: 37.3568000
	},
	{
		geoid: "01023",
		name: "Choctaw",
		state: "Alabama",
		countyState: "Choctaw County, Alabama",
		centroidLng: -88.2007000,
		centroidLat: 32.0043000
	},
	{
		geoid: "18083",
		name: "Knox",
		state: "Indiana",
		countyState: "Knox County, Indiana",
		centroidLng: -87.4238000,
		centroidLat: 38.6611000
	},
	{
		geoid: "31047",
		name: "Dawson",
		state: "Nebraska",
		countyState: "Dawson County, Nebraska",
		centroidLng: -99.8205000,
		centroidLat: 40.8592000
	},
	{
		geoid: "20057",
		name: "Ford",
		state: "Kansas",
		countyState: "Ford County, Kansas",
		centroidLng: -99.8918000,
		centroidLat: 37.6905000
	},
	{
		geoid: "38105",
		name: "Williams",
		state: "North Dakota",
		countyState: "Williams County, North Dakota",
		centroidLng: -103.4380000,
		centroidLat: 48.2951000
	},
	{
		geoid: "38017",
		name: "Cass",
		state: "North Dakota",
		countyState: "Cass County, North Dakota",
		centroidLng: -97.2297000,
		centroidLat: 46.9346000
	},
	{
		geoid: "17069",
		name: "Hardin",
		state: "Illinois",
		countyState: "Hardin County, Illinois",
		centroidLng: -88.2382000,
		centroidLat: 37.5008000
	},
	{
		geoid: "31005",
		name: "Arthur",
		state: "Nebraska",
		countyState: "Arthur County, Nebraska",
		centroidLng: -101.6960000,
		centroidLat: 41.5689000
	},
	{
		geoid: "48163",
		name: "Frio",
		state: "Texas",
		countyState: "Frio County, Texas",
		centroidLng: -99.1074000,
		centroidLat: 28.8661000
	},
	{
		geoid: "19103",
		name: "Johnson",
		state: "Iowa",
		countyState: "Johnson County, Iowa",
		centroidLng: -91.6000000,
		centroidLat: 41.6426000
	},
	{
		geoid: "20133",
		name: "Neosho",
		state: "Kansas",
		countyState: "Neosho County, Kansas",
		centroidLng: -95.3069000,
		centroidLat: 37.5590000
	},
	{
		geoid: "21089",
		name: "Greenup",
		state: "Kentucky",
		countyState: "Greenup County, Kentucky",
		centroidLng: -82.9165000,
		centroidLat: 38.5652000
	},
	{
		geoid: "20107",
		name: "Linn",
		state: "Kansas",
		countyState: "Linn County, Kansas",
		centroidLng: -94.8452000,
		centroidLat: 38.2134000
	},
	{
		geoid: "49017",
		name: "Garfield",
		state: "Utah",
		countyState: "Garfield County, Utah",
		centroidLng: -111.3080000,
		centroidLat: 37.8449000
	},
	{
		geoid: "32011",
		name: "Eureka",
		state: "Nevada",
		countyState: "Eureka County, Nevada",
		centroidLng: -116.1980000,
		centroidLat: 40.0808000
	},
	{
		geoid: "31085",
		name: "Hayes",
		state: "Nebraska",
		countyState: "Hayes County, Nebraska",
		centroidLng: -101.0610000,
		centroidLat: 40.5248000
	},
	{
		geoid: "08095",
		name: "Phillips",
		state: "Colorado",
		countyState: "Phillips County, Colorado",
		centroidLng: -102.3580000,
		centroidLat: 40.5941000
	},
	{
		geoid: "05013",
		name: "Calhoun",
		state: "Arkansas",
		countyState: "Calhoun County, Arkansas",
		centroidLng: -92.5439000,
		centroidLat: 33.5360000
	},
	{
		geoid: "02090",
		name: "Fairbanks North Star",
		state: "Alaska",
		countyState: "Fairbanks North Star Borough, Alaska",
		centroidLng: -146.2740000,
		centroidLat: 64.8553000
	},
	{
		geoid: "21101",
		name: "Henderson",
		state: "Kentucky",
		countyState: "Henderson County, Kentucky",
		centroidLng: -87.5991000,
		centroidLat: 37.8069000
	},
	{
		geoid: "28051",
		name: "Holmes",
		state: "Mississippi",
		countyState: "Holmes County, Mississippi",
		centroidLng: -90.0903000,
		centroidLat: 33.1273000
	},
	{
		geoid: "28105",
		name: "Oktibbeha",
		state: "Mississippi",
		countyState: "Oktibbeha County, Mississippi",
		centroidLng: -88.8788000,
		centroidLat: 33.4257000
	},
	{
		geoid: "72037",
		name: "Ceiba",
		state: "Puerto Rico",
		countyState: "Ceiba County, Puerto Rico",
		centroidLng: -65.5948000,
		centroidLat: 18.2649000
	},
	{
		geoid: "72047",
		name: "Corozal",
		state: "Puerto Rico",
		countyState: "Corozal County, Puerto Rico",
		centroidLng: -66.3305000,
		centroidLat: 18.3044000
	},
	{
		geoid: "30027",
		name: "Fergus",
		state: "Montana",
		countyState: "Fergus County, Montana",
		centroidLng: -109.2630000,
		centroidLat: 47.2484000
	},
	{
		geoid: "24023",
		name: "Garrett",
		state: "Maryland",
		countyState: "Garrett County, Maryland",
		centroidLng: -79.2080000,
		centroidLat: 39.4625000
	},
	{
		geoid: "22119",
		name: "Webster",
		state: "Louisiana",
		countyState: "Webster Parish, Louisiana",
		centroidLng: -93.3386000,
		centroidLat: 32.7143000
	},
	{
		geoid: "26059",
		name: "Hillsdale",
		state: "Michigan",
		countyState: "Hillsdale County, Michigan",
		centroidLng: -84.5935000,
		centroidLat: 41.8848000
	},
	{
		geoid: "21141",
		name: "Logan",
		state: "Kentucky",
		countyState: "Logan County, Kentucky",
		centroidLng: -86.8362000,
		centroidLat: 36.8583000
	},
	{
		geoid: "46033",
		name: "Custer",
		state: "South Dakota",
		countyState: "Custer County, South Dakota",
		centroidLng: -103.3710000,
		centroidLat: 43.6668000
	},
	{
		geoid: "33013",
		name: "Merrimack",
		state: "New Hampshire",
		countyState: "Merrimack County, New Hampshire",
		centroidLng: -71.6631000,
		centroidLat: 43.3103000
	},
	{
		geoid: "42133",
		name: "York",
		state: "Pennsylvania",
		countyState: "York County, Pennsylvania",
		centroidLng: -76.6881000,
		centroidLat: 39.9731000
	},
	{
		geoid: "37161",
		name: "Rutherford",
		state: "North Carolina",
		countyState: "Rutherford County, North Carolina",
		centroidLng: -81.9862000,
		centroidLat: 35.3971000
	},
	{
		geoid: "46005",
		name: "Beadle",
		state: "South Dakota",
		countyState: "Beadle County, South Dakota",
		centroidLng: -98.2794000,
		centroidLat: 44.4144000
	},
	{
		geoid: "01043",
		name: "Cullman",
		state: "Alabama",
		countyState: "Cullman County, Alabama",
		centroidLng: -86.8011000,
		centroidLat: 34.0859000
	},
	{
		geoid: "13097",
		name: "Douglas",
		state: "Georgia",
		countyState: "Douglas County, Georgia",
		centroidLng: -84.7446000,
		centroidLat: 33.6898000
	},
	{
		geoid: "31097",
		name: "Johnson",
		state: "Nebraska",
		countyState: "Johnson County, Nebraska",
		centroidLng: -96.2649000,
		centroidLat: 40.3926000
	},
	{
		geoid: "37187",
		name: "Washington",
		state: "North Carolina",
		countyState: "Washington County, North Carolina",
		centroidLng: -76.6004000,
		centroidLat: 35.8622000
	},
	{
		geoid: "46075",
		name: "Jones",
		state: "South Dakota",
		countyState: "Jones County, South Dakota",
		centroidLng: -100.7020000,
		centroidLat: 43.9381000
	},
	{
		geoid: "37007",
		name: "Anson",
		state: "North Carolina",
		countyState: "Anson County, North Carolina",
		centroidLng: -80.0847000,
		centroidLat: 35.0090000
	},
	{
		geoid: "02100",
		name: "Haines",
		state: "Alaska",
		countyState: "Haines Borough, Alaska",
		centroidLng: -135.4090000,
		centroidLat: 58.9241000
	},
	{
		geoid: "38007",
		name: "Billings",
		state: "North Dakota",
		countyState: "Billings County, North Dakota",
		centroidLng: -103.3500000,
		centroidLat: 46.9794000
	},
	{
		geoid: "31003",
		name: "Antelope",
		state: "Nebraska",
		countyState: "Antelope County, Nebraska",
		centroidLng: -98.0670000,
		centroidLat: 42.1763000
	},
	{
		geoid: "29073",
		name: "Gasconade",
		state: "Missouri",
		countyState: "Gasconade County, Missouri",
		centroidLng: -91.5052000,
		centroidLat: 38.4329000
	},
	{
		geoid: "21031",
		name: "Butler",
		state: "Kentucky",
		countyState: "Butler County, Kentucky",
		centroidLng: -86.6721000,
		centroidLat: 37.1974000
	},
	{
		geoid: "78020",
		name: "St. John",
		state: "United States Virgin Islands",
		countyState: "St. John County, United States Virgin Islands",
		centroidLng: -64.7407000,
		centroidLat: 18.3282000
	},
	{
		geoid: "56005",
		name: "Campbell",
		state: "Wyoming",
		countyState: "Campbell County, Wyoming",
		centroidLng: -105.5500000,
		centroidLat: 44.2476000
	},
	{
		geoid: "13211",
		name: "Morgan",
		state: "Georgia",
		countyState: "Morgan County, Georgia",
		centroidLng: -83.4783000,
		centroidLat: 33.6261000
	},
	{
		geoid: "05037",
		name: "Cross",
		state: "Arkansas",
		countyState: "Cross County, Arkansas",
		centroidLng: -90.7724000,
		centroidLat: 35.2965000
	},
	{
		geoid: "05107",
		name: "Phillips",
		state: "Arkansas",
		countyState: "Phillips County, Arkansas",
		centroidLng: -90.8293000,
		centroidLat: 34.3822000
	},
	{
		geoid: "22093",
		name: "St. James",
		state: "Louisiana",
		countyState: "St. James Parish, Louisiana",
		centroidLng: -90.8028000,
		centroidLat: 30.0278000
	},
	{
		geoid: "06115",
		name: "Yuba",
		state: "California",
		countyState: "Yuba County, California",
		centroidLng: -121.3230000,
		centroidLat: 39.2789000
	},
	{
		geoid: "01117",
		name: "Shelby",
		state: "Alabama",
		countyState: "Shelby County, Alabama",
		centroidLng: -86.6839000,
		centroidLat: 33.2824000
	},
	{
		geoid: "08091",
		name: "Ouray",
		state: "Colorado",
		countyState: "Ouray County, Colorado",
		centroidLng: -107.8220000,
		centroidLat: 38.1124000
	},
	{
		geoid: "35009",
		name: "Curry",
		state: "New Mexico",
		countyState: "Curry County, New Mexico",
		centroidLng: -103.3910000,
		centroidLat: 34.6284000
	},
	{
		geoid: "40149",
		name: "Washita",
		state: "Oklahoma",
		countyState: "Washita County, Oklahoma",
		centroidLng: -98.9913000,
		centroidLat: 35.2814000
	},
	{
		geoid: "37135",
		name: "Orange",
		state: "North Carolina",
		countyState: "Orange County, North Carolina",
		centroidLng: -79.1095000,
		centroidLat: 36.0521000
	},
	{
		geoid: "18131",
		name: "Pulaski",
		state: "Indiana",
		countyState: "Pulaski County, Indiana",
		centroidLng: -86.6990000,
		centroidLat: 41.0410000
	},
	{
		geoid: "26019",
		name: "Benzie",
		state: "Michigan",
		countyState: "Benzie County, Michigan",
		centroidLng: -86.3310000,
		centroidLat: 44.6458000
	},
	{
		geoid: "36029",
		name: "Erie",
		state: "New York",
		countyState: "Erie County, New York",
		centroidLng: -78.8871000,
		centroidLat: 42.7684000
	},
	{
		geoid: "24031",
		name: "Montgomery",
		state: "Maryland",
		countyState: "Montgomery County, Maryland",
		centroidLng: -77.2081000,
		centroidLat: 39.1439000
	},
	{
		geoid: "51685",
		name: "Manassas Park",
		state: "Virginia",
		countyState: "Manassas Park County, Virginia",
		centroidLng: -77.4498000,
		centroidLat: 38.7710000
	},
	{
		geoid: "51013",
		name: "Arlington",
		state: "Virginia",
		countyState: "Arlington County, Virginia",
		centroidLng: -77.1022000,
		centroidLat: 38.8809000
	},
	{
		geoid: "51035",
		name: "Carroll",
		state: "Virginia",
		countyState: "Carroll County, Virginia",
		centroidLng: -80.7481000,
		centroidLat: 36.7448000
	},
	{
		geoid: "29183",
		name: "St. Charles",
		state: "Missouri",
		countyState: "St. Charles County, Missouri",
		centroidLng: -90.5368000,
		centroidLat: 38.7509000
	},
	{
		geoid: "06069",
		name: "San Benito",
		state: "California",
		countyState: "San Benito County, California",
		centroidLng: -121.1200000,
		centroidLat: 36.5929000
	},
	{
		geoid: "24035",
		name: "Queen Anne's",
		state: "Maryland",
		countyState: "Queen Anne's County, Maryland",
		centroidLng: -76.0922000,
		centroidLat: 39.0346000
	},
	{
		geoid: "20013",
		name: "Brown",
		state: "Kansas",
		countyState: "Brown County, Kansas",
		centroidLng: -95.5643000,
		centroidLat: 39.8266000
	},
	{
		geoid: "13031",
		name: "Bulloch",
		state: "Georgia",
		countyState: "Bulloch County, Georgia",
		centroidLng: -81.7316000,
		centroidLat: 32.4032000
	},
	{
		geoid: "21071",
		name: "Floyd",
		state: "Kentucky",
		countyState: "Floyd County, Kentucky",
		centroidLng: -82.7548000,
		centroidLat: 37.5208000
	},
	{
		geoid: "30007",
		name: "Broadwater",
		state: "Montana",
		countyState: "Broadwater County, Montana",
		centroidLng: -111.4220000,
		centroidLat: 46.3077000
	},
	{
		geoid: "13309",
		name: "Wheeler",
		state: "Georgia",
		countyState: "Wheeler County, Georgia",
		centroidLng: -82.7397000,
		centroidLat: 32.1146000
	},
	{
		geoid: "37049",
		name: "Craven",
		state: "North Carolina",
		countyState: "Craven County, North Carolina",
		centroidLng: -77.0631000,
		centroidLat: 35.1136000
	},
	{
		geoid: "29087",
		name: "Holt",
		state: "Missouri",
		countyState: "Holt County, Missouri",
		centroidLng: -95.2714000,
		centroidLat: 40.0630000
	},
	{
		geoid: "48295",
		name: "Lipscomb",
		state: "Texas",
		countyState: "Lipscomb County, Texas",
		centroidLng: -100.2740000,
		centroidLat: 36.2776000
	},
	{
		geoid: "41009",
		name: "Columbia",
		state: "Oregon",
		countyState: "Columbia County, Oregon",
		centroidLng: -123.0640000,
		centroidLat: 45.9550000
	},
	{
		geoid: "45041",
		name: "Florence",
		state: "South Carolina",
		countyState: "Florence County, South Carolina",
		centroidLng: -79.6933000,
		centroidLat: 34.0415000
	},
	{
		geoid: "38061",
		name: "Mountrail",
		state: "North Dakota",
		countyState: "Mountrail County, North Dakota",
		centroidLng: -102.3790000,
		centroidLat: 48.1494000
	},
	{
		geoid: "49053",
		name: "Washington",
		state: "Utah",
		countyState: "Washington County, Utah",
		centroidLng: -113.4760000,
		centroidLat: 37.3091000
	},
	{
		geoid: "30003",
		name: "Big Horn",
		state: "Montana",
		countyState: "Big Horn County, Montana",
		centroidLng: -107.4820000,
		centroidLat: 45.5187000
	},
	{
		geoid: "42047",
		name: "Elk",
		state: "Pennsylvania",
		countyState: "Elk County, Pennsylvania",
		centroidLng: -78.6649000,
		centroidLat: 41.4159000
	},
	{
		geoid: "31165",
		name: "Sioux",
		state: "Nebraska",
		countyState: "Sioux County, Nebraska",
		centroidLng: -103.7270000,
		centroidLat: 42.5011000
	},
	{
		geoid: "28031",
		name: "Covington",
		state: "Mississippi",
		countyState: "Covington County, Mississippi",
		centroidLng: -89.5775000,
		centroidLat: 31.6153000
	},
	{
		geoid: "72099",
		name: "Moca",
		state: "Puerto Rico",
		countyState: "Moca County, Puerto Rico",
		centroidLng: -67.0808000,
		centroidLat: 18.3838000
	},
	{
		geoid: "18013",
		name: "Brown",
		state: "Indiana",
		countyState: "Brown County, Indiana",
		centroidLng: -86.2302000,
		centroidLat: 39.1963000
	},
	{
		geoid: "18019",
		name: "Clark",
		state: "Indiana",
		countyState: "Clark County, Indiana",
		centroidLng: -85.7051000,
		centroidLat: 38.4370000
	},
	{
		geoid: "21021",
		name: "Boyle",
		state: "Kentucky",
		countyState: "Boyle County, Kentucky",
		centroidLng: -84.8457000,
		centroidLat: 37.6247000
	},
	{
		geoid: "21065",
		name: "Estill",
		state: "Kentucky",
		countyState: "Estill County, Kentucky",
		centroidLng: -83.9243000,
		centroidLat: 37.6980000
	},
	{
		geoid: "48087",
		name: "Collingsworth",
		state: "Texas",
		countyState: "Collingsworth County, Texas",
		centroidLng: -100.2710000,
		centroidLat: 34.9648000
	},
	{
		geoid: "48089",
		name: "Colorado",
		state: "Texas",
		countyState: "Colorado County, Texas",
		centroidLng: -96.5248000,
		centroidLat: 29.6047000
	},
	{
		geoid: "17063",
		name: "Grundy",
		state: "Illinois",
		countyState: "Grundy County, Illinois",
		centroidLng: -88.4184000,
		centroidLat: 41.2857000
	},
	{
		geoid: "27081",
		name: "Lincoln",
		state: "Minnesota",
		countyState: "Lincoln County, Minnesota",
		centroidLng: -96.2657000,
		centroidLat: 44.4140000
	},
	{
		geoid: "13109",
		name: "Evans",
		state: "Georgia",
		countyState: "Evans County, Georgia",
		centroidLng: -81.8720000,
		centroidLat: 32.1634000
	},
	{
		geoid: "48497",
		name: "Wise",
		state: "Texas",
		countyState: "Wise County, Texas",
		centroidLng: -97.6523000,
		centroidLat: 33.2124000
	},
	{
		geoid: "02050",
		name: "Bethel",
		state: "Alaska",
		countyState: "Bethel Borough, Alaska",
		centroidLng: -159.2600000,
		centroidLat: 60.3943000
	},
	{
		geoid: "13307",
		name: "Webster",
		state: "Georgia",
		countyState: "Webster County, Georgia",
		centroidLng: -84.5435000,
		centroidLat: 32.0761000
	},
	{
		geoid: "13169",
		name: "Jones",
		state: "Georgia",
		countyState: "Jones County, Georgia",
		centroidLng: -83.5873000,
		centroidLat: 33.0136000
	},
	{
		geoid: "13135",
		name: "Gwinnett",
		state: "Georgia",
		countyState: "Gwinnett County, Georgia",
		centroidLng: -84.0381000,
		centroidLat: 33.9603000
	},
	{
		geoid: "55095",
		name: "Polk",
		state: "Wisconsin",
		countyState: "Polk County, Wisconsin",
		centroidLng: -92.5219000,
		centroidLat: 45.4689000
	},
	{
		geoid: "55045",
		name: "Green",
		state: "Wisconsin",
		countyState: "Green County, Wisconsin",
		centroidLng: -89.6022000,
		centroidLat: 42.6789000
	},
	{
		geoid: "72107",
		name: "Orocovis",
		state: "Puerto Rico",
		countyState: "Orocovis County, Puerto Rico",
		centroidLng: -66.4487000,
		centroidLat: 18.2207000
	},
	{
		geoid: "36079",
		name: "Putnam",
		state: "New York",
		countyState: "Putnam County, New York",
		centroidLng: -73.7563000,
		centroidLat: 41.4244000
	},
	{
		geoid: "51700",
		name: "Newport News",
		state: "Virginia",
		countyState: "Newport News County, Virginia",
		centroidLng: -76.5039000,
		centroidLat: 37.0756000
	},
	{
		geoid: "08011",
		name: "Bent",
		state: "Colorado",
		countyState: "Bent County, Colorado",
		centroidLng: -103.0720000,
		centroidLat: 37.9555000
	},
	{
		geoid: "40065",
		name: "Jackson",
		state: "Oklahoma",
		countyState: "Jackson County, Oklahoma",
		centroidLng: -99.4408000,
		centroidLat: 34.5987000
	},
	{
		geoid: "30089",
		name: "Sanders",
		state: "Montana",
		countyState: "Sanders County, Montana",
		centroidLng: -115.1180000,
		centroidLat: 47.6924000
	},
	{
		geoid: "48173",
		name: "Glasscock",
		state: "Texas",
		countyState: "Glasscock County, Texas",
		centroidLng: -101.5200000,
		centroidLat: 31.8692000
	},
	{
		geoid: "05047",
		name: "Franklin",
		state: "Arkansas",
		countyState: "Franklin County, Arkansas",
		centroidLng: -93.8894000,
		centroidLat: 35.4869000
	},
	{
		geoid: "40093",
		name: "Major",
		state: "Oklahoma",
		countyState: "Major County, Oklahoma",
		centroidLng: -98.5323000,
		centroidLat: 36.3340000
	},
	{
		geoid: "47125",
		name: "Montgomery",
		state: "Tennessee",
		countyState: "Montgomery County, Tennessee",
		centroidLng: -87.3772000,
		centroidLat: 36.4774000
	},
	{
		geoid: "53051",
		name: "Pend Oreille",
		state: "Washington",
		countyState: "Pend Oreille County, Washington",
		centroidLng: -117.3310000,
		centroidLat: 48.5233000
	},
	{
		geoid: "38055",
		name: "McLean",
		state: "North Dakota",
		countyState: "McLean County, North Dakota",
		centroidLng: -101.4850000,
		centroidLat: 47.5027000
	},
	{
		geoid: "41027",
		name: "Hood River",
		state: "Oregon",
		countyState: "Hood River County, Oregon",
		centroidLng: -121.6810000,
		centroidLat: 45.4919000
	},
	{
		geoid: "19197",
		name: "Wright",
		state: "Iowa",
		countyState: "Wright County, Iowa",
		centroidLng: -93.7352000,
		centroidLat: 42.7330000
	},
	{
		geoid: "48419",
		name: "Shelby",
		state: "Texas",
		countyState: "Shelby County, Texas",
		centroidLng: -94.1530000,
		centroidLat: 31.7746000
	},
	{
		geoid: "29055",
		name: "Crawford",
		state: "Missouri",
		countyState: "Crawford County, Missouri",
		centroidLng: -91.3146000,
		centroidLat: 37.9544000
	},
	{
		geoid: "51017",
		name: "Bath",
		state: "Virginia",
		countyState: "Bath County, Virginia",
		centroidLng: -79.7464000,
		centroidLat: 38.0553000
	},
	{
		geoid: "27111",
		name: "Otter Tail",
		state: "Minnesota",
		countyState: "Otter Tail County, Minnesota",
		centroidLng: -95.7135000,
		centroidLat: 46.4123000
	},
	{
		geoid: "22075",
		name: "Plaquemines",
		state: "Louisiana",
		countyState: "Plaquemines Parish, Louisiana",
		centroidLng: -89.4775000,
		centroidLat: 29.3801000
	},
	{
		geoid: "28115",
		name: "Pontotoc",
		state: "Mississippi",
		countyState: "Pontotoc County, Mississippi",
		centroidLng: -89.0355000,
		centroidLat: 34.2271000
	},
	{
		geoid: "22067",
		name: "Morehouse",
		state: "Louisiana",
		countyState: "Morehouse Parish, Louisiana",
		centroidLng: -91.7595000,
		centroidLat: 32.7569000
	},
	{
		geoid: "21029",
		name: "Bullitt",
		state: "Kentucky",
		countyState: "Bullitt County, Kentucky",
		centroidLng: -85.6836000,
		centroidLat: 37.9621000
	},
	{
		geoid: "01047",
		name: "Dallas",
		state: "Alabama",
		countyState: "Dallas County, Alabama",
		centroidLng: -87.1400000,
		centroidLat: 32.3884000
	},
	{
		geoid: "48093",
		name: "Comanche",
		state: "Texas",
		countyState: "Comanche County, Texas",
		centroidLng: -98.5405000,
		centroidLat: 31.9727000
	},
	{
		geoid: "30053",
		name: "Lincoln",
		state: "Montana",
		countyState: "Lincoln County, Montana",
		centroidLng: -115.3310000,
		centroidLat: 48.4456000
	},
	{
		geoid: "72129",
		name: "San Lorenzo",
		state: "Puerto Rico",
		countyState: "San Lorenzo County, Puerto Rico",
		centroidLng: -65.9866000,
		centroidLat: 18.1525000
	},
	{
		geoid: "72135",
		name: "Toa Alta",
		state: "Puerto Rico",
		countyState: "Toa Alta County, Puerto Rico",
		centroidLng: -66.2581000,
		centroidLat: 18.3587000
	},
	{
		geoid: "27021",
		name: "Cass",
		state: "Minnesota",
		countyState: "Cass County, Minnesota",
		centroidLng: -94.2805000,
		centroidLat: 46.8787000
	},
	{
		geoid: "08007",
		name: "Archuleta",
		state: "Colorado",
		countyState: "Archuleta County, Colorado",
		centroidLng: -106.9790000,
		centroidLat: 37.2080000
	},
	{
		geoid: "28163",
		name: "Yazoo",
		state: "Mississippi",
		countyState: "Yazoo County, Mississippi",
		centroidLng: -90.3628000,
		centroidLat: 32.7627000
	},
	{
		geoid: "55087",
		name: "Outagamie",
		state: "Wisconsin",
		countyState: "Outagamie County, Wisconsin",
		centroidLng: -88.4649000,
		centroidLat: 44.4163000
	},
	{
		geoid: "29107",
		name: "Lafayette",
		state: "Missouri",
		countyState: "Lafayette County, Missouri",
		centroidLng: -93.7958000,
		centroidLat: 39.1008000
	},
	{
		geoid: "37195",
		name: "Wilson",
		state: "North Carolina",
		countyState: "Wilson County, North Carolina",
		centroidLng: -77.9286000,
		centroidLat: 35.7253000
	},
	{
		geoid: "39117",
		name: "Morrow",
		state: "Ohio",
		countyState: "Morrow County, Ohio",
		centroidLng: -82.8228000,
		centroidLat: 40.5289000
	},
	{
		geoid: "30039",
		name: "Granite",
		state: "Montana",
		countyState: "Granite County, Montana",
		centroidLng: -113.4570000,
		centroidLat: 46.3854000
	},
	{
		geoid: "38031",
		name: "Foster",
		state: "North Dakota",
		countyState: "Foster County, North Dakota",
		centroidLng: -98.8828000,
		centroidLat: 47.4572000
	},
	{
		geoid: "05033",
		name: "Crawford",
		state: "Arkansas",
		countyState: "Crawford County, Arkansas",
		centroidLng: -94.1927000,
		centroidLat: 35.5576000
	},
	{
		geoid: "48053",
		name: "Burnet",
		state: "Texas",
		countyState: "Burnet County, Texas",
		centroidLng: -98.1439000,
		centroidLat: 30.7305000
	},
	{
		geoid: "29186",
		name: "Ste. Genevieve",
		state: "Missouri",
		countyState: "Ste. Genevieve County, Missouri",
		centroidLng: -90.1919000,
		centroidLat: 37.8987000
	},
	{
		geoid: "48179",
		name: "Gray",
		state: "Texas",
		countyState: "Gray County, Texas",
		centroidLng: -100.8120000,
		centroidLat: 35.4013000
	},
	{
		geoid: "17109",
		name: "McDonough",
		state: "Illinois",
		countyState: "McDonough County, Illinois",
		centroidLng: -90.6776000,
		centroidLat: 40.4578000
	},
	{
		geoid: "19137",
		name: "Montgomery",
		state: "Iowa",
		countyState: "Montgomery County, Iowa",
		centroidLng: -95.1562000,
		centroidLat: 41.0303000
	},
	{
		geoid: "05067",
		name: "Jackson",
		state: "Arkansas",
		countyState: "Jackson County, Arkansas",
		centroidLng: -91.3086000,
		centroidLat: 35.6221000
	},
	{
		geoid: "48229",
		name: "Hudspeth",
		state: "Texas",
		countyState: "Hudspeth County, Texas",
		centroidLng: -105.4530000,
		centroidLat: 31.3158000
	},
	{
		geoid: "22079",
		name: "Rapides",
		state: "Louisiana",
		countyState: "Rapides Parish, Louisiana",
		centroidLng: -92.5269000,
		centroidLat: 31.2035000
	},
	{
		geoid: "31111",
		name: "Lincoln",
		state: "Nebraska",
		countyState: "Lincoln County, Nebraska",
		centroidLng: -100.7470000,
		centroidLat: 41.0465000
	},
	{
		geoid: "48067",
		name: "Cass",
		state: "Texas",
		countyState: "Cass County, Texas",
		centroidLng: -94.3484000,
		centroidLat: 33.0958000
	},
	{
		geoid: "47079",
		name: "Henry",
		state: "Tennessee",
		countyState: "Henry County, Tennessee",
		centroidLng: -88.2606000,
		centroidLat: 36.3109000
	},
	{
		geoid: "51840",
		name: "Winchester",
		state: "Virginia",
		countyState: "Winchester County, Virginia",
		centroidLng: -78.1686000,
		centroidLat: 39.1679000
	},
	{
		geoid: "37061",
		name: "Duplin",
		state: "North Carolina",
		countyState: "Duplin County, North Carolina",
		centroidLng: -77.9248000,
		centroidLat: 34.9521000
	},
	{
		geoid: "37087",
		name: "Haywood",
		state: "North Carolina",
		countyState: "Haywood County, North Carolina",
		centroidLng: -83.0013000,
		centroidLat: 35.5413000
	},
	{
		geoid: "27037",
		name: "Dakota",
		state: "Minnesota",
		countyState: "Dakota County, Minnesota",
		centroidLng: -93.0308000,
		centroidLat: 44.6972000
	},
	{
		geoid: "41025",
		name: "Harney",
		state: "Oregon",
		countyState: "Harney County, Oregon",
		centroidLng: -119.0700000,
		centroidLat: 43.0200000
	},
	{
		geoid: "72095",
		name: "Maunabo",
		state: "Puerto Rico",
		countyState: "Maunabo County, Puerto Rico",
		centroidLng: -65.9028000,
		centroidLat: 17.9996000
	},
	{
		geoid: "55001",
		name: "Adams",
		state: "Wisconsin",
		countyState: "Adams County, Wisconsin",
		centroidLng: -89.8118000,
		centroidLat: 43.9453000
	},
	{
		geoid: "29045",
		name: "Clark",
		state: "Missouri",
		countyState: "Clark County, Missouri",
		centroidLng: -91.6851000,
		centroidLat: 40.4312000
	},
	{
		geoid: "21015",
		name: "Boone",
		state: "Kentucky",
		countyState: "Boone County, Kentucky",
		centroidLng: -84.7476000,
		centroidLat: 38.9625000
	},
	{
		geoid: "21229",
		name: "Washington",
		state: "Kentucky",
		countyState: "Washington County, Kentucky",
		centroidLng: -85.2011000,
		centroidLat: 37.7740000
	},
	{
		geoid: "22009",
		name: "Avoyelles",
		state: "Louisiana",
		countyState: "Avoyelles Parish, Louisiana",
		centroidLng: -91.9790000,
		centroidLat: 31.0959000
	},
	{
		geoid: "28137",
		name: "Tate",
		state: "Mississippi",
		countyState: "Tate County, Mississippi",
		centroidLng: -89.9564000,
		centroidLat: 34.6633000
	},
	{
		geoid: "29227",
		name: "Worth",
		state: "Missouri",
		countyState: "Worth County, Missouri",
		centroidLng: -94.4232000,
		centroidLat: 40.4781000
	},
	{
		geoid: "31163",
		name: "Sherman",
		state: "Nebraska",
		countyState: "Sherman County, Nebraska",
		centroidLng: -98.9760000,
		centroidLat: 41.2207000
	},
	{
		geoid: "46021",
		name: "Campbell",
		state: "South Dakota",
		countyState: "Campbell County, South Dakota",
		centroidLng: -100.1080000,
		centroidLat: 45.7684000
	},
	{
		geoid: "46063",
		name: "Harding",
		state: "South Dakota",
		countyState: "Harding County, South Dakota",
		centroidLng: -103.4940000,
		centroidLat: 45.5789000
	},
	{
		geoid: "26057",
		name: "Gratiot",
		state: "Michigan",
		countyState: "Gratiot County, Michigan",
		centroidLng: -84.6069000,
		centroidLat: 43.2920000
	},
	{
		geoid: "19175",
		name: "Union",
		state: "Iowa",
		countyState: "Union County, Iowa",
		centroidLng: -94.2427000,
		centroidLat: 41.0273000
	},
	{
		geoid: "17127",
		name: "Massac",
		state: "Illinois",
		countyState: "Massac County, Illinois",
		centroidLng: -88.7096000,
		centroidLat: 37.2012000
	},
	{
		geoid: "31113",
		name: "Logan",
		state: "Nebraska",
		countyState: "Logan County, Nebraska",
		centroidLng: -100.4830000,
		centroidLat: 41.5666000
	},
	{
		geoid: "31121",
		name: "Merrick",
		state: "Nebraska",
		countyState: "Merrick County, Nebraska",
		centroidLng: -97.9427000,
		centroidLat: 41.1319000
	},
	{
		geoid: "31161",
		name: "Sheridan",
		state: "Nebraska",
		countyState: "Sheridan County, Nebraska",
		centroidLng: -102.4000000,
		centroidLat: 42.5024000
	},
	{
		geoid: "21039",
		name: "Carlisle",
		state: "Kentucky",
		countyState: "Carlisle County, Kentucky",
		centroidLng: -88.9962000,
		centroidLat: 36.8632000
	},
	{
		geoid: "56025",
		name: "Natrona",
		state: "Wyoming",
		countyState: "Natrona County, Wyoming",
		centroidLng: -106.8070000,
		centroidLat: 42.9662000
	},
	{
		geoid: "13321",
		name: "Worth",
		state: "Georgia",
		countyState: "Worth County, Georgia",
		centroidLng: -83.8411000,
		centroidLat: 31.5840000
	},
	{
		geoid: "18109",
		name: "Morgan",
		state: "Indiana",
		countyState: "Morgan County, Indiana",
		centroidLng: -86.4687000,
		centroidLat: 39.4860000
	},
	{
		geoid: "48139",
		name: "Ellis",
		state: "Texas",
		countyState: "Ellis County, Texas",
		centroidLng: -96.7353000,
		centroidLat: 32.3008000
	},
	{
		geoid: "13229",
		name: "Pierce",
		state: "Georgia",
		countyState: "Pierce County, Georgia",
		centroidLng: -82.2048000,
		centroidLat: 31.3664000
	},
	{
		geoid: "60010",
		name: "Eastern",
		state: "American Samoa",
		countyState: "Eastern County, American Samoa",
		centroidLng: -170.6440000,
		centroidLat: -14.2757000
	},
	{
		geoid: "18053",
		name: "Grant",
		state: "Indiana",
		countyState: "Grant County, Indiana",
		centroidLng: -85.6546000,
		centroidLat: 40.5160000
	},
	{
		geoid: "12035",
		name: "Flagler",
		state: "Florida",
		countyState: "Flagler County, Florida",
		centroidLng: -81.2828000,
		centroidLat: 29.4661000
	},
	{
		geoid: "28077",
		name: "Lawrence",
		state: "Mississippi",
		countyState: "Lawrence County, Mississippi",
		centroidLng: -90.1021000,
		centroidLat: 31.5452000
	},
	{
		geoid: "22127",
		name: "Winn",
		state: "Louisiana",
		countyState: "Winn Parish, Louisiana",
		centroidLng: -92.6439000,
		centroidLat: 31.9283000
	},
	{
		geoid: "17073",
		name: "Henry",
		state: "Illinois",
		countyState: "Henry County, Illinois",
		centroidLng: -90.1471000,
		centroidLat: 41.3668000
	},
	{
		geoid: "41041",
		name: "Lincoln",
		state: "Oregon",
		countyState: "Lincoln County, Oregon",
		centroidLng: -123.8920000,
		centroidLat: 44.6604000
	},
	{
		geoid: "23031",
		name: "York",
		state: "Maine",
		countyState: "York County, Maine",
		centroidLng: -70.6056000,
		centroidLat: 43.3657000
	},
	{
		geoid: "46029",
		name: "Codington",
		state: "South Dakota",
		countyState: "Codington County, South Dakota",
		centroidLng: -97.1883000,
		centroidLat: 44.9776000
	},
	{
		geoid: "13231",
		name: "Pike",
		state: "Georgia",
		countyState: "Pike County, Georgia",
		centroidLng: -84.3928000,
		centroidLat: 33.0887000
	},
	{
		geoid: "13259",
		name: "Stewart",
		state: "Georgia",
		countyState: "Stewart County, Georgia",
		centroidLng: -84.8497000,
		centroidLat: 32.0766000
	},
	{
		geoid: "34025",
		name: "Monmouth",
		state: "New Jersey",
		countyState: "Monmouth County, New Jersey",
		centroidLng: -74.2498000,
		centroidLat: 40.3023000
	},
	{
		geoid: "05021",
		name: "Clay",
		state: "Arkansas",
		countyState: "Clay County, Arkansas",
		centroidLng: -90.4347000,
		centroidLat: 36.3496000
	},
	{
		geoid: "05045",
		name: "Faulkner",
		state: "Arkansas",
		countyState: "Faulkner County, Arkansas",
		centroidLng: -92.3549000,
		centroidLat: 35.1053000
	},
	{
		geoid: "40041",
		name: "Delaware",
		state: "Oklahoma",
		countyState: "Delaware County, Oklahoma",
		centroidLng: -94.7871000,
		centroidLat: 36.4159000
	},
	{
		geoid: "19107",
		name: "Keokuk",
		state: "Iowa",
		countyState: "Keokuk County, Iowa",
		centroidLng: -92.1784000,
		centroidLat: 41.3364000
	},
	{
		geoid: "17061",
		name: "Greene",
		state: "Illinois",
		countyState: "Greene County, Illinois",
		centroidLng: -90.3852000,
		centroidLat: 39.3197000
	},
	{
		geoid: "27075",
		name: "Lake",
		state: "Minnesota",
		countyState: "Lake County, Minnesota",
		centroidLng: -91.2978000,
		centroidLat: 47.5003000
	},
	{
		geoid: "13285",
		name: "Troup",
		state: "Georgia",
		countyState: "Troup County, Georgia",
		centroidLng: -85.0490000,
		centroidLat: 33.0457000
	},
	{
		geoid: "06023",
		name: "Humboldt",
		state: "California",
		countyState: "Humboldt County, California",
		centroidLng: -123.9440000,
		centroidLat: 40.7335000
	},
	{
		geoid: "47043",
		name: "Dickson",
		state: "Tennessee",
		countyState: "Dickson County, Tennessee",
		centroidLng: -87.3583000,
		centroidLat: 36.1469000
	},
	{
		geoid: "27003",
		name: "Anoka",
		state: "Minnesota",
		countyState: "Anoka County, Minnesota",
		centroidLng: -93.2655000,
		centroidLat: 45.2251000
	},
	{
		geoid: "46009",
		name: "Bon Homme",
		state: "South Dakota",
		countyState: "Bon Homme County, South Dakota",
		centroidLng: -97.8990000,
		centroidLat: 42.9659000
	},
	{
		geoid: "47059",
		name: "Greene",
		state: "Tennessee",
		countyState: "Greene County, Tennessee",
		centroidLng: -82.8749000,
		centroidLat: 36.1703000
	},
	{
		geoid: "51183",
		name: "Sussex",
		state: "Virginia",
		countyState: "Sussex County, Virginia",
		centroidLng: -77.2863000,
		centroidLat: 36.9098000
	},
	{
		geoid: "18141",
		name: "St. Joseph",
		state: "Indiana",
		countyState: "St. Joseph County, Indiana",
		centroidLng: -86.2931000,
		centroidLat: 41.5967000
	},
	{
		geoid: "38067",
		name: "Pembina",
		state: "North Dakota",
		countyState: "Pembina County, North Dakota",
		centroidLng: -97.5205000,
		centroidLat: 48.7714000
	},
	{
		geoid: "46007",
		name: "Bennett",
		state: "South Dakota",
		countyState: "Bennett County, South Dakota",
		centroidLng: -101.6690000,
		centroidLat: 43.1932000
	},
	{
		geoid: "22115",
		name: "Vernon",
		state: "Louisiana",
		countyState: "Vernon Parish, Louisiana",
		centroidLng: -93.2009000,
		centroidLat: 31.1120000
	},
	{
		geoid: "23019",
		name: "Penobscot",
		state: "Maine",
		countyState: "Penobscot County, Maine",
		centroidLng: -68.6477000,
		centroidLat: 45.5199000
	},
	{
		geoid: "24041",
		name: "Talbot",
		state: "Maryland",
		countyState: "Talbot County, Maryland",
		centroidLng: -76.1801000,
		centroidLat: 38.7585000
	},
	{
		geoid: "13257",
		name: "Stephens",
		state: "Georgia",
		countyState: "Stephens County, Georgia",
		centroidLng: -83.2816000,
		centroidLat: 34.5740000
	},
	{
		geoid: "06065",
		name: "Riverside",
		state: "California",
		countyState: "Riverside County, California",
		centroidLng: -116.0560000,
		centroidLat: 33.7529000
	},
	{
		geoid: "05005",
		name: "Baxter",
		state: "Arkansas",
		countyState: "Baxter County, Arkansas",
		centroidLng: -92.3726000,
		centroidLat: 36.2364000
	},
	{
		geoid: "28007",
		name: "Attala",
		state: "Mississippi",
		countyState: "Attala County, Mississippi",
		centroidLng: -89.6412000,
		centroidLat: 33.0828000
	},
	{
		geoid: "19169",
		name: "Story",
		state: "Iowa",
		countyState: "Story County, Iowa",
		centroidLng: -93.4651000,
		centroidLat: 42.0360000
	},
	{
		geoid: "20011",
		name: "Bourbon",
		state: "Kansas",
		countyState: "Bourbon County, Kansas",
		centroidLng: -94.8511000,
		centroidLat: 37.8554000
	},
	{
		geoid: "51049",
		name: "Cumberland",
		state: "Virginia",
		countyState: "Cumberland County, Virginia",
		centroidLng: -78.2686000,
		centroidLat: 37.5255000
	},
	{
		geoid: "45085",
		name: "Sumter",
		state: "South Carolina",
		countyState: "Sumter County, South Carolina",
		centroidLng: -80.2700000,
		centroidLat: 33.9063000
	},
	{
		geoid: "02164",
		name: "Lake and Peninsula",
		state: "Alaska",
		countyState: "Lake and Peninsula Borough, Alaska",
		centroidLng: -156.6490000,
		centroidLat: 58.2723000
	},
	{
		geoid: "54109",
		name: "Wyoming",
		state: "West Virginia",
		countyState: "Wyoming County, West Virginia",
		centroidLng: -81.5400000,
		centroidLat: 37.6033000
	},
	{
		geoid: "06029",
		name: "Kern",
		state: "California",
		countyState: "Kern County, California",
		centroidLng: -118.9050000,
		centroidLat: 35.2944000
	},
	{
		geoid: "48097",
		name: "Cooke",
		state: "Texas",
		countyState: "Cooke County, Texas",
		centroidLng: -97.2152000,
		centroidLat: 33.6864000
	},
	{
		geoid: "45075",
		name: "Orangeburg",
		state: "South Carolina",
		countyState: "Orangeburg County, South Carolina",
		centroidLng: -80.7975000,
		centroidLat: 33.4420000
	},
	{
		geoid: "55119",
		name: "Taylor",
		state: "Wisconsin",
		countyState: "Taylor County, Wisconsin",
		centroidLng: -90.4841000,
		centroidLat: 45.2068000
	},
	{
		geoid: "27099",
		name: "Mower",
		state: "Minnesota",
		countyState: "Mower County, Minnesota",
		centroidLng: -92.7493000,
		centroidLat: 43.6741000
	},
	{
		geoid: "27101",
		name: "Murray",
		state: "Minnesota",
		countyState: "Murray County, Minnesota",
		centroidLng: -95.7634000,
		centroidLat: 44.0222000
	},
	{
		geoid: "44007",
		name: "Providence",
		state: "Rhode Island",
		countyState: "Providence County, Rhode Island",
		centroidLng: -71.5585000,
		centroidLat: 41.8720000
	},
	{
		geoid: "29161",
		name: "Phelps",
		state: "Missouri",
		countyState: "Phelps County, Missouri",
		centroidLng: -91.7772000,
		centroidLat: 37.8762000
	},
	{
		geoid: "28117",
		name: "Prentiss",
		state: "Mississippi",
		countyState: "Prentiss County, Mississippi",
		centroidLng: -88.5328000,
		centroidLat: 34.6102000
	},
	{
		geoid: "21235",
		name: "Whitley",
		state: "Kentucky",
		countyState: "Whitley County, Kentucky",
		centroidLng: -84.1167000,
		centroidLat: 36.7803000
	},
	{
		geoid: "18149",
		name: "Starke",
		state: "Indiana",
		countyState: "Starke County, Indiana",
		centroidLng: -86.6982000,
		centroidLat: 41.3022000
	},
	{
		geoid: "48491",
		name: "Williamson",
		state: "Texas",
		countyState: "Williamson County, Texas",
		centroidLng: -97.6026000,
		centroidLat: 30.6545000
	},
	{
		geoid: "72109",
		name: "Patillas",
		state: "Puerto Rico",
		countyState: "Patillas County, Puerto Rico",
		centroidLng: -65.9813000,
		centroidLat: 18.0094000
	},
	{
		geoid: "19131",
		name: "Mitchell",
		state: "Iowa",
		countyState: "Mitchell County, Iowa",
		centroidLng: -92.7888000,
		centroidLat: 43.3564000
	},
	{
		geoid: "13107",
		name: "Emanuel",
		state: "Georgia",
		countyState: "Emanuel County, Georgia",
		centroidLng: -82.3245000,
		centroidLat: 32.5666000
	},
	{
		geoid: "22025",
		name: "Catahoula",
		state: "Louisiana",
		countyState: "Catahoula Parish, Louisiana",
		centroidLng: -91.7794000,
		centroidLat: 31.5963000
	},
	{
		geoid: "42025",
		name: "Carbon",
		state: "Pennsylvania",
		countyState: "Carbon County, Pennsylvania",
		centroidLng: -75.7358000,
		centroidLat: 40.9347000
	},
	{
		geoid: "31133",
		name: "Pawnee",
		state: "Nebraska",
		countyState: "Pawnee County, Nebraska",
		centroidLng: -96.2370000,
		centroidLat: 40.1315000
	},
	{
		geoid: "47171",
		name: "Unicoi",
		state: "Tennessee",
		countyState: "Unicoi County, Tennessee",
		centroidLng: -82.4186000,
		centroidLat: 36.1071000
	},
	{
		geoid: "45083",
		name: "Spartanburg",
		state: "South Carolina",
		countyState: "Spartanburg County, South Carolina",
		centroidLng: -81.9691000,
		centroidLat: 34.8882000
	},
	{
		geoid: "55073",
		name: "Marathon",
		state: "Wisconsin",
		countyState: "Marathon County, Wisconsin",
		centroidLng: -89.7702000,
		centroidLat: 44.9009000
	},
	{
		geoid: "32007",
		name: "Elko",
		state: "Nevada",
		countyState: "Elko County, Nevada",
		centroidLng: -115.5290000,
		centroidLat: 41.0592000
	},
	{
		geoid: "54007",
		name: "Braxton",
		state: "West Virginia",
		countyState: "Braxton County, West Virginia",
		centroidLng: -80.7369000,
		centroidLat: 38.7060000
	},
	{
		geoid: "26165",
		name: "Wexford",
		state: "Michigan",
		countyState: "Wexford County, Michigan",
		centroidLng: -85.5777000,
		centroidLat: 44.3386000
	},
	{
		geoid: "48079",
		name: "Cochran",
		state: "Texas",
		countyState: "Cochran County, Texas",
		centroidLng: -102.8260000,
		centroidLat: 33.6066000
	},
	{
		geoid: "42013",
		name: "Blair",
		state: "Pennsylvania",
		countyState: "Blair County, Pennsylvania",
		centroidLng: -78.3685000,
		centroidLat: 40.4926000
	},
	{
		geoid: "29085",
		name: "Hickory",
		state: "Missouri",
		countyState: "Hickory County, Missouri",
		centroidLng: -93.3196000,
		centroidLat: 37.9379000
	},
	{
		geoid: "29009",
		name: "Barry",
		state: "Missouri",
		countyState: "Barry County, Missouri",
		centroidLng: -93.8255000,
		centroidLat: 36.7152000
	},
	{
		geoid: "27123",
		name: "Ramsey",
		state: "Minnesota",
		countyState: "Ramsey County, Minnesota",
		centroidLng: -93.1062000,
		centroidLat: 45.0060000
	},
	{
		geoid: "05081",
		name: "Little River",
		state: "Arkansas",
		countyState: "Little River County, Arkansas",
		centroidLng: -94.1552000,
		centroidLat: 33.7395000
	},
	{
		geoid: "32019",
		name: "Lyon",
		state: "Nevada",
		countyState: "Lyon County, Nevada",
		centroidLng: -119.2330000,
		centroidLat: 39.0772000
	},
	{
		geoid: "12071",
		name: "Lee",
		state: "Florida",
		countyState: "Lee County, Florida",
		centroidLng: -81.9486000,
		centroidLat: 26.5529000
	},
	{
		geoid: "41033",
		name: "Josephine",
		state: "Oregon",
		countyState: "Josephine County, Oregon",
		centroidLng: -123.6360000,
		centroidLat: 42.3896000
	},
	{
		geoid: "72091",
		name: "Manat?",
		state: "Puerto Rico",
		countyState: "Manat? County, Puerto Rico",
		centroidLng: -66.4903000,
		centroidLat: 18.4455000
	},
	{
		geoid: "28113",
		name: "Pike",
		state: "Mississippi",
		countyState: "Pike County, Mississippi",
		centroidLng: -90.4040000,
		centroidLat: 31.1749000
	},
	{
		geoid: "47031",
		name: "Coffee",
		state: "Tennessee",
		countyState: "Coffee County, Tennessee",
		centroidLng: -86.0747000,
		centroidLat: 35.4978000
	},
	{
		geoid: "16059",
		name: "Lemhi",
		state: "Idaho",
		countyState: "Lemhi County, Idaho",
		centroidLng: -113.8170000,
		centroidLat: 44.9681000
	},
	{
		geoid: "21183",
		name: "Ohio",
		state: "Kentucky",
		countyState: "Ohio County, Kentucky",
		centroidLng: -86.8569000,
		centroidLat: 37.4750000
	},
	{
		geoid: "31153",
		name: "Sarpy",
		state: "Nebraska",
		countyState: "Sarpy County, Nebraska",
		centroidLng: -96.0907000,
		centroidLat: 41.0927000
	},
	{
		geoid: "13071",
		name: "Colquitt",
		state: "Georgia",
		countyState: "Colquitt County, Georgia",
		centroidLng: -83.7596000,
		centroidLat: 31.1804000
	},
	{
		geoid: "27167",
		name: "Wilkin",
		state: "Minnesota",
		countyState: "Wilkin County, Minnesota",
		centroidLng: -96.5280000,
		centroidLat: 46.3262000
	},
	{
		geoid: "17157",
		name: "Randolph",
		state: "Illinois",
		countyState: "Randolph County, Illinois",
		centroidLng: -89.8995000,
		centroidLat: 38.0131000
	},
	{
		geoid: "23011",
		name: "Kennebec",
		state: "Maine",
		countyState: "Kennebec County, Maine",
		centroidLng: -69.7526000,
		centroidLat: 44.4140000
	},
	{
		geoid: "51173",
		name: "Smyth",
		state: "Virginia",
		countyState: "Smyth County, Virginia",
		centroidLng: -81.5495000,
		centroidLat: 36.8262000
	},
	{
		geoid: "35041",
		name: "Roosevelt",
		state: "New Mexico",
		countyState: "Roosevelt County, New Mexico",
		centroidLng: -103.4960000,
		centroidLat: 34.0876000
	},
	{
		geoid: "42111",
		name: "Somerset",
		state: "Pennsylvania",
		countyState: "Somerset County, Pennsylvania",
		centroidLng: -79.0380000,
		centroidLat: 40.0035000
	},
	{
		geoid: "51191",
		name: "Washington",
		state: "Virginia",
		countyState: "Washington County, Virginia",
		centroidLng: -81.9687000,
		centroidLat: 36.7616000
	},
	{
		geoid: "37081",
		name: "Guilford",
		state: "North Carolina",
		countyState: "Guilford County, North Carolina",
		centroidLng: -79.7896000,
		centroidLat: 36.0785000
	},
	{
		geoid: "16001",
		name: "Ada",
		state: "Idaho",
		countyState: "Ada County, Idaho",
		centroidLng: -116.2440000,
		centroidLat: 43.4598000
	},
	{
		geoid: "40039",
		name: "Custer",
		state: "Oklahoma",
		countyState: "Custer County, Oklahoma",
		centroidLng: -99.0001000,
		centroidLat: 35.6386000
	},
	{
		geoid: "39101",
		name: "Marion",
		state: "Ohio",
		countyState: "Marion County, Ohio",
		centroidLng: -83.1393000,
		centroidLat: 40.5694000
	},
	{
		geoid: "17149",
		name: "Pike",
		state: "Illinois",
		countyState: "Pike County, Illinois",
		centroidLng: -90.9679000,
		centroidLat: 39.6204000
	},
	{
		geoid: "17203",
		name: "Woodford",
		state: "Illinois",
		countyState: "Woodford County, Illinois",
		centroidLng: -89.2466000,
		centroidLat: 40.7610000
	},
	{
		geoid: "13245",
		name: "Richmond",
		state: "Georgia",
		countyState: "Richmond County, Georgia",
		centroidLng: -82.0909000,
		centroidLat: 33.3861000
	},
	{
		geoid: "01107",
		name: "Pickens",
		state: "Alabama",
		countyState: "Pickens County, Alabama",
		centroidLng: -88.0885000,
		centroidLat: 33.2626000
	},
	{
		geoid: "40045",
		name: "Ellis",
		state: "Oklahoma",
		countyState: "Ellis County, Oklahoma",
		centroidLng: -99.6924000,
		centroidLat: 36.2177000
	},
	{
		geoid: "17145",
		name: "Perry",
		state: "Illinois",
		countyState: "Perry County, Illinois",
		centroidLng: -89.3552000,
		centroidLat: 38.0847000
	},
	{
		geoid: "19075",
		name: "Grundy",
		state: "Iowa",
		countyState: "Grundy County, Iowa",
		centroidLng: -92.7803000,
		centroidLat: 42.3830000
	},
	{
		geoid: "08107",
		name: "Routt",
		state: "Colorado",
		countyState: "Routt County, Colorado",
		centroidLng: -107.0340000,
		centroidLat: 40.4611000
	},
	{
		geoid: "34035",
		name: "Somerset",
		state: "New Jersey",
		countyState: "Somerset County, New Jersey",
		centroidLng: -74.5998000,
		centroidLat: 40.5660000
	},
	{
		geoid: "06011",
		name: "Colusa",
		state: "California",
		countyState: "Colusa County, California",
		centroidLng: -122.2900000,
		centroidLat: 39.1692000
	},
	{
		geoid: "17087",
		name: "Johnson",
		state: "Illinois",
		countyState: "Johnson County, Illinois",
		centroidLng: -88.8767000,
		centroidLat: 37.4474000
	},
	{
		geoid: "08053",
		name: "Hinsdale",
		state: "Colorado",
		countyState: "Hinsdale County, Colorado",
		centroidLng: -107.2960000,
		centroidLat: 37.7850000
	},
	{
		geoid: "16031",
		name: "Cassia",
		state: "Idaho",
		countyState: "Cassia County, Idaho",
		centroidLng: -113.6430000,
		centroidLat: 42.3380000
	},
	{
		geoid: "27059",
		name: "Isanti",
		state: "Minnesota",
		countyState: "Isanti County, Minnesota",
		centroidLng: -93.2666000,
		centroidLat: 45.5730000
	},
	{
		geoid: "42049",
		name: "Erie",
		state: "Pennsylvania",
		countyState: "Erie County, Pennsylvania",
		centroidLng: -80.0652000,
		centroidLat: 42.1826000
	},
	{
		geoid: "28029",
		name: "Copiah",
		state: "Mississippi",
		countyState: "Copiah County, Mississippi",
		centroidLng: -90.4221000,
		centroidLat: 31.8676000
	},
	{
		geoid: "48467",
		name: "Van Zandt",
		state: "Texas",
		countyState: "Van Zandt County, Texas",
		centroidLng: -95.7629000,
		centroidLat: 32.5968000
	},
	{
		geoid: "51161",
		name: "Roanoke",
		state: "Virginia",
		countyState: "Roanoke County, Virginia",
		centroidLng: -80.0509000,
		centroidLat: 37.2658000
	},
	{
		geoid: "51015",
		name: "Augusta",
		state: "Virginia",
		countyState: "Augusta County, Virginia",
		centroidLng: -79.1413000,
		centroidLat: 38.1796000
	},
	{
		geoid: "02060",
		name: "Bristol Bay",
		state: "Alaska",
		countyState: "Bristol Bay Borough, Alaska",
		centroidLng: -157.1570000,
		centroidLat: 58.7512000
	},
	{
		geoid: "49031",
		name: "Piute",
		state: "Utah",
		countyState: "Piute County, Utah",
		centroidLng: -112.1430000,
		centroidLat: 38.3289000
	},
	{
		geoid: "21161",
		name: "Mason",
		state: "Kentucky",
		countyState: "Mason County, Kentucky",
		centroidLng: -83.8081000,
		centroidLat: 38.6123000
	},
	{
		geoid: "27009",
		name: "Benton",
		state: "Minnesota",
		countyState: "Benton County, Minnesota",
		centroidLng: -94.0563000,
		centroidLat: 45.6917000
	},
	{
		geoid: "48129",
		name: "Donley",
		state: "Texas",
		countyState: "Donley County, Texas",
		centroidLng: -100.8150000,
		centroidLat: 34.9655000
	},
	{
		geoid: "12005",
		name: "Bay",
		state: "Florida",
		countyState: "Bay County, Florida",
		centroidLng: -85.6918000,
		centroidLat: 30.2344000
	},
	{
		geoid: "01077",
		name: "Lauderdale",
		state: "Alabama",
		countyState: "Lauderdale County, Alabama",
		centroidLng: -87.7069000,
		centroidLat: 34.8700000
	},
	{
		geoid: "18045",
		name: "Fountain",
		state: "Indiana",
		countyState: "Fountain County, Indiana",
		centroidLng: -87.2651000,
		centroidLat: 40.1596000
	},
	{
		geoid: "17173",
		name: "Shelby",
		state: "Illinois",
		countyState: "Shelby County, Illinois",
		centroidLng: -88.8053000,
		centroidLat: 39.4346000
	},
	{
		geoid: "17051",
		name: "Fayette",
		state: "Illinois",
		countyState: "Fayette County, Illinois",
		centroidLng: -88.9755000,
		centroidLat: 38.9771000
	},
	{
		geoid: "39127",
		name: "Perry",
		state: "Ohio",
		countyState: "Perry County, Ohio",
		centroidLng: -82.2483000,
		centroidLat: 39.7403000
	},
	{
		geoid: "51590",
		name: "Danville",
		state: "Virginia",
		countyState: "Danville County, Virginia",
		centroidLng: -79.4109000,
		centroidLat: 36.5925000
	},
	{
		geoid: "13011",
		name: "Banks",
		state: "Georgia",
		countyState: "Banks County, Georgia",
		centroidLng: -83.5038000,
		centroidLat: 34.3446000
	},
	{
		geoid: "13305",
		name: "Wayne",
		state: "Georgia",
		countyState: "Wayne County, Georgia",
		centroidLng: -81.8800000,
		centroidLat: 31.5782000
	},
	{
		geoid: "40109",
		name: "Oklahoma",
		state: "Oklahoma",
		countyState: "Oklahoma County, Oklahoma",
		centroidLng: -97.4074000,
		centroidLat: 35.5514000
	},
	{
		geoid: "40069",
		name: "Johnston",
		state: "Oklahoma",
		countyState: "Johnston County, Oklahoma",
		centroidLng: -96.6704000,
		centroidLat: 34.3102000
	},
	{
		geoid: "37099",
		name: "Jackson",
		state: "North Carolina",
		countyState: "Jackson County, North Carolina",
		centroidLng: -83.1415000,
		centroidLat: 35.2634000
	},
	{
		geoid: "49043",
		name: "Summit",
		state: "Utah",
		countyState: "Summit County, Utah",
		centroidLng: -110.8250000,
		centroidLat: 40.9000000
	},
	{
		geoid: "29167",
		name: "Polk",
		state: "Missouri",
		countyState: "Polk County, Missouri",
		centroidLng: -93.4028000,
		centroidLat: 37.6233000
	},
	{
		geoid: "32015",
		name: "Lander",
		state: "Nevada",
		countyState: "Lander County, Nevada",
		centroidLng: -117.1970000,
		centroidLat: 40.0470000
	},
	{
		geoid: "19191",
		name: "Winneshiek",
		state: "Iowa",
		countyState: "Winneshiek County, Iowa",
		centroidLng: -91.8426000,
		centroidLat: 43.2913000
	},
	{
		geoid: "21023",
		name: "Bracken",
		state: "Kentucky",
		countyState: "Bracken County, Kentucky",
		centroidLng: -84.0699000,
		centroidLat: 38.6886000
	},
	{
		geoid: "55037",
		name: "Florence",
		state: "Wisconsin",
		countyState: "Florence County, Wisconsin",
		centroidLng: -88.3712000,
		centroidLat: 45.8671000
	},
	{
		geoid: "54087",
		name: "Roane",
		state: "West Virginia",
		countyState: "Roane County, West Virginia",
		centroidLng: -81.3146000,
		centroidLat: 38.7317000
	},
	{
		geoid: "27159",
		name: "Wadena",
		state: "Minnesota",
		countyState: "Wadena County, Minnesota",
		centroidLng: -94.9460000,
		centroidLat: 46.5870000
	},
	{
		geoid: "51670",
		name: "Hopewell",
		state: "Virginia",
		countyState: "Hopewell County, Virginia",
		centroidLng: -77.2951000,
		centroidLat: 37.2876000
	},
	{
		geoid: "17201",
		name: "Winnebago",
		state: "Illinois",
		countyState: "Winnebago County, Illinois",
		centroidLng: -89.1706000,
		centroidLat: 42.3254000
	},
	{
		geoid: "04007",
		name: "Gila",
		state: "Arizona",
		countyState: "Gila County, Arizona",
		centroidLng: -110.8610000,
		centroidLat: 33.7415000
	},
	{
		geoid: "24045",
		name: "Wicomico",
		state: "Maryland",
		countyState: "Wicomico County, Maryland",
		centroidLng: -75.6277000,
		centroidLat: 38.3884000
	},
	{
		geoid: "21043",
		name: "Carter",
		state: "Kentucky",
		countyState: "Carter County, Kentucky",
		centroidLng: -83.0566000,
		centroidLat: 38.3365000
	},
	{
		geoid: "37097",
		name: "Iredell",
		state: "North Carolina",
		countyState: "Iredell County, North Carolina",
		centroidLng: -80.9014000,
		centroidLat: 35.7740000
	},
	{
		geoid: "51083",
		name: "Halifax",
		state: "Virginia",
		countyState: "Halifax County, Virginia",
		centroidLng: -78.8838000,
		centroidLat: 36.8016000
	},
	{
		geoid: "37091",
		name: "Hertford",
		state: "North Carolina",
		countyState: "Hertford County, North Carolina",
		centroidLng: -76.9490000,
		centroidLat: 36.3922000
	},
	{
		geoid: "36057",
		name: "Montgomery",
		state: "New York",
		countyState: "Montgomery County, New York",
		centroidLng: -74.4236000,
		centroidLat: 42.9102000
	},
	{
		geoid: "27113",
		name: "Pennington",
		state: "Minnesota",
		countyState: "Pennington County, Minnesota",
		centroidLng: -96.0417000,
		centroidLat: 48.0545000
	},
	{
		geoid: "53073",
		name: "Whatcom",
		state: "Washington",
		countyState: "Whatcom County, Washington",
		centroidLng: -121.9890000,
		centroidLat: 48.8141000
	},
	{
		geoid: "41021",
		name: "Gilliam",
		state: "Oregon",
		countyState: "Gilliam County, Oregon",
		centroidLng: -120.2070000,
		centroidLat: 45.4389000
	},
	{
		geoid: "34029",
		name: "Ocean",
		state: "New Jersey",
		countyState: "Ocean County, New Jersey",
		centroidLng: -74.2593000,
		centroidLat: 39.8238000
	},
	{
		geoid: "05009",
		name: "Boone",
		state: "Arkansas",
		countyState: "Boone County, Arkansas",
		centroidLng: -93.0783000,
		centroidLat: 36.3063000
	},
	{
		geoid: "40031",
		name: "Comanche",
		state: "Oklahoma",
		countyState: "Comanche County, Oklahoma",
		centroidLng: -98.4576000,
		centroidLat: 34.6308000
	},
	{
		geoid: "48253",
		name: "Jones",
		state: "Texas",
		countyState: "Jones County, Texas",
		centroidLng: -99.8792000,
		centroidLat: 32.7375000
	},
	{
		geoid: "48185",
		name: "Grimes",
		state: "Texas",
		countyState: "Grimes County, Texas",
		centroidLng: -95.9963000,
		centroidLat: 30.5447000
	},
	{
		geoid: "26049",
		name: "Genesee",
		state: "Michigan",
		countyState: "Genesee County, Michigan",
		centroidLng: -83.6927000,
		centroidLat: 43.0020000
	},
	{
		geoid: "22007",
		name: "Assumption",
		state: "Louisiana",
		countyState: "Assumption Parish, Louisiana",
		centroidLng: -91.0726000,
		centroidLat: 29.8540000
	},
	{
		geoid: "28013",
		name: "Calhoun",
		state: "Mississippi",
		countyState: "Calhoun County, Mississippi",
		centroidLng: -89.3239000,
		centroidLat: 33.9418000
	},
	{
		geoid: "28041",
		name: "Greene",
		state: "Mississippi",
		countyState: "Greene County, Mississippi",
		centroidLng: -88.6337000,
		centroidLat: 31.2167000
	},
	{
		geoid: "30017",
		name: "Custer",
		state: "Montana",
		countyState: "Custer County, Montana",
		centroidLng: -105.4620000,
		centroidLat: 46.3239000
	},
	{
		geoid: "46109",
		name: "Roberts",
		state: "South Dakota",
		countyState: "Roberts County, South Dakota",
		centroidLng: -96.8494000,
		centroidLat: 45.6164000
	},
	{
		geoid: "17141",
		name: "Ogle",
		state: "Illinois",
		countyState: "Ogle County, Illinois",
		centroidLng: -89.3137000,
		centroidLat: 42.0452000
	},
	{
		geoid: "38077",
		name: "Richland",
		state: "North Dakota",
		countyState: "Richland County, North Dakota",
		centroidLng: -96.9176000,
		centroidLat: 46.2835000
	},
	{
		geoid: "55005",
		name: "Barron",
		state: "Wisconsin",
		countyState: "Barron County, Wisconsin",
		centroidLng: -91.8482000,
		centroidLat: 45.4234000
	},
	{
		geoid: "55051",
		name: "Iron",
		state: "Wisconsin",
		countyState: "Iron County, Wisconsin",
		centroidLng: -90.2402000,
		centroidLat: 46.3898000
	},
	{
		geoid: "27157",
		name: "Wabasha",
		state: "Minnesota",
		countyState: "Wabasha County, Minnesota",
		centroidLng: -92.2046000,
		centroidLat: 44.2810000
	},
	{
		geoid: "06015",
		name: "Del Norte",
		state: "California",
		countyState: "Del Norte County, California",
		centroidLng: -123.9790000,
		centroidLat: 41.6908000
	},
	{
		geoid: "40127",
		name: "Pushmataha",
		state: "Oklahoma",
		countyState: "Pushmataha County, Oklahoma",
		centroidLng: -95.3585000,
		centroidLat: 34.4188000
	},
	{
		geoid: "53007",
		name: "Chelan",
		state: "Washington",
		countyState: "Chelan County, Washington",
		centroidLng: -120.5200000,
		centroidLat: 47.9059000
	},
	{
		geoid: "26141",
		name: "Presque Isle",
		state: "Michigan",
		countyState: "Presque Isle County, Michigan",
		centroidLng: -83.3713000,
		centroidLat: 45.4880000
	},
	{
		geoid: "40027",
		name: "Cleveland",
		state: "Oklahoma",
		countyState: "Cleveland County, Oklahoma",
		centroidLng: -97.4066000,
		centroidLat: 35.1525000
	},
	{
		geoid: "36105",
		name: "Sullivan",
		state: "New York",
		countyState: "Sullivan County, New York",
		centroidLng: -74.7554000,
		centroidLat: 41.7190000
	},
	{
		geoid: "37149",
		name: "Polk",
		state: "North Carolina",
		countyState: "Polk County, North Carolina",
		centroidLng: -82.1610000,
		centroidLat: 35.2973000
	},
	{
		geoid: "37165",
		name: "Scotland",
		state: "North Carolina",
		countyState: "Scotland County, North Carolina",
		centroidLng: -79.5119000,
		centroidLat: 34.8371000
	},
	{
		geoid: "37085",
		name: "Harnett",
		state: "North Carolina",
		countyState: "Harnett County, North Carolina",
		centroidLng: -78.8764000,
		centroidLat: 35.3878000
	},
	{
		geoid: "37105",
		name: "Lee",
		state: "North Carolina",
		countyState: "Lee County, North Carolina",
		centroidLng: -79.1648000,
		centroidLat: 35.4677000
	},
	{
		geoid: "18159",
		name: "Tipton",
		state: "Indiana",
		countyState: "Tipton County, Indiana",
		centroidLng: -86.0516000,
		centroidLat: 40.3114000
	},
	{
		geoid: "20027",
		name: "Clay",
		state: "Kansas",
		countyState: "Clay County, Kansas",
		centroidLng: -97.1649000,
		centroidLat: 39.3495000
	},
	{
		geoid: "40145",
		name: "Wagoner",
		state: "Oklahoma",
		countyState: "Wagoner County, Oklahoma",
		centroidLng: -95.5139000,
		centroidLat: 35.9641000
	},
	{
		geoid: "30083",
		name: "Richland",
		state: "Montana",
		countyState: "Richland County, Montana",
		centroidLng: -104.6380000,
		centroidLat: 47.7521000
	},
	{
		geoid: "05003",
		name: "Ashley",
		state: "Arkansas",
		countyState: "Ashley County, Arkansas",
		centroidLng: -91.8005000,
		centroidLat: 33.2021000
	},
	{
		geoid: "05041",
		name: "Desha",
		state: "Arkansas",
		countyState: "Desha County, Arkansas",
		centroidLng: -91.2141000,
		centroidLat: 33.8184000
	},
	{
		geoid: "72147",
		name: "Vieques",
		state: "Puerto Rico",
		countyState: "Vieques County, Puerto Rico",
		centroidLng: -65.4231000,
		centroidLat: 18.1249000
	},
	{
		geoid: "47135",
		name: "Perry",
		state: "Tennessee",
		countyState: "Perry County, Tennessee",
		centroidLng: -87.8460000,
		centroidLat: 35.6321000
	},
	{
		geoid: "27163",
		name: "Washington",
		state: "Minnesota",
		countyState: "Washington County, Minnesota",
		centroidLng: -92.8805000,
		centroidLat: 45.0213000
	},
	{
		geoid: "51740",
		name: "Portsmouth",
		state: "Virginia",
		countyState: "Portsmouth County, Virginia",
		centroidLng: -76.3552000,
		centroidLat: 36.8686000
	},
	{
		geoid: "42051",
		name: "Fayette",
		state: "Pennsylvania",
		countyState: "Fayette County, Pennsylvania",
		centroidLng: -79.6522000,
		centroidLat: 39.9321000
	},
	{
		geoid: "54023",
		name: "Grant",
		state: "West Virginia",
		countyState: "Grant County, West Virginia",
		centroidLng: -79.2335000,
		centroidLat: 39.0707000
	},
	{
		geoid: "51171",
		name: "Shenandoah",
		state: "Virginia",
		countyState: "Shenandoah County, Virginia",
		centroidLng: -78.5850000,
		centroidLat: 38.8538000
	},
	{
		geoid: "48235",
		name: "Irion",
		state: "Texas",
		countyState: "Irion County, Texas",
		centroidLng: -100.9820000,
		centroidLat: 31.3041000
	},
	{
		geoid: "29025",
		name: "Caldwell",
		state: "Missouri",
		countyState: "Caldwell County, Missouri",
		centroidLng: -93.9833000,
		centroidLat: 39.6567000
	},
	{
		geoid: "12107",
		name: "Putnam",
		state: "Florida",
		countyState: "Putnam County, Florida",
		centroidLng: -81.7449000,
		centroidLat: 29.5823000
	},
	{
		geoid: "18025",
		name: "Crawford",
		state: "Indiana",
		countyState: "Crawford County, Indiana",
		centroidLng: -86.4635000,
		centroidLat: 38.2636000
	},
	{
		geoid: "29111",
		name: "Lewis",
		state: "Missouri",
		countyState: "Lewis County, Missouri",
		centroidLng: -91.6941000,
		centroidLat: 40.1012000
	},
	{
		geoid: "31075",
		name: "Grant",
		state: "Nebraska",
		countyState: "Grant County, Nebraska",
		centroidLng: -101.7470000,
		centroidLat: 41.9191000
	},
	{
		geoid: "72087",
		name: "Lo?za",
		state: "Puerto Rico",
		countyState: "Lo?za County, Puerto Rico",
		centroidLng: -65.8994000,
		centroidLat: 18.4474000
	},
	{
		geoid: "16071",
		name: "Oneida",
		state: "Idaho",
		countyState: "Oneida County, Idaho",
		centroidLng: -112.5350000,
		centroidLat: 42.2501000
	},
	{
		geoid: "17139",
		name: "Moultrie",
		state: "Illinois",
		countyState: "Moultrie County, Illinois",
		centroidLng: -88.6416000,
		centroidLat: 39.6197000
	},
	{
		geoid: "21107",
		name: "Hopkins",
		state: "Kentucky",
		countyState: "Hopkins County, Kentucky",
		centroidLng: -87.5736000,
		centroidLat: 37.3377000
	},
	{
		geoid: "16035",
		name: "Clearwater",
		state: "Idaho",
		countyState: "Clearwater County, Idaho",
		centroidLng: -115.5250000,
		centroidLat: 46.6002000
	},
	{
		geoid: "21013",
		name: "Bell",
		state: "Kentucky",
		countyState: "Bell County, Kentucky",
		centroidLng: -83.7084000,
		centroidLat: 36.7692000
	},
	{
		geoid: "27035",
		name: "Crow Wing",
		state: "Minnesota",
		countyState: "Crow Wing County, Minnesota",
		centroidLng: -94.0859000,
		centroidLat: 46.4807000
	},
	{
		geoid: "60050",
		name: "Western",
		state: "American Samoa",
		countyState: "Western County, American Samoa",
		centroidLng: -170.7780000,
		centroidLat: -14.3331000
	},
	{
		geoid: "72011",
		name: "A?asco",
		state: "Puerto Rico",
		countyState: "A?asco County, Puerto Rico",
		centroidLng: -67.1407000,
		centroidLat: 18.2834000
	},
	{
		geoid: "04009",
		name: "Graham",
		state: "Arizona",
		countyState: "Graham County, Arizona",
		centroidLng: -109.7860000,
		centroidLat: 33.0386000
	},
	{
		geoid: "05123",
		name: "St. Francis",
		state: "Arkansas",
		countyState: "St. Francis County, Arkansas",
		centroidLng: -90.7765000,
		centroidLat: 35.0080000
	},
	{
		geoid: "13101",
		name: "Echols",
		state: "Georgia",
		countyState: "Echols County, Georgia",
		centroidLng: -82.8577000,
		centroidLat: 30.7319000
	},
	{
		geoid: "02070",
		name: "Dillingham",
		state: "Alaska",
		countyState: "Dillingham Borough, Alaska",
		centroidLng: -159.9800000,
		centroidLat: 58.6001000
	},
	{
		geoid: "20105",
		name: "Lincoln",
		state: "Kansas",
		countyState: "Lincoln County, Kansas",
		centroidLng: -98.2093000,
		centroidLat: 39.0451000
	},
	{
		geoid: "20183",
		name: "Smith",
		state: "Kansas",
		countyState: "Smith County, Kansas",
		centroidLng: -98.7856000,
		centroidLat: 39.7850000
	},
	{
		geoid: "12065",
		name: "Jefferson",
		state: "Florida",
		countyState: "Jefferson County, Florida",
		centroidLng: -83.8402000,
		centroidLat: 30.3422000
	},
	{
		geoid: "72041",
		name: "Cidra",
		state: "Puerto Rico",
		countyState: "Cidra County, Puerto Rico",
		centroidLng: -66.1689000,
		centroidLat: 18.1815000
	},
	{
		geoid: "28005",
		name: "Amite",
		state: "Mississippi",
		countyState: "Amite County, Mississippi",
		centroidLng: -90.8215000,
		centroidLat: 31.1743000
	},
	{
		geoid: "05097",
		name: "Montgomery",
		state: "Arkansas",
		countyState: "Montgomery County, Arkansas",
		centroidLng: -93.6646000,
		centroidLat: 34.5430000
	},
	{
		geoid: "26025",
		name: "Calhoun",
		state: "Michigan",
		countyState: "Calhoun County, Michigan",
		centroidLng: -85.0046000,
		centroidLat: 42.2463000
	},
	{
		geoid: "01029",
		name: "Cleburne",
		state: "Alabama",
		countyState: "Cleburne County, Alabama",
		centroidLng: -85.5961000,
		centroidLat: 33.7167000
	},
	{
		geoid: "08123",
		name: "Weld",
		state: "Colorado",
		countyState: "Weld County, Colorado",
		centroidLng: -104.3150000,
		centroidLat: 40.5011000
	},
	{
		geoid: "40009",
		name: "Beckham",
		state: "Oklahoma",
		countyState: "Beckham County, Oklahoma",
		centroidLng: -99.6803000,
		centroidLat: 35.2695000
	},
	{
		geoid: "06049",
		name: "Modoc",
		state: "California",
		countyState: "Modoc County, California",
		centroidLng: -120.7280000,
		centroidLat: 41.5905000
	},
	{
		geoid: "19153",
		name: "Polk",
		state: "Iowa",
		countyState: "Polk County, Iowa",
		centroidLng: -93.5721000,
		centroidLat: 41.6726000
	},
	{
		geoid: "13179",
		name: "Liberty",
		state: "Georgia",
		countyState: "Liberty County, Georgia",
		centroidLng: -81.4371000,
		centroidLat: 31.8068000
	},
	{
		geoid: "48349",
		name: "Navarro",
		state: "Texas",
		countyState: "Navarro County, Texas",
		centroidLng: -96.4735000,
		centroidLat: 32.0625000
	},
	{
		geoid: "48047",
		name: "Brooks",
		state: "Texas",
		countyState: "Brooks County, Texas",
		centroidLng: -98.2611000,
		centroidLat: 27.0231000
	},
	{
		geoid: "47187",
		name: "Williamson",
		state: "Tennessee",
		countyState: "Williamson County, Tennessee",
		centroidLng: -86.9076000,
		centroidLat: 35.8746000
	},
	{
		geoid: "56021",
		name: "Laramie",
		state: "Wyoming",
		countyState: "Laramie County, Wyoming",
		centroidLng: -104.6660000,
		centroidLat: 41.3274000
	},
	{
		geoid: "53003",
		name: "Asotin",
		state: "Washington",
		countyState: "Asotin County, Washington",
		centroidLng: -117.1980000,
		centroidLat: 46.2288000
	},
	{
		geoid: "27155",
		name: "Traverse",
		state: "Minnesota",
		countyState: "Traverse County, Minnesota",
		centroidLng: -96.5549000,
		centroidLat: 45.8036000
	},
	{
		geoid: "17017",
		name: "Cass",
		state: "Illinois",
		countyState: "Cass County, Illinois",
		centroidLng: -90.2890000,
		centroidLat: 39.9989000
	},
	{
		geoid: "13247",
		name: "Rockdale",
		state: "Georgia",
		countyState: "Rockdale County, Georgia",
		centroidLng: -84.0487000,
		centroidLat: 33.6559000
	},
	{
		geoid: "42067",
		name: "Juniata",
		state: "Pennsylvania",
		countyState: "Juniata County, Pennsylvania",
		centroidLng: -77.3458000,
		centroidLat: 40.4786000
	},
	{
		geoid: "42083",
		name: "McKean",
		state: "Pennsylvania",
		countyState: "McKean County, Pennsylvania",
		centroidLng: -78.5797000,
		centroidLat: 41.8011000
	},
	{
		geoid: "40049",
		name: "Garvin",
		state: "Oklahoma",
		countyState: "Garvin County, Oklahoma",
		centroidLng: -97.3004000,
		centroidLat: 34.6810000
	},
	{
		geoid: "31021",
		name: "Burt",
		state: "Nebraska",
		countyState: "Burt County, Nebraska",
		centroidLng: -96.3100000,
		centroidLat: 41.8650000
	},
	{
		geoid: "35045",
		name: "San Juan",
		state: "New Mexico",
		countyState: "San Juan County, New Mexico",
		centroidLng: -108.2340000,
		centroidLat: 36.5003000
	},
	{
		geoid: "36073",
		name: "Orleans",
		state: "New York",
		countyState: "Orleans County, New York",
		centroidLng: -78.2313000,
		centroidLat: 43.3810000
	},
	{
		geoid: "22031",
		name: "De Soto",
		state: "Louisiana",
		countyState: "De Soto Parish, Louisiana",
		centroidLng: -93.6980000,
		centroidLat: 32.0949000
	},
	{
		geoid: "06019",
		name: "Fresno",
		state: "California",
		countyState: "Fresno County, California",
		centroidLng: -119.6400000,
		centroidLat: 36.7464000
	},
	{
		geoid: "13105",
		name: "Elbert",
		state: "Georgia",
		countyState: "Elbert County, Georgia",
		centroidLng: -82.8339000,
		centroidLat: 34.1223000
	},
	{
		geoid: "01005",
		name: "Barbour",
		state: "Alabama",
		countyState: "Barbour County, Alabama",
		centroidLng: -85.3986000,
		centroidLat: 31.8829000
	},
	{
		geoid: "37017",
		name: "Bladen",
		state: "North Carolina",
		countyState: "Bladen County, North Carolina",
		centroidLng: -78.5393000,
		centroidLat: 34.6119000
	},
	{
		geoid: "48111",
		name: "Dallam",
		state: "Texas",
		countyState: "Dallam County, Texas",
		centroidLng: -102.6020000,
		centroidLat: 36.2778000
	},
	{
		geoid: "17167",
		name: "Sangamon",
		state: "Illinois",
		countyState: "Sangamon County, Illinois",
		centroidLng: -89.6059000,
		centroidLat: 39.7494000
	},
	{
		geoid: "40035",
		name: "Craig",
		state: "Oklahoma",
		countyState: "Craig County, Oklahoma",
		centroidLng: -95.2128000,
		centroidLat: 36.7547000
	},
	{
		geoid: "47035",
		name: "Cumberland",
		state: "Tennessee",
		countyState: "Cumberland County, Tennessee",
		centroidLng: -84.9770000,
		centroidLat: 35.9584000
	},
	{
		geoid: "26137",
		name: "Otsego",
		state: "Michigan",
		countyState: "Otsego County, Michigan",
		centroidLng: -84.6123000,
		centroidLat: 45.0284000
	},
	{
		geoid: "19161",
		name: "Sac",
		state: "Iowa",
		countyState: "Sac County, Iowa",
		centroidLng: -95.1224000,
		centroidLat: 42.3857000
	},
	{
		geoid: "49015",
		name: "Emery",
		state: "Utah",
		countyState: "Emery County, Utah",
		centroidLng: -110.6470000,
		centroidLat: 39.1022000
	},
	{
		geoid: "53019",
		name: "Ferry",
		state: "Washington",
		countyState: "Ferry County, Washington",
		centroidLng: -118.4870000,
		centroidLat: 48.4152000
	},
	{
		geoid: "30109",
		name: "Wibaux",
		state: "Montana",
		countyState: "Wibaux County, Montana",
		centroidLng: -104.3260000,
		centroidLat: 47.0194000
	},
	{
		geoid: "35027",
		name: "Lincoln",
		state: "New Mexico",
		countyState: "Lincoln County, New Mexico",
		centroidLng: -105.6290000,
		centroidLat: 33.7396000
	},
	{
		geoid: "40021",
		name: "Cherokee",
		state: "Oklahoma",
		countyState: "Cherokee County, Oklahoma",
		centroidLng: -95.0400000,
		centroidLat: 35.9001000
	},
	{
		geoid: "27173",
		name: "Yellow Medicine",
		state: "Minnesota",
		countyState: "Yellow Medicine County, Minnesota",
		centroidLng: -95.9060000,
		centroidLat: 44.7390000
	},
	{
		geoid: "48231",
		name: "Hunt",
		state: "Texas",
		countyState: "Hunt County, Texas",
		centroidLng: -96.0780000,
		centroidLat: 33.1234000
	},
	{
		geoid: "48335",
		name: "Mitchell",
		state: "Texas",
		countyState: "Mitchell County, Texas",
		centroidLng: -100.9220000,
		centroidLat: 32.3068000
	},
	{
		geoid: "48499",
		name: "Wood",
		state: "Texas",
		countyState: "Wood County, Texas",
		centroidLng: -95.4077000,
		centroidLat: 32.7772000
	},
	{
		geoid: "40113",
		name: "Osage",
		state: "Oklahoma",
		countyState: "Osage County, Oklahoma",
		centroidLng: -96.5324000,
		centroidLat: 36.5801000
	},
	{
		geoid: "37039",
		name: "Cherokee",
		state: "North Carolina",
		countyState: "Cherokee County, North Carolina",
		centroidLng: -84.0060000,
		centroidLat: 35.1396000
	},
	{
		geoid: "26107",
		name: "Mecosta",
		state: "Michigan",
		countyState: "Mecosta County, Michigan",
		centroidLng: -85.3240000,
		centroidLat: 43.6408000
	},
	{
		geoid: "21195",
		name: "Pike",
		state: "Kentucky",
		countyState: "Pike County, Kentucky",
		centroidLng: -82.3488000,
		centroidLat: 37.4706000
	},
	{
		geoid: "12067",
		name: "Lafayette",
		state: "Florida",
		countyState: "Lafayette County, Florida",
		centroidLng: -83.1231000,
		centroidLat: 30.0414000
	},
	{
		geoid: "37035",
		name: "Catawba",
		state: "North Carolina",
		countyState: "Catawba County, North Carolina",
		centroidLng: -81.2303000,
		centroidLat: 35.6880000
	},
	{
		geoid: "21025",
		name: "Breathitt",
		state: "Kentucky",
		countyState: "Breathitt County, Kentucky",
		centroidLng: -83.2644000,
		centroidLat: 37.5150000
	},
	{
		geoid: "48075",
		name: "Childress",
		state: "Texas",
		countyState: "Childress County, Texas",
		centroidLng: -100.2080000,
		centroidLat: 34.5297000
	},
	{
		geoid: "29181",
		name: "Ripley",
		state: "Missouri",
		countyState: "Ripley County, Missouri",
		centroidLng: -90.8508000,
		centroidLat: 36.6610000
	},
	{
		geoid: "17179",
		name: "Tazewell",
		state: "Illinois",
		countyState: "Tazewell County, Illinois",
		centroidLng: -89.5942000,
		centroidLat: 40.5337000
	},
	{
		geoid: "48243",
		name: "Jeff Davis",
		state: "Texas",
		countyState: "Jeff Davis County, Texas",
		centroidLng: -104.2100000,
		centroidLat: 30.7587000
	},
	{
		geoid: "51678",
		name: "Lexington",
		state: "Virginia",
		countyState: "Lexington County, Virginia",
		centroidLng: -79.4383000,
		centroidLat: 37.7800000
	},
	{
		geoid: "05135",
		name: "Sharp",
		state: "Arkansas",
		countyState: "Sharp County, Arkansas",
		centroidLng: -91.4847000,
		centroidLat: 36.1941000
	},
	{
		geoid: "08069",
		name: "Larimer",
		state: "Colorado",
		countyState: "Larimer County, Colorado",
		centroidLng: -105.5690000,
		centroidLat: 40.6281000
	},
	{
		geoid: "37177",
		name: "Tyrrell",
		state: "North Carolina",
		countyState: "Tyrrell County, North Carolina",
		centroidLng: -76.1230000,
		centroidLat: 35.8474000
	},
	{
		geoid: "27131",
		name: "Rice",
		state: "Minnesota",
		countyState: "Rice County, Minnesota",
		centroidLng: -93.2824000,
		centroidLat: 44.3701000
	},
	{
		geoid: "29219",
		name: "Warren",
		state: "Missouri",
		countyState: "Warren County, Missouri",
		centroidLng: -91.1886000,
		centroidLat: 38.7702000
	},
	{
		geoid: "21227",
		name: "Warren",
		state: "Kentucky",
		countyState: "Warren County, Kentucky",
		centroidLng: -86.3940000,
		centroidLat: 36.9834000
	},
	{
		geoid: "22017",
		name: "Caddo",
		state: "Louisiana",
		countyState: "Caddo Parish, Louisiana",
		centroidLng: -93.7526000,
		centroidLat: 32.6075000
	},
	{
		geoid: "46121",
		name: "Todd",
		state: "South Dakota",
		countyState: "Todd County, South Dakota",
		centroidLng: -100.7130000,
		centroidLat: 43.1940000
	},
	{
		geoid: "19127",
		name: "Marshall",
		state: "Iowa",
		countyState: "Marshall County, Iowa",
		centroidLng: -92.9988000,
		centroidLat: 42.0362000
	},
	{
		geoid: "40141",
		name: "Tillman",
		state: "Oklahoma",
		countyState: "Tillman County, Oklahoma",
		centroidLng: -98.9238000,
		centroidLat: 34.3811000
	},
	{
		geoid: "37113",
		name: "Macon",
		state: "North Carolina",
		countyState: "Macon County, North Carolina",
		centroidLng: -83.4233000,
		centroidLat: 35.1621000
	},
	{
		geoid: "54063",
		name: "Monroe",
		state: "West Virginia",
		countyState: "Monroe County, West Virginia",
		centroidLng: -80.5397000,
		centroidLat: 37.5520000
	},
	{
		geoid: "18155",
		name: "Switzerland",
		state: "Indiana",
		countyState: "Switzerland County, Indiana",
		centroidLng: -84.9941000,
		centroidLat: 38.8086000
	},
	{
		geoid: "21105",
		name: "Hickman",
		state: "Kentucky",
		countyState: "Hickman County, Kentucky",
		centroidLng: -89.0073000,
		centroidLat: 36.6499000
	},
	{
		geoid: "01061",
		name: "Geneva",
		state: "Alabama",
		countyState: "Geneva County, Alabama",
		centroidLng: -85.8394000,
		centroidLat: 31.0964000
	},
	{
		geoid: "17099",
		name: "LaSalle",
		state: "Illinois",
		countyState: "LaSalle County, Illinois",
		centroidLng: -88.8767000,
		centroidLat: 41.2786000
	},
	{
		geoid: "19055",
		name: "Delaware",
		state: "Iowa",
		countyState: "Delaware County, Iowa",
		centroidLng: -91.3696000,
		centroidLat: 42.4708000
	},
	{
		geoid: "20127",
		name: "Morris",
		state: "Kansas",
		countyState: "Morris County, Kansas",
		centroidLng: -96.6433000,
		centroidLat: 38.6959000
	},
	{
		geoid: "19051",
		name: "Davis",
		state: "Iowa",
		countyState: "Davis County, Iowa",
		centroidLng: -92.4086000,
		centroidLat: 40.7453000
	},
	{
		geoid: "16015",
		name: "Boise",
		state: "Idaho",
		countyState: "Boise County, Idaho",
		centroidLng: -115.6250000,
		centroidLat: 43.9688000
	},
	{
		geoid: "55081",
		name: "Monroe",
		state: "Wisconsin",
		countyState: "Monroe County, Wisconsin",
		centroidLng: -90.6450000,
		centroidLat: 43.9432000
	},
	{
		geoid: "37143",
		name: "Perquimans",
		state: "North Carolina",
		countyState: "Perquimans County, North Carolina",
		centroidLng: -76.3740000,
		centroidLat: 36.1986000
	},
	{
		geoid: "12047",
		name: "Hamilton",
		state: "Florida",
		countyState: "Hamilton County, Florida",
		centroidLng: -82.9775000,
		centroidLat: 30.4759000
	},
	{
		geoid: "02170",
		name: "Matanuska-Susitna",
		state: "Alaska",
		countyState: "Matanuska-Susitna Borough, Alaska",
		centroidLng: -149.7130000,
		centroidLat: 62.2812000
	},
	{
		geoid: "18035",
		name: "Delaware",
		state: "Indiana",
		countyState: "Delaware County, Indiana",
		centroidLng: -85.3967000,
		centroidLat: 40.2279000
	},
	{
		geoid: "12075",
		name: "Levy",
		state: "Florida",
		countyState: "Levy County, Florida",
		centroidLng: -82.8215000,
		centroidLat: 29.2529000
	},
	{
		geoid: "72063",
		name: "Gurabo",
		state: "Puerto Rico",
		countyState: "Gurabo County, Puerto Rico",
		centroidLng: -65.9746000,
		centroidLat: 18.2591000
	},
	{
		geoid: "72065",
		name: "Hatillo",
		state: "Puerto Rico",
		countyState: "Hatillo County, Puerto Rico",
		centroidLng: -66.8016000,
		centroidLat: 18.4322000
	},
	{
		geoid: "28045",
		name: "Hancock",
		state: "Mississippi",
		countyState: "Hancock County, Mississippi",
		centroidLng: -89.4984000,
		centroidLat: 30.4045000
	},
	{
		geoid: "13315",
		name: "Wilcox",
		state: "Georgia",
		countyState: "Wilcox County, Georgia",
		centroidLng: -83.3945000,
		centroidLat: 31.9889000
	},
	{
		geoid: "22081",
		name: "Red River",
		state: "Louisiana",
		countyState: "Red River Parish, Louisiana",
		centroidLng: -93.3679000,
		centroidLat: 32.0624000
	},
	{
		geoid: "47093",
		name: "Knox",
		state: "Tennessee",
		countyState: "Knox County, Tennessee",
		centroidLng: -83.9622000,
		centroidLat: 35.9901000
	},
	{
		geoid: "23017",
		name: "Oxford",
		state: "Maine",
		countyState: "Oxford County, Maine",
		centroidLng: -70.6614000,
		centroidLat: 44.5663000
	},
	{
		geoid: "29095",
		name: "Jackson",
		state: "Missouri",
		countyState: "Jackson County, Missouri",
		centroidLng: -94.3566000,
		centroidLat: 39.0351000
	},
	{
		geoid: "13281",
		name: "Towns",
		state: "Georgia",
		countyState: "Towns County, Georgia",
		centroidLng: -83.7465000,
		centroidLat: 34.8915000
	},
	{
		geoid: "28129",
		name: "Smith",
		state: "Mississippi",
		countyState: "Smith County, Mississippi",
		centroidLng: -89.5239000,
		centroidLat: 32.0022000
	},
	{
		geoid: "16075",
		name: "Payette",
		state: "Idaho",
		countyState: "Payette County, Idaho",
		centroidLng: -116.7180000,
		centroidLat: 43.9727000
	},
	{
		geoid: "16079",
		name: "Shoshone",
		state: "Idaho",
		countyState: "Shoshone County, Idaho",
		centroidLng: -115.6440000,
		centroidLat: 47.5009000
	},
	{
		geoid: "37095",
		name: "Hyde",
		state: "North Carolina",
		countyState: "Hyde County, North Carolina",
		centroidLng: -76.1871000,
		centroidLat: 35.3556000
	},
	{
		geoid: "19067",
		name: "Floyd",
		state: "Iowa",
		countyState: "Floyd County, Iowa",
		centroidLng: -92.7896000,
		centroidLat: 43.0600000
	},
	{
		geoid: "21145",
		name: "McCracken",
		state: "Kentucky",
		countyState: "McCracken County, Kentucky",
		centroidLng: -88.7126000,
		centroidLat: 37.0855000
	},
	{
		geoid: "21225",
		name: "Union",
		state: "Kentucky",
		countyState: "Union County, Kentucky",
		centroidLng: -87.9475000,
		centroidLat: 37.6858000
	},
	{
		geoid: "46125",
		name: "Turner",
		state: "South Dakota",
		countyState: "Turner County, South Dakota",
		centroidLng: -97.1627000,
		centroidLat: 43.2916000
	},
	{
		geoid: "47029",
		name: "Cocke",
		state: "Tennessee",
		countyState: "Cocke County, Tennessee",
		centroidLng: -83.1065000,
		centroidLat: 35.9477000
	},
	{
		geoid: "47067",
		name: "Hancock",
		state: "Tennessee",
		countyState: "Hancock County, Tennessee",
		centroidLng: -83.1513000,
		centroidLat: 36.4932000
	},
	{
		geoid: "47071",
		name: "Hardin",
		state: "Tennessee",
		countyState: "Hardin County, Tennessee",
		centroidLng: -88.1810000,
		centroidLat: 35.2094000
	},
	{
		geoid: "45067",
		name: "Marion",
		state: "South Carolina",
		countyState: "Marion County, South Carolina",
		centroidLng: -79.3492000,
		centroidLat: 33.9974000
	},
	{
		geoid: "72057",
		name: "Guayama",
		state: "Puerto Rico",
		countyState: "Guayama County, Puerto Rico",
		centroidLng: -66.1393000,
		centroidLat: 17.9923000
	},
	{
		geoid: "22117",
		name: "Washington",
		state: "Louisiana",
		countyState: "Washington Parish, Louisiana",
		centroidLng: -90.0371000,
		centroidLat: 30.8349000
	},
	{
		geoid: "29207",
		name: "Stoddard",
		state: "Missouri",
		countyState: "Stoddard County, Missouri",
		centroidLng: -89.9723000,
		centroidLat: 36.8774000
	},
	{
		geoid: "13093",
		name: "Dooly",
		state: "Georgia",
		countyState: "Dooly County, Georgia",
		centroidLng: -83.8198000,
		centroidLat: 32.1601000
	},
	{
		geoid: "27145",
		name: "Stearns",
		state: "Minnesota",
		countyState: "Stearns County, Minnesota",
		centroidLng: -94.5936000,
		centroidLat: 45.5288000
	},
	{
		geoid: "31155",
		name: "Saunders",
		state: "Nebraska",
		countyState: "Saunders County, Nebraska",
		centroidLng: -96.6100000,
		centroidLat: 41.2342000
	},
	{
		geoid: "27141",
		name: "Sherburne",
		state: "Minnesota",
		countyState: "Sherburne County, Minnesota",
		centroidLng: -93.8301000,
		centroidLat: 45.4030000
	},
	{
		geoid: "46035",
		name: "Davison",
		state: "South Dakota",
		countyState: "Davison County, South Dakota",
		centroidLng: -98.1454000,
		centroidLat: 43.6747000
	},
	{
		geoid: "47025",
		name: "Claiborne",
		state: "Tennessee",
		countyState: "Claiborne County, Tennessee",
		centroidLng: -83.6785000,
		centroidLat: 36.4664000
	},
	{
		geoid: "48337",
		name: "Montague",
		state: "Texas",
		countyState: "Montague County, Texas",
		centroidLng: -97.7316000,
		centroidLat: 33.7126000
	},
	{
		geoid: "48049",
		name: "Brown",
		state: "Texas",
		countyState: "Brown County, Texas",
		centroidLng: -98.9360000,
		centroidLat: 31.7639000
	},
	{
		geoid: "55101",
		name: "Racine",
		state: "Wisconsin",
		countyState: "Racine County, Wisconsin",
		centroidLng: -87.6760000,
		centroidLat: 42.7272000
	},
	{
		geoid: "55115",
		name: "Shawano",
		state: "Wisconsin",
		countyState: "Shawano County, Wisconsin",
		centroidLng: -88.7335000,
		centroidLat: 44.8072000
	},
	{
		geoid: "19009",
		name: "Audubon",
		state: "Iowa",
		countyState: "Audubon County, Iowa",
		centroidLng: -94.8970000,
		centroidLat: 41.6838000
	},
	{
		geoid: "48207",
		name: "Haskell",
		state: "Texas",
		countyState: "Haskell County, Texas",
		centroidLng: -99.7313000,
		centroidLat: 33.1780000
	},
	{
		geoid: "40083",
		name: "Logan",
		state: "Oklahoma",
		countyState: "Logan County, Oklahoma",
		centroidLng: -97.4082000,
		centroidLat: 35.9446000
	},
	{
		geoid: "36065",
		name: "Oneida",
		state: "New York",
		countyState: "Oneida County, New York",
		centroidLng: -75.4780000,
		centroidLat: 43.2393000
	},
	{
		geoid: "08065",
		name: "Lake",
		state: "Colorado",
		countyState: "Lake County, Colorado",
		centroidLng: -106.3610000,
		centroidLat: 39.2185000
	},
	{
		geoid: "40121",
		name: "Pittsburg",
		state: "Oklahoma",
		countyState: "Pittsburg County, Oklahoma",
		centroidLng: -95.7207000,
		centroidLat: 34.9473000
	},
	{
		geoid: "54017",
		name: "Doddridge",
		state: "West Virginia",
		countyState: "Doddridge County, West Virginia",
		centroidLng: -80.7197000,
		centroidLat: 39.2708000
	},
	{
		geoid: "54105",
		name: "Wirt",
		state: "West Virginia",
		countyState: "Wirt County, West Virginia",
		centroidLng: -81.3719000,
		centroidLat: 39.0406000
	},
	{
		geoid: "33003",
		name: "Carroll",
		state: "New Hampshire",
		countyState: "Carroll County, New Hampshire",
		centroidLng: -71.2576000,
		centroidLat: 43.8839000
	},
	{
		geoid: "54095",
		name: "Tyler",
		state: "West Virginia",
		countyState: "Tyler County, West Virginia",
		centroidLng: -80.8701000,
		centroidLat: 39.4506000
	},
	{
		geoid: "69085",
		name: "Northern Islands",
		state: "Commonwealth of the Northern Mariana Islands",
		countyState: "Northern Islands County, Commonwealth of the Northern Mariana Islands",
		centroidLng: 145.6670000,
		centroidLat: 18.7693000
	},
	{
		geoid: "27079",
		name: "Le Sueur",
		state: "Minnesota",
		countyState: "Le Sueur County, Minnesota",
		centroidLng: -93.7748000,
		centroidLat: 44.3699000
	},
	{
		geoid: "53017",
		name: "Douglas",
		state: "Washington",
		countyState: "Douglas County, Washington",
		centroidLng: -119.6340000,
		centroidLat: 47.6857000
	},
	{
		geoid: "36009",
		name: "Cattaraugus",
		state: "New York",
		countyState: "Cattaraugus County, New York",
		centroidLng: -78.6847000,
		centroidLat: 42.2704000
	},
	{
		geoid: "55027",
		name: "Dodge",
		state: "Wisconsin",
		countyState: "Dodge County, Wisconsin",
		centroidLng: -88.7050000,
		centroidLat: 43.4141000
	},
	{
		geoid: "19105",
		name: "Jones",
		state: "Iowa",
		countyState: "Jones County, Iowa",
		centroidLng: -91.1319000,
		centroidLat: 42.1213000
	},
	{
		geoid: "20007",
		name: "Barber",
		state: "Kansas",
		countyState: "Barber County, Kansas",
		centroidLng: -98.6799000,
		centroidLat: 37.2345000
	},
	{
		geoid: "29169",
		name: "Pulaski",
		state: "Missouri",
		countyState: "Pulaski County, Missouri",
		centroidLng: -92.2174000,
		centroidLat: 37.8119000
	},
	{
		geoid: "01045",
		name: "Dale",
		state: "Alabama",
		countyState: "Dale County, Alabama",
		centroidLng: -85.6035000,
		centroidLat: 31.4079000
	},
	{
		geoid: "20109",
		name: "Logan",
		state: "Kansas",
		countyState: "Logan County, Kansas",
		centroidLng: -101.1480000,
		centroidLat: 38.9172000
	},
	{
		geoid: "06039",
		name: "Madera",
		state: "California",
		countyState: "Madera County, California",
		centroidLng: -119.7840000,
		centroidLat: 37.2705000
	},
	{
		geoid: "29075",
		name: "Gentry",
		state: "Missouri",
		countyState: "Gentry County, Missouri",
		centroidLng: -94.4104000,
		centroidLat: 40.2109000
	},
	{
		geoid: "31009",
		name: "Blaine",
		state: "Nebraska",
		countyState: "Blaine County, Nebraska",
		centroidLng: -99.9772000,
		centroidLat: 41.9123000
	},
	{
		geoid: "49009",
		name: "Daggett",
		state: "Utah",
		countyState: "Daggett County, Utah",
		centroidLng: -109.5250000,
		centroidLat: 40.8322000
	},
	{
		geoid: "56031",
		name: "Platte",
		state: "Wyoming",
		countyState: "Platte County, Wyoming",
		centroidLng: -104.9700000,
		centroidLat: 42.1315000
	},
	{
		geoid: "37071",
		name: "Gaston",
		state: "North Carolina",
		countyState: "Gaston County, North Carolina",
		centroidLng: -81.1893000,
		centroidLat: 35.2841000
	},
	{
		geoid: "35019",
		name: "Guadalupe",
		state: "New Mexico",
		countyState: "Guadalupe County, New Mexico",
		centroidLng: -104.7190000,
		centroidLat: 34.7818000
	},
	{
		geoid: "30037",
		name: "Golden Valley",
		state: "Montana",
		countyState: "Golden Valley County, Montana",
		centroidLng: -109.2160000,
		centroidLat: 46.3981000
	},
	{
		geoid: "37101",
		name: "Johnston",
		state: "North Carolina",
		countyState: "Johnston County, North Carolina",
		centroidLng: -78.3868000,
		centroidLat: 35.5364000
	},
	{
		geoid: "06085",
		name: "Santa Clara",
		state: "California",
		countyState: "Santa Clara County, California",
		centroidLng: -121.7050000,
		centroidLat: 37.1888000
	},
	{
		geoid: "45019",
		name: "Charleston",
		state: "South Carolina",
		countyState: "Charleston County, South Carolina",
		centroidLng: -79.8577000,
		centroidLat: 32.8489000
	},
	{
		geoid: "53005",
		name: "Benton",
		state: "Washington",
		countyState: "Benton County, Washington",
		centroidLng: -119.4090000,
		centroidLat: 46.2820000
	},
	{
		geoid: "29001",
		name: "Adair",
		state: "Missouri",
		countyState: "Adair County, Missouri",
		centroidLng: -92.6007000,
		centroidLat: 40.1918000
	},
	{
		geoid: "39043",
		name: "Erie",
		state: "Ohio",
		countyState: "Erie County, Ohio",
		centroidLng: -82.6476000,
		centroidLat: 41.4918000
	},
	{
		geoid: "17183",
		name: "Vermilion",
		state: "Illinois",
		countyState: "Vermilion County, Illinois",
		centroidLng: -87.7342000,
		centroidLat: 40.1798000
	},
	{
		geoid: "29189",
		name: "St. Louis",
		state: "Missouri",
		countyState: "St. Louis County, Missouri",
		centroidLng: -90.4271000,
		centroidLat: 38.6397000
	},
	{
		geoid: "17007",
		name: "Boone",
		state: "Illinois",
		countyState: "Boone County, Illinois",
		centroidLng: -88.8231000,
		centroidLat: 42.3237000
	},
	{
		geoid: "06103",
		name: "Tehama",
		state: "California",
		countyState: "Tehama County, California",
		centroidLng: -122.2040000,
		centroidLat: 40.1253000
	},
	{
		geoid: "55025",
		name: "Dane",
		state: "Wisconsin",
		countyState: "Dane County, Wisconsin",
		centroidLng: -89.4237000,
		centroidLat: 43.0696000
	},
	{
		geoid: "48151",
		name: "Fisher",
		state: "Texas",
		countyState: "Fisher County, Texas",
		centroidLng: -100.4020000,
		centroidLat: 32.7431000
	},
	{
		geoid: "22085",
		name: "Sabine",
		state: "Louisiana",
		countyState: "Sabine Parish, Louisiana",
		centroidLng: -93.5593000,
		centroidLat: 31.5057000
	},
	{
		geoid: "17137",
		name: "Morgan",
		state: "Illinois",
		countyState: "Morgan County, Illinois",
		centroidLng: -90.2615000,
		centroidLat: 39.6983000
	},
	{
		geoid: "13235",
		name: "Pulaski",
		state: "Georgia",
		countyState: "Pulaski County, Georgia",
		centroidLng: -83.4535000,
		centroidLat: 32.2526000
	},
	{
		geoid: "37015",
		name: "Bertie",
		state: "North Carolina",
		countyState: "Bertie County, North Carolina",
		centroidLng: -76.9671000,
		centroidLat: 36.0292000
	},
	{
		geoid: "37167",
		name: "Stanly",
		state: "North Carolina",
		countyState: "Stanly County, North Carolina",
		centroidLng: -80.2787000,
		centroidLat: 35.3236000
	},
	{
		geoid: "47143",
		name: "Rhea",
		state: "Tennessee",
		countyState: "Rhea County, Tennessee",
		centroidLng: -84.9289000,
		centroidLat: 35.6172000
	},
	{
		geoid: "37079",
		name: "Greene",
		state: "North Carolina",
		countyState: "Greene County, North Carolina",
		centroidLng: -77.6505000,
		centroidLat: 35.4998000
	},
	{
		geoid: "21131",
		name: "Leslie",
		state: "Kentucky",
		countyState: "Leslie County, Kentucky",
		centroidLng: -83.3587000,
		centroidLat: 37.1019000
	},
	{
		geoid: "24039",
		name: "Somerset",
		state: "Maryland",
		countyState: "Somerset County, Maryland",
		centroidLng: -75.8880000,
		centroidLat: 38.0928000
	},
	{
		geoid: "17123",
		name: "Marshall",
		state: "Illinois",
		countyState: "Marshall County, Illinois",
		centroidLng: -89.3429000,
		centroidLat: 41.0350000
	},
	{
		geoid: "21143",
		name: "Lyon",
		state: "Kentucky",
		countyState: "Lyon County, Kentucky",
		centroidLng: -88.0587000,
		centroidLat: 37.0234000
	},
	{
		geoid: "48095",
		name: "Concho",
		state: "Texas",
		countyState: "Concho County, Texas",
		centroidLng: -99.8584000,
		centroidLat: 31.3336000
	},
	{
		geoid: "48081",
		name: "Coke",
		state: "Texas",
		countyState: "Coke County, Texas",
		centroidLng: -100.5300000,
		centroidLat: 31.8896000
	},
	{
		geoid: "47133",
		name: "Overton",
		state: "Tennessee",
		countyState: "Overton County, Tennessee",
		centroidLng: -85.2900000,
		centroidLat: 36.3425000
	},
	{
		geoid: "26063",
		name: "Huron",
		state: "Michigan",
		countyState: "Huron County, Michigan",
		centroidLng: -82.9295000,
		centroidLat: 43.9194000
	},
	{
		geoid: "26069",
		name: "Iosco",
		state: "Michigan",
		countyState: "Iosco County, Michigan",
		centroidLng: -83.0683000,
		centroidLat: 44.3368000
	},
	{
		geoid: "13069",
		name: "Coffee",
		state: "Georgia",
		countyState: "Coffee County, Georgia",
		centroidLng: -82.8737000,
		centroidLat: 31.5900000
	},
	{
		geoid: "13299",
		name: "Ware",
		state: "Georgia",
		countyState: "Ware County, Georgia",
		centroidLng: -82.4164000,
		centroidLat: 31.0190000
	},
	{
		geoid: "38037",
		name: "Grant",
		state: "North Dakota",
		countyState: "Grant County, North Dakota",
		centroidLng: -101.5600000,
		centroidLat: 46.3624000
	},
	{
		geoid: "22051",
		name: "Jefferson",
		state: "Louisiana",
		countyState: "Jefferson Parish, Louisiana",
		centroidLng: -90.0900000,
		centroidLat: 29.6421000
	},
	{
		geoid: "22103",
		name: "St. Tammany",
		state: "Louisiana",
		countyState: "St. Tammany Parish, Louisiana",
		centroidLng: -89.8763000,
		centroidLat: 30.4254000
	},
	{
		geoid: "19083",
		name: "Hardin",
		state: "Iowa",
		countyState: "Hardin County, Iowa",
		centroidLng: -93.2506000,
		centroidLat: 42.3832000
	},
	{
		geoid: "22023",
		name: "Cameron",
		state: "Louisiana",
		countyState: "Cameron Parish, Louisiana",
		centroidLng: -93.2714000,
		centroidLat: 29.7899000
	},
	{
		geoid: "39027",
		name: "Clinton",
		state: "Ohio",
		countyState: "Clinton County, Ohio",
		centroidLng: -83.7915000,
		centroidLat: 39.3960000
	},
	{
		geoid: "27137",
		name: "St. Louis",
		state: "Minnesota",
		countyState: "St. Louis County, Minnesota",
		centroidLng: -92.4428000,
		centroidLat: 47.6404000
	},
	{
		geoid: "39093",
		name: "Lorain",
		state: "Ohio",
		countyState: "Lorain County, Ohio",
		centroidLng: -82.1126000,
		centroidLat: 41.4707000
	},
	{
		geoid: "72143",
		name: "Vega Alta",
		state: "Puerto Rico",
		countyState: "Vega Alta County, Puerto Rico",
		centroidLng: -66.3389000,
		centroidLat: 18.4358000
	},
	{
		geoid: "19015",
		name: "Boone",
		state: "Iowa",
		countyState: "Boone County, Iowa",
		centroidLng: -93.9316000,
		centroidLat: 42.0366000
	},
	{
		geoid: "48373",
		name: "Polk",
		state: "Texas",
		countyState: "Polk County, Texas",
		centroidLng: -94.8691000,
		centroidLat: 30.8178000
	},
	{
		geoid: "31051",
		name: "Dixon",
		state: "Nebraska",
		countyState: "Dixon County, Nebraska",
		centroidLng: -96.8247000,
		centroidLat: 42.5127000
	},
	{
		geoid: "24025",
		name: "Harford",
		state: "Maryland",
		countyState: "Harford County, Maryland",
		centroidLng: -76.3026000,
		centroidLat: 39.4878000
	},
	{
		geoid: "45037",
		name: "Edgefield",
		state: "South Carolina",
		countyState: "Edgefield County, South Carolina",
		centroidLng: -81.9174000,
		centroidLat: 33.7545000
	},
	{
		geoid: "51001",
		name: "Accomack",
		state: "Virginia",
		countyState: "Accomack County, Virginia",
		centroidLng: -75.7014000,
		centroidLat: 37.7382000
	},
	{
		geoid: "55015",
		name: "Calumet",
		state: "Wisconsin",
		countyState: "Calumet County, Wisconsin",
		centroidLng: -88.2232000,
		centroidLat: 44.0678000
	},
	{
		geoid: "21121",
		name: "Knox",
		state: "Kentucky",
		countyState: "Knox County, Kentucky",
		centroidLng: -83.8304000,
		centroidLat: 36.8712000
	},
	{
		geoid: "26067",
		name: "Ionia",
		state: "Michigan",
		countyState: "Ionia County, Michigan",
		centroidLng: -85.0747000,
		centroidLat: 42.9449000
	},
	{
		geoid: "37129",
		name: "New Hanover",
		state: "North Carolina",
		countyState: "New Hanover County, North Carolina",
		centroidLng: -77.8526000,
		centroidLat: 34.0881000
	},
	{
		geoid: "47131",
		name: "Obion",
		state: "Tennessee",
		countyState: "Obion County, Tennessee",
		centroidLng: -89.1499000,
		centroidLat: 36.3544000
	},
	{
		geoid: "21035",
		name: "Calloway",
		state: "Kentucky",
		countyState: "Calloway County, Kentucky",
		centroidLng: -88.2608000,
		centroidLat: 36.6239000
	},
	{
		geoid: "51003",
		name: "Albemarle",
		state: "Virginia",
		countyState: "Albemarle County, Virginia",
		centroidLng: -78.5241000,
		centroidLat: 38.0003000
	},
	{
		geoid: "47147",
		name: "Robertson",
		state: "Tennessee",
		countyState: "Robertson County, Tennessee",
		centroidLng: -86.8571000,
		centroidLat: 36.5011000
	},
	{
		geoid: "51043",
		name: "Clarke",
		state: "Virginia",
		countyState: "Clarke County, Virginia",
		centroidLng: -77.9900000,
		centroidLat: 39.1220000
	},
	{
		geoid: "53023",
		name: "Garfield",
		state: "Washington",
		countyState: "Garfield County, Washington",
		centroidLng: -117.5460000,
		centroidLat: 46.3505000
	},
	{
		geoid: "21109",
		name: "Jackson",
		state: "Kentucky",
		countyState: "Jackson County, Kentucky",
		centroidLng: -83.9936000,
		centroidLat: 37.4160000
	},
	{
		geoid: "27031",
		name: "Cook",
		state: "Minnesota",
		countyState: "Cook County, Minnesota",
		centroidLng: -90.2579000,
		centroidLat: 47.7450000
	},
	{
		geoid: "45073",
		name: "Oconee",
		state: "South Carolina",
		countyState: "Oconee County, South Carolina",
		centroidLng: -83.0883000,
		centroidLat: 34.7641000
	},
	{
		geoid: "22033",
		name: "East Baton Rouge",
		state: "Louisiana",
		countyState: "East Baton Rouge Parish, Louisiana",
		centroidLng: -91.0804000,
		centroidLat: 30.5159000
	},
	{
		geoid: "28021",
		name: "Claiborne",
		state: "Mississippi",
		countyState: "Claiborne County, Mississippi",
		centroidLng: -90.9813000,
		centroidLat: 32.0055000
	},
	{
		geoid: "24037",
		name: "St. Mary's",
		state: "Maryland",
		countyState: "St. Mary's County, Maryland",
		centroidLng: -76.5379000,
		centroidLat: 38.2011000
	},
	{
		geoid: "26081",
		name: "Kent",
		state: "Michigan",
		countyState: "Kent County, Michigan",
		centroidLng: -85.5501000,
		centroidLat: 43.0313000
	},
	{
		geoid: "37147",
		name: "Pitt",
		state: "North Carolina",
		countyState: "Pitt County, North Carolina",
		centroidLng: -77.3927000,
		centroidLat: 35.5802000
	},
	{
		geoid: "30009",
		name: "Carbon",
		state: "Montana",
		countyState: "Carbon County, Montana",
		centroidLng: -108.9480000,
		centroidLat: 45.3190000
	},
	{
		geoid: "31167",
		name: "Stanton",
		state: "Nebraska",
		countyState: "Stanton County, Nebraska",
		centroidLng: -97.1939000,
		centroidLat: 41.9170000
	},
	{
		geoid: "13001",
		name: "Appling",
		state: "Georgia",
		countyState: "Appling County, Georgia",
		centroidLng: -82.2996000,
		centroidLat: 31.7177000
	},
	{
		geoid: "19099",
		name: "Jasper",
		state: "Iowa",
		countyState: "Jasper County, Iowa",
		centroidLng: -93.0526000,
		centroidLat: 41.6855000
	},
	{
		geoid: "28153",
		name: "Wayne",
		state: "Mississippi",
		countyState: "Wayne County, Mississippi",
		centroidLng: -88.6965000,
		centroidLat: 31.6636000
	},
	{
		geoid: "48101",
		name: "Cottle",
		state: "Texas",
		countyState: "Cottle County, Texas",
		centroidLng: -100.2580000,
		centroidLat: 34.0749000
	},
	{
		geoid: "51081",
		name: "Greensville",
		state: "Virginia",
		countyState: "Greensville County, Virginia",
		centroidLng: -77.5320000,
		centroidLat: 36.7215000
	},
	{
		geoid: "12105",
		name: "Polk",
		state: "Florida",
		countyState: "Polk County, Florida",
		centroidLng: -81.6186000,
		centroidLat: 28.0026000
	},
	{
		geoid: "48503",
		name: "Young",
		state: "Texas",
		countyState: "Young County, Texas",
		centroidLng: -98.6873000,
		centroidLat: 33.1749000
	},
	{
		geoid: "01123",
		name: "Tallapoosa",
		state: "Alabama",
		countyState: "Tallapoosa County, Alabama",
		centroidLng: -85.8034000,
		centroidLat: 32.8002000
	},
	{
		geoid: "33015",
		name: "Rockingham",
		state: "New Hampshire",
		countyState: "Rockingham County, New Hampshire",
		centroidLng: -71.0132000,
		centroidLat: 43.0063000
	},
	{
		geoid: "08105",
		name: "Rio Grande",
		state: "Colorado",
		countyState: "Rio Grande County, Colorado",
		centroidLng: -106.3750000,
		centroidLat: 37.6173000
	},
	{
		geoid: "19149",
		name: "Plymouth",
		state: "Iowa",
		countyState: "Plymouth County, Iowa",
		centroidLng: -96.2490000,
		centroidLat: 42.7354000
	},
	{
		geoid: "36123",
		name: "Yates",
		state: "New York",
		countyState: "Yates County, New York",
		centroidLng: -77.1285000,
		centroidLat: 42.6118000
	},
	{
		geoid: "53033",
		name: "King",
		state: "Washington",
		countyState: "King County, Washington",
		centroidLng: -121.8040000,
		centroidLat: 47.4325000
	},
	{
		geoid: "41017",
		name: "Deschutes",
		state: "Oregon",
		countyState: "Deschutes County, Oregon",
		centroidLng: -120.9490000,
		centroidLat: 44.0023000
	},
	{
		geoid: "19155",
		name: "Pottawattamie",
		state: "Iowa",
		countyState: "Pottawattamie County, Iowa",
		centroidLng: -95.5871000,
		centroidLat: 41.3331000
	},
	{
		geoid: "29229",
		name: "Wright",
		state: "Missouri",
		countyState: "Wright County, Missouri",
		centroidLng: -92.4697000,
		centroidLat: 37.2706000
	},
	{
		geoid: "30101",
		name: "Toole",
		state: "Montana",
		countyState: "Toole County, Montana",
		centroidLng: -111.7320000,
		centroidLat: 48.6091000
	},
	{
		geoid: "36107",
		name: "Tioga",
		state: "New York",
		countyState: "Tioga County, New York",
		centroidLng: -76.3221000,
		centroidLat: 42.2043000
	},
	{
		geoid: "48287",
		name: "Lee",
		state: "Texas",
		countyState: "Lee County, Texas",
		centroidLng: -96.9875000,
		centroidLat: 30.2946000
	},
	{
		geoid: "13121",
		name: "Fulton",
		state: "Georgia",
		countyState: "Fulton County, Georgia",
		centroidLng: -84.4742000,
		centroidLat: 33.8444000
	},
	{
		geoid: "48073",
		name: "Cherokee",
		state: "Texas",
		countyState: "Cherokee County, Texas",
		centroidLng: -95.1640000,
		centroidLat: 31.7819000
	},
	{
		geoid: "29007",
		name: "Audrain",
		state: "Missouri",
		countyState: "Audrain County, Missouri",
		centroidLng: -91.8618000,
		centroidLat: 39.2034000
	},
	{
		geoid: "45029",
		name: "Colleton",
		state: "South Carolina",
		countyState: "Colleton County, South Carolina",
		centroidLng: -80.6529000,
		centroidLat: 32.7894000
	},
	{
		geoid: "55055",
		name: "Jefferson",
		state: "Wisconsin",
		countyState: "Jefferson County, Wisconsin",
		centroidLng: -88.7747000,
		centroidLat: 43.0203000
	},
	{
		geoid: "05055",
		name: "Greene",
		state: "Arkansas",
		countyState: "Greene County, Arkansas",
		centroidLng: -90.5252000,
		centroidLat: 36.1163000
	},
	{
		geoid: "48191",
		name: "Hall",
		state: "Texas",
		countyState: "Hall County, Texas",
		centroidLng: -100.6810000,
		centroidLat: 34.5306000
	},
	{
		geoid: "55013",
		name: "Burnett",
		state: "Wisconsin",
		countyState: "Burnett County, Wisconsin",
		centroidLng: -92.4591000,
		centroidLat: 45.8987000
	},
	{
		geoid: "35057",
		name: "Torrance",
		state: "New Mexico",
		countyState: "Torrance County, New Mexico",
		centroidLng: -105.8810000,
		centroidLat: 34.6509000
	},
	{
		geoid: "53049",
		name: "Pacific",
		state: "Washington",
		countyState: "Pacific County, Washington",
		centroidLng: -123.7660000,
		centroidLat: 46.5146000
	},
	{
		geoid: "37141",
		name: "Pender",
		state: "North Carolina",
		countyState: "Pender County, North Carolina",
		centroidLng: -77.8786000,
		centroidLat: 34.4929000
	},
	{
		geoid: "48221",
		name: "Hood",
		state: "Texas",
		countyState: "Hood County, Texas",
		centroidLng: -97.8419000,
		centroidLat: 32.3961000
	},
	{
		geoid: "46117",
		name: "Stanley",
		state: "South Dakota",
		countyState: "Stanley County, South Dakota",
		centroidLng: -100.5300000,
		centroidLat: 44.4758000
	},
	{
		geoid: "32031",
		name: "Washoe",
		state: "Nevada",
		countyState: "Washoe County, Nevada",
		centroidLng: -119.5890000,
		centroidLat: 40.5815000
	},
	{
		geoid: "21007",
		name: "Ballard",
		state: "Kentucky",
		countyState: "Ballard County, Kentucky",
		centroidLng: -88.9993000,
		centroidLat: 37.0728000
	},
	{
		geoid: "48009",
		name: "Archer",
		state: "Texas",
		countyState: "Archer County, Texas",
		centroidLng: -98.6873000,
		centroidLat: 33.6157000
	},
	{
		geoid: "48023",
		name: "Baylor",
		state: "Texas",
		countyState: "Baylor County, Texas",
		centroidLng: -99.2143000,
		centroidLat: 33.6159000
	},
	{
		geoid: "48405",
		name: "San Augustine",
		state: "Texas",
		countyState: "San Augustine County, Texas",
		centroidLng: -94.1915000,
		centroidLat: 31.3764000
	},
	{
		geoid: "32029",
		name: "Storey",
		state: "Nevada",
		countyState: "Storey County, Nevada",
		centroidLng: -119.4970000,
		centroidLat: 39.4399000
	},
	{
		geoid: "13249",
		name: "Schley",
		state: "Georgia",
		countyState: "Schley County, Georgia",
		centroidLng: -84.3059000,
		centroidLat: 32.2933000
	},
	{
		geoid: "09001",
		name: "Fairfield",
		state: "Connecticut",
		countyState: "Fairfield County, Connecticut",
		centroidLng: -73.3637000,
		centroidLat: 41.3088000
	},
	{
		geoid: "48313",
		name: "Madison",
		state: "Texas",
		countyState: "Madison County, Texas",
		centroidLng: -95.9265000,
		centroidLat: 30.9586000
	},
	{
		geoid: "06077",
		name: "San Joaquin",
		state: "California",
		countyState: "San Joaquin County, California",
		centroidLng: -121.2510000,
		centroidLat: 37.8910000
	},
	{
		geoid: "28159",
		name: "Winston",
		state: "Mississippi",
		countyState: "Winston County, Mississippi",
		centroidLng: -89.0660000,
		centroidLat: 33.1028000
	},
	{
		geoid: "21103",
		name: "Henry",
		state: "Kentucky",
		countyState: "Henry County, Kentucky",
		centroidLng: -85.1065000,
		centroidLat: 38.4662000
	},
	{
		geoid: "21167",
		name: "Mercer",
		state: "Kentucky",
		countyState: "Mercer County, Kentucky",
		centroidLng: -84.8599000,
		centroidLat: 37.8245000
	},
	{
		geoid: "31183",
		name: "Wheeler",
		state: "Nebraska",
		countyState: "Wheeler County, Nebraska",
		centroidLng: -98.5283000,
		centroidLat: 41.9146000
	},
	{
		geoid: "21135",
		name: "Lewis",
		state: "Kentucky",
		countyState: "Lewis County, Kentucky",
		centroidLng: -83.3344000,
		centroidLat: 38.5219000
	},
	{
		geoid: "27051",
		name: "Grant",
		state: "Minnesota",
		countyState: "Grant County, Minnesota",
		centroidLng: -96.0124000,
		centroidLat: 45.9340000
	},
	{
		geoid: "28053",
		name: "Humphreys",
		state: "Mississippi",
		countyState: "Humphreys County, Mississippi",
		centroidLng: -90.5318000,
		centroidLat: 33.1260000
	},
	{
		geoid: "01025",
		name: "Clarke",
		state: "Alabama",
		countyState: "Clarke County, Alabama",
		centroidLng: -87.8409000,
		centroidLat: 31.5884000
	},
	{
		geoid: "20017",
		name: "Chase",
		state: "Kansas",
		countyState: "Chase County, Kansas",
		centroidLng: -96.5975000,
		centroidLat: 38.3040000
	},
	{
		geoid: "21221",
		name: "Trigg",
		state: "Kentucky",
		countyState: "Trigg County, Kentucky",
		centroidLng: -87.9085000,
		centroidLat: 36.8176000
	},
	{
		geoid: "01069",
		name: "Houston",
		state: "Alabama",
		countyState: "Houston County, Alabama",
		centroidLng: -85.3559000,
		centroidLat: 31.1560000
	},
	{
		geoid: "46003",
		name: "Aurora",
		state: "South Dakota",
		countyState: "Aurora County, South Dakota",
		centroidLng: -98.5642000,
		centroidLat: 43.7181000
	},
	{
		geoid: "02013",
		name: "Aleutians East",
		state: "Alaska",
		countyState: "Aleutians East Borough, Alaska",
		centroidLng: -162.7280000,
		centroidLat: 54.8055000
	},
	{
		geoid: "31061",
		name: "Franklin",
		state: "Nebraska",
		countyState: "Franklin County, Nebraska",
		centroidLng: -98.9527000,
		centroidLat: 40.1763000
	},
	{
		geoid: "24029",
		name: "Kent",
		state: "Maryland",
		countyState: "Kent County, Maryland",
		centroidLng: -76.0554000,
		centroidLat: 39.1990000
	},
	{
		geoid: "20037",
		name: "Crawford",
		state: "Kansas",
		countyState: "Crawford County, Kansas",
		centroidLng: -94.8526000,
		centroidLat: 37.5066000
	},
	{
		geoid: "27023",
		name: "Chippewa",
		state: "Minnesota",
		countyState: "Chippewa County, Minnesota",
		centroidLng: -95.6417000,
		centroidLat: 44.9517000
	},
	{
		geoid: "46103",
		name: "Pennington",
		state: "South Dakota",
		countyState: "Pennington County, South Dakota",
		centroidLng: -103.0280000,
		centroidLat: 44.0980000
	},
	{
		geoid: "45049",
		name: "Hampton",
		state: "South Carolina",
		countyState: "Hampton County, South Carolina",
		centroidLng: -81.1269000,
		centroidLat: 32.7889000
	},
	{
		geoid: "16043",
		name: "Fremont",
		state: "Idaho",
		countyState: "Fremont County, Idaho",
		centroidLng: -111.6020000,
		centroidLat: 44.3191000
	},
	{
		geoid: "13029",
		name: "Bryan",
		state: "Georgia",
		countyState: "Bryan County, Georgia",
		centroidLng: -81.4600000,
		centroidLat: 31.9770000
	},
	{
		geoid: "51053",
		name: "Dinwiddie",
		state: "Virginia",
		countyState: "Dinwiddie County, Virginia",
		centroidLng: -77.6475000,
		centroidLat: 37.0724000
	},
	{
		geoid: "12111",
		name: "St. Lucie",
		state: "Florida",
		countyState: "St. Lucie County, Florida",
		centroidLng: -80.4090000,
		centroidLat: 27.3821000
	},
	{
		geoid: "36055",
		name: "Monroe",
		state: "New York",
		countyState: "Monroe County, New York",
		centroidLng: -77.6843000,
		centroidLat: 43.2860000
	},
	{
		geoid: "46083",
		name: "Lincoln",
		state: "South Dakota",
		countyState: "Lincoln County, South Dakota",
		centroidLng: -96.6807000,
		centroidLat: 43.2919000
	},
	{
		geoid: "55017",
		name: "Chippewa",
		state: "Wisconsin",
		countyState: "Chippewa County, Wisconsin",
		centroidLng: -91.2943000,
		centroidLat: 45.0741000
	},
	{
		geoid: "20207",
		name: "Woodson",
		state: "Kansas",
		countyState: "Woodson County, Kansas",
		centroidLng: -95.7403000,
		centroidLat: 37.8865000
	},
	{
		geoid: "21157",
		name: "Marshall",
		state: "Kentucky",
		countyState: "Marshall County, Kentucky",
		centroidLng: -88.2983000,
		centroidLat: 36.9052000
	},
	{
		geoid: "13065",
		name: "Clinch",
		state: "Georgia",
		countyState: "Clinch County, Georgia",
		centroidLng: -82.6950000,
		centroidLat: 30.8831000
	},
	{
		geoid: "13185",
		name: "Lowndes",
		state: "Georgia",
		countyState: "Lowndes County, Georgia",
		centroidLng: -83.2521000,
		centroidLat: 30.8280000
	},
	{
		geoid: "15005",
		name: "Kalawao",
		state: "Hawaii",
		countyState: "Kalawao County, Hawaii",
		centroidLng: -156.9430000,
		centroidLat: 21.2020000
	},
	{
		geoid: "15007",
		name: "Kauai",
		state: "Hawaii",
		countyState: "Kauai County, Hawaii",
		centroidLng: -159.5460000,
		centroidLat: 22.0540000
	},
	{
		geoid: "26099",
		name: "Macomb",
		state: "Michigan",
		countyState: "Macomb County, Michigan",
		centroidLng: -82.9044000,
		centroidLat: 42.6723000
	},
	{
		geoid: "13287",
		name: "Turner",
		state: "Georgia",
		countyState: "Turner County, Georgia",
		centroidLng: -83.6274000,
		centroidLat: 31.7098000
	},
	{
		geoid: "17079",
		name: "Jasper",
		state: "Illinois",
		countyState: "Jasper County, Illinois",
		centroidLng: -88.1539000,
		centroidLat: 39.0109000
	},
	{
		geoid: "48331",
		name: "Milam",
		state: "Texas",
		countyState: "Milam County, Texas",
		centroidLng: -96.9633000,
		centroidLat: 30.7839000
	},
	{
		geoid: "18147",
		name: "Spencer",
		state: "Indiana",
		countyState: "Spencer County, Indiana",
		centroidLng: -87.0170000,
		centroidLat: 37.9937000
	},
	{
		geoid: "21099",
		name: "Hart",
		state: "Kentucky",
		countyState: "Hart County, Kentucky",
		centroidLng: -85.9061000,
		centroidLat: 37.2992000
	},
	{
		geoid: "21137",
		name: "Lincoln",
		state: "Kentucky",
		countyState: "Lincoln County, Kentucky",
		centroidLng: -84.6534000,
		centroidLat: 37.4394000
	},
	{
		geoid: "30005",
		name: "Blaine",
		state: "Montana",
		countyState: "Blaine County, Montana",
		centroidLng: -108.8940000,
		centroidLat: 48.3602000
	},
	{
		geoid: "51710",
		name: "Norfolk",
		state: "Virginia",
		countyState: "Norfolk County, Virginia",
		centroidLng: -76.2397000,
		centroidLat: 36.9308000
	},
	{
		geoid: "48389",
		name: "Reeves",
		state: "Texas",
		countyState: "Reeves County, Texas",
		centroidLng: -103.5560000,
		centroidLat: 31.3833000
	},
	{
		geoid: "51155",
		name: "Pulaski",
		state: "Virginia",
		countyState: "Pulaski County, Virginia",
		centroidLng: -80.7229000,
		centroidLat: 37.0619000
	},
	{
		geoid: "37179",
		name: "Union",
		state: "North Carolina",
		countyState: "Union County, North Carolina",
		centroidLng: -80.5586000,
		centroidLat: 35.0112000
	},
	{
		geoid: "38053",
		name: "McKenzie",
		state: "North Dakota",
		countyState: "McKenzie County, North Dakota",
		centroidLng: -103.2980000,
		centroidLat: 47.7374000
	},
	{
		geoid: "12091",
		name: "Okaloosa",
		state: "Florida",
		countyState: "Okaloosa County, Florida",
		centroidLng: -86.5945000,
		centroidLat: 30.6612000
	},
	{
		geoid: "06001",
		name: "Alameda",
		state: "California",
		countyState: "Alameda County, California",
		centroidLng: -121.9220000,
		centroidLat: 37.6800000
	},
	{
		geoid: "17013",
		name: "Calhoun",
		state: "Illinois",
		countyState: "Calhoun County, Illinois",
		centroidLng: -90.6938000,
		centroidLat: 39.1343000
	},
	{
		geoid: "18085",
		name: "Kosciusko",
		state: "Indiana",
		countyState: "Kosciusko County, Indiana",
		centroidLng: -85.8644000,
		centroidLat: 41.2394000
	},
	{
		geoid: "28103",
		name: "Noxubee",
		state: "Mississippi",
		countyState: "Noxubee County, Mississippi",
		centroidLng: -88.5591000,
		centroidLat: 33.1072000
	},
	{
		geoid: "05147",
		name: "Woodruff",
		state: "Arkansas",
		countyState: "Woodruff County, Arkansas",
		centroidLng: -91.2594000,
		centroidLat: 35.1791000
	},
	{
		geoid: "34015",
		name: "Gloucester",
		state: "New Jersey",
		countyState: "Gloucester County, New Jersey",
		centroidLng: -75.1532000,
		centroidLat: 39.7016000
	},
	{
		geoid: "33011",
		name: "Hillsborough",
		state: "New Hampshire",
		countyState: "Hillsborough County, New Hampshire",
		centroidLng: -71.6539000,
		centroidLat: 42.9521000
	},
	{
		geoid: "30045",
		name: "Judith Basin",
		state: "Montana",
		countyState: "Judith Basin County, Montana",
		centroidLng: -110.2670000,
		centroidLat: 47.0445000
	},
	{
		geoid: "37121",
		name: "Mitchell",
		state: "North Carolina",
		countyState: "Mitchell County, North Carolina",
		centroidLng: -82.1979000,
		centroidLat: 35.9909000
	},
	{
		geoid: "30077",
		name: "Powell",
		state: "Montana",
		countyState: "Powell County, Montana",
		centroidLng: -112.8800000,
		centroidLat: 46.9329000
	},
	{
		geoid: "41053",
		name: "Polk",
		state: "Oregon",
		countyState: "Polk County, Oregon",
		centroidLng: -123.3810000,
		centroidLat: 44.8978000
	},
	{
		geoid: "41065",
		name: "Wasco",
		state: "Oregon",
		countyState: "Wasco County, Oregon",
		centroidLng: -121.0890000,
		centroidLat: 45.2632000
	},
	{
		geoid: "46105",
		name: "Perkins",
		state: "South Dakota",
		countyState: "Perkins County, South Dakota",
		centroidLng: -102.4780000,
		centroidLat: 45.4917000
	},
	{
		geoid: "19173",
		name: "Taylor",
		state: "Iowa",
		countyState: "Taylor County, Iowa",
		centroidLng: -94.6999000,
		centroidLat: 40.7357000
	},
	{
		geoid: "01129",
		name: "Washington",
		state: "Alabama",
		countyState: "Washington County, Alabama",
		centroidLng: -88.1758000,
		centroidLat: 31.4067000
	},
	{
		geoid: "39039",
		name: "Defiance",
		state: "Ohio",
		countyState: "Defiance County, Ohio",
		centroidLng: -84.5157000,
		centroidLat: 41.2966000
	},
	{
		geoid: "54025",
		name: "Greenbrier",
		state: "West Virginia",
		countyState: "Greenbrier County, West Virginia",
		centroidLng: -80.4270000,
		centroidLat: 37.9756000
	},
	{
		geoid: "17121",
		name: "Marion",
		state: "Illinois",
		countyState: "Marion County, Illinois",
		centroidLng: -88.9198000,
		centroidLat: 38.6501000
	},
	{
		geoid: "51051",
		name: "Dickenson",
		state: "Virginia",
		countyState: "Dickenson County, Virginia",
		centroidLng: -82.3450000,
		centroidLat: 37.1310000
	},
	{
		geoid: "51007",
		name: "Amelia",
		state: "Virginia",
		countyState: "Amelia County, Virginia",
		centroidLng: -77.9448000,
		centroidLat: 37.3451000
	},
	{
		geoid: "13243",
		name: "Randolph",
		state: "Georgia",
		countyState: "Randolph County, Georgia",
		centroidLng: -84.7472000,
		centroidLat: 31.7752000
	},
	{
		geoid: "35029",
		name: "Luna",
		state: "New Mexico",
		countyState: "Luna County, New Mexico",
		centroidLng: -107.7630000,
		centroidLat: 32.1945000
	},
	{
		geoid: "48355",
		name: "Nueces",
		state: "Texas",
		countyState: "Nueces County, Texas",
		centroidLng: -97.4632000,
		centroidLat: 27.7770000
	},
	{
		geoid: "56045",
		name: "Weston",
		state: "Wyoming",
		countyState: "Weston County, Wyoming",
		centroidLng: -104.5680000,
		centroidLat: 43.8400000
	},
	{
		geoid: "72137",
		name: "Toa Baja",
		state: "Puerto Rico",
		countyState: "Toa Baja County, Puerto Rico",
		centroidLng: -66.1967000,
		centroidLat: 18.4578000
	},
	{
		geoid: "17187",
		name: "Warren",
		state: "Illinois",
		countyState: "Warren County, Illinois",
		centroidLng: -90.6145000,
		centroidLat: 40.8483000
	},
	{
		geoid: "01089",
		name: "Madison",
		state: "Alabama",
		countyState: "Madison County, Alabama",
		centroidLng: -86.5229000,
		centroidLat: 34.7340000
	},
	{
		geoid: "47189",
		name: "Wilson",
		state: "Tennessee",
		countyState: "Wilson County, Tennessee",
		centroidLng: -86.3049000,
		centroidLat: 36.1562000
	},
	{
		geoid: "48063",
		name: "Camp",
		state: "Texas",
		countyState: "Camp County, Texas",
		centroidLng: -94.9363000,
		centroidLat: 32.9899000
	},
	{
		geoid: "40067",
		name: "Jefferson",
		state: "Oklahoma",
		countyState: "Jefferson County, Oklahoma",
		centroidLng: -97.8381000,
		centroidLat: 34.0697000
	},
	{
		geoid: "54085",
		name: "Ritchie",
		state: "West Virginia",
		countyState: "Ritchie County, West Virginia",
		centroidLng: -81.0691000,
		centroidLat: 39.1985000
	},
	{
		geoid: "19145",
		name: "Page",
		state: "Iowa",
		countyState: "Page County, Iowa",
		centroidLng: -95.1493000,
		centroidLat: 40.7383000
	},
	{
		geoid: "20087",
		name: "Jefferson",
		state: "Kansas",
		countyState: "Jefferson County, Kansas",
		centroidLng: -95.3891000,
		centroidLat: 39.2262000
	},
	{
		geoid: "22045",
		name: "Iberia",
		state: "Louisiana",
		countyState: "Iberia Parish, Louisiana",
		centroidLng: -91.6337000,
		centroidLat: 29.7407000
	},
	{
		geoid: "02158",
		name: "Kusilvak",
		state: "Alaska",
		countyState: "Kusilvak Borough, Alaska",
		centroidLng: -163.2380000,
		centroidLat: 62.1172000
	},
	{
		geoid: "37133",
		name: "Onslow",
		state: "North Carolina",
		countyState: "Onslow County, North Carolina",
		centroidLng: -77.3881000,
		centroidLat: 34.6921000
	},
	{
		geoid: "27043",
		name: "Faribault",
		state: "Minnesota",
		countyState: "Faribault County, Minnesota",
		centroidLng: -93.9480000,
		centroidLat: 43.6740000
	},
	{
		geoid: "47057",
		name: "Grainger",
		state: "Tennessee",
		countyState: "Grainger County, Tennessee",
		centroidLng: -83.4940000,
		centroidLat: 36.2506000
	},
	{
		geoid: "48161",
		name: "Freestone",
		state: "Texas",
		countyState: "Freestone County, Texas",
		centroidLng: -96.1382000,
		centroidLat: 31.7130000
	},
	{
		geoid: "42087",
		name: "Mifflin",
		state: "Pennsylvania",
		countyState: "Mifflin County, Pennsylvania",
		centroidLng: -77.6009000,
		centroidLat: 40.6037000
	},
	{
		geoid: "01041",
		name: "Crenshaw",
		state: "Alabama",
		countyState: "Crenshaw County, Alabama",
		centroidLng: -86.3240000,
		centroidLat: 31.7459000
	},
	{
		geoid: "51520",
		name: "Bristol",
		state: "Virginia",
		countyState: "Bristol County, Virginia",
		centroidLng: -82.1254000,
		centroidLat: 36.6479000
	},
	{
		geoid: "48399",
		name: "Runnels",
		state: "Texas",
		countyState: "Runnels County, Texas",
		centroidLng: -99.9751000,
		centroidLat: 31.8297000
	},
	{
		geoid: "48443",
		name: "Terrell",
		state: "Texas",
		countyState: "Terrell County, Texas",
		centroidLng: -102.1070000,
		centroidLat: 30.2187000
	},
	{
		geoid: "20153",
		name: "Rawlins",
		state: "Kansas",
		countyState: "Rawlins County, Kansas",
		centroidLng: -101.0760000,
		centroidLat: 39.7852000
	},
	{
		geoid: "20193",
		name: "Thomas",
		state: "Kansas",
		countyState: "Thomas County, Kansas",
		centroidLng: -101.0560000,
		centroidLat: 39.3508000
	},
	{
		geoid: "13161",
		name: "Jeff Davis",
		state: "Georgia",
		countyState: "Jeff Davis County, Georgia",
		centroidLng: -82.6337000,
		centroidLat: 31.8215000
	},
	{
		geoid: "19035",
		name: "Cherokee",
		state: "Iowa",
		countyState: "Cherokee County, Iowa",
		centroidLng: -95.6241000,
		centroidLat: 42.7354000
	},
	{
		geoid: "05145",
		name: "White",
		state: "Arkansas",
		countyState: "White County, Arkansas",
		centroidLng: -91.7326000,
		centroidLat: 35.2774000
	},
	{
		geoid: "28083",
		name: "Leflore",
		state: "Mississippi",
		countyState: "Leflore County, Mississippi",
		centroidLng: -90.2776000,
		centroidLat: 33.5234000
	},
	{
		geoid: "29053",
		name: "Cooper",
		state: "Missouri",
		countyState: "Cooper County, Missouri",
		centroidLng: -92.7792000,
		centroidLat: 38.8723000
	},
	{
		geoid: "26043",
		name: "Dickinson",
		state: "Michigan",
		countyState: "Dickinson County, Michigan",
		centroidLng: -87.8757000,
		centroidLat: 45.9848000
	},
	{
		geoid: "17197",
		name: "Will",
		state: "Illinois",
		countyState: "Will County, Illinois",
		centroidLng: -87.8934000,
		centroidLat: 41.4652000
	},
	{
		geoid: "48117",
		name: "Deaf Smith",
		state: "Texas",
		countyState: "Deaf Smith County, Texas",
		centroidLng: -102.6050000,
		centroidLat: 34.9666000
	},
	{
		geoid: "46011",
		name: "Brookings",
		state: "South Dakota",
		countyState: "Brookings County, South Dakota",
		centroidLng: -96.7906000,
		centroidLat: 44.3697000
	},
	{
		geoid: "29047",
		name: "Clay",
		state: "Missouri",
		countyState: "Clay County, Missouri",
		centroidLng: -94.4096000,
		centroidLat: 39.2830000
	},
	{
		geoid: "28027",
		name: "Coahoma",
		state: "Mississippi",
		countyState: "Coahoma County, Mississippi",
		centroidLng: -90.6784000,
		centroidLat: 34.2553000
	},
	{
		geoid: "18011",
		name: "Boone",
		state: "Indiana",
		countyState: "Boone County, Indiana",
		centroidLng: -86.4678000,
		centroidLat: 40.0517000
	},
	{
		geoid: "19057",
		name: "Des Moines",
		state: "Iowa",
		countyState: "Des Moines County, Iowa",
		centroidLng: -91.1758000,
		centroidLat: 40.8852000
	},
	{
		geoid: "19163",
		name: "Scott",
		state: "Iowa",
		countyState: "Scott County, Iowa",
		centroidLng: -90.6063000,
		centroidLat: 41.6130000
	},
	{
		geoid: "20143",
		name: "Ottawa",
		state: "Kansas",
		countyState: "Ottawa County, Kansas",
		centroidLng: -97.6507000,
		centroidLat: 39.1323000
	},
	{
		geoid: "12023",
		name: "Columbia",
		state: "Florida",
		countyState: "Columbia County, Florida",
		centroidLng: -82.6265000,
		centroidLat: 30.2117000
	},
	{
		geoid: "12059",
		name: "Holmes",
		state: "Florida",
		countyState: "Holmes County, Florida",
		centroidLng: -85.7679000,
		centroidLat: 30.8500000
	},
	{
		geoid: "28047",
		name: "Harrison",
		state: "Mississippi",
		countyState: "Harrison County, Mississippi",
		centroidLng: -89.0685000,
		centroidLat: 30.4086000
	},
	{
		geoid: "72141",
		name: "Utuado",
		state: "Puerto Rico",
		countyState: "Utuado County, Puerto Rico",
		centroidLng: -66.6989000,
		centroidLat: 18.2502000
	},
	{
		geoid: "47011",
		name: "Bradley",
		state: "Tennessee",
		countyState: "Bradley County, Tennessee",
		centroidLng: -84.8619000,
		centroidLat: 35.1727000
	},
	{
		geoid: "27017",
		name: "Carlton",
		state: "Minnesota",
		countyState: "Carlton County, Minnesota",
		centroidLng: -92.6782000,
		centroidLat: 46.5932000
	},
	{
		geoid: "48315",
		name: "Marion",
		state: "Texas",
		countyState: "Marion County, Texas",
		centroidLng: -94.3763000,
		centroidLat: 32.7841000
	},
	{
		geoid: "36095",
		name: "Schoharie",
		state: "New York",
		countyState: "Schoharie County, New York",
		centroidLng: -74.4381000,
		centroidLat: 42.5923000
	},
	{
		geoid: "40117",
		name: "Pawnee",
		state: "Oklahoma",
		countyState: "Pawnee County, Oklahoma",
		centroidLng: -96.6376000,
		centroidLat: 36.3621000
	},
	{
		geoid: "36007",
		name: "Broome",
		state: "New York",
		countyState: "Broome County, New York",
		centroidLng: -75.7449000,
		centroidLat: 42.2067000
	},
	{
		geoid: "48291",
		name: "Liberty",
		state: "Texas",
		countyState: "Liberty County, Texas",
		centroidLng: -94.8041000,
		centroidLat: 30.1889000
	},
	{
		geoid: "39009",
		name: "Athens",
		state: "Ohio",
		countyState: "Athens County, Ohio",
		centroidLng: -82.0101000,
		centroidLat: 39.3686000
	},
	{
		geoid: "39081",
		name: "Jefferson",
		state: "Ohio",
		countyState: "Jefferson County, Ohio",
		centroidLng: -80.7683000,
		centroidLat: 40.3773000
	},
	{
		geoid: "54083",
		name: "Randolph",
		state: "West Virginia",
		countyState: "Randolph County, West Virginia",
		centroidLng: -79.8147000,
		centroidLat: 38.7534000
	},
	{
		geoid: "54049",
		name: "Marion",
		state: "West Virginia",
		countyState: "Marion County, West Virginia",
		centroidLng: -80.2185000,
		centroidLat: 39.5143000
	},
	{
		geoid: "34011",
		name: "Cumberland",
		state: "New Jersey",
		countyState: "Cumberland County, New Jersey",
		centroidLng: -75.1483000,
		centroidLat: 39.3125000
	},
	{
		geoid: "06057",
		name: "Nevada",
		state: "California",
		countyState: "Nevada County, California",
		centroidLng: -120.6420000,
		centroidLat: 39.2660000
	},
	{
		geoid: "55031",
		name: "Douglas",
		state: "Wisconsin",
		countyState: "Douglas County, Wisconsin",
		centroidLng: -91.9215000,
		centroidLat: 46.5251000
	},
	{
		geoid: "21199",
		name: "Pulaski",
		state: "Kentucky",
		countyState: "Pulaski County, Kentucky",
		centroidLng: -84.5928000,
		centroidLat: 37.1114000
	},
	{
		geoid: "37065",
		name: "Edgecombe",
		state: "North Carolina",
		countyState: "Edgecombe County, North Carolina",
		centroidLng: -77.5854000,
		centroidLat: 35.9147000
	},
	{
		geoid: "48241",
		name: "Jasper",
		state: "Texas",
		countyState: "Jasper County, Texas",
		centroidLng: -94.1629000,
		centroidLat: 30.6999000
	},
	{
		geoid: "49001",
		name: "Beaver",
		state: "Utah",
		countyState: "Beaver County, Utah",
		centroidLng: -113.1930000,
		centroidLat: 38.3607000
	},
	{
		geoid: "13213",
		name: "Murray",
		state: "Georgia",
		countyState: "Murray County, Georgia",
		centroidLng: -84.7634000,
		centroidLat: 34.7858000
	},
	{
		geoid: "18075",
		name: "Jay",
		state: "Indiana",
		countyState: "Jay County, Indiana",
		centroidLng: -85.0113000,
		centroidLat: 40.4395000
	},
	{
		geoid: "26117",
		name: "Montcalm",
		state: "Michigan",
		countyState: "Montcalm County, Michigan",
		centroidLng: -85.1990000,
		centroidLat: 43.2936000
	},
	{
		geoid: "36083",
		name: "Rensselaer",
		state: "New York",
		countyState: "Rensselaer County, New York",
		centroidLng: -73.5255000,
		centroidLat: 42.7114000
	},
	{
		geoid: "12021",
		name: "Collier",
		state: "Florida",
		countyState: "Collier County, Florida",
		centroidLng: -81.3891000,
		centroidLat: 26.1433000
	},
	{
		geoid: "35031",
		name: "McKinley",
		state: "New Mexico",
		countyState: "McKinley County, New Mexico",
		centroidLng: -108.1770000,
		centroidLat: 35.4810000
	},
	{
		geoid: "13017",
		name: "Ben Hill",
		state: "Georgia",
		countyState: "Ben Hill County, Georgia",
		centroidLng: -83.2401000,
		centroidLat: 31.7518000
	},
	{
		geoid: "05077",
		name: "Lee",
		state: "Arkansas",
		countyState: "Lee County, Arkansas",
		centroidLng: -90.7552000,
		centroidLat: 34.7697000
	},
	{
		geoid: "12003",
		name: "Baker",
		state: "Florida",
		countyState: "Baker County, Florida",
		centroidLng: -82.2543000,
		centroidLat: 30.3604000
	},
	{
		geoid: "13173",
		name: "Lanier",
		state: "Georgia",
		countyState: "Lanier County, Georgia",
		centroidLng: -83.0839000,
		centroidLat: 31.0145000
	},
	{
		geoid: "20159",
		name: "Rice",
		state: "Kansas",
		countyState: "Rice County, Kansas",
		centroidLng: -98.2014000,
		centroidLat: 38.3408000
	},
	{
		geoid: "01015",
		name: "Calhoun",
		state: "Alabama",
		countyState: "Calhoun County, Alabama",
		centroidLng: -85.8378000,
		centroidLat: 33.7622000
	},
	{
		geoid: "28059",
		name: "Jackson",
		state: "Mississippi",
		countyState: "Jackson County, Mississippi",
		centroidLng: -88.6345000,
		centroidLat: 30.4411000
	},
	{
		geoid: "21037",
		name: "Campbell",
		state: "Kentucky",
		countyState: "Campbell County, Kentucky",
		centroidLng: -84.3690000,
		centroidLat: 38.9639000
	},
	{
		geoid: "21057",
		name: "Cumberland",
		state: "Kentucky",
		countyState: "Cumberland County, Kentucky",
		centroidLng: -85.4085000,
		centroidLat: 36.7784000
	},
	{
		geoid: "28107",
		name: "Panola",
		state: "Mississippi",
		countyState: "Panola County, Mississippi",
		centroidLng: -89.9590000,
		centroidLat: 34.3569000
	},
	{
		geoid: "48195",
		name: "Hansford",
		state: "Texas",
		countyState: "Hansford County, Texas",
		centroidLng: -101.3540000,
		centroidLat: 36.2773000
	},
	{
		geoid: "48071",
		name: "Chambers",
		state: "Texas",
		countyState: "Chambers County, Texas",
		centroidLng: -94.6858000,
		centroidLat: 29.6931000
	},
	{
		geoid: "13239",
		name: "Quitman",
		state: "Georgia",
		countyState: "Quitman County, Georgia",
		centroidLng: -85.0245000,
		centroidLat: 31.8814000
	},
	{
		geoid: "48199",
		name: "Hardin",
		state: "Texas",
		countyState: "Hardin County, Texas",
		centroidLng: -94.4018000,
		centroidLat: 30.3121000
	},
	{
		geoid: "09005",
		name: "Litchfield",
		state: "Connecticut",
		countyState: "Litchfield County, Connecticut",
		centroidLng: -73.2026000,
		centroidLat: 41.7590000
	},
	{
		geoid: "39143",
		name: "Sandusky",
		state: "Ohio",
		countyState: "Sandusky County, Ohio",
		centroidLng: -83.1300000,
		centroidLat: 41.3771000
	},
	{
		geoid: "23021",
		name: "Piscataquis",
		state: "Maine",
		countyState: "Piscataquis County, Maine",
		centroidLng: -69.3046000,
		centroidLat: 45.7925000
	},
	{
		geoid: "17153",
		name: "Pulaski",
		state: "Illinois",
		countyState: "Pulaski County, Illinois",
		centroidLng: -89.1008000,
		centroidLat: 37.2017000
	},
	{
		geoid: "40107",
		name: "Okfuskee",
		state: "Oklahoma",
		countyState: "Okfuskee County, Oklahoma",
		centroidLng: -96.3031000,
		centroidLat: 35.4645000
	},
	{
		geoid: "26145",
		name: "Saginaw",
		state: "Michigan",
		countyState: "Saginaw County, Michigan",
		centroidLng: -84.0326000,
		centroidLat: 43.3487000
	},
	{
		geoid: "35039",
		name: "Rio Arriba",
		state: "New Mexico",
		countyState: "Rio Arriba County, New Mexico",
		centroidLng: -106.5780000,
		centroidLat: 36.4654000
	},
	{
		geoid: "50005",
		name: "Caledonia",
		state: "Vermont",
		countyState: "Caledonia County, Vermont",
		centroidLng: -72.1358000,
		centroidLat: 44.4628000
	},
	{
		geoid: "51023",
		name: "Botetourt",
		state: "Virginia",
		countyState: "Botetourt County, Virginia",
		centroidLng: -79.7864000,
		centroidLat: 37.5552000
	},
	{
		geoid: "17119",
		name: "Madison",
		state: "Illinois",
		countyState: "Madison County, Illinois",
		centroidLng: -89.9356000,
		centroidLat: 38.8276000
	},
	{
		geoid: "48085",
		name: "Collin",
		state: "Texas",
		countyState: "Collin County, Texas",
		centroidLng: -96.5695000,
		centroidLat: 33.1935000
	},
	{
		geoid: "45001",
		name: "Abbeville",
		state: "South Carolina",
		countyState: "Abbeville County, South Carolina",
		centroidLng: -82.4905000,
		centroidLat: 34.2489000
	},
	{
		geoid: "31053",
		name: "Dodge",
		state: "Nebraska",
		countyState: "Dodge County, Nebraska",
		centroidLng: -96.6173000,
		centroidLat: 41.5679000
	},
	{
		geoid: "12115",
		name: "Sarasota",
		state: "Florida",
		countyState: "Sarasota County, Florida",
		centroidLng: -82.3810000,
		centroidLat: 27.1675000
	},
	{
		geoid: "55077",
		name: "Marquette",
		state: "Wisconsin",
		countyState: "Marquette County, Wisconsin",
		centroidLng: -89.3836000,
		centroidLat: 43.8126000
	},
	{
		geoid: "04015",
		name: "Mohave",
		state: "Arizona",
		countyState: "Mohave County, Arizona",
		centroidLng: -113.6420000,
		centroidLat: 35.6052000
	},
	{
		geoid: "04025",
		name: "Yavapai",
		state: "Arizona",
		countyState: "Yavapai County, Arizona",
		centroidLng: -112.3980000,
		centroidLat: 34.7067000
	},
	{
		geoid: "45051",
		name: "Horry",
		state: "South Carolina",
		countyState: "Horry County, South Carolina",
		centroidLng: -78.9192000,
		centroidLat: 33.9345000
	},
	{
		geoid: "42091",
		name: "Montgomery",
		state: "Pennsylvania",
		countyState: "Montgomery County, Pennsylvania",
		centroidLng: -75.3559000,
		centroidLat: 40.2120000
	},
	{
		geoid: "16061",
		name: "Lewis",
		state: "Idaho",
		countyState: "Lewis County, Idaho",
		centroidLng: -116.3630000,
		centroidLat: 46.2305000
	},
	{
		geoid: "72013",
		name: "Arecibo",
		state: "Puerto Rico",
		countyState: "Arecibo County, Puerto Rico",
		centroidLng: -66.6746000,
		centroidLat: 18.4297000
	},
	{
		geoid: "51720",
		name: "Norton",
		state: "Virginia",
		countyState: "Norton County, Virginia",
		centroidLng: -82.6264000,
		centroidLat: 36.9299000
	},
	{
		geoid: "34027",
		name: "Morris",
		state: "New Jersey",
		countyState: "Morris County, New Jersey",
		centroidLng: -74.5782000,
		centroidLat: 40.8673000
	},
	{
		geoid: "29211",
		name: "Sullivan",
		state: "Missouri",
		countyState: "Sullivan County, Missouri",
		centroidLng: -93.1113000,
		centroidLat: 40.2098000
	},
	{
		geoid: "20135",
		name: "Ness",
		state: "Kansas",
		countyState: "Ness County, Kansas",
		centroidLng: -99.9164000,
		centroidLat: 38.4798000
	},
	{
		geoid: "48039",
		name: "Brazoria",
		state: "Texas",
		countyState: "Brazoria County, Texas",
		centroidLng: -95.4653000,
		centroidLat: 29.1820000
	},
	{
		geoid: "51790",
		name: "Staunton",
		state: "Virginia",
		countyState: "Staunton County, Virginia",
		centroidLng: -79.0626000,
		centroidLat: 38.1601000
	},
	{
		geoid: "31029",
		name: "Chase",
		state: "Nebraska",
		countyState: "Chase County, Nebraska",
		centroidLng: -101.6970000,
		centroidLat: 40.5247000
	},
	{
		geoid: "39113",
		name: "Montgomery",
		state: "Ohio",
		countyState: "Montgomery County, Ohio",
		centroidLng: -84.2683000,
		centroidLat: 39.7505000
	},
	{
		geoid: "48447",
		name: "Throckmorton",
		state: "Texas",
		countyState: "Throckmorton County, Texas",
		centroidLng: -99.2117000,
		centroidLat: 33.1777000
	},
	{
		geoid: "13221",
		name: "Oglethorpe",
		state: "Georgia",
		countyState: "Oglethorpe County, Georgia",
		centroidLng: -83.0431000,
		centroidLat: 33.8688000
	},
	{
		geoid: "05029",
		name: "Conway",
		state: "Arkansas",
		countyState: "Conway County, Arkansas",
		centroidLng: -92.7601000,
		centroidLat: 35.2671000
	},
	{
		geoid: "29147",
		name: "Nodaway",
		state: "Missouri",
		countyState: "Nodaway County, Missouri",
		centroidLng: -94.9004000,
		centroidLat: 40.3523000
	},
	{
		geoid: "38073",
		name: "Ransom",
		state: "North Dakota",
		countyState: "Ransom County, North Dakota",
		centroidLng: -97.6574000,
		centroidLat: 46.4565000
	},
	{
		geoid: "26017",
		name: "Bay",
		state: "Michigan",
		countyState: "Bay County, Michigan",
		centroidLng: -83.9335000,
		centroidLat: 43.7378000
	},
	{
		geoid: "47157",
		name: "Shelby",
		state: "Tennessee",
		countyState: "Shelby County, Tennessee",
		centroidLng: -89.9715000,
		centroidLat: 35.2018000
	},
	{
		geoid: "06007",
		name: "Butte",
		state: "California",
		countyState: "Butte County, California",
		centroidLng: -121.5730000,
		centroidLat: 39.7238000
	},
	{
		geoid: "27115",
		name: "Pine",
		state: "Minnesota",
		countyState: "Pine County, Minnesota",
		centroidLng: -92.7176000,
		centroidLat: 46.0746000
	},
	{
		geoid: "48021",
		name: "Bastrop",
		state: "Texas",
		countyState: "Bastrop County, Texas",
		centroidLng: -97.3369000,
		centroidLat: 30.1031000
	},
	{
		geoid: "53039",
		name: "Klickitat",
		state: "Washington",
		countyState: "Klickitat County, Washington",
		centroidLng: -120.7390000,
		centroidLat: 45.8256000
	},
	{
		geoid: "53055",
		name: "San Juan",
		state: "Washington",
		countyState: "San Juan County, Washington",
		centroidLng: -122.9860000,
		centroidLat: 48.5579000
	},
	{
		geoid: "55009",
		name: "Brown",
		state: "Wisconsin",
		countyState: "Brown County, Wisconsin",
		centroidLng: -88.0053000,
		centroidLat: 44.4601000
	},
	{
		geoid: "20205",
		name: "Wilson",
		state: "Kansas",
		countyState: "Wilson County, Kansas",
		centroidLng: -95.7438000,
		centroidLat: 37.5592000
	},
	{
		geoid: "22069",
		name: "Natchitoches",
		state: "Louisiana",
		countyState: "Natchitoches Parish, Louisiana",
		centroidLng: -93.0791000,
		centroidLat: 31.7475000
	},
	{
		geoid: "22109",
		name: "Terrebonne",
		state: "Louisiana",
		countyState: "Terrebonne Parish, Louisiana",
		centroidLng: -90.8654000,
		centroidLat: 29.3820000
	},
	{
		geoid: "13155",
		name: "Irwin",
		state: "Georgia",
		countyState: "Irwin County, Georgia",
		centroidLng: -83.2497000,
		centroidLat: 31.6208000
	},
	{
		geoid: "02185",
		name: "North Slope",
		state: "Alaska",
		countyState: "North Slope Borough, Alaska",
		centroidLng: -153.9860000,
		centroidLat: 69.7199000
	},
	{
		geoid: "26011",
		name: "Arenac",
		state: "Michigan",
		countyState: "Arenac County, Michigan",
		centroidLng: -83.6760000,
		centroidLat: 44.0012000
	},
	{
		geoid: "19111",
		name: "Lee",
		state: "Iowa",
		countyState: "Lee County, Iowa",
		centroidLng: -91.4156000,
		centroidLat: 40.5947000
	},
	{
		geoid: "20141",
		name: "Osborne",
		state: "Kansas",
		countyState: "Osborne County, Kansas",
		centroidLng: -98.7680000,
		centroidLat: 39.3504000
	},
	{
		geoid: "26047",
		name: "Emmet",
		state: "Michigan",
		countyState: "Emmet County, Michigan",
		centroidLng: -85.0192000,
		centroidLat: 45.5709000
	},
	{
		geoid: "19071",
		name: "Fremont",
		state: "Iowa",
		countyState: "Fremont County, Iowa",
		centroidLng: -95.6298000,
		centroidLat: 40.7411000
	},
	{
		geoid: "23015",
		name: "Lincoln",
		state: "Maine",
		countyState: "Lincoln County, Maine",
		centroidLng: -69.5231000,
		centroidLat: 44.0213000
	},
	{
		geoid: "36099",
		name: "Seneca",
		state: "New York",
		countyState: "Seneca County, New York",
		centroidLng: -76.7837000,
		centroidLat: 42.7828000
	},
	{
		geoid: "21125",
		name: "Laurel",
		state: "Kentucky",
		countyState: "Laurel County, Kentucky",
		centroidLng: -84.1157000,
		centroidLat: 37.1375000
	},
	{
		geoid: "47121",
		name: "Meigs",
		state: "Tennessee",
		countyState: "Meigs County, Tennessee",
		centroidLng: -84.8251000,
		centroidLat: 35.5203000
	},
	{
		geoid: "29213",
		name: "Taney",
		state: "Missouri",
		countyState: "Taney County, Missouri",
		centroidLng: -93.0401000,
		centroidLat: 36.6573000
	},
	{
		geoid: "13269",
		name: "Taylor",
		state: "Georgia",
		countyState: "Taylor County, Georgia",
		centroidLng: -84.0310000,
		centroidLat: 32.5095000
	},
	{
		geoid: "51830",
		name: "Williamsburg",
		state: "Virginia",
		countyState: "Williamsburg County, Virginia",
		centroidLng: -76.7036000,
		centroidLat: 37.2787000
	},
	{
		geoid: "48415",
		name: "Scurry",
		state: "Texas",
		countyState: "Scurry County, Texas",
		centroidLng: -100.9150000,
		centroidLat: 32.7477000
	},
	{
		geoid: "08047",
		name: "Gilpin",
		state: "Colorado",
		countyState: "Gilpin County, Colorado",
		centroidLng: -105.5490000,
		centroidLat: 39.8421000
	},
	{
		geoid: "40087",
		name: "McClain",
		state: "Oklahoma",
		countyState: "McClain County, Oklahoma",
		centroidLng: -97.3011000,
		centroidLat: 35.0960000
	},
	{
		geoid: "54071",
		name: "Pendleton",
		state: "West Virginia",
		countyState: "Pendleton County, West Virginia",
		centroidLng: -79.3536000,
		centroidLat: 38.6847000
	},
	{
		geoid: "51139",
		name: "Page",
		state: "Virginia",
		countyState: "Page County, Virginia",
		centroidLng: -78.4881000,
		centroidLat: 38.6222000
	},
	{
		geoid: "40079",
		name: "Le Flore",
		state: "Oklahoma",
		countyState: "Le Flore County, Oklahoma",
		centroidLng: -94.7458000,
		centroidLat: 34.9463000
	},
	{
		geoid: "54089",
		name: "Summers",
		state: "West Virginia",
		countyState: "Summers County, West Virginia",
		centroidLng: -80.8775000,
		centroidLat: 37.6486000
	},
	{
		geoid: "37119",
		name: "Mecklenburg",
		state: "North Carolina",
		countyState: "Mecklenburg County, North Carolina",
		centroidLng: -80.8043000,
		centroidLat: 35.2583000
	},
	{
		geoid: "34013",
		name: "Essex",
		state: "New Jersey",
		countyState: "Essex County, New Jersey",
		centroidLng: -74.2451000,
		centroidLat: 40.7916000
	},
	{
		geoid: "29115",
		name: "Linn",
		state: "Missouri",
		countyState: "Linn County, Missouri",
		centroidLng: -93.1061000,
		centroidLat: 39.8686000
	},
	{
		geoid: "13209",
		name: "Montgomery",
		state: "Georgia",
		countyState: "Montgomery County, Georgia",
		centroidLng: -82.5327000,
		centroidLat: 32.1512000
	},
	{
		geoid: "41055",
		name: "Sherman",
		state: "Oregon",
		countyState: "Sherman County, Oregon",
		centroidLng: -120.6950000,
		centroidLat: 45.4103000
	},
	{
		geoid: "13133",
		name: "Greene",
		state: "Georgia",
		countyState: "Greene County, Georgia",
		centroidLng: -83.1785000,
		centroidLat: 33.5584000
	},
	{
		geoid: "46017",
		name: "Buffalo",
		state: "South Dakota",
		countyState: "Buffalo County, South Dakota",
		centroidLng: -99.2513000,
		centroidLat: 44.0650000
	},
	{
		geoid: "46095",
		name: "Mellette",
		state: "South Dakota",
		countyState: "Mellette County, South Dakota",
		centroidLng: -100.7210000,
		centroidLat: 43.6235000
	},
	{
		geoid: "48135",
		name: "Ector",
		state: "Texas",
		countyState: "Ector County, Texas",
		centroidLng: -102.5430000,
		centroidLat: 31.8691000
	},
	{
		geoid: "48293",
		name: "Limestone",
		state: "Texas",
		countyState: "Limestone County, Texas",
		centroidLng: -96.5844000,
		centroidLat: 31.5179000
	},
	{
		geoid: "53027",
		name: "Grays Harbor",
		state: "Washington",
		countyState: "Grays Harbor County, Washington",
		centroidLng: -123.7940000,
		centroidLat: 47.1624000
	},
	{
		geoid: "54003",
		name: "Berkeley",
		state: "West Virginia",
		countyState: "Berkeley County, West Virginia",
		centroidLng: -78.0263000,
		centroidLat: 39.4428000
	},
	{
		geoid: "72149",
		name: "Villalba",
		state: "Puerto Rico",
		countyState: "Villalba County, Puerto Rico",
		centroidLng: -66.4744000,
		centroidLat: 18.1299000
	},
	{
		geoid: "30067",
		name: "Park",
		state: "Montana",
		countyState: "Park County, Montana",
		centroidLng: -110.4230000,
		centroidLat: 45.5925000
	},
	{
		geoid: "48121",
		name: "Denton",
		state: "Texas",
		countyState: "Denton County, Texas",
		centroidLng: -97.1163000,
		centroidLat: 33.2090000
	},
	{
		geoid: "45021",
		name: "Cherokee",
		state: "South Carolina",
		countyState: "Cherokee County, South Carolina",
		centroidLng: -81.6205000,
		centroidLat: 35.0117000
	},
	{
		geoid: "37199",
		name: "Yancey",
		state: "North Carolina",
		countyState: "Yancey County, North Carolina",
		centroidLng: -82.3195000,
		centroidLat: 35.8916000
	},
	{
		geoid: "48387",
		name: "Red River",
		state: "Texas",
		countyState: "Red River County, Texas",
		centroidLng: -95.0217000,
		centroidLat: 33.6400000
	},
	{
		geoid: "48321",
		name: "Matagorda",
		state: "Texas",
		countyState: "Matagorda County, Texas",
		centroidLng: -95.9454000,
		centroidLat: 28.7547000
	},
	{
		geoid: "27149",
		name: "Stevens",
		state: "Minnesota",
		countyState: "Stevens County, Minnesota",
		centroidLng: -96.0007000,
		centroidLat: 45.5860000
	},
	{
		geoid: "13301",
		name: "Warren",
		state: "Georgia",
		countyState: "Warren County, Georgia",
		centroidLng: -82.6252000,
		centroidLat: 33.4324000
	},
	{
		geoid: "48197",
		name: "Hardeman",
		state: "Texas",
		countyState: "Hardeman County, Texas",
		centroidLng: -99.7365000,
		centroidLat: 34.3175000
	},
	{
		geoid: "17133",
		name: "Monroe",
		state: "Illinois",
		countyState: "Monroe County, Illinois",
		centroidLng: -90.1347000,
		centroidLat: 38.3045000
	},
	{
		geoid: "13165",
		name: "Jenkins",
		state: "Georgia",
		countyState: "Jenkins County, Georgia",
		centroidLng: -81.9577000,
		centroidLat: 32.7804000
	},
	{
		geoid: "21085",
		name: "Grayson",
		state: "Kentucky",
		countyState: "Grayson County, Kentucky",
		centroidLng: -86.3438000,
		centroidLat: 37.4721000
	},
	{
		geoid: "48363",
		name: "Palo Pinto",
		state: "Texas",
		countyState: "Palo Pinto County, Texas",
		centroidLng: -98.3162000,
		centroidLat: 32.7598000
	},
	{
		geoid: "13149",
		name: "Heard",
		state: "Georgia",
		countyState: "Heard County, Georgia",
		centroidLng: -85.1164000,
		centroidLat: 33.2788000
	},
	{
		geoid: "01079",
		name: "Lawrence",
		state: "Alabama",
		countyState: "Lawrence County, Alabama",
		centroidLng: -87.3180000,
		centroidLat: 34.5520000
	},
	{
		geoid: "72061",
		name: "Guaynabo",
		state: "Puerto Rico",
		countyState: "Guaynabo County, Puerto Rico",
		centroidLng: -66.1123000,
		centroidLat: 18.3591000
	},
	{
		geoid: "28093",
		name: "Marshall",
		state: "Mississippi",
		countyState: "Marshall County, Mississippi",
		centroidLng: -89.4850000,
		centroidLat: 34.7450000
	},
	{
		geoid: "20025",
		name: "Clark",
		state: "Kansas",
		countyState: "Clark County, Kansas",
		centroidLng: -99.8243000,
		centroidLat: 37.2372000
	},
	{
		geoid: "20081",
		name: "Haskell",
		state: "Kansas",
		countyState: "Haskell County, Kansas",
		centroidLng: -100.8710000,
		centroidLat: 37.5620000
	},
	{
		geoid: "22013",
		name: "Bienville",
		state: "Louisiana",
		countyState: "Bienville Parish, Louisiana",
		centroidLng: -93.1010000,
		centroidLat: 32.3661000
	},
	{
		geoid: "13295",
		name: "Walker",
		state: "Georgia",
		countyState: "Walker County, Georgia",
		centroidLng: -85.2923000,
		centroidLat: 34.7835000
	},
	{
		geoid: "19095",
		name: "Iowa",
		state: "Iowa",
		countyState: "Iowa County, Iowa",
		centroidLng: -92.0636000,
		centroidLat: 41.6864000
	},
	{
		geoid: "31019",
		name: "Buffalo",
		state: "Nebraska",
		countyState: "Buffalo County, Nebraska",
		centroidLng: -99.0741000,
		centroidLat: 40.8496000
	},
	{
		geoid: "40007",
		name: "Beaver",
		state: "Oklahoma",
		countyState: "Beaver County, Oklahoma",
		centroidLng: -100.4780000,
		centroidLat: 36.7508000
	},
	{
		geoid: "19023",
		name: "Butler",
		state: "Iowa",
		countyState: "Butler County, Iowa",
		centroidLng: -92.7906000,
		centroidLat: 42.7317000
	},
	{
		geoid: "30085",
		name: "Roosevelt",
		state: "Montana",
		countyState: "Roosevelt County, Montana",
		centroidLng: -104.9440000,
		centroidLat: 48.2797000
	},
	{
		geoid: "72017",
		name: "Barceloneta",
		state: "Puerto Rico",
		countyState: "Barceloneta County, Puerto Rico",
		centroidLng: -66.5573000,
		centroidLat: 18.4654000
	},
	{
		geoid: "53015",
		name: "Cowlitz",
		state: "Washington",
		countyState: "Cowlitz County, Washington",
		centroidLng: -122.7290000,
		centroidLat: 46.1193000
	},
	{
		geoid: "48297",
		name: "Live Oak",
		state: "Texas",
		countyState: "Live Oak County, Texas",
		centroidLng: -98.0719000,
		centroidLat: 28.4218000
	},
	{
		geoid: "29135",
		name: "Moniteau",
		state: "Missouri",
		countyState: "Moniteau County, Missouri",
		centroidLng: -92.6108000,
		centroidLat: 38.6749000
	},
	{
		geoid: "51193",
		name: "Westmoreland",
		state: "Virginia",
		countyState: "Westmoreland County, Virginia",
		centroidLng: -76.7904000,
		centroidLat: 38.1218000
	},
	{
		geoid: "37197",
		name: "Yadkin",
		state: "North Carolina",
		countyState: "Yadkin County, North Carolina",
		centroidLng: -80.6569000,
		centroidLat: 36.1672000
	},
	{
		geoid: "06047",
		name: "Merced",
		state: "California",
		countyState: "Merced County, California",
		centroidLng: -120.6500000,
		centroidLat: 37.1869000
	},
	{
		geoid: "40043",
		name: "Dewey",
		state: "Oklahoma",
		countyState: "Dewey County, Oklahoma",
		centroidLng: -99.0071000,
		centroidLat: 35.9884000
	},
	{
		geoid: "37023",
		name: "Burke",
		state: "North Carolina",
		countyState: "Burke County, North Carolina",
		centroidLng: -81.6754000,
		centroidLat: 35.7790000
	},
	{
		geoid: "37191",
		name: "Wayne",
		state: "North Carolina",
		countyState: "Wayne County, North Carolina",
		centroidLng: -78.0532000,
		centroidLat: 35.3721000
	},
	{
		geoid: "42123",
		name: "Warren",
		state: "Pennsylvania",
		countyState: "Warren County, Pennsylvania",
		centroidLng: -79.2643000,
		centroidLat: 41.8115000
	},
	{
		geoid: "05061",
		name: "Howard",
		state: "Arkansas",
		countyState: "Howard County, Arkansas",
		centroidLng: -94.0384000,
		centroidLat: 34.0517000
	},
	{
		geoid: "37059",
		name: "Davie",
		state: "North Carolina",
		countyState: "Davie County, North Carolina",
		centroidLng: -80.5365000,
		centroidLat: 35.9029000
	},
	{
		geoid: "37111",
		name: "McDowell",
		state: "North Carolina",
		countyState: "McDowell County, North Carolina",
		centroidLng: -82.0596000,
		centroidLat: 35.7390000
	},
	{
		geoid: "05099",
		name: "Nevada",
		state: "Arkansas",
		countyState: "Nevada County, Arkansas",
		centroidLng: -93.2941000,
		centroidLat: 33.7006000
	},
	{
		geoid: "08125",
		name: "Yuma",
		state: "Colorado",
		countyState: "Yuma County, Colorado",
		centroidLng: -102.4270000,
		centroidLat: 40.0039000
	},
	{
		geoid: "48421",
		name: "Sherman",
		state: "Texas",
		countyState: "Sherman County, Texas",
		centroidLng: -101.8930000,
		centroidLat: 36.2779000
	},
	{
		geoid: "48463",
		name: "Uvalde",
		state: "Texas",
		countyState: "Uvalde County, Texas",
		centroidLng: -99.7620000,
		centroidLat: 29.3570000
	},
	{
		geoid: "39011",
		name: "Auglaize",
		state: "Ohio",
		countyState: "Auglaize County, Ohio",
		centroidLng: -84.1681000,
		centroidLat: 40.5203000
	},
	{
		geoid: "22015",
		name: "Bossier",
		state: "Louisiana",
		countyState: "Bossier Parish, Louisiana",
		centroidLng: -93.6090000,
		centroidLat: 32.6276000
	},
	{
		geoid: "19063",
		name: "Emmet",
		state: "Iowa",
		countyState: "Emmet County, Iowa",
		centroidLng: -94.6786000,
		centroidLat: 43.3780000
	},
	{
		geoid: "20169",
		name: "Saline",
		state: "Kansas",
		countyState: "Saline County, Kansas",
		centroidLng: -97.6503000,
		centroidLat: 38.7837000
	},
	{
		geoid: "21153",
		name: "Magoffin",
		state: "Kentucky",
		countyState: "Magoffin County, Kentucky",
		centroidLng: -83.0746000,
		centroidLat: 37.6906000
	},
	{
		geoid: "48017",
		name: "Bailey",
		state: "Texas",
		countyState: "Bailey County, Texas",
		centroidLng: -102.8310000,
		centroidLat: 34.0688000
	},
	{
		geoid: "29027",
		name: "Callaway",
		state: "Missouri",
		countyState: "Callaway County, Missouri",
		centroidLng: -91.9300000,
		centroidLat: 38.8108000
	},
	{
		geoid: "56001",
		name: "Albany",
		state: "Wyoming",
		countyState: "Albany County, Wyoming",
		centroidLng: -105.7970000,
		centroidLat: 41.7150000
	},
	{
		geoid: "56029",
		name: "Park",
		state: "Wyoming",
		countyState: "Park County, Wyoming",
		centroidLng: -109.8030000,
		centroidLat: 44.4035000
	},
	{
		geoid: "42093",
		name: "Montour",
		state: "Pennsylvania",
		countyState: "Montour County, Pennsylvania",
		centroidLng: -76.6533000,
		centroidLat: 41.0273000
	},
	{
		geoid: "36081",
		name: "Queens",
		state: "New York",
		countyState: "Queens County, New York",
		centroidLng: -73.8711000,
		centroidLat: 40.6508000
	},
	{
		geoid: "12121",
		name: "Suwannee",
		state: "Florida",
		countyState: "Suwannee County, Florida",
		centroidLng: -83.0059000,
		centroidLat: 30.1626000
	},
	{
		geoid: "26031",
		name: "Cheboygan",
		state: "Michigan",
		countyState: "Cheboygan County, Michigan",
		centroidLng: -84.4677000,
		centroidLat: 45.5045000
	},
	{
		geoid: "26051",
		name: "Gladwin",
		state: "Michigan",
		countyState: "Gladwin County, Michigan",
		centroidLng: -84.3870000,
		centroidLat: 43.9877000
	},
	{
		geoid: "21173",
		name: "Montgomery",
		state: "Kentucky",
		countyState: "Montgomery County, Kentucky",
		centroidLng: -83.9181000,
		centroidLat: 38.0461000
	},
	{
		geoid: "45057",
		name: "Lancaster",
		state: "South Carolina",
		countyState: "Lancaster County, South Carolina",
		centroidLng: -80.6590000,
		centroidLat: 34.7670000
	},
	{
		geoid: "21123",
		name: "Larue",
		state: "Kentucky",
		countyState: "Larue County, Kentucky",
		centroidLng: -85.6803000,
		centroidLat: 37.5772000
	},
	{
		geoid: "19193",
		name: "Woodbury",
		state: "Iowa",
		countyState: "Woodbury County, Iowa",
		centroidLng: -96.0841000,
		centroidLat: 42.3867000
	},
	{
		geoid: "12123",
		name: "Taylor",
		state: "Florida",
		countyState: "Taylor County, Florida",
		centroidLng: -83.6594000,
		centroidLat: 29.9665000
	},
	{
		geoid: "17003",
		name: "Alexander",
		state: "Illinois",
		countyState: "Alexander County, Illinois",
		centroidLng: -89.3257000,
		centroidLat: 37.1528000
	},
	{
		geoid: "29099",
		name: "Jefferson",
		state: "Missouri",
		countyState: "Jefferson County, Missouri",
		centroidLng: -90.5150000,
		centroidLat: 38.2527000
	},
	{
		geoid: "29113",
		name: "Lincoln",
		state: "Missouri",
		countyState: "Lincoln County, Missouri",
		centroidLng: -90.9637000,
		centroidLat: 39.0491000
	},
	{
		geoid: "13051",
		name: "Chatham",
		state: "Georgia",
		countyState: "Chatham County, Georgia",
		centroidLng: -81.0716000,
		centroidLat: 31.9714000
	},
	{
		geoid: "12125",
		name: "Union",
		state: "Florida",
		countyState: "Union County, Florida",
		centroidLng: -82.3570000,
		centroidLat: 30.0315000
	},
	{
		geoid: "36037",
		name: "Genesee",
		state: "New York",
		countyState: "Genesee County, New York",
		centroidLng: -78.1848000,
		centroidLat: 42.9982000
	},
	{
		geoid: "19041",
		name: "Clay",
		state: "Iowa",
		countyState: "Clay County, Iowa",
		centroidLng: -95.1511000,
		centroidLat: 43.0826000
	},
	{
		geoid: "30025",
		name: "Fallon",
		state: "Montana",
		countyState: "Fallon County, Montana",
		centroidLng: -104.4750000,
		centroidLat: 46.2835000
	},
	{
		geoid: "46107",
		name: "Potter",
		state: "South Dakota",
		countyState: "Potter County, South Dakota",
		centroidLng: -99.9984000,
		centroidLat: 45.0720000
	},
	{
		geoid: "48109",
		name: "Culberson",
		state: "Texas",
		countyState: "Culberson County, Texas",
		centroidLng: -104.4720000,
		centroidLat: 31.3321000
	},
	{
		geoid: "23005",
		name: "Cumberland",
		state: "Maine",
		countyState: "Cumberland County, Maine",
		centroidLng: -70.3618000,
		centroidLat: 43.8190000
	},
	{
		geoid: "48013",
		name: "Atascosa",
		state: "Texas",
		countyState: "Atascosa County, Texas",
		centroidLng: -98.4516000,
		centroidLat: 28.9317000
	},
	{
		geoid: "20095",
		name: "Kingman",
		state: "Kansas",
		countyState: "Kingman County, Kansas",
		centroidLng: -98.1359000,
		centroidLat: 37.5589000
	},
	{
		geoid: "13241",
		name: "Rabun",
		state: "Georgia",
		countyState: "Rabun County, Georgia",
		centroidLng: -83.3800000,
		centroidLat: 34.8587000
	},
	{
		geoid: "54097",
		name: "Upshur",
		state: "West Virginia",
		countyState: "Upshur County, West Virginia",
		centroidLng: -80.2270000,
		centroidLat: 38.8993000
	},
	{
		geoid: "29215",
		name: "Texas",
		state: "Missouri",
		countyState: "Texas County, Missouri",
		centroidLng: -91.9500000,
		centroidLat: 37.3270000
	},
	{
		geoid: "05119",
		name: "Pulaski",
		state: "Arkansas",
		countyState: "Pulaski County, Arkansas",
		centroidLng: -92.3925000,
		centroidLat: 34.7519000
	},
	{
		geoid: "19047",
		name: "Crawford",
		state: "Iowa",
		countyState: "Crawford County, Iowa",
		centroidLng: -95.3818000,
		centroidLat: 42.0373000
	},
	{
		geoid: "16005",
		name: "Bannock",
		state: "Idaho",
		countyState: "Bannock County, Idaho",
		centroidLng: -112.3140000,
		centroidLat: 42.6394000
	},
	{
		geoid: "39055",
		name: "Geauga",
		state: "Ohio",
		countyState: "Geauga County, Ohio",
		centroidLng: -81.1973000,
		centroidLat: 41.5312000
	},
	{
		geoid: "72035",
		name: "Cayey",
		state: "Puerto Rico",
		countyState: "Cayey County, Puerto Rico",
		centroidLng: -66.1391000,
		centroidLat: 18.1030000
	},
	{
		geoid: "06107",
		name: "Tulare",
		state: "California",
		countyState: "Tulare County, California",
		centroidLng: -118.7770000,
		centroidLat: 36.2670000
	},
	{
		geoid: "72015",
		name: "Arroyo",
		state: "Puerto Rico",
		countyState: "Arroyo County, Puerto Rico",
		centroidLng: -66.0414000,
		centroidLat: 17.9673000
	},
	{
		geoid: "72125",
		name: "San Germ?n",
		state: "Puerto Rico",
		countyState: "San Germ?n County, Puerto Rico",
		centroidLng: -67.0397000,
		centroidLat: 18.1133000
	},
	{
		geoid: "36117",
		name: "Wayne",
		state: "New York",
		countyState: "Wayne County, New York",
		centroidLng: -77.0452000,
		centroidLat: 43.3484000
	},
	{
		geoid: "51163",
		name: "Rockbridge",
		state: "Virginia",
		countyState: "Rockbridge County, Virginia",
		centroidLng: -79.4164000,
		centroidLat: 37.8094000
	},
	{
		geoid: "38095",
		name: "Towner",
		state: "North Dakota",
		countyState: "Towner County, North Dakota",
		centroidLng: -99.2481000,
		centroidLat: 48.6852000
	},
	{
		geoid: "42045",
		name: "Delaware",
		state: "Pennsylvania",
		countyState: "Delaware County, Pennsylvania",
		centroidLng: -75.4065000,
		centroidLat: 39.9341000
	},
	{
		geoid: "36063",
		name: "Niagara",
		state: "New York",
		countyState: "Niagara County, New York",
		centroidLng: -78.8307000,
		centroidLat: 43.3268000
	},
	{
		geoid: "50003",
		name: "Bennington",
		state: "Vermont",
		countyState: "Bennington County, Vermont",
		centroidLng: -73.0552000,
		centroidLat: 43.0270000
	},
	{
		geoid: "12019",
		name: "Clay",
		state: "Florida",
		countyState: "Clay County, Florida",
		centroidLng: -81.8153000,
		centroidLat: 29.9561000
	},
	{
		geoid: "37183",
		name: "Wake",
		state: "North Carolina",
		countyState: "Wake County, North Carolina",
		centroidLng: -78.6244000,
		centroidLat: 35.7979000
	},
	{
		geoid: "39061",
		name: "Hamilton",
		state: "Ohio",
		countyState: "Hamilton County, Ohio",
		centroidLng: -84.5384000,
		centroidLat: 39.1668000
	},
	{
		geoid: "34003",
		name: "Bergen",
		state: "New Jersey",
		countyState: "Bergen County, New Jersey",
		centroidLng: -74.0831000,
		centroidLat: 40.9477000
	},
	{
		geoid: "32017",
		name: "Lincoln",
		state: "Nevada",
		countyState: "Lincoln County, Nevada",
		centroidLng: -114.9730000,
		centroidLat: 37.7598000
	},
	{
		geoid: "02180",
		name: "Nome",
		state: "Alaska",
		countyState: "Nome Borough, Alaska",
		centroidLng: -168.0640000,
		centroidLat: 64.9763000
	},
	{
		geoid: "30055",
		name: "McCone",
		state: "Montana",
		countyState: "McCone County, Montana",
		centroidLng: -105.8130000,
		centroidLat: 47.5954000
	},
	{
		geoid: "19019",
		name: "Buchanan",
		state: "Iowa",
		countyState: "Buchanan County, Iowa",
		centroidLng: -91.8397000,
		centroidLat: 42.4701000
	},
	{
		geoid: "17043",
		name: "DuPage",
		state: "Illinois",
		countyState: "DuPage County, Illinois",
		centroidLng: -88.0887000,
		centroidLat: 41.8397000
	},
	{
		geoid: "46047",
		name: "Fall River",
		state: "South Dakota",
		countyState: "Fall River County, South Dakota",
		centroidLng: -103.5280000,
		centroidLat: 43.2397000
	},
	{
		geoid: "27069",
		name: "Kittson",
		state: "Minnesota",
		countyState: "Kittson County, Minnesota",
		centroidLng: -96.8129000,
		centroidLat: 48.7719000
	},
	{
		geoid: "13219",
		name: "Oconee",
		state: "Georgia",
		countyState: "Oconee County, Georgia",
		centroidLng: -83.4615000,
		centroidLat: 33.8321000
	},
	{
		geoid: "48493",
		name: "Wilson",
		state: "Texas",
		countyState: "Wilson County, Texas",
		centroidLng: -98.0679000,
		centroidLat: 29.1620000
	},
	{
		geoid: "31059",
		name: "Fillmore",
		state: "Nebraska",
		countyState: "Fillmore County, Nebraska",
		centroidLng: -97.5966000,
		centroidLat: 40.5246000
	},
	{
		geoid: "12079",
		name: "Madison",
		state: "Florida",
		countyState: "Madison County, Florida",
		centroidLng: -83.4964000,
		centroidLat: 30.4557000
	},
	{
		geoid: "72031",
		name: "Carolina",
		state: "Puerto Rico",
		countyState: "Carolina County, Puerto Rico",
		centroidLng: -65.9724000,
		centroidLat: 18.3934000
	},
	{
		geoid: "18153",
		name: "Sullivan",
		state: "Indiana",
		countyState: "Sullivan County, Indiana",
		centroidLng: -87.4496000,
		centroidLat: 39.0805000
	},
	{
		geoid: "04012",
		name: "La Paz",
		state: "Arizona",
		countyState: "La Paz County, Arizona",
		centroidLng: -114.0320000,
		centroidLat: 33.6723000
	},
	{
		geoid: "13027",
		name: "Brooks",
		state: "Georgia",
		countyState: "Brooks County, Georgia",
		centroidLng: -83.5451000,
		centroidLat: 30.8582000
	},
	{
		geoid: "02016",
		name: "Aleutians West",
		state: "Alaska",
		countyState: "Aleutians West Borough, Alaska",
		centroidLng: 175.9140000,
		centroidLat: 52.3607000
	},
	{
		geoid: "02150",
		name: "Kodiak Island",
		state: "Alaska",
		countyState: "Kodiak Island Borough, Alaska",
		centroidLng: -152.1590000,
		centroidLat: 58.9189000
	},
	{
		geoid: "20185",
		name: "Stafford",
		state: "Kansas",
		countyState: "Stafford County, Kansas",
		centroidLng: -98.7473000,
		centroidLat: 38.0428000
	},
	{
		geoid: "13271",
		name: "Telfair",
		state: "Georgia",
		countyState: "Telfair County, Georgia",
		centroidLng: -82.9266000,
		centroidLat: 31.9640000
	},
	{
		geoid: "26115",
		name: "Monroe",
		state: "Michigan",
		countyState: "Monroe County, Michigan",
		centroidLng: -83.4432000,
		centroidLat: 41.9089000
	},
	{
		geoid: "18181",
		name: "White",
		state: "Indiana",
		countyState: "White County, Indiana",
		centroidLng: -86.8401000,
		centroidLat: 40.7369000
	},
	{
		geoid: "18113",
		name: "Noble",
		state: "Indiana",
		countyState: "Noble County, Indiana",
		centroidLng: -85.4234000,
		centroidLat: 41.3952000
	},
	{
		geoid: "21139",
		name: "Livingston",
		state: "Kentucky",
		countyState: "Livingston County, Kentucky",
		centroidLng: -88.3767000,
		centroidLat: 37.2037000
	},
	{
		geoid: "13201",
		name: "Miller",
		state: "Georgia",
		countyState: "Miller County, Georgia",
		centroidLng: -84.7298000,
		centroidLat: 31.1637000
	},
	{
		geoid: "47103",
		name: "Lincoln",
		state: "Tennessee",
		countyState: "Lincoln County, Tennessee",
		centroidLng: -86.5771000,
		centroidLat: 35.1807000
	},
	{
		geoid: "21211",
		name: "Shelby",
		state: "Kentucky",
		countyState: "Shelby County, Kentucky",
		centroidLng: -85.2126000,
		centroidLat: 38.1974000
	},
	{
		geoid: "31185",
		name: "York",
		state: "Nebraska",
		countyState: "York County, Nebraska",
		centroidLng: -97.5972000,
		centroidLat: 40.8727000
	},
	{
		geoid: "42001",
		name: "Adams",
		state: "Pennsylvania",
		countyState: "Adams County, Pennsylvania",
		centroidLng: -77.2130000,
		centroidLat: 39.8949000
	},
	{
		geoid: "45005",
		name: "Allendale",
		state: "South Carolina",
		countyState: "Allendale County, South Carolina",
		centroidLng: -81.3482000,
		centroidLat: 32.9516000
	},
	{
		geoid: "47037",
		name: "Davidson",
		state: "Tennessee",
		countyState: "Davidson County, Tennessee",
		centroidLng: -86.7852000,
		centroidLat: 36.1866000
	},
	{
		geoid: "49047",
		name: "Uintah",
		state: "Utah",
		countyState: "Uintah County, Utah",
		centroidLng: -109.5480000,
		centroidLat: 40.1601000
	},
	{
		geoid: "49049",
		name: "Utah",
		state: "Utah",
		countyState: "Utah County, Utah",
		centroidLng: -111.5360000,
		centroidLat: 40.1771000
	},
	{
		geoid: "18021",
		name: "Clay",
		state: "Indiana",
		countyState: "Clay County, Indiana",
		centroidLng: -87.0904000,
		centroidLat: 39.3876000
	},
	{
		geoid: "36047",
		name: "Kings",
		state: "New York",
		countyState: "Kings County, New York",
		centroidLng: -73.9448000,
		centroidLat: 40.6452000
	},
	{
		geoid: "29141",
		name: "Morgan",
		state: "Missouri",
		countyState: "Morgan County, Missouri",
		centroidLng: -92.8514000,
		centroidLat: 38.4395000
	},
	{
		geoid: "39075",
		name: "Holmes",
		state: "Ohio",
		countyState: "Holmes County, Ohio",
		centroidLng: -81.9350000,
		centroidLat: 40.5561000
	},
	{
		geoid: "19143",
		name: "Osceola",
		state: "Iowa",
		countyState: "Osceola County, Iowa",
		centroidLng: -95.6247000,
		centroidLat: 43.3779000
	},
	{
		geoid: "18079",
		name: "Jennings",
		state: "Indiana",
		countyState: "Jennings County, Indiana",
		centroidLng: -85.6199000,
		centroidLat: 39.0013000
	},
	{
		geoid: "16083",
		name: "Twin Falls",
		state: "Idaho",
		countyState: "Twin Falls County, Idaho",
		centroidLng: -114.5190000,
		centroidLat: 42.4563000
	},
	{
		geoid: "17165",
		name: "Saline",
		state: "Illinois",
		countyState: "Saline County, Illinois",
		centroidLng: -88.5412000,
		centroidLat: 37.7534000
	},
	{
		geoid: "26077",
		name: "Kalamazoo",
		state: "Michigan",
		countyState: "Kalamazoo County, Michigan",
		centroidLng: -85.5298000,
		centroidLat: 42.2454000
	},
	{
		geoid: "45061",
		name: "Lee",
		state: "South Carolina",
		countyState: "Lee County, South Carolina",
		centroidLng: -80.2410000,
		centroidLat: 34.1600000
	},
	{
		geoid: "56019",
		name: "Johnson",
		state: "Wyoming",
		countyState: "Johnson County, Wyoming",
		centroidLng: -106.6900000,
		centroidLat: 44.0277000
	},
	{
		geoid: "18027",
		name: "Daviess",
		state: "Indiana",
		countyState: "Daviess County, Indiana",
		centroidLng: -87.0918000,
		centroidLat: 38.6973000
	},
	{
		geoid: "26055",
		name: "Grand Traverse",
		state: "Michigan",
		countyState: "Grand Traverse County, Michigan",
		centroidLng: -85.5753000,
		centroidLat: 44.8162000
	},
	{
		geoid: "22003",
		name: "Allen",
		state: "Louisiana",
		countyState: "Allen Parish, Louisiana",
		centroidLng: -92.8570000,
		centroidLat: 30.6615000
	},
	{
		geoid: "46065",
		name: "Hughes",
		state: "South Dakota",
		countyState: "Hughes County, South Dakota",
		centroidLng: -100.0900000,
		centroidLat: 44.3232000
	},
	{
		geoid: "46069",
		name: "Hyde",
		state: "South Dakota",
		countyState: "Hyde County, South Dakota",
		centroidLng: -99.4883000,
		centroidLat: 44.5450000
	},
	{
		geoid: "45027",
		name: "Clarendon",
		state: "South Carolina",
		countyState: "Clarendon County, South Carolina",
		centroidLng: -80.2081000,
		centroidLat: 33.6884000
	},
	{
		geoid: "30095",
		name: "Stillwater",
		state: "Montana",
		countyState: "Stillwater County, Montana",
		centroidLng: -109.4540000,
		centroidLat: 45.6499000
	},
	{
		geoid: "47007",
		name: "Bledsoe",
		state: "Tennessee",
		countyState: "Bledsoe County, Tennessee",
		centroidLng: -85.1761000,
		centroidLat: 35.5630000
	},
	{
		geoid: "21219",
		name: "Todd",
		state: "Kentucky",
		countyState: "Todd County, Kentucky",
		centroidLng: -87.1931000,
		centroidLat: 36.8587000
	},
	{
		geoid: "47183",
		name: "Weakley",
		state: "Tennessee",
		countyState: "Weakley County, Tennessee",
		centroidLng: -88.7381000,
		centroidLat: 36.2823000
	},
	{
		geoid: "29077",
		name: "Greene",
		state: "Missouri",
		countyState: "Greene County, Missouri",
		centroidLng: -93.3437000,
		centroidLat: 37.2581000
	},
	{
		geoid: "42077",
		name: "Lehigh",
		state: "Pennsylvania",
		countyState: "Lehigh County, Pennsylvania",
		centroidLng: -75.6125000,
		centroidLat: 40.6030000
	},
	{
		geoid: "31159",
		name: "Seward",
		state: "Nebraska",
		countyState: "Seward County, Nebraska",
		centroidLng: -97.1395000,
		centroidLat: 40.8724000
	},
	{
		geoid: "36015",
		name: "Chemung",
		state: "New York",
		countyState: "Chemung County, New York",
		centroidLng: -76.7509000,
		centroidLat: 42.1473000
	},
	{
		geoid: "36121",
		name: "Wyoming",
		state: "New York",
		countyState: "Wyoming County, New York",
		centroidLng: -78.2214000,
		centroidLat: 42.6948000
	},
	{
		geoid: "06099",
		name: "Stanislaus",
		state: "California",
		countyState: "Stanislaus County, California",
		centroidLng: -120.9370000,
		centroidLat: 37.6061000
	},
	{
		geoid: "42095",
		name: "Northampton",
		state: "Pennsylvania",
		countyState: "Northampton County, Pennsylvania",
		centroidLng: -75.3299000,
		centroidLat: 40.7527000
	},
	{
		geoid: "01103",
		name: "Morgan",
		state: "Alabama",
		countyState: "Morgan County, Alabama",
		centroidLng: -86.8300000,
		centroidLat: 34.4954000
	},
	{
		geoid: "21027",
		name: "Breckinridge",
		state: "Kentucky",
		countyState: "Breckinridge County, Kentucky",
		centroidLng: -86.4146000,
		centroidLat: 37.8023000
	},
	{
		geoid: "28043",
		name: "Grenada",
		state: "Mississippi",
		countyState: "Grenada County, Mississippi",
		centroidLng: -89.8219000,
		centroidLat: 33.7872000
	},
	{
		geoid: "21213",
		name: "Simpson",
		state: "Kentucky",
		countyState: "Simpson County, Kentucky",
		centroidLng: -86.5819000,
		centroidLat: 36.7583000
	},
	{
		geoid: "50013",
		name: "Grand Isle",
		state: "Vermont",
		countyState: "Grand Isle County, Vermont",
		centroidLng: -73.2908000,
		centroidLat: 44.7886000
	},
	{
		geoid: "39029",
		name: "Columbiana",
		state: "Ohio",
		countyState: "Columbiana County, Ohio",
		centroidLng: -80.8031000,
		centroidLat: 40.7563000
	},
	{
		geoid: "51125",
		name: "Nelson",
		state: "Virginia",
		countyState: "Nelson County, Virginia",
		centroidLng: -78.9065000,
		centroidLat: 37.7915000
	},
	{
		geoid: "12097",
		name: "Osceola",
		state: "Florida",
		countyState: "Osceola County, Florida",
		centroidLng: -81.2593000,
		centroidLat: 27.9953000
	},
	{
		geoid: "05053",
		name: "Grant",
		state: "Arkansas",
		countyState: "Grant County, Arkansas",
		centroidLng: -92.4432000,
		centroidLat: 34.2778000
	},
	{
		geoid: "13175",
		name: "Laurens",
		state: "Georgia",
		countyState: "Laurens County, Georgia",
		centroidLng: -82.9298000,
		centroidLat: 32.4315000
	},
	{
		geoid: "36061",
		name: "New York",
		state: "New York",
		countyState: "New York County, New York",
		centroidLng: -73.9708000,
		centroidLat: 40.7809000
	},
	{
		geoid: "05007",
		name: "Benton",
		state: "Arkansas",
		countyState: "Benton County, Arkansas",
		centroidLng: -94.2167000,
		centroidLat: 36.2996000
	},
	{
		geoid: "72071",
		name: "Isabela",
		state: "Puerto Rico",
		countyState: "Isabela County, Puerto Rico",
		centroidLng: -67.0134000,
		centroidLat: 18.4716000
	},
	{
		geoid: "12083",
		name: "Marion",
		state: "Florida",
		countyState: "Marion County, Florida",
		centroidLng: -82.0873000,
		centroidLat: 29.2407000
	},
	{
		geoid: "51135",
		name: "Nottoway",
		state: "Virginia",
		countyState: "Nottoway County, Virginia",
		centroidLng: -78.0176000,
		centroidLat: 37.1394000
	},
	{
		geoid: "34041",
		name: "Warren",
		state: "New Jersey",
		countyState: "Warren County, New Jersey",
		centroidLng: -74.9867000,
		centroidLat: 40.8431000
	},
	{
		geoid: "36021",
		name: "Columbia",
		state: "New York",
		countyState: "Columbia County, New York",
		centroidLng: -73.6410000,
		centroidLat: 42.2440000
	},
	{
		geoid: "51690",
		name: "Martinsville",
		state: "Virginia",
		countyState: "Martinsville County, Virginia",
		centroidLng: -79.8652000,
		centroidLat: 36.6796000
	},
	{
		geoid: "40023",
		name: "Choctaw",
		state: "Oklahoma",
		countyState: "Choctaw County, Oklahoma",
		centroidLng: -95.5737000,
		centroidLat: 34.0108000
	},
	{
		geoid: "20041",
		name: "Dickinson",
		state: "Kansas",
		countyState: "Dickinson County, Kansas",
		centroidLng: -97.1356000,
		centroidLat: 38.8712000
	},
	{
		geoid: "48427",
		name: "Starr",
		state: "Texas",
		countyState: "Starr County, Texas",
		centroidLng: -98.7463000,
		centroidLat: 26.5105000
	},
	{
		geoid: "13103",
		name: "Effingham",
		state: "Georgia",
		countyState: "Effingham County, Georgia",
		centroidLng: -81.3337000,
		centroidLat: 32.3456000
	},
	{
		geoid: "40129",
		name: "Roger Mills",
		state: "Oklahoma",
		countyState: "Roger Mills County, Oklahoma",
		centroidLng: -99.6822000,
		centroidLat: 35.7176000
	},
	{
		geoid: "55103",
		name: "Richland",
		state: "Wisconsin",
		countyState: "Richland County, Wisconsin",
		centroidLng: -90.4315000,
		centroidLat: 43.3597000
	},
	{
		geoid: "35025",
		name: "Lea",
		state: "New Mexico",
		countyState: "Lea County, New Mexico",
		centroidLng: -103.4340000,
		centroidLat: 32.7854000
	},
	{
		geoid: "01075",
		name: "Lamar",
		state: "Alabama",
		countyState: "Lamar County, Alabama",
		centroidLng: -88.1105000,
		centroidLat: 33.7912000
	},
	{
		geoid: "72054",
		name: "Florida",
		state: "Puerto Rico",
		countyState: "Florida County, Puerto Rico",
		centroidLng: -66.5611000,
		centroidLat: 18.3753000
	},
	{
		geoid: "47117",
		name: "Marshall",
		state: "Tennessee",
		countyState: "Marshall County, Tennessee",
		centroidLng: -86.7801000,
		centroidLat: 35.4819000
	},
	{
		geoid: "51011",
		name: "Appomattox",
		state: "Virginia",
		countyState: "Appomattox County, Virginia",
		centroidLng: -78.8080000,
		centroidLat: 37.3772000
	},
	{
		geoid: "05087",
		name: "Madison",
		state: "Arkansas",
		countyState: "Madison County, Arkansas",
		centroidLng: -93.7043000,
		centroidLat: 36.0336000
	},
	{
		geoid: "56033",
		name: "Sheridan",
		state: "Wyoming",
		countyState: "Sheridan County, Wyoming",
		centroidLng: -106.9610000,
		centroidLat: 44.7791000
	},
	{
		geoid: "42041",
		name: "Cumberland",
		state: "Pennsylvania",
		countyState: "Cumberland County, Pennsylvania",
		centroidLng: -77.2431000,
		centroidLat: 40.1363000
	},
	{
		geoid: "42055",
		name: "Franklin",
		state: "Pennsylvania",
		countyState: "Franklin County, Pennsylvania",
		centroidLng: -77.7768000,
		centroidLat: 40.0049000
	},
	{
		geoid: "42079",
		name: "Luzerne",
		state: "Pennsylvania",
		countyState: "Luzerne County, Pennsylvania",
		centroidLng: -75.9602000,
		centroidLat: 41.1646000
	},
	{
		geoid: "26039",
		name: "Crawford",
		state: "Michigan",
		countyState: "Crawford County, Michigan",
		centroidLng: -84.6108000,
		centroidLat: 44.6828000
	},
	{
		geoid: "01113",
		name: "Russell",
		state: "Alabama",
		countyState: "Russell County, Alabama",
		centroidLng: -85.1615000,
		centroidLat: 32.2859000
	},
	{
		geoid: "39019",
		name: "Carroll",
		state: "Ohio",
		countyState: "Carroll County, Ohio",
		centroidLng: -81.0912000,
		centroidLat: 40.5769000
	},
	{
		geoid: "51065",
		name: "Fluvanna",
		state: "Virginia",
		countyState: "Fluvanna County, Virginia",
		centroidLng: -78.2769000,
		centroidLat: 37.8484000
	},
	{
		geoid: "17097",
		name: "Lake",
		state: "Illinois",
		countyState: "Lake County, Illinois",
		centroidLng: -87.6098000,
		centroidLat: 42.3225000
	},
	{
		geoid: "13195",
		name: "Madison",
		state: "Georgia",
		countyState: "Madison County, Georgia",
		centroidLng: -83.1894000,
		centroidLat: 34.1362000
	},
	{
		geoid: "05039",
		name: "Dallas",
		state: "Arkansas",
		countyState: "Dallas County, Arkansas",
		centroidLng: -92.6478000,
		centroidLat: 33.9755000
	},
	{
		geoid: "08057",
		name: "Jackson",
		state: "Colorado",
		countyState: "Jackson County, Colorado",
		centroidLng: -106.3620000,
		centroidLat: 40.6630000
	},
	{
		geoid: "08063",
		name: "Kit Carson",
		state: "Colorado",
		countyState: "Kit Carson County, Colorado",
		centroidLng: -102.6050000,
		centroidLat: 39.3058000
	},
	{
		geoid: "16073",
		name: "Owyhee",
		state: "Idaho",
		countyState: "Owyhee County, Idaho",
		centroidLng: -116.0320000,
		centroidLat: 42.8385000
	},
	{
		geoid: "55141",
		name: "Wood",
		state: "Wisconsin",
		countyState: "Wood County, Wisconsin",
		centroidLng: -90.0212000,
		centroidLat: 44.4666000
	},
	{
		geoid: "36013",
		name: "Chautauqua",
		state: "New York",
		countyState: "Chautauqua County, New York",
		centroidLng: -79.4109000,
		centroidLat: 42.3425000
	},
	{
		geoid: "13067",
		name: "Cobb",
		state: "Georgia",
		countyState: "Cobb County, Georgia",
		centroidLng: -84.5572000,
		centroidLat: 33.9126000
	},
	{
		geoid: "47091",
		name: "Johnson",
		state: "Tennessee",
		countyState: "Johnson County, Tennessee",
		centroidLng: -81.8529000,
		centroidLat: 36.4380000
	},
	{
		geoid: "39013",
		name: "Belmont",
		state: "Ohio",
		countyState: "Belmont County, Ohio",
		centroidLng: -80.9700000,
		centroidLat: 40.0113000
	},
	{
		geoid: "51195",
		name: "Wise",
		state: "Virginia",
		countyState: "Wise County, Virginia",
		centroidLng: -82.5886000,
		centroidLat: 37.0009000
	},
	{
		geoid: "29035",
		name: "Carter",
		state: "Missouri",
		countyState: "Carter County, Missouri",
		centroidLng: -90.9430000,
		centroidLat: 36.9559000
	},
	{
		geoid: "40013",
		name: "Bryan",
		state: "Oklahoma",
		countyState: "Bryan County, Oklahoma",
		centroidLng: -96.1906000,
		centroidLat: 33.9219000
	},
	{
		geoid: "31035",
		name: "Clay",
		state: "Nebraska",
		countyState: "Clay County, Nebraska",
		centroidLng: -98.0512000,
		centroidLat: 40.5244000
	},
	{
		geoid: "24017",
		name: "Charles",
		state: "Maryland",
		countyState: "Charles County, Maryland",
		centroidLng: -76.9928000,
		centroidLat: 38.4388000
	},
	{
		geoid: "19033",
		name: "Cerro Gordo",
		state: "Iowa",
		countyState: "Cerro Gordo County, Iowa",
		centroidLng: -93.2608000,
		centroidLat: 43.0816000
	},
	{
		geoid: "19073",
		name: "Greene",
		state: "Iowa",
		countyState: "Greene County, Iowa",
		centroidLng: -94.3969000,
		centroidLat: 42.0363000
	},
	{
		geoid: "21205",
		name: "Rowan",
		state: "Kentucky",
		countyState: "Rowan County, Kentucky",
		centroidLng: -83.4171000,
		centroidLat: 38.2067000
	},
	{
		geoid: "49055",
		name: "Wayne",
		state: "Utah",
		countyState: "Wayne County, Utah",
		centroidLng: -110.8670000,
		centroidLat: 38.3301000
	},
	{
		geoid: "29031",
		name: "Cape Girardeau",
		state: "Missouri",
		countyState: "Cape Girardeau County, Missouri",
		centroidLng: -89.6437000,
		centroidLat: 37.3662000
	},
	{
		geoid: "53071",
		name: "Walla Walla",
		state: "Washington",
		countyState: "Walla Walla County, Washington",
		centroidLng: -118.5160000,
		centroidLat: 46.3033000
	},
	{
		geoid: "29133",
		name: "Mississippi",
		state: "Missouri",
		countyState: "Mississippi County, Missouri",
		centroidLng: -89.3094000,
		centroidLat: 36.8185000
	},
	{
		geoid: "05025",
		name: "Cleveland",
		state: "Arkansas",
		countyState: "Cleveland County, Arkansas",
		centroidLng: -92.2143000,
		centroidLat: 33.8842000
	},
	{
		geoid: "48113",
		name: "Dallas",
		state: "Texas",
		countyState: "Dallas County, Texas",
		centroidLng: -96.7776000,
		centroidLat: 32.7673000
	},
	{
		geoid: "72079",
		name: "Lajas",
		state: "Puerto Rico",
		countyState: "Lajas County, Puerto Rico",
		centroidLng: -67.0373000,
		centroidLat: 17.9751000
	},
	{
		geoid: "18033",
		name: "DeKalb",
		state: "Indiana",
		countyState: "DeKalb County, Indiana",
		centroidLng: -84.9988000,
		centroidLat: 41.3972000
	},
	{
		geoid: "22073",
		name: "Ouachita",
		state: "Louisiana",
		countyState: "Ouachita Parish, Louisiana",
		centroidLng: -92.1593000,
		centroidLat: 32.4908000
	},
	{
		geoid: "22095",
		name: "St. John the Baptist",
		state: "Louisiana",
		countyState: "St. John the Baptist Parish, Louisiana",
		centroidLng: -90.4214000,
		centroidLat: 30.0910000
	},
	{
		geoid: "22091",
		name: "St. Helena",
		state: "Louisiana",
		countyState: "St. Helena Parish, Louisiana",
		centroidLng: -90.7390000,
		centroidLat: 30.8245000
	},
	{
		geoid: "29197",
		name: "Schuyler",
		state: "Missouri",
		countyState: "Schuyler County, Missouri",
		centroidLng: -92.5356000,
		centroidLat: 40.4704000
	},
	{
		geoid: "01059",
		name: "Franklin",
		state: "Alabama",
		countyState: "Franklin County, Alabama",
		centroidLng: -87.8517000,
		centroidLat: 34.4431000
	},
	{
		geoid: "21041",
		name: "Carroll",
		state: "Kentucky",
		countyState: "Carroll County, Kentucky",
		centroidLng: -85.1337000,
		centroidLat: 38.6710000
	},
	{
		geoid: "08019",
		name: "Clear Creek",
		state: "Colorado",
		countyState: "Clear Creek County, Colorado",
		centroidLng: -105.6620000,
		centroidLat: 39.7084000
	},
	{
		geoid: "48267",
		name: "Kimble",
		state: "Texas",
		countyState: "Kimble County, Texas",
		centroidLng: -99.7093000,
		centroidLat: 30.4988000
	},
	{
		geoid: "26097",
		name: "Mackinac",
		state: "Michigan",
		countyState: "Mackinac County, Michigan",
		centroidLng: -84.9912000,
		centroidLat: 45.9691000
	},
	{
		geoid: "48307",
		name: "McCulloch",
		state: "Texas",
		countyState: "McCulloch County, Texas",
		centroidLng: -99.3472000,
		centroidLat: 31.2171000
	},
	{
		geoid: "28141",
		name: "Tishomingo",
		state: "Mississippi",
		countyState: "Tishomingo County, Mississippi",
		centroidLng: -88.2319000,
		centroidLat: 34.7294000
	},
	{
		geoid: "48105",
		name: "Crockett",
		state: "Texas",
		countyState: "Crockett County, Texas",
		centroidLng: -101.6760000,
		centroidLat: 30.6872000
	},
	{
		geoid: "48217",
		name: "Hill",
		state: "Texas",
		countyState: "Hill County, Texas",
		centroidLng: -97.1081000,
		centroidLat: 31.9872000
	},
	{
		geoid: "08075",
		name: "Logan",
		state: "Colorado",
		countyState: "Logan County, Colorado",
		centroidLng: -103.1160000,
		centroidLat: 40.7189000
	},
	{
		geoid: "20055",
		name: "Finney",
		state: "Kansas",
		countyState: "Finney County, Kansas",
		centroidLng: -100.6640000,
		centroidLat: 38.0007000
	},
	{
		geoid: "20125",
		name: "Montgomery",
		state: "Kansas",
		countyState: "Montgomery County, Kansas",
		centroidLng: -95.7414000,
		centroidLat: 37.1929000
	},
	{
		geoid: "45039",
		name: "Fairfield",
		state: "South Carolina",
		countyState: "Fairfield County, South Carolina",
		centroidLng: -81.0987000,
		centroidLat: 34.3733000
	},
	{
		geoid: "31033",
		name: "Cheyenne",
		state: "Nebraska",
		countyState: "Cheyenne County, Nebraska",
		centroidLng: -102.9960000,
		centroidLat: 41.2206000
	},
	{
		geoid: "27091",
		name: "Martin",
		state: "Minnesota",
		countyState: "Martin County, Minnesota",
		centroidLng: -94.5509000,
		centroidLat: 43.6743000
	},
	{
		geoid: "47155",
		name: "Sevier",
		state: "Tennessee",
		countyState: "Sevier County, Tennessee",
		centroidLng: -83.5229000,
		centroidLat: 35.8025000
	},
	{
		geoid: "47139",
		name: "Polk",
		state: "Tennessee",
		countyState: "Polk County, Tennessee",
		centroidLng: -84.5341000,
		centroidLat: 35.1367000
	},
	{
		geoid: "48263",
		name: "Kent",
		state: "Texas",
		countyState: "Kent County, Texas",
		centroidLng: -100.7780000,
		centroidLat: 33.1810000
	},
	{
		geoid: "30099",
		name: "Teton",
		state: "Montana",
		countyState: "Teton County, Montana",
		centroidLng: -112.2120000,
		centroidLat: 47.8167000
	},
	{
		geoid: "31069",
		name: "Garden",
		state: "Nebraska",
		countyState: "Garden County, Nebraska",
		centroidLng: -102.3320000,
		centroidLat: 41.6153000
	},
	{
		geoid: "17117",
		name: "Macoupin",
		state: "Illinois",
		countyState: "Macoupin County, Illinois",
		centroidLng: -89.9261000,
		centroidLat: 39.2609000
	},
	{
		geoid: "39141",
		name: "Ross",
		state: "Ohio",
		countyState: "Ross County, Ohio",
		centroidLng: -83.0673000,
		centroidLat: 39.3420000
	},
	{
		geoid: "17195",
		name: "Whiteside",
		state: "Illinois",
		countyState: "Whiteside County, Illinois",
		centroidLng: -89.9382000,
		centroidLat: 41.7575000
	},
	{
		geoid: "48141",
		name: "El Paso",
		state: "Texas",
		countyState: "El Paso County, Texas",
		centroidLng: -106.3000000,
		centroidLat: 31.6949000
	},
	{
		geoid: "48301",
		name: "Loving",
		state: "Texas",
		countyState: "Loving County, Texas",
		centroidLng: -103.6550000,
		centroidLat: 31.8255000
	},
	{
		geoid: "40077",
		name: "Latimer",
		state: "Oklahoma",
		countyState: "Latimer County, Oklahoma",
		centroidLng: -95.2210000,
		centroidLat: 34.8695000
	},
	{
		geoid: "39167",
		name: "Washington",
		state: "Ohio",
		countyState: "Washington County, Ohio",
		centroidLng: -81.4452000,
		centroidLat: 39.4309000
	},
	{
		geoid: "29091",
		name: "Howell",
		state: "Missouri",
		countyState: "Howell County, Missouri",
		centroidLng: -91.8880000,
		centroidLat: 36.7773000
	},
	{
		geoid: "20047",
		name: "Edwards",
		state: "Kansas",
		countyState: "Edwards County, Kansas",
		centroidLng: -99.2919000,
		centroidLat: 37.9106000
	},
	{
		geoid: "21111",
		name: "Jefferson",
		state: "Kentucky",
		countyState: "Jefferson County, Kentucky",
		centroidLng: -85.6760000,
		centroidLat: 38.1887000
	},
	{
		geoid: "17107",
		name: "Logan",
		state: "Illinois",
		countyState: "Logan County, Illinois",
		centroidLng: -89.3737000,
		centroidLat: 40.1211000
	},
	{
		geoid: "55139",
		name: "Winnebago",
		state: "Wisconsin",
		countyState: "Winnebago County, Wisconsin",
		centroidLng: -88.6450000,
		centroidLat: 44.0687000
	},
	{
		geoid: "21075",
		name: "Fulton",
		state: "Kentucky",
		countyState: "Fulton County, Kentucky",
		centroidLng: -89.1259000,
		centroidLat: 36.5747000
	},
	{
		geoid: "20079",
		name: "Harvey",
		state: "Kansas",
		countyState: "Harvey County, Kansas",
		centroidLng: -97.4273000,
		centroidLat: 38.0432000
	},
	{
		geoid: "30021",
		name: "Dawson",
		state: "Montana",
		countyState: "Dawson County, Montana",
		centroidLng: -104.8560000,
		centroidLat: 47.3257000
	},
	{
		geoid: "54091",
		name: "Taylor",
		state: "West Virginia",
		countyState: "Taylor County, West Virginia",
		centroidLng: -80.0499000,
		centroidLat: 39.3454000
	},
	{
		geoid: "26111",
		name: "Midland",
		state: "Michigan",
		countyState: "Midland County, Michigan",
		centroidLng: -84.3875000,
		centroidLat: 43.6472000
	},
	{
		geoid: "28119",
		name: "Quitman",
		state: "Mississippi",
		countyState: "Quitman County, Mississippi",
		centroidLng: -90.2927000,
		centroidLat: 34.2917000
	},
	{
		geoid: "21093",
		name: "Hardin",
		state: "Kentucky",
		countyState: "Hardin County, Kentucky",
		centroidLng: -85.9768000,
		centroidLat: 37.7227000
	},
	{
		geoid: "17081",
		name: "Jefferson",
		state: "Illinois",
		countyState: "Jefferson County, Illinois",
		centroidLng: -88.9244000,
		centroidLat: 38.3006000
	},
	{
		geoid: "46057",
		name: "Hamlin",
		state: "South Dakota",
		countyState: "Hamlin County, South Dakota",
		centroidLng: -97.1884000,
		centroidLat: 44.6737000
	},
	{
		geoid: "55069",
		name: "Lincoln",
		state: "Wisconsin",
		countyState: "Lincoln County, Wisconsin",
		centroidLng: -89.7354000,
		centroidLat: 45.3375000
	},
	{
		geoid: "36033",
		name: "Franklin",
		state: "New York",
		countyState: "Franklin County, New York",
		centroidLng: -74.3180000,
		centroidLat: 44.5527000
	},
	{
		geoid: "51630",
		name: "Fredericksburg",
		state: "Virginia",
		countyState: "Fredericksburg County, Virginia",
		centroidLng: -77.4899000,
		centroidLat: 38.2983000
	},
	{
		geoid: "54081",
		name: "Raleigh",
		state: "West Virginia",
		countyState: "Raleigh County, West Virginia",
		centroidLng: -81.2245000,
		centroidLat: 37.7489000
	},
	{
		geoid: "35005",
		name: "Chaves",
		state: "New Mexico",
		countyState: "Chaves County, New Mexico",
		centroidLng: -104.4320000,
		centroidLat: 33.3038000
	},
	{
		geoid: "35049",
		name: "Santa Fe",
		state: "New Mexico",
		countyState: "Santa Fe County, New Mexico",
		centroidLng: -105.9820000,
		centroidLat: 35.5212000
	},
	{
		geoid: "06059",
		name: "Orange",
		state: "California",
		countyState: "Orange County, California",
		centroidLng: -117.7690000,
		centroidLat: 33.6408000
	},
	{
		geoid: "04023",
		name: "Santa Cruz",
		state: "Arizona",
		countyState: "Santa Cruz County, Arizona",
		centroidLng: -110.9090000,
		centroidLat: 31.5320000
	},
	{
		geoid: "37053",
		name: "Currituck",
		state: "North Carolina",
		countyState: "Currituck County, North Carolina",
		centroidLng: -76.0112000,
		centroidLat: 36.2899000
	},
	{
		geoid: "17101",
		name: "Lawrence",
		state: "Illinois",
		countyState: "Lawrence County, Illinois",
		centroidLng: -87.7038000,
		centroidLat: 38.7103000
	},
	{
		geoid: "18067",
		name: "Howard",
		state: "Indiana",
		countyState: "Howard County, Indiana",
		centroidLng: -86.1189000,
		centroidLat: 40.4696000
	},
	{
		geoid: "18125",
		name: "Pike",
		state: "Indiana",
		countyState: "Pike County, Indiana",
		centroidLng: -87.2676000,
		centroidLat: 38.3916000
	},
	{
		geoid: "21047",
		name: "Christian",
		state: "Kentucky",
		countyState: "Christian County, Kentucky",
		centroidLng: -87.4964000,
		centroidLat: 36.8982000
	},
	{
		geoid: "26045",
		name: "Eaton",
		state: "Michigan",
		countyState: "Eaton County, Michigan",
		centroidLng: -84.8380000,
		centroidLat: 42.5961000
	},
	{
		geoid: "19179",
		name: "Wapello",
		state: "Iowa",
		countyState: "Wapello County, Iowa",
		centroidLng: -92.4097000,
		centroidLat: 41.0308000
	},
	{
		geoid: "06025",
		name: "Imperial",
		state: "California",
		countyState: "Imperial County, California",
		centroidLng: -115.2850000,
		centroidLat: 33.0262000
	},
	{
		geoid: "26027",
		name: "Cass",
		state: "Michigan",
		countyState: "Cass County, Michigan",
		centroidLng: -85.9931000,
		centroidLat: 41.9153000
	},
	{
		geoid: "28157",
		name: "Wilkinson",
		state: "Mississippi",
		countyState: "Wilkinson County, Mississippi",
		centroidLng: -91.3479000,
		centroidLat: 31.1859000
	},
	{
		geoid: "02282",
		name: "Yakutat",
		state: "Alaska",
		countyState: "Yakutat Borough, Alaska",
		centroidLng: -140.6970000,
		centroidLat: 59.6651000
	},
	{
		geoid: "20179",
		name: "Sheridan",
		state: "Kansas",
		countyState: "Sheridan County, Kansas",
		centroidLng: -100.4420000,
		centroidLat: 39.3500000
	},
	{
		geoid: "28079",
		name: "Leake",
		state: "Mississippi",
		countyState: "Leake County, Mississippi",
		centroidLng: -89.5239000,
		centroidLat: 32.7539000
	},
	{
		geoid: "72025",
		name: "Caguas",
		state: "Puerto Rico",
		countyState: "Caguas County, Puerto Rico",
		centroidLng: -66.0578000,
		centroidLat: 18.2128000
	},
	{
		geoid: "72027",
		name: "Camuy",
		state: "Puerto Rico",
		countyState: "Camuy County, Puerto Rico",
		centroidLng: -66.8564000,
		centroidLat: 18.4428000
	},
	{
		geoid: "28025",
		name: "Clay",
		state: "Mississippi",
		countyState: "Clay County, Mississippi",
		centroidLng: -88.7540000,
		centroidLat: 33.6593000
	},
	{
		geoid: "28037",
		name: "Franklin",
		state: "Mississippi",
		countyState: "Franklin County, Mississippi",
		centroidLng: -90.8954000,
		centroidLat: 31.4657000
	},
	{
		geoid: "28099",
		name: "Neshoba",
		state: "Mississippi",
		countyState: "Neshoba County, Mississippi",
		centroidLng: -89.1168000,
		centroidLat: 32.7544000
	},
	{
		geoid: "46045",
		name: "Edmunds",
		state: "South Dakota",
		countyState: "Edmunds County, South Dakota",
		centroidLng: -99.2156000,
		centroidLat: 45.4186000
	},
	{
		geoid: "72119",
		name: "R?o Grande",
		state: "Puerto Rico",
		countyState: "R?o Grande County, Puerto Rico",
		centroidLng: -65.7953000,
		centroidLat: 18.3750000
	},
	{
		geoid: "01035",
		name: "Conecuh",
		state: "Alabama",
		countyState: "Conecuh County, Alabama",
		centroidLng: -87.0448000,
		centroidLat: 31.4671000
	},
	{
		geoid: "18029",
		name: "Dearborn",
		state: "Indiana",
		countyState: "Dearborn County, Indiana",
		centroidLng: -84.9759000,
		centroidLat: 39.1213000
	},
	{
		geoid: "18071",
		name: "Jackson",
		state: "Indiana",
		countyState: "Jackson County, Indiana",
		centroidLng: -86.0567000,
		centroidLat: 38.8980000
	},
	{
		geoid: "28081",
		name: "Lee",
		state: "Mississippi",
		countyState: "Lee County, Mississippi",
		centroidLng: -88.6837000,
		centroidLat: 34.2920000
	},
	{
		geoid: "22125",
		name: "West Feliciana",
		state: "Louisiana",
		countyState: "West Feliciana Parish, Louisiana",
		centroidLng: -91.6969000,
		centroidLat: 31.0196000
	},
	{
		geoid: "05035",
		name: "Crittenden",
		state: "Arkansas",
		countyState: "Crittenden County, Arkansas",
		centroidLng: -90.2855000,
		centroidLat: 35.1374000
	},
	{
		geoid: "12063",
		name: "Jackson",
		state: "Florida",
		countyState: "Jackson County, Florida",
		centroidLng: -85.2291000,
		centroidLat: 30.7827000
	},
	{
		geoid: "48125",
		name: "Dickens",
		state: "Texas",
		countyState: "Dickens County, Texas",
		centroidLng: -100.7790000,
		centroidLat: 33.6164000
	},
	{
		geoid: "51115",
		name: "Mathews",
		state: "Virginia",
		countyState: "Mathews County, Virginia",
		centroidLng: -76.2941000,
		centroidLat: 37.4103000
	},
	{
		geoid: "38075",
		name: "Renville",
		state: "North Dakota",
		countyState: "Renville County, North Dakota",
		centroidLng: -101.5410000,
		centroidLat: 48.7290000
	},
	{
		geoid: "48283",
		name: "La Salle",
		state: "Texas",
		countyState: "La Salle County, Texas",
		centroidLng: -99.0984000,
		centroidLat: 28.3390000
	},
	{
		geoid: "36067",
		name: "Onondaga",
		state: "New York",
		countyState: "Onondaga County, New York",
		centroidLng: -76.1977000,
		centroidLat: 43.0214000
	},
	{
		geoid: "19087",
		name: "Henry",
		state: "Iowa",
		countyState: "Henry County, Iowa",
		centroidLng: -91.5445000,
		centroidLat: 40.9881000
	},
	{
		geoid: "31083",
		name: "Harlan",
		state: "Nebraska",
		countyState: "Harlan County, Nebraska",
		centroidLng: -99.4048000,
		centroidLat: 40.1765000
	},
	{
		geoid: "30075",
		name: "Powder River",
		state: "Montana",
		countyState: "Powder River County, Montana",
		centroidLng: -105.6310000,
		centroidLat: 45.3910000
	},
	{
		geoid: "72075",
		name: "Juana D?az",
		state: "Puerto Rico",
		countyState: "Juana D?az County, Puerto Rico",
		centroidLng: -66.4931000,
		centroidLat: 17.9974000
	},
	{
		geoid: "55091",
		name: "Pepin",
		state: "Wisconsin",
		countyState: "Pepin County, Wisconsin",
		centroidLng: -91.9832000,
		centroidLat: 44.5459000
	},
	{
		geoid: "04001",
		name: "Apache",
		state: "Arizona",
		countyState: "Apache County, Arizona",
		centroidLng: -109.5230000,
		centroidLat: 35.2374000
	},
	{
		geoid: "29121",
		name: "Macon",
		state: "Missouri",
		countyState: "Macon County, Missouri",
		centroidLng: -92.5729000,
		centroidLat: 39.8218000
	},
	{
		geoid: "48279",
		name: "Lamb",
		state: "Texas",
		countyState: "Lamb County, Texas",
		centroidLng: -102.3510000,
		centroidLat: 34.0689000
	},
	{
		geoid: "20097",
		name: "Kiowa",
		state: "Kansas",
		countyState: "Kiowa County, Kansas",
		centroidLng: -99.2856000,
		centroidLat: 37.5580000
	},
	{
		geoid: "48015",
		name: "Austin",
		state: "Texas",
		countyState: "Austin County, Texas",
		centroidLng: -96.3133000,
		centroidLat: 29.8492000
	},
	{
		geoid: "29061",
		name: "Daviess",
		state: "Missouri",
		countyState: "Daviess County, Missouri",
		centroidLng: -93.9888000,
		centroidLat: 39.9607000
	},
	{
		geoid: "13253",
		name: "Seminole",
		state: "Georgia",
		countyState: "Seminole County, Georgia",
		centroidLng: -84.8797000,
		centroidLat: 30.8935000
	},
	{
		geoid: "26101",
		name: "Manistee",
		state: "Michigan",
		countyState: "Manistee County, Michigan",
		centroidLng: -86.4028000,
		centroidLat: 44.3419000
	},
	{
		geoid: "19119",
		name: "Lyon",
		state: "Iowa",
		countyState: "Lyon County, Iowa",
		centroidLng: -96.2317000,
		centroidLat: 43.3789000
	},
	{
		geoid: "48233",
		name: "Hutchinson",
		state: "Texas",
		countyState: "Hutchinson County, Texas",
		centroidLng: -101.3550000,
		centroidLat: 35.8397000
	},
	{
		geoid: "40139",
		name: "Texas",
		state: "Oklahoma",
		countyState: "Texas County, Oklahoma",
		centroidLng: -101.4890000,
		centroidLat: 36.7488000
	},
	{
		geoid: "40153",
		name: "Woodward",
		state: "Oklahoma",
		countyState: "Woodward County, Oklahoma",
		centroidLng: -99.2808000,
		centroidLat: 36.4906000
	},
	{
		geoid: "19003",
		name: "Adams",
		state: "Iowa",
		countyState: "Adams County, Iowa",
		centroidLng: -94.6995000,
		centroidLat: 41.0289000
	},
	{
		geoid: "06101",
		name: "Sutter",
		state: "California",
		countyState: "Sutter County, California",
		centroidLng: -121.6810000,
		centroidLat: 39.0201000
	},
	{
		geoid: "42105",
		name: "Potter",
		state: "Pennsylvania",
		countyState: "Potter County, Pennsylvania",
		centroidLng: -77.9024000,
		centroidLat: 41.7376000
	},
	{
		geoid: "48465",
		name: "Val Verde",
		state: "Texas",
		countyState: "Val Verde County, Texas",
		centroidLng: -101.2300000,
		centroidLat: 29.7631000
	},
	{
		geoid: "20077",
		name: "Harper",
		state: "Kansas",
		countyState: "Harper County, Kansas",
		centroidLng: -98.0759000,
		centroidLat: 37.1920000
	},
	{
		geoid: "19133",
		name: "Monona",
		state: "Iowa",
		countyState: "Monona County, Iowa",
		centroidLng: -96.0152000,
		centroidLat: 42.0391000
	},
	{
		geoid: "49023",
		name: "Juab",
		state: "Utah",
		countyState: "Juab County, Utah",
		centroidLng: -112.8160000,
		centroidLat: 39.6631000
	},
	{
		geoid: "40137",
		name: "Stephens",
		state: "Oklahoma",
		countyState: "Stephens County, Oklahoma",
		centroidLng: -97.8522000,
		centroidLat: 34.4854000
	},
	{
		geoid: "36017",
		name: "Chenango",
		state: "New York",
		countyState: "Chenango County, New York",
		centroidLng: -75.5913000,
		centroidLat: 42.4702000
	},
	{
		geoid: "17143",
		name: "Peoria",
		state: "Illinois",
		countyState: "Peoria County, Illinois",
		centroidLng: -89.7184000,
		centroidLat: 40.7441000
	},
	{
		geoid: "19091",
		name: "Humboldt",
		state: "Iowa",
		countyState: "Humboldt County, Iowa",
		centroidLng: -94.2072000,
		centroidLat: 42.7763000
	},
	{
		geoid: "31177",
		name: "Washington",
		state: "Nebraska",
		countyState: "Washington County, Nebraska",
		centroidLng: -96.1822000,
		centroidLat: 41.5372000
	},
	{
		geoid: "55049",
		name: "Iowa",
		state: "Wisconsin",
		countyState: "Iowa County, Wisconsin",
		centroidLng: -90.1339000,
		centroidLat: 43.0113000
	},
	{
		geoid: "27097",
		name: "Morrison",
		state: "Minnesota",
		countyState: "Morrison County, Minnesota",
		centroidLng: -94.2087000,
		centroidLat: 46.0613000
	},
	{
		geoid: "42003",
		name: "Allegheny",
		state: "Pennsylvania",
		countyState: "Allegheny County, Pennsylvania",
		centroidLng: -80.0248000,
		centroidLat: 40.4346000
	},
	{
		geoid: "29205",
		name: "Shelby",
		state: "Missouri",
		countyState: "Shelby County, Missouri",
		centroidLng: -92.0698000,
		centroidLat: 39.7793000
	},
	{
		geoid: "39097",
		name: "Madison",
		state: "Ohio",
		countyState: "Madison County, Ohio",
		centroidLng: -83.4298000,
		centroidLat: 39.9035000
	},
	{
		geoid: "20201",
		name: "Washington",
		state: "Kansas",
		countyState: "Washington County, Kansas",
		centroidLng: -97.0875000,
		centroidLat: 39.7842000
	},
	{
		geoid: "17019",
		name: "Champaign",
		state: "Illinois",
		countyState: "Champaign County, Illinois",
		centroidLng: -88.1962000,
		centroidLat: 40.1399000
	},
	{
		geoid: "16087",
		name: "Washington",
		state: "Idaho",
		countyState: "Washington County, Idaho",
		centroidLng: -116.7710000,
		centroidLat: 44.4958000
	},
	{
		geoid: "17045",
		name: "Edgar",
		state: "Illinois",
		countyState: "Edgar County, Illinois",
		centroidLng: -87.7502000,
		centroidLat: 39.6798000
	},
	{
		geoid: "08121",
		name: "Washington",
		state: "Colorado",
		countyState: "Washington County, Colorado",
		centroidLng: -103.2440000,
		centroidLat: 40.0021000
	},
	{
		geoid: "39065",
		name: "Hardin",
		state: "Ohio",
		countyState: "Hardin County, Ohio",
		centroidLng: -83.6479000,
		centroidLat: 40.6620000
	},
	{
		geoid: "26113",
		name: "Missaukee",
		state: "Michigan",
		countyState: "Missaukee County, Michigan",
		centroidLng: -85.0947000,
		centroidLat: 44.3369000
	},
	{
		geoid: "38093",
		name: "Stutsman",
		state: "North Dakota",
		countyState: "Stutsman County, North Dakota",
		centroidLng: -98.9601000,
		centroidLat: 46.9780000
	},
	{
		geoid: "29203",
		name: "Shannon",
		state: "Missouri",
		countyState: "Shannon County, Missouri",
		centroidLng: -91.3375000,
		centroidLat: 37.1533000
	},
	{
		geoid: "48423",
		name: "Smith",
		state: "Texas",
		countyState: "Smith County, Texas",
		centroidLng: -95.2899000,
		centroidLat: 32.4112000
	},
	{
		geoid: "40143",
		name: "Tulsa",
		state: "Oklahoma",
		countyState: "Tulsa County, Oklahoma",
		centroidLng: -96.0298000,
		centroidLat: 36.1398000
	},
	{
		geoid: "40091",
		name: "McIntosh",
		state: "Oklahoma",
		countyState: "McIntosh County, Oklahoma",
		centroidLng: -95.6641000,
		centroidLat: 35.3474000
	},
	{
		geoid: "48395",
		name: "Robertson",
		state: "Texas",
		countyState: "Robertson County, Texas",
		centroidLng: -96.5329000,
		centroidLat: 31.0264000
	},
	{
		geoid: "48171",
		name: "Gillespie",
		state: "Texas",
		countyState: "Gillespie County, Texas",
		centroidLng: -98.9459000,
		centroidLat: 30.3171000
	},
	{
		geoid: "26125",
		name: "Oakland",
		state: "Michigan",
		countyState: "Oakland County, Michigan",
		centroidLng: -83.3864000,
		centroidLat: 42.6599000
	},
	{
		geoid: "38033",
		name: "Golden Valley",
		state: "North Dakota",
		countyState: "Golden Valley County, North Dakota",
		centroidLng: -103.8280000,
		centroidLat: 46.9355000
	},
	{
		geoid: "56027",
		name: "Niobrara",
		state: "Wyoming",
		countyState: "Niobrara County, Wyoming",
		centroidLng: -104.4760000,
		centroidLat: 43.0561000
	},
	{
		geoid: "27117",
		name: "Pipestone",
		state: "Minnesota",
		countyState: "Pipestone County, Minnesota",
		centroidLng: -96.2587000,
		centroidLat: 44.0231000
	},
	{
		geoid: "51099",
		name: "King George",
		state: "Virginia",
		countyState: "King George County, Virginia",
		centroidLng: -77.1726000,
		centroidLat: 38.2711000
	},
	{
		geoid: "53025",
		name: "Grant",
		state: "Washington",
		countyState: "Grant County, Washington",
		centroidLng: -119.5080000,
		centroidLat: 47.2938000
	},
	{
		geoid: "27129",
		name: "Renville",
		state: "Minnesota",
		countyState: "Renville County, Minnesota",
		centroidLng: -94.9911000,
		centroidLat: 44.6742000
	},
	{
		geoid: "12001",
		name: "Alachua",
		state: "Florida",
		countyState: "Alachua County, Florida",
		centroidLng: -82.3538000,
		centroidLat: 29.6812000
	},
	{
		geoid: "31091",
		name: "Hooker",
		state: "Nebraska",
		countyState: "Hooker County, Nebraska",
		centroidLng: -101.1350000,
		centroidLat: 41.9160000
	},
	{
		geoid: "51147",
		name: "Prince Edward",
		state: "Virginia",
		countyState: "Prince Edward County, Virginia",
		centroidLng: -78.4619000,
		centroidLat: 37.2381000
	},
	{
		geoid: "49007",
		name: "Carbon",
		state: "Utah",
		countyState: "Carbon County, Utah",
		centroidLng: -110.5610000,
		centroidLat: 39.6408000
	},
	{
		geoid: "42017",
		name: "Bucks",
		state: "Pennsylvania",
		countyState: "Bucks County, Pennsylvania",
		centroidLng: -75.1028000,
		centroidLat: 40.3286000
	},
	{
		geoid: "46059",
		name: "Hand",
		state: "South Dakota",
		countyState: "Hand County, South Dakota",
		centroidLng: -99.0065000,
		centroidLat: 44.5459000
	},
	{
		geoid: "42081",
		name: "Lycoming",
		state: "Pennsylvania",
		countyState: "Lycoming County, Pennsylvania",
		centroidLng: -77.0235000,
		centroidLat: 41.3328000
	},
	{
		geoid: "16057",
		name: "Latah",
		state: "Idaho",
		countyState: "Latah County, Idaho",
		centroidLng: -116.6850000,
		centroidLat: 46.8363000
	},
	{
		geoid: "13237",
		name: "Putnam",
		state: "Georgia",
		countyState: "Putnam County, Georgia",
		centroidLng: -83.3447000,
		centroidLat: 33.3255000
	},
	{
		geoid: "51640",
		name: "Galax",
		state: "Virginia",
		countyState: "Galax County, Virginia",
		centroidLng: -80.9137000,
		centroidLat: 36.6605000
	},
	{
		geoid: "35037",
		name: "Quay",
		state: "New Mexico",
		countyState: "Quay County, New Mexico",
		centroidLng: -103.5850000,
		centroidLat: 35.1722000
	},
	{
		geoid: "48269",
		name: "King",
		state: "Texas",
		countyState: "King County, Texas",
		centroidLng: -100.2550000,
		centroidLat: 33.6166000
	},
	{
		geoid: "05073",
		name: "Lafayette",
		state: "Arkansas",
		countyState: "Lafayette County, Arkansas",
		centroidLng: -93.6130000,
		centroidLat: 33.2502000
	},
	{
		geoid: "48451",
		name: "Tom Green",
		state: "Texas",
		countyState: "Tom Green County, Texas",
		centroidLng: -100.6900000,
		centroidLat: 31.3958000
	},
	{
		geoid: "12049",
		name: "Hardee",
		state: "Florida",
		countyState: "Hardee County, Florida",
		centroidLng: -81.8095000,
		centroidLat: 27.4917000
	},
	{
		geoid: "39025",
		name: "Clermont",
		state: "Ohio",
		countyState: "Clermont County, Ohio",
		centroidLng: -84.1566000,
		centroidLat: 39.0206000
	},
	{
		geoid: "48501",
		name: "Yoakum",
		state: "Texas",
		countyState: "Yoakum County, Texas",
		centroidLng: -102.8290000,
		centroidLat: 33.1735000
	},
	{
		geoid: "41071",
		name: "Yamhill",
		state: "Oregon",
		countyState: "Yamhill County, Oregon",
		centroidLng: -123.3180000,
		centroidLat: 45.2544000
	},
	{
		geoid: "19115",
		name: "Louisa",
		state: "Iowa",
		countyState: "Louisa County, Iowa",
		centroidLng: -91.2161000,
		centroidLat: 41.2485000
	},
	{
		geoid: "20049",
		name: "Elk",
		state: "Kansas",
		countyState: "Elk County, Kansas",
		centroidLng: -96.2437000,
		centroidLat: 37.4532000
	},
	{
		geoid: "39121",
		name: "Noble",
		state: "Ohio",
		countyState: "Noble County, Ohio",
		centroidLng: -81.4658000,
		centroidLat: 39.7685000
	},
	{
		geoid: "39137",
		name: "Putnam",
		state: "Ohio",
		countyState: "Putnam County, Ohio",
		centroidLng: -84.1400000,
		centroidLat: 41.0135000
	},
	{
		geoid: "48425",
		name: "Somervell",
		state: "Texas",
		countyState: "Somervell County, Texas",
		centroidLng: -97.7800000,
		centroidLat: 32.2030000
	},
	{
		geoid: "42075",
		name: "Lebanon",
		state: "Pennsylvania",
		countyState: "Lebanon County, Pennsylvania",
		centroidLng: -76.4146000,
		centroidLat: 40.3754000
	},
	{
		geoid: "30035",
		name: "Glacier",
		state: "Montana",
		countyState: "Glacier County, Montana",
		centroidLng: -113.1260000,
		centroidLat: 48.6543000
	},
	{
		geoid: "32027",
		name: "Pershing",
		state: "Nevada",
		countyState: "Pershing County, Nevada",
		centroidLng: -118.3200000,
		centroidLat: 40.4797000
	},
	{
		geoid: "27087",
		name: "Mahnomen",
		state: "Minnesota",
		countyState: "Mahnomen County, Minnesota",
		centroidLng: -95.8093000,
		centroidLat: 47.3252000
	},
	{
		geoid: "34031",
		name: "Passaic",
		state: "New Jersey",
		countyState: "Passaic County, New Jersey",
		centroidLng: -74.3050000,
		centroidLat: 41.0116000
	},
	{
		geoid: "30047",
		name: "Lake",
		state: "Montana",
		countyState: "Lake County, Montana",
		centroidLng: -114.1010000,
		centroidLat: 47.5950000
	},
	{
		geoid: "48261",
		name: "Kenedy",
		state: "Texas",
		countyState: "Kenedy County, Texas",
		centroidLng: -97.6056000,
		centroidLat: 26.9409000
	},
	{
		geoid: "48033",
		name: "Borden",
		state: "Texas",
		countyState: "Borden County, Texas",
		centroidLng: -101.4320000,
		centroidLat: 32.7438000
	},
	{
		geoid: "29157",
		name: "Perry",
		state: "Missouri",
		countyState: "Perry County, Missouri",
		centroidLng: -89.8116000,
		centroidLat: 37.7348000
	},
	{
		geoid: "48411",
		name: "San Saba",
		state: "Texas",
		countyState: "San Saba County, Texas",
		centroidLng: -98.7535000,
		centroidLat: 31.2055000
	},
	{
		geoid: "40073",
		name: "Kingfisher",
		state: "Oklahoma",
		countyState: "Kingfisher County, Oklahoma",
		centroidLng: -97.9423000,
		centroidLat: 35.9450000
	},
	{
		geoid: "48051",
		name: "Burleson",
		state: "Texas",
		countyState: "Burleson County, Texas",
		centroidLng: -96.6177000,
		centroidLat: 30.5132000
	},
	{
		geoid: "36035",
		name: "Fulton",
		state: "New York",
		countyState: "Fulton County, New York",
		centroidLng: -74.4359000,
		centroidLat: 43.1359000
	},
	{
		geoid: "28133",
		name: "Sunflower",
		state: "Mississippi",
		countyState: "Sunflower County, Mississippi",
		centroidLng: -90.6074000,
		centroidLat: 33.6288000
	},
	{
		geoid: "27053",
		name: "Hennepin",
		state: "Minnesota",
		countyState: "Hennepin County, Minnesota",
		centroidLng: -93.4725000,
		centroidLat: 45.0160000
	},
	{
		geoid: "42027",
		name: "Centre",
		state: "Pennsylvania",
		countyState: "Centre County, Pennsylvania",
		centroidLng: -77.7604000,
		centroidLat: 40.9722000
	},
	{
		geoid: "48025",
		name: "Bee",
		state: "Texas",
		countyState: "Bee County, Texas",
		centroidLng: -97.7327000,
		centroidLat: 28.4247000
	},
	{
		geoid: "47077",
		name: "Henderson",
		state: "Tennessee",
		countyState: "Henderson County, Tennessee",
		centroidLng: -88.3964000,
		centroidLat: 35.6206000
	},
	{
		geoid: "19171",
		name: "Tama",
		state: "Iowa",
		countyState: "Tama County, Iowa",
		centroidLng: -92.5327000,
		centroidLat: 42.0799000
	},
	{
		geoid: "20175",
		name: "Seward",
		state: "Kansas",
		countyState: "Seward County, Kansas",
		centroidLng: -100.8510000,
		centroidLat: 37.1929000
	},
	{
		geoid: "19135",
		name: "Monroe",
		state: "Iowa",
		countyState: "Monroe County, Iowa",
		centroidLng: -92.8692000,
		centroidLat: 41.0298000
	},
	{
		geoid: "31147",
		name: "Richardson",
		state: "Nebraska",
		countyState: "Richardson County, Nebraska",
		centroidLng: -95.6595000,
		centroidLat: 40.1310000
	},
	{
		geoid: "17021",
		name: "Christian",
		state: "Illinois",
		countyState: "Christian County, Illinois",
		centroidLng: -89.2795000,
		centroidLat: 39.5860000
	},
	{
		geoid: "12089",
		name: "Nassau",
		state: "Florida",
		countyState: "Nassau County, Florida",
		centroidLng: -81.6983000,
		centroidLat: 30.5516000
	},
	{
		geoid: "12133",
		name: "Washington",
		state: "Florida",
		countyState: "Washington County, Florida",
		centroidLng: -85.7130000,
		centroidLat: 30.6099000
	},
	{
		geoid: "13007",
		name: "Baker",
		state: "Georgia",
		countyState: "Baker County, Georgia",
		centroidLng: -84.3914000,
		centroidLat: 31.2666000
	},
	{
		geoid: "13275",
		name: "Thomas",
		state: "Georgia",
		countyState: "Thomas County, Georgia",
		centroidLng: -83.9277000,
		centroidLat: 30.8682000
	},
	{
		geoid: "21005",
		name: "Anderson",
		state: "Kentucky",
		countyState: "Anderson County, Kentucky",
		centroidLng: -84.9832000,
		centroidLat: 38.0070000
	},
	{
		geoid: "21185",
		name: "Oldham",
		state: "Kentucky",
		countyState: "Oldham County, Kentucky",
		centroidLng: -85.4605000,
		centroidLat: 38.4048000
	},
	{
		geoid: "13057",
		name: "Cherokee",
		state: "Georgia",
		countyState: "Cherokee County, Georgia",
		centroidLng: -84.4584000,
		centroidLat: 34.2429000
	},
	{
		geoid: "38065",
		name: "Oliver",
		state: "North Dakota",
		countyState: "Oliver County, North Dakota",
		centroidLng: -101.3220000,
		centroidLat: 47.1388000
	},
	{
		geoid: "26133",
		name: "Osceola",
		state: "Michigan",
		countyState: "Osceola County, Michigan",
		centroidLng: -85.3260000,
		centroidLat: 43.9893000
	},
	{
		geoid: "47109",
		name: "McNairy",
		state: "Tennessee",
		countyState: "McNairy County, Tennessee",
		centroidLng: -88.5748000,
		centroidLat: 35.1902000
	},
	{
		geoid: "53001",
		name: "Adams",
		state: "Washington",
		countyState: "Adams County, Washington",
		centroidLng: -118.6640000,
		centroidLat: 46.9986000
	},
	{
		geoid: "29057",
		name: "Dade",
		state: "Missouri",
		countyState: "Dade County, Missouri",
		centroidLng: -93.8499000,
		centroidLat: 37.4318000
	},
	{
		geoid: "22101",
		name: "St. Mary",
		state: "Louisiana",
		countyState: "St. Mary Parish, Louisiana",
		centroidLng: -91.5089000,
		centroidLat: 29.5978000
	},
	{
		geoid: "50007",
		name: "Chittenden",
		state: "Vermont",
		countyState: "Chittenden County, Vermont",
		centroidLng: -73.0825000,
		centroidLat: 44.4421000
	},
	{
		geoid: "72045",
		name: "Comer?o",
		state: "Puerto Rico",
		countyState: "Comer?o County, Puerto Rico",
		centroidLng: -66.2199000,
		centroidLat: 18.2250000
	},
	{
		geoid: "15009",
		name: "Maui",
		state: "Hawaii",
		countyState: "Maui County, Hawaii",
		centroidLng: -156.9340000,
		centroidLat: 20.8353000
	},
	{
		geoid: "22057",
		name: "Lafourche",
		state: "Louisiana",
		countyState: "Lafourche Parish, Louisiana",
		centroidLng: -90.5028000,
		centroidLat: 29.4587000
	},
	{
		geoid: "19139",
		name: "Muscatine",
		state: "Iowa",
		countyState: "Muscatine County, Iowa",
		centroidLng: -91.0765000,
		centroidLat: 41.4660000
	},
	{
		geoid: "12113",
		name: "Santa Rosa",
		state: "Florida",
		countyState: "Santa Rosa County, Florida",
		centroidLng: -87.0498000,
		centroidLat: 30.6591000
	},
	{
		geoid: "18101",
		name: "Martin",
		state: "Indiana",
		countyState: "Martin County, Indiana",
		centroidLng: -86.8034000,
		centroidLat: 38.6997000
	},
	{
		geoid: "21203",
		name: "Rockcastle",
		state: "Kentucky",
		countyState: "Rockcastle County, Kentucky",
		centroidLng: -84.3160000,
		centroidLat: 37.3458000
	},
	{
		geoid: "21223",
		name: "Trimble",
		state: "Kentucky",
		countyState: "Trimble County, Kentucky",
		centroidLng: -85.3126000,
		centroidLat: 38.6149000
	},
	{
		geoid: "13055",
		name: "Chattooga",
		state: "Georgia",
		countyState: "Chattooga County, Georgia",
		centroidLng: -85.3175000,
		centroidLat: 34.4378000
	},
	{
		geoid: "45033",
		name: "Dillon",
		state: "South Carolina",
		countyState: "Dillon County, South Carolina",
		centroidLng: -79.3523000,
		centroidLat: 34.4260000
	},
	{
		geoid: "37103",
		name: "Jones",
		state: "North Carolina",
		countyState: "Jones County, North Carolina",
		centroidLng: -77.3891000,
		centroidLat: 35.0117000
	},
	{
		geoid: "29199",
		name: "Scotland",
		state: "Missouri",
		countyState: "Scotland County, Missouri",
		centroidLng: -92.1473000,
		centroidLat: 40.4533000
	},
	{
		geoid: "20131",
		name: "Nemaha",
		state: "Kansas",
		countyState: "Nemaha County, Kansas",
		centroidLng: -96.0139000,
		centroidLat: 39.7834000
	},
	{
		geoid: "20137",
		name: "Norton",
		state: "Kansas",
		countyState: "Norton County, Kansas",
		centroidLng: -99.9029000,
		centroidLat: 39.7843000
	},
	{
		geoid: "72007",
		name: "Aguas Buenas",
		state: "Puerto Rico",
		countyState: "Aguas Buenas County, Puerto Rico",
		centroidLng: -66.1284000,
		centroidLat: 18.2505000
	},
	{
		geoid: "72033",
		name: "Cata?o",
		state: "Puerto Rico",
		countyState: "Cata?o County, Puerto Rico",
		centroidLng: -66.1393000,
		centroidLat: 18.4432000
	},
	{
		geoid: "31065",
		name: "Furnas",
		state: "Nebraska",
		countyState: "Furnas County, Nebraska",
		centroidLng: -99.9130000,
		centroidLat: 40.1763000
	},
	{
		geoid: "13087",
		name: "Decatur",
		state: "Georgia",
		countyState: "Decatur County, Georgia",
		centroidLng: -84.6236000,
		centroidLat: 30.8848000
	},
	{
		geoid: "18177",
		name: "Wayne",
		state: "Indiana",
		countyState: "Wayne County, Indiana",
		centroidLng: -85.0160000,
		centroidLat: 39.8606000
	},
	{
		geoid: "21009",
		name: "Barren",
		state: "Kentucky",
		countyState: "Barren County, Kentucky",
		centroidLng: -85.9578000,
		centroidLat: 36.9463000
	},
	{
		geoid: "31093",
		name: "Howard",
		state: "Nebraska",
		countyState: "Howard County, Nebraska",
		centroidLng: -98.5179000,
		centroidLat: 41.2202000
	},
	{
		geoid: "38025",
		name: "Dunn",
		state: "North Dakota",
		countyState: "Dunn County, North Dakota",
		centroidLng: -102.6220000,
		centroidLat: 47.4021000
	},
	{
		geoid: "06005",
		name: "Amador",
		state: "California",
		countyState: "Amador County, California",
		centroidLng: -120.5500000,
		centroidLat: 38.4635000
	},
	{
		geoid: "47049",
		name: "Fentress",
		state: "Tennessee",
		countyState: "Fentress County, Tennessee",
		centroidLng: -84.8907000,
		centroidLat: 36.3570000
	},
	{
		geoid: "20031",
		name: "Coffey",
		state: "Kansas",
		countyState: "Coffey County, Kansas",
		centroidLng: -95.7334000,
		centroidLat: 38.2362000
	},
	{
		geoid: "26023",
		name: "Branch",
		state: "Michigan",
		countyState: "Branch County, Michigan",
		centroidLng: -85.0594000,
		centroidLat: 41.9162000
	},
	{
		geoid: "51031",
		name: "Campbell",
		state: "Virginia",
		countyState: "Campbell County, Virginia",
		centroidLng: -79.1335000,
		centroidLat: 37.2280000
	},
	{
		geoid: "39105",
		name: "Meigs",
		state: "Ohio",
		countyState: "Meigs County, Ohio",
		centroidLng: -82.0330000,
		centroidLat: 39.0397000
	},
	{
		geoid: "54067",
		name: "Nicholas",
		state: "West Virginia",
		countyState: "Nicholas County, West Virginia",
		centroidLng: -80.8341000,
		centroidLat: 38.3184000
	},
	{
		geoid: "40053",
		name: "Grant",
		state: "Oklahoma",
		countyState: "Grant County, Oklahoma",
		centroidLng: -97.7871000,
		centroidLat: 36.7962000
	},
	{
		geoid: "46115",
		name: "Spink",
		state: "South Dakota",
		countyState: "Spink County, South Dakota",
		centroidLng: -98.3473000,
		centroidLat: 44.9377000
	},
	{
		geoid: "50027",
		name: "Windsor",
		state: "Vermont",
		countyState: "Windsor County, Vermont",
		centroidLng: -72.5927000,
		centroidLat: 43.5922000
	},
	{
		geoid: "48455",
		name: "Trinity",
		state: "Texas",
		countyState: "Trinity County, Texas",
		centroidLng: -95.1389000,
		centroidLat: 31.1057000
	},
	{
		geoid: "35001",
		name: "Bernalillo",
		state: "New Mexico",
		countyState: "Bernalillo County, New Mexico",
		centroidLng: -106.6730000,
		centroidLat: 35.0444000
	},
	{
		geoid: "13205",
		name: "Mitchell",
		state: "Georgia",
		countyState: "Mitchell County, Georgia",
		centroidLng: -84.2531000,
		centroidLat: 31.2605000
	},
	{
		geoid: "15001",
		name: "Hawaii",
		state: "Hawaii",
		countyState: "Hawaii County, Hawaii",
		centroidLng: -155.4380000,
		centroidLat: 19.5935000
	},
	{
		geoid: "21147",
		name: "McCreary",
		state: "Kentucky",
		countyState: "McCreary County, Kentucky",
		centroidLng: -84.5026000,
		centroidLat: 36.7773000
	},
	{
		geoid: "21191",
		name: "Pendleton",
		state: "Kentucky",
		countyState: "Pendleton County, Kentucky",
		centroidLng: -84.3638000,
		centroidLat: 38.7021000
	},
	{
		geoid: "21215",
		name: "Spencer",
		state: "Kentucky",
		countyState: "Spencer County, Kentucky",
		centroidLng: -85.3093000,
		centroidLat: 38.0436000
	},
	{
		geoid: "30041",
		name: "Hill",
		state: "Montana",
		countyState: "Hill County, Montana",
		centroidLng: -110.1100000,
		centroidLat: 48.5664000
	},
	{
		geoid: "31175",
		name: "Valley",
		state: "Nebraska",
		countyState: "Valley County, Nebraska",
		centroidLng: -98.9816000,
		centroidLat: 41.5673000
	},
	{
		geoid: "48069",
		name: "Castro",
		state: "Texas",
		countyState: "Castro County, Texas",
		centroidLng: -102.2620000,
		centroidLat: 34.5305000
	},
	{
		geoid: "24043",
		name: "Washington",
		state: "Maryland",
		countyState: "Washington County, Maryland",
		centroidLng: -77.9163000,
		centroidLat: 39.5206000
	},
	{
		geoid: "21133",
		name: "Letcher",
		state: "Kentucky",
		countyState: "Letcher County, Kentucky",
		centroidLng: -82.8597000,
		centroidLat: 37.1138000
	},
	{
		geoid: "34033",
		name: "Salem",
		state: "New Jersey",
		countyState: "Salem County, New Jersey",
		centroidLng: -75.3127000,
		centroidLat: 39.5757000
	},
	{
		geoid: "51680",
		name: "Lynchburg",
		state: "Virginia",
		countyState: "Lynchburg County, Virginia",
		centroidLng: -79.1783000,
		centroidLat: 37.4010000
	},
	{
		geoid: "05125",
		name: "Saline",
		state: "Arkansas",
		countyState: "Saline County, Arkansas",
		centroidLng: -92.6597000,
		centroidLat: 34.6350000
	},
	{
		geoid: "54053",
		name: "Mason",
		state: "West Virginia",
		countyState: "Mason County, West Virginia",
		centroidLng: -81.9973000,
		centroidLat: 38.7522000
	},
	{
		geoid: "54021",
		name: "Gilmer",
		state: "West Virginia",
		countyState: "Gilmer County, West Virginia",
		centroidLng: -80.8313000,
		centroidLat: 38.9137000
	},
	{
		geoid: "28063",
		name: "Jefferson",
		state: "Mississippi",
		countyState: "Jefferson County, Mississippi",
		centroidLng: -91.0509000,
		centroidLat: 31.7400000
	},
	{
		geoid: "38079",
		name: "Rolette",
		state: "North Dakota",
		countyState: "Rolette County, North Dakota",
		centroidLng: -99.8380000,
		centroidLat: 48.7720000
	},
	{
		geoid: "30103",
		name: "Treasure",
		state: "Montana",
		countyState: "Treasure County, Montana",
		centroidLng: -107.3390000,
		centroidLat: 46.1764000
	},
	{
		geoid: "21049",
		name: "Clark",
		state: "Kentucky",
		countyState: "Clark County, Kentucky",
		centroidLng: -84.1576000,
		centroidLat: 37.9685000
	},
	{
		geoid: "72101",
		name: "Morovis",
		state: "Puerto Rico",
		countyState: "Morovis County, Puerto Rico",
		centroidLng: -66.4152000,
		centroidLat: 18.3170000
	},
	{
		geoid: "26005",
		name: "Allegan",
		state: "Michigan",
		countyState: "Allegan County, Michigan",
		centroidLng: -86.3008000,
		centroidLat: 42.5922000
	},
	{
		geoid: "47005",
		name: "Benton",
		state: "Tennessee",
		countyState: "Benton County, Tennessee",
		centroidLng: -88.0704000,
		centroidLat: 36.0856000
	},
	{
		geoid: "27015",
		name: "Brown",
		state: "Minnesota",
		countyState: "Brown County, Minnesota",
		centroidLng: -94.7388000,
		centroidLat: 44.3030000
	},
	{
		geoid: "47137",
		name: "Pickett",
		state: "Tennessee",
		countyState: "Pickett County, Tennessee",
		centroidLng: -85.0086000,
		centroidLat: 36.5162000
	},
	{
		geoid: "51109",
		name: "Louisa",
		state: "Virginia",
		countyState: "Louisa County, Virginia",
		centroidLng: -77.9970000,
		centroidLat: 37.9410000
	},
	{
		geoid: "18145",
		name: "Shelby",
		state: "Indiana",
		countyState: "Shelby County, Indiana",
		centroidLng: -85.7911000,
		centroidLat: 39.5232000
	},
	{
		geoid: "39049",
		name: "Franklin",
		state: "Ohio",
		countyState: "Franklin County, Ohio",
		centroidLng: -83.0114000,
		centroidLat: 39.9690000
	},
	{
		geoid: "49021",
		name: "Iron",
		state: "Utah",
		countyState: "Iron County, Utah",
		centroidLng: -113.2600000,
		centroidLat: 37.8116000
	},
	{
		geoid: "32009",
		name: "Esmeralda",
		state: "Nevada",
		countyState: "Esmeralda County, Nevada",
		centroidLng: -117.7960000,
		centroidLat: 37.7225000
	},
	{
		geoid: "26083",
		name: "Keweenaw",
		state: "Michigan",
		countyState: "Keweenaw County, Michigan",
		centroidLng: -88.3964000,
		centroidLat: 47.7529000
	},
	{
		geoid: "49039",
		name: "Sanpete",
		state: "Utah",
		countyState: "Sanpete County, Utah",
		centroidLng: -111.6320000,
		centroidLat: 39.4228000
	},
	{
		geoid: "20093",
		name: "Kearny",
		state: "Kansas",
		countyState: "Kearny County, Kansas",
		centroidLng: -101.3160000,
		centroidLat: 38.0003000
	},
	{
		geoid: "12009",
		name: "Brevard",
		state: "Florida",
		countyState: "Brevard County, Florida",
		centroidLng: -80.6862000,
		centroidLat: 28.3067000
	},
	{
		geoid: "18105",
		name: "Monroe",
		state: "Indiana",
		countyState: "Monroe County, Indiana",
		centroidLng: -86.5016000,
		centroidLat: 39.1732000
	},
	{
		geoid: "19117",
		name: "Lucas",
		state: "Iowa",
		countyState: "Lucas County, Iowa",
		centroidLng: -93.3276000,
		centroidLat: 41.0294000
	},
	{
		geoid: "21239",
		name: "Woodford",
		state: "Kentucky",
		countyState: "Woodford County, Kentucky",
		centroidLng: -84.7455000,
		centroidLat: 38.0212000
	},
	{
		geoid: "45053",
		name: "Jasper",
		state: "South Carolina",
		countyState: "Jasper County, South Carolina",
		centroidLng: -81.0115000,
		centroidLat: 32.3933000
	},
	{
		geoid: "48043",
		name: "Brewster",
		state: "Texas",
		countyState: "Brewster County, Texas",
		centroidLng: -103.0610000,
		centroidLat: 29.8187000
	},
	{
		geoid: "45065",
		name: "McCormick",
		state: "South Carolina",
		countyState: "McCormick County, South Carolina",
		centroidLng: -82.3199000,
		centroidLat: 33.8356000
	},
	{
		geoid: "45087",
		name: "Union",
		state: "South Carolina",
		countyState: "Union County, South Carolina",
		centroidLng: -81.6349000,
		centroidLat: 34.6790000
	},
	{
		geoid: "27103",
		name: "Nicollet",
		state: "Minnesota",
		countyState: "Nicollet County, Minnesota",
		centroidLng: -94.3569000,
		centroidLat: 44.3054000
	},
	{
		geoid: "55075",
		name: "Marinette",
		state: "Wisconsin",
		countyState: "Marinette County, Wisconsin",
		centroidLng: -87.9354000,
		centroidLat: 45.2800000
	},
	{
		geoid: "53011",
		name: "Clark",
		state: "Washington",
		countyState: "Clark County, Washington",
		centroidLng: -122.5200000,
		centroidLat: 45.8016000
	},
	{
		geoid: "55099",
		name: "Price",
		state: "Wisconsin",
		countyState: "Price County, Wisconsin",
		centroidLng: -90.3610000,
		centroidLat: 45.6795000
	},
	{
		geoid: "18103",
		name: "Miami",
		state: "Indiana",
		countyState: "Miami County, Indiana",
		centroidLng: -86.0164000,
		centroidLat: 40.7809000
	},
	{
		geoid: "20053",
		name: "Ellsworth",
		state: "Kansas",
		countyState: "Ellsworth County, Kansas",
		centroidLng: -98.2052000,
		centroidLat: 38.6966000
	},
	{
		geoid: "22123",
		name: "West Carroll",
		state: "Louisiana",
		countyState: "West Carroll Parish, Louisiana",
		centroidLng: -91.4502000,
		centroidLat: 32.7940000
	},
	{
		geoid: "12077",
		name: "Liberty",
		state: "Florida",
		countyState: "Liberty County, Florida",
		centroidLng: -84.8484000,
		centroidLat: 30.2889000
	},
	{
		geoid: "20083",
		name: "Hodgeman",
		state: "Kansas",
		countyState: "Hodgeman County, Kansas",
		centroidLng: -99.8983000,
		centroidLat: 38.0875000
	},
	{
		geoid: "20119",
		name: "Meade",
		state: "Kansas",
		countyState: "Meade County, Kansas",
		centroidLng: -100.3710000,
		centroidLat: 37.2377000
	},
	{
		geoid: "21149",
		name: "McLean",
		state: "Kentucky",
		countyState: "McLean County, Kentucky",
		centroidLng: -87.2691000,
		centroidLat: 37.5320000
	},
	{
		geoid: "28001",
		name: "Adams",
		state: "Mississippi",
		countyState: "Adams County, Mississippi",
		centroidLng: -91.4044000,
		centroidLat: 31.4706000
	},
	{
		geoid: "72083",
		name: "Las Mar?as",
		state: "Puerto Rico",
		countyState: "Las Mar?as County, Puerto Rico",
		centroidLng: -66.9900000,
		centroidLat: 18.2389000
	},
	{
		geoid: "28057",
		name: "Itawamba",
		state: "Mississippi",
		countyState: "Itawamba County, Mississippi",
		centroidLng: -88.3506000,
		centroidLat: 34.2760000
	},
	{
		geoid: "21073",
		name: "Franklin",
		state: "Kentucky",
		countyState: "Franklin County, Kentucky",
		centroidLng: -84.8747000,
		centroidLat: 38.2341000
	},
	{
		geoid: "23009",
		name: "Hancock",
		state: "Maine",
		countyState: "Hancock County, Maine",
		centroidLng: -68.4108000,
		centroidLat: 44.6405000
	},
	{
		geoid: "23013",
		name: "Knox",
		state: "Maine",
		countyState: "Knox County, Maine",
		centroidLng: -68.9679000,
		centroidLat: 44.0485000
	},
	{
		geoid: "13217",
		name: "Newton",
		state: "Georgia",
		countyState: "Newton County, Georgia",
		centroidLng: -83.8649000,
		centroidLat: 33.5557000
	},
	{
		geoid: "46049",
		name: "Faulk",
		state: "South Dakota",
		countyState: "Faulk County, South Dakota",
		centroidLng: -99.1451000,
		centroidLat: 45.0712000
	},
	{
		geoid: "26061",
		name: "Houghton",
		state: "Michigan",
		countyState: "Houghton County, Michigan",
		centroidLng: -88.5176000,
		centroidLat: 46.9484000
	},
	{
		geoid: "30061",
		name: "Mineral",
		state: "Montana",
		countyState: "Mineral County, Montana",
		centroidLng: -115.0890000,
		centroidLat: 47.0967000
	},
	{
		geoid: "47051",
		name: "Franklin",
		state: "Tennessee",
		countyState: "Franklin County, Tennessee",
		centroidLng: -86.0983000,
		centroidLat: 35.1754000
	},
	{
		geoid: "22029",
		name: "Concordia",
		state: "Louisiana",
		countyState: "Concordia Parish, Louisiana",
		centroidLng: -91.6227000,
		centroidLat: 31.3662000
	},
	{
		geoid: "22063",
		name: "Livingston",
		state: "Louisiana",
		countyState: "Livingston Parish, Louisiana",
		centroidLng: -90.7326000,
		centroidLat: 30.4125000
	},
	{
		geoid: "25009",
		name: "Essex",
		state: "Massachusetts",
		countyState: "Essex County, Massachusetts",
		centroidLng: -70.8789000,
		centroidLat: 42.6354000
	},
	{
		geoid: "46019",
		name: "Butte",
		state: "South Dakota",
		countyState: "Butte County, South Dakota",
		centroidLng: -103.5070000,
		centroidLat: 44.8922000
	},
	{
		geoid: "08035",
		name: "Douglas",
		state: "Colorado",
		countyState: "Douglas County, Colorado",
		centroidLng: -104.9950000,
		centroidLat: 39.3479000
	},
	{
		geoid: "45079",
		name: "Richland",
		state: "South Carolina",
		countyState: "Richland County, South Carolina",
		centroidLng: -80.9708000,
		centroidLat: 34.0060000
	},
	{
		geoid: "18095",
		name: "Madison",
		state: "Indiana",
		countyState: "Madison County, Indiana",
		centroidLng: -85.7196000,
		centroidLat: 40.1617000
	},
	{
		geoid: "40099",
		name: "Murray",
		state: "Oklahoma",
		countyState: "Murray County, Oklahoma",
		centroidLng: -97.0897000,
		centroidLat: 34.4847000
	},
	{
		geoid: "54009",
		name: "Brooke",
		state: "West Virginia",
		countyState: "Brooke County, West Virginia",
		centroidLng: -80.5999000,
		centroidLat: 40.2797000
	},
	{
		geoid: "54013",
		name: "Calhoun",
		state: "West Virginia",
		countyState: "Calhoun County, West Virginia",
		centroidLng: -81.1322000,
		centroidLat: 38.8286000
	},
	{
		geoid: "51021",
		name: "Bland",
		state: "Virginia",
		countyState: "Bland County, Virginia",
		centroidLng: -81.1585000,
		centroidLat: 37.1274000
	},
	{
		geoid: "30111",
		name: "Yellowstone",
		state: "Montana",
		countyState: "Yellowstone County, Montana",
		centroidLng: -108.1940000,
		centroidLat: 45.9782000
	},
	{
		geoid: "54043",
		name: "Lincoln",
		state: "West Virginia",
		countyState: "Lincoln County, West Virginia",
		centroidLng: -82.0539000,
		centroidLat: 38.1602000
	},
	{
		geoid: "06033",
		name: "Lake",
		state: "California",
		countyState: "Lake County, California",
		centroidLng: -122.7170000,
		centroidLat: 39.1245000
	},
	{
		geoid: "13131",
		name: "Grady",
		state: "Georgia",
		countyState: "Grady County, Georgia",
		centroidLng: -84.2268000,
		centroidLat: 30.8774000
	},
	{
		geoid: "08021",
		name: "Conejos",
		state: "Colorado",
		countyState: "Conejos County, Colorado",
		centroidLng: -106.1980000,
		centroidLat: 37.1986000
	},
	{
		geoid: "49037",
		name: "San Juan",
		state: "Utah",
		countyState: "San Juan County, Utah",
		centroidLng: -110.2270000,
		centroidLat: 37.7488000
	},
	{
		geoid: "18133",
		name: "Putnam",
		state: "Indiana",
		countyState: "Putnam County, Indiana",
		centroidLng: -86.8282000,
		centroidLat: 39.6684000
	},
	{
		geoid: "16047",
		name: "Gooding",
		state: "Idaho",
		countyState: "Gooding County, Idaho",
		centroidLng: -114.8410000,
		centroidLat: 42.9239000
	},
	{
		geoid: "47167",
		name: "Tipton",
		state: "Tennessee",
		countyState: "Tipton County, Tennessee",
		centroidLng: -89.7633000,
		centroidLat: 35.5118000
	},
	{
		geoid: "22121",
		name: "West Baton Rouge",
		state: "Louisiana",
		countyState: "West Baton Rouge Parish, Louisiana",
		centroidLng: -91.3136000,
		centroidLat: 30.4906000
	},
	{
		geoid: "29171",
		name: "Putnam",
		state: "Missouri",
		countyState: "Putnam County, Missouri",
		centroidLng: -93.0198000,
		centroidLat: 40.4659000
	},
	{
		geoid: "56039",
		name: "Teton",
		state: "Wyoming",
		countyState: "Teton County, Wyoming",
		centroidLng: -110.5520000,
		centroidLat: 43.9509000
	},
	{
		geoid: "55043",
		name: "Grant",
		state: "Wisconsin",
		countyState: "Grant County, Wisconsin",
		centroidLng: -90.7913000,
		centroidLat: 42.8594000
	},
	{
		geoid: "38059",
		name: "Morton",
		state: "North Dakota",
		countyState: "Morton County, North Dakota",
		centroidLng: -101.3210000,
		centroidLat: 46.6336000
	},
	{
		geoid: "37041",
		name: "Chowan",
		state: "North Carolina",
		countyState: "Chowan County, North Carolina",
		centroidLng: -76.5670000,
		centroidLat: 36.1627000
	},
	{
		geoid: "37021",
		name: "Buncombe",
		state: "North Carolina",
		countyState: "Buncombe County, North Carolina",
		centroidLng: -82.5286000,
		centroidLat: 35.6203000
	},
	{
		geoid: "06063",
		name: "Plumas",
		state: "California",
		countyState: "Plumas County, California",
		centroidLng: -120.7990000,
		centroidLat: 40.0235000
	},
	{
		geoid: "51730",
		name: "Petersburg",
		state: "Virginia",
		countyState: "Petersburg County, Virginia",
		centroidLng: -77.3923000,
		centroidLat: 37.2050000
	},
	{
		geoid: "50023",
		name: "Washington",
		state: "Vermont",
		countyState: "Washington County, Vermont",
		centroidLng: -72.5846000,
		centroidLat: 44.2595000
	},
	{
		geoid: "46093",
		name: "Meade",
		state: "South Dakota",
		countyState: "Meade County, South Dakota",
		centroidLng: -102.7870000,
		centroidLat: 44.5899000
	},
	{
		geoid: "18097",
		name: "Marion",
		state: "Indiana",
		countyState: "Marion County, Indiana",
		centroidLng: -86.1328000,
		centroidLat: 39.7798000
	},
	{
		geoid: "05017",
		name: "Chicot",
		state: "Arkansas",
		countyState: "Chicot County, Arkansas",
		centroidLng: -91.2521000,
		centroidLat: 33.2839000
	},
	{
		geoid: "13021",
		name: "Bibb",
		state: "Georgia",
		countyState: "Bibb County, Georgia",
		centroidLng: -83.6907000,
		centroidLat: 32.8067000
	},
	{
		geoid: "72059",
		name: "Guayanilla",
		state: "Puerto Rico",
		countyState: "Guayanilla County, Puerto Rico",
		centroidLng: -66.7956000,
		centroidLat: 18.0188000
	},
	{
		geoid: "72131",
		name: "San Sebasti?n",
		state: "Puerto Rico",
		countyState: "San Sebasti?n County, Puerto Rico",
		centroidLng: -66.9723000,
		centroidLat: 18.3236000
	},
	{
		geoid: "05115",
		name: "Pope",
		state: "Arkansas",
		countyState: "Pope County, Arkansas",
		centroidLng: -93.0544000,
		centroidLat: 35.4231000
	},
	{
		geoid: "51175",
		name: "Southampton",
		state: "Virginia",
		countyState: "Southampton County, Virginia",
		centroidLng: -77.1584000,
		centroidLat: 36.7700000
	},
	{
		geoid: "48183",
		name: "Gregg",
		state: "Texas",
		countyState: "Gregg County, Texas",
		centroidLng: -94.7831000,
		centroidLat: 32.5138000
	},
	{
		geoid: "36087",
		name: "Rockland",
		state: "New York",
		countyState: "Rockland County, New York",
		centroidLng: -74.0607000,
		centroidLat: 41.1609000
	},
	{
		geoid: "05089",
		name: "Marion",
		state: "Arkansas",
		countyState: "Marion County, Arkansas",
		centroidLng: -92.6489000,
		centroidLat: 36.2807000
	},
	{
		geoid: "51800",
		name: "Suffolk",
		state: "Virginia",
		countyState: "Suffolk County, Virginia",
		centroidLng: -76.6653000,
		centroidLat: 36.7463000
	},
	{
		geoid: "08041",
		name: "El Paso",
		state: "Colorado",
		countyState: "El Paso County, Colorado",
		centroidLng: -104.5620000,
		centroidLat: 38.8247000
	},
	{
		geoid: "29187",
		name: "St. Francois",
		state: "Missouri",
		countyState: "St. Francois County, Missouri",
		centroidLng: -90.3792000,
		centroidLat: 37.8588000
	},
	{
		geoid: "48475",
		name: "Ward",
		state: "Texas",
		countyState: "Ward County, Texas",
		centroidLng: -103.1890000,
		centroidLat: 31.4593000
	},
	{
		geoid: "08061",
		name: "Kiowa",
		state: "Colorado",
		countyState: "Kiowa County, Colorado",
		centroidLng: -102.7760000,
		centroidLat: 38.4404000
	},
	{
		geoid: "48487",
		name: "Wilbarger",
		state: "Texas",
		countyState: "Wilbarger County, Texas",
		centroidLng: -99.2140000,
		centroidLat: 34.1463000
	},
	{
		geoid: "41015",
		name: "Curry",
		state: "Oregon",
		countyState: "Curry County, Oregon",
		centroidLng: -124.2100000,
		centroidLat: 42.4754000
	},
	{
		geoid: "08119",
		name: "Teller",
		state: "Colorado",
		countyState: "Teller County, Colorado",
		centroidLng: -105.1350000,
		centroidLat: 38.8887000
	},
	{
		geoid: "29049",
		name: "Clinton",
		state: "Missouri",
		countyState: "Clinton County, Missouri",
		centroidLng: -94.4041000,
		centroidLat: 39.6013000
	},
	{
		geoid: "49051",
		name: "Wasatch",
		state: "Utah",
		countyState: "Wasatch County, Utah",
		centroidLng: -111.2560000,
		centroidLat: 40.2950000
	},
	{
		geoid: "16029",
		name: "Caribou",
		state: "Idaho",
		countyState: "Caribou County, Idaho",
		centroidLng: -111.5970000,
		centroidLat: 42.7189000
	},
	{
		geoid: "37157",
		name: "Rockingham",
		state: "North Carolina",
		countyState: "Rockingham County, North Carolina",
		centroidLng: -79.7744000,
		centroidLat: 36.3921000
	},
	{
		geoid: "05117",
		name: "Prairie",
		state: "Arkansas",
		countyState: "Prairie County, Arkansas",
		centroidLng: -91.5738000,
		centroidLat: 34.7856000
	},
	{
		geoid: "05011",
		name: "Bradley",
		state: "Arkansas",
		countyState: "Bradley County, Arkansas",
		centroidLng: -92.1712000,
		centroidLat: 33.4345000
	},
	{
		geoid: "48193",
		name: "Hamilton",
		state: "Texas",
		countyState: "Hamilton County, Texas",
		centroidLng: -98.1151000,
		centroidLat: 31.7171000
	},
	{
		geoid: "24011",
		name: "Caroline",
		state: "Maryland",
		countyState: "Caroline County, Maryland",
		centroidLng: -75.8612000,
		centroidLat: 38.8893000
	},
	{
		geoid: "27041",
		name: "Douglas",
		state: "Minnesota",
		countyState: "Douglas County, Minnesota",
		centroidLng: -95.4542000,
		centroidLat: 45.9335000
	},
	{
		geoid: "30043",
		name: "Jefferson",
		state: "Montana",
		countyState: "Jefferson County, Montana",
		centroidLng: -112.1290000,
		centroidLat: 46.1585000
	},
	{
		geoid: "51059",
		name: "Fairfax",
		state: "Virginia",
		countyState: "Fairfax County, Virginia",
		centroidLng: -77.2892000,
		centroidLat: 38.8311000
	},
	{
		geoid: "30023",
		name: "Deer Lodge",
		state: "Montana",
		countyState: "Deer Lodge County, Montana",
		centroidLng: -113.0380000,
		centroidLat: 46.0124000
	},
	{
		geoid: "47001",
		name: "Anderson",
		state: "Tennessee",
		countyState: "Anderson County, Tennessee",
		centroidLng: -84.1958000,
		centroidLat: 36.1060000
	},
	{
		geoid: "39077",
		name: "Huron",
		state: "Ohio",
		countyState: "Huron County, Ohio",
		centroidLng: -82.5890000,
		centroidLat: 41.1415000
	},
	{
		geoid: "13005",
		name: "Bacon",
		state: "Georgia",
		countyState: "Bacon County, Georgia",
		centroidLng: -82.4274000,
		centroidLat: 31.5639000
	},
	{
		geoid: "10001",
		name: "Kent",
		state: "Delaware",
		countyState: "Kent County, Delaware",
		centroidLng: -75.4493000,
		centroidLat: 39.1087000
	},
	{
		geoid: "39173",
		name: "Wood",
		state: "Ohio",
		countyState: "Wood County, Ohio",
		centroidLng: -83.6491000,
		centroidLat: 41.3923000
	},
	{
		geoid: "13157",
		name: "Jackson",
		state: "Georgia",
		countyState: "Jackson County, Georgia",
		centroidLng: -83.5874000,
		centroidLat: 34.1306000
	},
	{
		geoid: "20003",
		name: "Anderson",
		state: "Kansas",
		countyState: "Anderson County, Kansas",
		centroidLng: -95.2919000,
		centroidLat: 38.2141000
	},
	{
		geoid: "17095",
		name: "Knox",
		state: "Illinois",
		countyState: "Knox County, Illinois",
		centroidLng: -90.2144000,
		centroidLat: 40.9322000
	},
	{
		geoid: "30107",
		name: "Wheatland",
		state: "Montana",
		countyState: "Wheatland County, Montana",
		centroidLng: -109.8350000,
		centroidLat: 46.4860000
	},
	{
		geoid: "47015",
		name: "Cannon",
		state: "Tennessee",
		countyState: "Cannon County, Tennessee",
		centroidLng: -86.0470000,
		centroidLat: 35.8087000
	},
	{
		geoid: "23007",
		name: "Franklin",
		state: "Maine",
		countyState: "Franklin County, Maine",
		centroidLng: -70.3816000,
		centroidLat: 45.0634000
	},
	{
		geoid: "21165",
		name: "Menifee",
		state: "Kentucky",
		countyState: "Menifee County, Kentucky",
		centroidLng: -83.6056000,
		centroidLat: 37.9386000
	},
	{
		geoid: "46089",
		name: "McPherson",
		state: "South Dakota",
		countyState: "McPherson County, South Dakota",
		centroidLng: -99.2212000,
		centroidLat: 45.7662000
	},
	{
		geoid: "08051",
		name: "Gunnison",
		state: "Colorado",
		countyState: "Gunnison County, Colorado",
		centroidLng: -106.9400000,
		centroidLat: 38.7023000
	},
	{
		geoid: "26029",
		name: "Charlevoix",
		state: "Michigan",
		countyState: "Charlevoix County, Michigan",
		centroidLng: -85.3030000,
		centroidLat: 45.4991000
	},
	{
		geoid: "27151",
		name: "Swift",
		state: "Minnesota",
		countyState: "Swift County, Minnesota",
		centroidLng: -95.6824000,
		centroidLat: 45.2819000
	},
	{
		geoid: "39147",
		name: "Seneca",
		state: "Ohio",
		countyState: "Seneca County, Ohio",
		centroidLng: -83.1254000,
		centroidLat: 41.1244000
	},
	{
		geoid: "13267",
		name: "Tattnall",
		state: "Georgia",
		countyState: "Tattnall County, Georgia",
		centroidLng: -81.9984000,
		centroidLat: 32.0507000
	},
	{
		geoid: "48429",
		name: "Stephens",
		state: "Texas",
		countyState: "Stephens County, Texas",
		centroidLng: -98.8358000,
		centroidLat: 32.7360000
	},
	{
		geoid: "37117",
		name: "Martin",
		state: "North Carolina",
		countyState: "Martin County, North Carolina",
		centroidLng: -77.0849000,
		centroidLat: 35.8651000
	},
	{
		geoid: "30081",
		name: "Ravalli",
		state: "Montana",
		countyState: "Ravalli County, Montana",
		centroidLng: -114.0410000,
		centroidLat: 46.0600000
	},
	{
		geoid: "13317",
		name: "Wilkes",
		state: "Georgia",
		countyState: "Wilkes County, Georgia",
		centroidLng: -82.7334000,
		centroidLat: 33.7937000
	},
	{
		geoid: "53043",
		name: "Lincoln",
		state: "Washington",
		countyState: "Lincoln County, Washington",
		centroidLng: -118.4000000,
		centroidLat: 47.6090000
	},
	{
		geoid: "06081",
		name: "San Mateo",
		state: "California",
		countyState: "San Mateo County, California",
		centroidLng: -122.3350000,
		centroidLat: 37.3811000
	},
	{
		geoid: "39171",
		name: "Williams",
		state: "Ohio",
		countyState: "Williams County, Ohio",
		centroidLng: -84.5739000,
		centroidLat: 41.5659000
	},
	{
		geoid: "08009",
		name: "Baca",
		state: "Colorado",
		countyState: "Baca County, Colorado",
		centroidLng: -102.5640000,
		centroidLat: 37.3187000
	},
	{
		geoid: "16021",
		name: "Boundary",
		state: "Idaho",
		countyState: "Boundary County, Idaho",
		centroidLng: -116.5410000,
		centroidLat: 48.7504000
	},
	{
		geoid: "16007",
		name: "Bear Lake",
		state: "Idaho",
		countyState: "Bear Lake County, Idaho",
		centroidLng: -111.3410000,
		centroidLat: 42.3014000
	},
	{
		geoid: "39159",
		name: "Union",
		state: "Ohio",
		countyState: "Union County, Ohio",
		centroidLng: -83.3606000,
		centroidLat: 40.3070000
	},
	{
		geoid: "39035",
		name: "Cuyahoga",
		state: "Ohio",
		countyState: "Cuyahoga County, Ohio",
		centroidLng: -81.6730000,
		centroidLat: 41.6857000
	},
	{
		geoid: "48285",
		name: "Lavaca",
		state: "Texas",
		countyState: "Lavaca County, Texas",
		centroidLng: -96.9003000,
		centroidLat: 29.3480000
	},
	{
		geoid: "40105",
		name: "Nowata",
		state: "Oklahoma",
		countyState: "Nowata County, Oklahoma",
		centroidLng: -95.6087000,
		centroidLat: 36.7984000
	},
	{
		geoid: "13125",
		name: "Glascock",
		state: "Georgia",
		countyState: "Glascock County, Georgia",
		centroidLng: -82.5939000,
		centroidLat: 33.2231000
	},
	{
		geoid: "41059",
		name: "Umatilla",
		state: "Oregon",
		countyState: "Umatilla County, Oregon",
		centroidLng: -118.7040000,
		centroidLat: 45.4985000
	},
	{
		geoid: "46102",
		name: "Oglala Lakota",
		state: "South Dakota",
		countyState: "Oglala Lakota County, South Dakota",
		centroidLng: -102.5410000,
		centroidLat: 43.3527000
	},
	{
		geoid: "20117",
		name: "Marshall",
		state: "Kansas",
		countyState: "Marshall County, Kansas",
		centroidLng: -96.5229000,
		centroidLat: 39.7837000
	},
	{
		geoid: "22065",
		name: "Madison",
		state: "Louisiana",
		countyState: "Madison Parish, Louisiana",
		centroidLng: -91.2165000,
		centroidLat: 32.3770000
	},
	{
		geoid: "30105",
		name: "Valley",
		state: "Montana",
		countyState: "Valley County, Montana",
		centroidLng: -106.6100000,
		centroidLat: 48.3303000
	},
	{
		geoid: "13313",
		name: "Whitfield",
		state: "Georgia",
		countyState: "Whitfield County, Georgia",
		centroidLng: -84.9897000,
		centroidLat: 34.8020000
	},
	{
		geoid: "20203",
		name: "Wichita",
		state: "Kansas",
		countyState: "Wichita County, Kansas",
		centroidLng: -101.3460000,
		centroidLat: 38.4824000
	},
	{
		geoid: "46023",
		name: "Charles Mix",
		state: "South Dakota",
		countyState: "Charles Mix County, South Dakota",
		centroidLng: -98.6899000,
		centroidLat: 43.1684000
	},
	{
		geoid: "31027",
		name: "Cedar",
		state: "Nebraska",
		countyState: "Cedar County, Nebraska",
		centroidLng: -97.2505000,
		centroidLat: 42.6097000
	},
	{
		geoid: "53035",
		name: "Kitsap",
		state: "Washington",
		countyState: "Kitsap County, Washington",
		centroidLng: -122.7330000,
		centroidLat: 47.6880000
	},
	{
		geoid: "17111",
		name: "McHenry",
		state: "Illinois",
		countyState: "McHenry County, Illinois",
		centroidLng: -88.4528000,
		centroidLat: 42.3243000
	},
	{
		geoid: "30069",
		name: "Petroleum",
		state: "Montana",
		countyState: "Petroleum County, Montana",
		centroidLng: -108.2810000,
		centroidLat: 47.1738000
	},
	{
		geoid: "29033",
		name: "Carroll",
		state: "Missouri",
		countyState: "Carroll County, Missouri",
		centroidLng: -93.4319000,
		centroidLat: 39.4110000
	},
	{
		geoid: "53075",
		name: "Whitman",
		state: "Washington",
		countyState: "Whitman County, Washington",
		centroidLng: -117.6440000,
		centroidLat: 46.8387000
	},
	{
		geoid: "27147",
		name: "Steele",
		state: "Minnesota",
		countyState: "Steele County, Minnesota",
		centroidLng: -93.2261000,
		centroidLat: 44.0224000
	},
	{
		geoid: "31169",
		name: "Thayer",
		state: "Nebraska",
		countyState: "Thayer County, Nebraska",
		centroidLng: -97.5950000,
		centroidLat: 40.1762000
	},
	{
		geoid: "47033",
		name: "Crockett",
		state: "Tennessee",
		countyState: "Crockett County, Tennessee",
		centroidLng: -89.1273000,
		centroidLat: 35.8356000
	},
	{
		geoid: "19079",
		name: "Hamilton",
		state: "Iowa",
		countyState: "Hamilton County, Iowa",
		centroidLng: -93.7171000,
		centroidLat: 42.3836000
	},
	{
		geoid: "39165",
		name: "Warren",
		state: "Ohio",
		countyState: "Warren County, Ohio",
		centroidLng: -84.1711000,
		centroidLat: 39.4223000
	},
	{
		geoid: "13255",
		name: "Spalding",
		state: "Georgia",
		countyState: "Spalding County, Georgia",
		centroidLng: -84.2990000,
		centroidLat: 33.2656000
	},
	{
		geoid: "34019",
		name: "Hunterdon",
		state: "New Jersey",
		countyState: "Hunterdon County, New Jersey",
		centroidLng: -74.9491000,
		centroidLat: 40.5636000
	},
	{
		geoid: "29217",
		name: "Vernon",
		state: "Missouri",
		countyState: "Vernon County, Missouri",
		centroidLng: -94.3381000,
		centroidLat: 37.8499000
	},
	{
		geoid: "18121",
		name: "Parke",
		state: "Indiana",
		countyState: "Parke County, Indiana",
		centroidLng: -87.2218000,
		centroidLat: 39.7791000
	},
	{
		geoid: "19069",
		name: "Franklin",
		state: "Iowa",
		countyState: "Franklin County, Iowa",
		centroidLng: -93.2624000,
		centroidLat: 42.7326000
	},
	{
		geoid: "13035",
		name: "Butts",
		state: "Georgia",
		countyState: "Butts County, Georgia",
		centroidLng: -83.9655000,
		centroidLat: 33.3124000
	},
	{
		geoid: "08039",
		name: "Elbert",
		state: "Colorado",
		countyState: "Elbert County, Colorado",
		centroidLng: -104.1890000,
		centroidLat: 39.2165000
	},
	{
		geoid: "44001",
		name: "Bristol",
		state: "Rhode Island",
		countyState: "Bristol County, Rhode Island",
		centroidLng: -71.2868000,
		centroidLat: 41.7072000
	},
	{
		geoid: "48035",
		name: "Bosque",
		state: "Texas",
		countyState: "Bosque County, Texas",
		centroidLng: -97.6411000,
		centroidLat: 31.8976000
	},
	{
		geoid: "44005",
		name: "Newport",
		state: "Rhode Island",
		countyState: "Newport County, Rhode Island",
		centroidLng: -71.2990000,
		centroidLat: 41.4990000
	},
	{
		geoid: "39045",
		name: "Fairfield",
		state: "Ohio",
		countyState: "Fairfield County, Ohio",
		centroidLng: -82.6045000,
		centroidLat: 39.7478000
	},
	{
		geoid: "38069",
		name: "Pierce",
		state: "North Dakota",
		countyState: "Pierce County, North Dakota",
		centroidLng: -99.8849000,
		centroidLat: 48.1962000
	},
	{
		geoid: "17015",
		name: "Carroll",
		state: "Illinois",
		countyState: "Carroll County, Illinois",
		centroidLng: -90.0013000,
		centroidLat: 42.0640000
	},
	{
		geoid: "27125",
		name: "Red Lake",
		state: "Minnesota",
		countyState: "Red Lake County, Minnesota",
		centroidLng: -96.0958000,
		centroidLat: 47.8621000
	},
	{
		geoid: "41031",
		name: "Jefferson",
		state: "Oregon",
		countyState: "Jefferson County, Oregon",
		centroidLng: -121.1100000,
		centroidLat: 44.6101000
	},
	{
		geoid: "47021",
		name: "Cheatham",
		state: "Tennessee",
		countyState: "Cheatham County, Tennessee",
		centroidLng: -87.0964000,
		centroidLat: 36.2485000
	},
	{
		geoid: "46127",
		name: "Union",
		state: "South Dakota",
		countyState: "Union County, South Dakota",
		centroidLng: -96.6260000,
		centroidLat: 42.7818000
	},
	{
		geoid: "01051",
		name: "Elmore",
		state: "Alabama",
		countyState: "Elmore County, Alabama",
		centroidLng: -86.1330000,
		centroidLat: 32.5876000
	},
	{
		geoid: "13187",
		name: "Lumpkin",
		state: "Georgia",
		countyState: "Lumpkin County, Georgia",
		centroidLng: -84.0119000,
		centroidLat: 34.5796000
	},
	{
		geoid: "39083",
		name: "Knox",
		state: "Ohio",
		countyState: "Knox County, Ohio",
		centroidLng: -82.4649000,
		centroidLat: 40.4061000
	},
	{
		geoid: "19017",
		name: "Bremer",
		state: "Iowa",
		countyState: "Bremer County, Iowa",
		centroidLng: -92.3181000,
		centroidLat: 42.7746000
	},
	{
		geoid: "48175",
		name: "Goliad",
		state: "Texas",
		countyState: "Goliad County, Texas",
		centroidLng: -97.4661000,
		centroidLat: 28.6570000
	},
	{
		geoid: "37089",
		name: "Henderson",
		state: "North Carolina",
		countyState: "Henderson County, North Carolina",
		centroidLng: -82.5031000,
		centroidLat: 35.3223000
	},
	{
		geoid: "12061",
		name: "Indian River",
		state: "Florida",
		countyState: "Indian River County, Florida",
		centroidLng: -80.5726000,
		centroidLat: 27.7090000
	},
	{
		geoid: "48393",
		name: "Roberts",
		state: "Texas",
		countyState: "Roberts County, Texas",
		centroidLng: -100.8130000,
		centroidLat: 35.8385000
	},
	{
		geoid: "31049",
		name: "Deuel",
		state: "Nebraska",
		countyState: "Deuel County, Nebraska",
		centroidLng: -102.3360000,
		centroidLat: 41.1121000
	},
	{
		geoid: "36051",
		name: "Livingston",
		state: "New York",
		countyState: "Livingston County, New York",
		centroidLng: -77.7740000,
		centroidLat: 42.7298000
	},
	{
		geoid: "19141",
		name: "O'Brien",
		state: "Iowa",
		countyState: "O'Brien County, Iowa",
		centroidLng: -95.6251000,
		centroidLat: 43.0838000
	},
	{
		geoid: "48059",
		name: "Callahan",
		state: "Texas",
		countyState: "Callahan County, Texas",
		centroidLng: -99.3728000,
		centroidLat: 32.2972000
	},
	{
		geoid: "48379",
		name: "Rains",
		state: "Texas",
		countyState: "Rains County, Texas",
		centroidLng: -95.8112000,
		centroidLat: 32.8458000
	},
	{
		geoid: "20067",
		name: "Grant",
		state: "Kansas",
		countyState: "Grant County, Kansas",
		centroidLng: -101.3080000,
		centroidLat: 37.5621000
	},
	{
		geoid: "51105",
		name: "Lee",
		state: "Virginia",
		countyState: "Lee County, Virginia",
		centroidLng: -83.2235000,
		centroidLat: 36.7433000
	},
	{
		geoid: "35051",
		name: "Sierra",
		state: "New Mexico",
		countyState: "Sierra County, New Mexico",
		centroidLng: -107.1710000,
		centroidLat: 33.0428000
	},
	{
		geoid: "05043",
		name: "Drew",
		state: "Arkansas",
		countyState: "Drew County, Arkansas",
		centroidLng: -91.7328000,
		centroidLat: 33.5906000
	},
	{
		geoid: "31123",
		name: "Morrill",
		state: "Nebraska",
		countyState: "Morrill County, Nebraska",
		centroidLng: -103.0040000,
		centroidLat: 41.7215000
	},
	{
		geoid: "29015",
		name: "Benton",
		state: "Missouri",
		countyState: "Benton County, Missouri",
		centroidLng: -93.2913000,
		centroidLat: 38.2989000
	},
	{
		geoid: "05109",
		name: "Pike",
		state: "Arkansas",
		countyState: "Pike County, Arkansas",
		centroidLng: -93.6537000,
		centroidLat: 34.1466000
	},
	{
		geoid: "08077",
		name: "Mesa",
		state: "Colorado",
		countyState: "Mesa County, Colorado",
		centroidLng: -108.2190000,
		centroidLat: 38.9333000
	},
	{
		geoid: "29065",
		name: "Dent",
		state: "Missouri",
		countyState: "Dent County, Missouri",
		centroidLng: -91.4812000,
		centroidLat: 37.6033000
	},
	{
		geoid: "51185",
		name: "Tazewell",
		state: "Virginia",
		countyState: "Tazewell County, Virginia",
		centroidLng: -81.5635000,
		centroidLat: 37.1355000
	},
	{
		geoid: "42103",
		name: "Pike",
		state: "Pennsylvania",
		countyState: "Pike County, Pennsylvania",
		centroidLng: -75.0243000,
		centroidLat: 41.3409000
	},
	{
		geoid: "42115",
		name: "Susquehanna",
		state: "Pennsylvania",
		countyState: "Susquehanna County, Pennsylvania",
		centroidLng: -75.8039000,
		centroidLat: 41.8203000
	},
	{
		geoid: "48343",
		name: "Morris",
		state: "Texas",
		countyState: "Morris County, Texas",
		centroidLng: -94.7360000,
		centroidLat: 33.1226000
	},
	{
		geoid: "42125",
		name: "Washington",
		state: "Pennsylvania",
		countyState: "Washington County, Pennsylvania",
		centroidLng: -80.1791000,
		centroidLat: 40.2149000
	},
	{
		geoid: "50001",
		name: "Addison",
		state: "Vermont",
		countyState: "Addison County, Vermont",
		centroidLng: -73.0901000,
		centroidLat: 44.0284000
	},
	{
		geoid: "20103",
		name: "Leavenworth",
		state: "Kansas",
		countyState: "Leavenworth County, Kansas",
		centroidLng: -94.9818000,
		centroidLat: 39.1865000
	},
	{
		geoid: "26121",
		name: "Muskegon",
		state: "Michigan",
		countyState: "Muskegon County, Michigan",
		centroidLng: -86.4688000,
		centroidLat: 43.2955000
	},
	{
		geoid: "28049",
		name: "Hinds",
		state: "Mississippi",
		countyState: "Hinds County, Mississippi",
		centroidLng: -90.3972000,
		centroidLat: 32.3111000
	},
	{
		geoid: "48281",
		name: "Lampasas",
		state: "Texas",
		countyState: "Lampasas County, Texas",
		centroidLng: -98.2384000,
		centroidLat: 31.2466000
	},
	{
		geoid: "51107",
		name: "Loudoun",
		state: "Virginia",
		countyState: "Loudoun County, Virginia",
		centroidLng: -77.6453000,
		centroidLat: 39.0854000
	},
	{
		geoid: "29185",
		name: "St. Clair",
		state: "Missouri",
		countyState: "St. Clair County, Missouri",
		centroidLng: -93.7815000,
		centroidLat: 38.0244000
	},
	{
		geoid: "36025",
		name: "Delaware",
		state: "New York",
		countyState: "Delaware County, New York",
		centroidLng: -74.9256000,
		centroidLat: 42.1830000
	},
	{
		geoid: "30071",
		name: "Phillips",
		state: "Montana",
		countyState: "Phillips County, Montana",
		centroidLng: -108.0350000,
		centroidLat: 48.2248000
	},
	{
		geoid: "29129",
		name: "Mercer",
		state: "Missouri",
		countyState: "Mercer County, Missouri",
		centroidLng: -93.5708000,
		centroidLat: 40.4221000
	},
	{
		geoid: "53045",
		name: "Mason",
		state: "Washington",
		countyState: "Mason County, Washington",
		centroidLng: -123.1530000,
		centroidLat: 47.3449000
	},
	{
		geoid: "37127",
		name: "Nash",
		state: "North Carolina",
		countyState: "Nash County, North Carolina",
		centroidLng: -77.9776000,
		centroidLat: 35.9652000
	},
	{
		geoid: "51199",
		name: "York",
		state: "Virginia",
		countyState: "York County, Virginia",
		centroidLng: -76.4455000,
		centroidLat: 37.2360000
	},
	{
		geoid: "29159",
		name: "Pettis",
		state: "Missouri",
		countyState: "Pettis County, Missouri",
		centroidLng: -93.2816000,
		centroidLat: 38.7246000
	},
	{
		geoid: "30063",
		name: "Missoula",
		state: "Montana",
		countyState: "Missoula County, Montana",
		centroidLng: -114.0500000,
		centroidLat: 47.1159000
	},
	{
		geoid: "20019",
		name: "Chautauqua",
		state: "Kansas",
		countyState: "Chautauqua County, Kansas",
		centroidLng: -96.2449000,
		centroidLat: 37.1507000
	},
	{
		geoid: "48459",
		name: "Upshur",
		state: "Texas",
		countyState: "Upshur County, Texas",
		centroidLng: -94.9129000,
		centroidLat: 32.7104000
	},
	{
		geoid: "51047",
		name: "Culpeper",
		state: "Virginia",
		countyState: "Culpeper County, Virginia",
		centroidLng: -77.9253000,
		centroidLat: 38.5037000
	},
	{
		geoid: "40061",
		name: "Haskell",
		state: "Oklahoma",
		countyState: "Haskell County, Oklahoma",
		centroidLng: -95.1345000,
		centroidLat: 35.2582000
	},
	{
		geoid: "54069",
		name: "Ohio",
		state: "West Virginia",
		countyState: "Ohio County, West Virginia",
		centroidLng: -80.6291000,
		centroidLat: 40.1013000
	},
	{
		geoid: "54065",
		name: "Morgan",
		state: "West Virginia",
		countyState: "Morgan County, West Virginia",
		centroidLng: -78.2453000,
		centroidLat: 39.5436000
	},
	{
		geoid: "51165",
		name: "Rockingham",
		state: "Virginia",
		countyState: "Rockingham County, Virginia",
		centroidLng: -78.8552000,
		centroidLat: 38.5284000
	},
	{
		geoid: "18183",
		name: "Whitley",
		state: "Indiana",
		countyState: "Whitley County, Indiana",
		centroidLng: -85.4972000,
		centroidLat: 41.1486000
	},
	{
		geoid: "06093",
		name: "Siskiyou",
		state: "California",
		countyState: "Siskiyou County, California",
		centroidLng: -122.5830000,
		centroidLat: 41.5008000
	},
	{
		geoid: "46037",
		name: "Day",
		state: "South Dakota",
		countyState: "Day County, South Dakota",
		centroidLng: -97.6039000,
		centroidLat: 45.3698000
	},
	{
		geoid: "22071",
		name: "Orleans",
		state: "Louisiana",
		countyState: "Orleans Parish, Louisiana",
		centroidLng: -89.8826000,
		centroidLat: 30.0327000
	},
	{
		geoid: "01073",
		name: "Jefferson",
		state: "Alabama",
		countyState: "Jefferson County, Alabama",
		centroidLng: -86.9292000,
		centroidLat: 33.5446000
	},
	{
		geoid: "01127",
		name: "Walker",
		state: "Alabama",
		countyState: "Walker County, Alabama",
		centroidLng: -87.2949000,
		centroidLat: 33.7591000
	},
	{
		geoid: "29063",
		name: "DeKalb",
		state: "Missouri",
		countyState: "DeKalb County, Missouri",
		centroidLng: -94.4044000,
		centroidLat: 39.8926000
	},
	{
		geoid: "12039",
		name: "Gadsden",
		state: "Florida",
		countyState: "Gadsden County, Florida",
		centroidLng: -84.6075000,
		centroidLat: 30.5497000
	},
	{
		geoid: "13083",
		name: "Dade",
		state: "Georgia",
		countyState: "Dade County, Georgia",
		centroidLng: -85.4845000,
		centroidLat: 34.8043000
	},
	{
		geoid: "28033",
		name: "DeSoto",
		state: "Mississippi",
		countyState: "DeSoto County, Mississippi",
		centroidLng: -90.0185000,
		centroidLat: 34.8518000
	},
	{
		geoid: "51187",
		name: "Warren",
		state: "Virginia",
		countyState: "Warren County, Virginia",
		centroidLng: -78.1995000,
		centroidLat: 38.8968000
	},
	{
		geoid: "17047",
		name: "Edwards",
		state: "Illinois",
		countyState: "Edwards County, Illinois",
		centroidLng: -88.0440000,
		centroidLat: 38.4131000
	},
	{
		geoid: "47113",
		name: "Madison",
		state: "Tennessee",
		countyState: "Madison County, Tennessee",
		centroidLng: -88.8411000,
		centroidLat: 35.6124000
	},
	{
		geoid: "69110",
		name: "Saipan",
		state: "Commonwealth of the Northern Mariana Islands",
		countyState: "Saipan County, Commonwealth of the Northern Mariana Islands",
		centroidLng: 145.7410000,
		centroidLat: 15.1904000
	},
	{
		geoid: "01111",
		name: "Randolph",
		state: "Alabama",
		countyState: "Randolph County, Alabama",
		centroidLng: -85.4432000,
		centroidLat: 33.3040000
	},
	{
		geoid: "37009",
		name: "Ashe",
		state: "North Carolina",
		countyState: "Ashe County, North Carolina",
		centroidLng: -81.4930000,
		centroidLat: 36.4142000
	},
	{
		geoid: "08033",
		name: "Dolores",
		state: "Colorado",
		countyState: "Dolores County, Colorado",
		centroidLng: -108.4520000,
		centroidLat: 37.6902000
	},
	{
		geoid: "39089",
		name: "Licking",
		state: "Ohio",
		countyState: "Licking County, Ohio",
		centroidLng: -82.4827000,
		centroidLat: 40.0951000
	},
	{
		geoid: "51683",
		name: "Manassas",
		state: "Virginia",
		countyState: "Manassas County, Virginia",
		centroidLng: -77.4872000,
		centroidLat: 38.7438000
	},
	{
		geoid: "05139",
		name: "Union",
		state: "Arkansas",
		countyState: "Union County, Arkansas",
		centroidLng: -92.5289000,
		centroidLat: 33.1980000
	},
	{
		geoid: "17071",
		name: "Henderson",
		state: "Illinois",
		countyState: "Henderson County, Illinois",
		centroidLng: -90.9852000,
		centroidLat: 40.8526000
	},
	{
		geoid: "01101",
		name: "Montgomery",
		state: "Alabama",
		countyState: "Montgomery County, Alabama",
		centroidLng: -86.2086000,
		centroidLat: 32.2334000
	},
	{
		geoid: "08005",
		name: "Arapahoe",
		state: "Colorado",
		countyState: "Arapahoe County, Colorado",
		centroidLng: -104.9010000,
		centroidLat: 39.6846000
	},
	{
		geoid: "21155",
		name: "Marion",
		state: "Kentucky",
		countyState: "Marion County, Kentucky",
		centroidLng: -85.2757000,
		centroidLat: 37.5713000
	},
	{
		geoid: "53057",
		name: "Skagit",
		state: "Washington",
		countyState: "Skagit County, Washington",
		centroidLng: -121.7250000,
		centroidLat: 48.4765000
	},
	{
		geoid: "28067",
		name: "Jones",
		state: "Mississippi",
		countyState: "Jones County, Mississippi",
		centroidLng: -89.1720000,
		centroidLat: 31.6290000
	},
	{
		geoid: "55021",
		name: "Columbia",
		state: "Wisconsin",
		countyState: "Columbia County, Wisconsin",
		centroidLng: -89.3951000,
		centroidLat: 43.4622000
	},
	{
		geoid: "37019",
		name: "Brunswick",
		state: "North Carolina",
		countyState: "Brunswick County, North Carolina",
		centroidLng: -78.2739000,
		centroidLat: 34.0623000
	},
	{
		geoid: "27029",
		name: "Clearwater",
		state: "Minnesota",
		countyState: "Clearwater County, Minnesota",
		centroidLng: -95.3760000,
		centroidLat: 47.5859000
	},
	{
		geoid: "51131",
		name: "Northampton",
		state: "Virginia",
		countyState: "Northampton County, Virginia",
		centroidLng: -75.8691000,
		centroidLat: 37.2740000
	},
	{
		geoid: "20163",
		name: "Rooks",
		state: "Kansas",
		countyState: "Rooks County, Kansas",
		centroidLng: -99.3248000,
		centroidLat: 39.3502000
	},
	{
		geoid: "18041",
		name: "Fayette",
		state: "Indiana",
		countyState: "Fayette County, Indiana",
		centroidLng: -85.1681000,
		centroidLat: 39.6570000
	},
	{
		geoid: "31079",
		name: "Hall",
		state: "Nebraska",
		countyState: "Hall County, Nebraska",
		centroidLng: -98.5022000,
		centroidLat: 40.8725000
	},
	{
		geoid: "31081",
		name: "Hamilton",
		state: "Nebraska",
		countyState: "Hamilton County, Nebraska",
		centroidLng: -98.0542000,
		centroidLat: 40.9359000
	},
	{
		geoid: "31115",
		name: "Loup",
		state: "Nebraska",
		countyState: "Loup County, Nebraska",
		centroidLng: -99.4543000,
		centroidLat: 41.9141000
	},
	{
		geoid: "30091",
		name: "Sheridan",
		state: "Montana",
		countyState: "Sheridan County, Montana",
		centroidLng: -104.5520000,
		centroidLat: 48.6944000
	},
	{
		geoid: "31063",
		name: "Frontier",
		state: "Nebraska",
		countyState: "Frontier County, Nebraska",
		centroidLng: -100.3820000,
		centroidLat: 40.5249000
	},
	{
		geoid: "72089",
		name: "Luquillo",
		state: "Puerto Rico",
		countyState: "Luquillo County, Puerto Rico",
		centroidLng: -65.7083000,
		centroidLat: 18.3660000
	},
	{
		geoid: "01007",
		name: "Bibb",
		state: "Alabama",
		countyState: "Bibb County, Alabama",
		centroidLng: -87.1490000,
		centroidLat: 33.0392000
	},
	{
		geoid: "28075",
		name: "Lauderdale",
		state: "Mississippi",
		countyState: "Lauderdale County, Mississippi",
		centroidLng: -88.6522000,
		centroidLat: 32.4012000
	},
	{
		geoid: "27011",
		name: "Big Stone",
		state: "Minnesota",
		countyState: "Big Stone County, Minnesota",
		centroidLng: -96.4695000,
		centroidLat: 45.3815000
	},
	{
		geoid: "27013",
		name: "Blue Earth",
		state: "Minnesota",
		countyState: "Blue Earth County, Minnesota",
		centroidLng: -94.0697000,
		centroidLat: 44.0561000
	},
	{
		geoid: "51091",
		name: "Highland",
		state: "Virginia",
		countyState: "Highland County, Virginia",
		centroidLng: -79.5592000,
		centroidLat: 38.3860000
	},
	{
		geoid: "51177",
		name: "Spotsylvania",
		state: "Virginia",
		countyState: "Spotsylvania County, Virginia",
		centroidLng: -77.6620000,
		centroidLat: 38.1844000
	},
	{
		geoid: "33017",
		name: "Strafford",
		state: "New Hampshire",
		countyState: "Strafford County, New Hampshire",
		centroidLng: -71.0284000,
		centroidLat: 43.3266000
	},
	{
		geoid: "13153",
		name: "Houston",
		state: "Georgia",
		countyState: "Houston County, Georgia",
		centroidLng: -83.6697000,
		centroidLat: 32.4874000
	},
	{
		geoid: "26041",
		name: "Delta",
		state: "Michigan",
		countyState: "Delta County, Michigan",
		centroidLng: -86.9136000,
		centroidLat: 45.7420000
	},
	{
		geoid: "05131",
		name: "Sebastian",
		state: "Arkansas",
		countyState: "Sebastian County, Arkansas",
		centroidLng: -94.2382000,
		centroidLat: 35.1910000
	},
	{
		geoid: "06087",
		name: "Santa Cruz",
		state: "California",
		countyState: "Santa Cruz County, California",
		centroidLng: -121.9490000,
		centroidLat: 37.0528000
	},
	{
		geoid: "13191",
		name: "McIntosh",
		state: "Georgia",
		countyState: "McIntosh County, Georgia",
		centroidLng: -81.3819000,
		centroidLat: 31.4953000
	},
	{
		geoid: "13207",
		name: "Monroe",
		state: "Georgia",
		countyState: "Monroe County, Georgia",
		centroidLng: -83.9151000,
		centroidLat: 33.0255000
	},
	{
		geoid: "29069",
		name: "Dunklin",
		state: "Missouri",
		countyState: "Dunklin County, Missouri",
		centroidLng: -90.1658000,
		centroidLat: 36.3134000
	},
	{
		geoid: "48483",
		name: "Wheeler",
		state: "Texas",
		countyState: "Wheeler County, Texas",
		centroidLng: -100.2700000,
		centroidLat: 35.4012000
	},
	{
		geoid: "13117",
		name: "Forsyth",
		state: "Georgia",
		countyState: "Forsyth County, Georgia",
		centroidLng: -84.0922000,
		centroidLat: 34.1929000
	},
	{
		geoid: "48409",
		name: "San Patricio",
		state: "Texas",
		countyState: "San Patricio County, Texas",
		centroidLng: -97.5220000,
		centroidLat: 28.0003000
	},
	{
		geoid: "28147",
		name: "Walthall",
		state: "Mississippi",
		countyState: "Walthall County, Mississippi",
		centroidLng: -90.0484000,
		centroidLat: 31.1757000
	},
	{
		geoid: "29137",
		name: "Monroe",
		state: "Missouri",
		countyState: "Monroe County, Missouri",
		centroidLng: -92.0110000,
		centroidLat: 39.5003000
	},
	{
		geoid: "26073",
		name: "Isabella",
		state: "Michigan",
		countyState: "Isabella County, Michigan",
		centroidLng: -84.8474000,
		centroidLat: 43.6406000
	},
	{
		geoid: "48341",
		name: "Moore",
		state: "Texas",
		countyState: "Moore County, Texas",
		centroidLng: -101.8930000,
		centroidLat: 35.8378000
	},
	{
		geoid: "45017",
		name: "Calhoun",
		state: "South Carolina",
		countyState: "Calhoun County, South Carolina",
		centroidLng: -80.7784000,
		centroidLat: 33.6730000
	},
	{
		geoid: "56035",
		name: "Sublette",
		state: "Wyoming",
		countyState: "Sublette County, Wyoming",
		centroidLng: -109.8330000,
		centroidLat: 42.8641000
	},
	{
		geoid: "21069",
		name: "Fleming",
		state: "Kentucky",
		countyState: "Fleming County, Kentucky",
		centroidLng: -83.7185000,
		centroidLat: 38.3482000
	},
	{
		geoid: "55019",
		name: "Clark",
		state: "Wisconsin",
		countyState: "Clark County, Wisconsin",
		centroidLng: -90.6192000,
		centroidLat: 44.7280000
	},
	{
		geoid: "45091",
		name: "York",
		state: "South Carolina",
		countyState: "York County, South Carolina",
		centroidLng: -81.1787000,
		centroidLat: 34.9926000
	},
	{
		geoid: "18001",
		name: "Adams",
		state: "Indiana",
		countyState: "Adams County, Indiana",
		centroidLng: -84.9380000,
		centroidLat: 40.7454000
	},
	{
		geoid: "22005",
		name: "Ascension",
		state: "Louisiana",
		countyState: "Ascension Parish, Louisiana",
		centroidLng: -90.8696000,
		centroidLat: 30.2047000
	},
	{
		geoid: "42119",
		name: "Union",
		state: "Pennsylvania",
		countyState: "Union County, Pennsylvania",
		centroidLng: -77.0817000,
		centroidLat: 40.9781000
	},
	{
		geoid: "51041",
		name: "Chesterfield",
		state: "Virginia",
		countyState: "Chesterfield County, Virginia",
		centroidLng: -77.5613000,
		centroidLat: 37.3905000
	},
	{
		geoid: "22001",
		name: "Acadia",
		state: "Louisiana",
		countyState: "Acadia Parish, Louisiana",
		centroidLng: -92.3869000,
		centroidLat: 30.2651000
	},
	{
		geoid: "42039",
		name: "Crawford",
		state: "Pennsylvania",
		countyState: "Crawford County, Pennsylvania",
		centroidLng: -80.0658000,
		centroidLat: 41.6660000
	},
	{
		geoid: "46123",
		name: "Tripp",
		state: "South Dakota",
		countyState: "Tripp County, South Dakota",
		centroidLng: -99.8823000,
		centroidLat: 43.3821000
	},
	{
		geoid: "19077",
		name: "Guthrie",
		state: "Iowa",
		countyState: "Guthrie County, Iowa",
		centroidLng: -94.4931000,
		centroidLat: 41.6834000
	},
	{
		geoid: "19183",
		name: "Washington",
		state: "Iowa",
		countyState: "Washington County, Iowa",
		centroidLng: -91.7154000,
		centroidLat: 41.3366000
	},
	{
		geoid: "46111",
		name: "Sanborn",
		state: "South Dakota",
		countyState: "Sanborn County, South Dakota",
		centroidLng: -98.0908000,
		centroidLat: 44.0233000
	},
	{
		geoid: "40051",
		name: "Grady",
		state: "Oklahoma",
		countyState: "Grady County, Oklahoma",
		centroidLng: -97.8820000,
		centroidLat: 35.0298000
	},
	{
		geoid: "54039",
		name: "Kanawha",
		state: "West Virginia",
		countyState: "Kanawha County, West Virginia",
		centroidLng: -81.5547000,
		centroidLat: 38.2968000
	},
	{
		geoid: "55109",
		name: "St. Croix",
		state: "Wisconsin",
		countyState: "St. Croix County, Wisconsin",
		centroidLng: -92.4692000,
		centroidLat: 45.0341000
	},
	{
		geoid: "42121",
		name: "Venango",
		state: "Pennsylvania",
		countyState: "Venango County, Pennsylvania",
		centroidLng: -79.7388000,
		centroidLat: 41.3981000
	},
	{
		geoid: "39153",
		name: "Summit",
		state: "Ohio",
		countyState: "Summit County, Ohio",
		centroidLng: -81.5400000,
		centroidLat: 41.1288000
	},
	{
		geoid: "13127",
		name: "Glynn",
		state: "Georgia",
		countyState: "Glynn County, Georgia",
		centroidLng: -81.4825000,
		centroidLat: 31.2218000
	},
	{
		geoid: "48479",
		name: "Webb",
		state: "Texas",
		countyState: "Webb County, Texas",
		centroidLng: -99.5051000,
		centroidLat: 27.7321000
	},
	{
		geoid: "37123",
		name: "Montgomery",
		state: "North Carolina",
		countyState: "Montgomery County, North Carolina",
		centroidLng: -79.8984000,
		centroidLat: 35.3243000
	},
	{
		geoid: "23003",
		name: "Aroostook",
		state: "Maine",
		countyState: "Aroostook County, Maine",
		centroidLng: -68.8870000,
		centroidLat: 46.5163000
	},
	{
		geoid: "28065",
		name: "Jefferson Davis",
		state: "Mississippi",
		countyState: "Jefferson Davis County, Mississippi",
		centroidLng: -89.7814000,
		centroidLat: 31.5823000
	},
	{
		geoid: "18175",
		name: "Washington",
		state: "Indiana",
		countyState: "Washington County, Indiana",
		centroidLng: -86.0788000,
		centroidLat: 38.6007000
	},
	{
		geoid: "48031",
		name: "Blanco",
		state: "Texas",
		countyState: "Blanco County, Texas",
		centroidLng: -98.3586000,
		centroidLat: 30.2198000
	},
	{
		geoid: "51025",
		name: "Brunswick",
		state: "Virginia",
		countyState: "Brunswick County, Virginia",
		centroidLng: -77.8511000,
		centroidLat: 36.7840000
	},
	{
		geoid: "47045",
		name: "Dyer",
		state: "Tennessee",
		countyState: "Dyer County, Tennessee",
		centroidLng: -89.4438000,
		centroidLat: 36.0469000
	},
	{
		geoid: "47053",
		name: "Gibson",
		state: "Tennessee",
		countyState: "Gibson County, Tennessee",
		centroidLng: -88.9495000,
		centroidLat: 36.0070000
	},
	{
		geoid: "51149",
		name: "Prince George",
		state: "Virginia",
		countyState: "Prince George County, Virginia",
		centroidLng: -77.1858000,
		centroidLat: 37.1570000
	},
	{
		geoid: "05075",
		name: "Lawrence",
		state: "Arkansas",
		countyState: "Lawrence County, Arkansas",
		centroidLng: -91.0525000,
		centroidLat: 36.0694000
	},
	{
		geoid: "38027",
		name: "Eddy",
		state: "North Dakota",
		countyState: "Eddy County, North Dakota",
		centroidLng: -98.8987000,
		centroidLat: 47.7174000
	},
	{
		geoid: "53053",
		name: "Pierce",
		state: "Washington",
		countyState: "Pierce County, Washington",
		centroidLng: -122.1140000,
		centroidLat: 47.0660000
	},
	{
		geoid: "16063",
		name: "Lincoln",
		state: "Idaho",
		countyState: "Lincoln County, Idaho",
		centroidLng: -114.1540000,
		centroidLat: 42.9822000
	},
	{
		geoid: "42071",
		name: "Lancaster",
		state: "Pennsylvania",
		countyState: "Lancaster County, Pennsylvania",
		centroidLng: -76.2975000,
		centroidLat: 40.0186000
	},
	{
		geoid: "55057",
		name: "Juneau",
		state: "Wisconsin",
		countyState: "Juneau County, Wisconsin",
		centroidLng: -90.0491000,
		centroidLat: 43.9452000
	},
	{
		geoid: "47151",
		name: "Scott",
		state: "Tennessee",
		countyState: "Scott County, Tennessee",
		centroidLng: -84.5173000,
		centroidLat: 36.3835000
	},
	{
		geoid: "46031",
		name: "Corson",
		state: "South Dakota",
		countyState: "Corson County, South Dakota",
		centroidLng: -101.1550000,
		centroidLat: 45.7085000
	},
	{
		geoid: "46053",
		name: "Gregory",
		state: "South Dakota",
		countyState: "Gregory County, South Dakota",
		centroidLng: -99.0165000,
		centroidLat: 43.2488000
	},
	{
		geoid: "37181",
		name: "Vance",
		state: "North Carolina",
		countyState: "Vance County, North Carolina",
		centroidLng: -78.3942000,
		centroidLat: 36.3537000
	},
	{
		geoid: "21033",
		name: "Caldwell",
		state: "Kentucky",
		countyState: "Caldwell County, Kentucky",
		centroidLng: -87.8879000,
		centroidLat: 37.1651000
	},
	{
		geoid: "45011",
		name: "Barnwell",
		state: "South Carolina",
		countyState: "Barnwell County, South Carolina",
		centroidLng: -81.4827000,
		centroidLat: 33.2917000
	},
	{
		geoid: "47039",
		name: "Decatur",
		state: "Tennessee",
		countyState: "Decatur County, Tennessee",
		centroidLng: -88.1094000,
		centroidLat: 35.6149000
	},
	{
		geoid: "18009",
		name: "Blackford",
		state: "Indiana",
		countyState: "Blackford County, Indiana",
		centroidLng: -85.3238000,
		centroidLat: 40.4728000
	},
	{
		geoid: "01055",
		name: "Etowah",
		state: "Alabama",
		countyState: "Etowah County, Alabama",
		centroidLng: -86.0536000,
		centroidLat: 34.0212000
	},
	{
		geoid: "16025",
		name: "Camas",
		state: "Idaho",
		countyState: "Camas County, Idaho",
		centroidLng: -114.7310000,
		centroidLat: 43.5283000
	},
	{
		geoid: "20155",
		name: "Reno",
		state: "Kansas",
		countyState: "Reno County, Kansas",
		centroidLng: -98.0859000,
		centroidLat: 37.9532000
	},
	{
		geoid: "47119",
		name: "Maury",
		state: "Tennessee",
		countyState: "Maury County, Tennessee",
		centroidLng: -87.0682000,
		centroidLat: 35.6294000
	},
	{
		geoid: "56015",
		name: "Goshen",
		state: "Wyoming",
		countyState: "Goshen County, Wyoming",
		centroidLng: -104.3540000,
		centroidLat: 42.0881000
	},
	{
		geoid: "55107",
		name: "Rusk",
		state: "Wisconsin",
		countyState: "Rusk County, Wisconsin",
		centroidLng: -91.1096000,
		centroidLat: 45.4654000
	},
	{
		geoid: "55133",
		name: "Waukesha",
		state: "Wisconsin",
		countyState: "Waukesha County, Wisconsin",
		centroidLng: -88.3028000,
		centroidLat: 43.0190000
	},
	{
		geoid: "21079",
		name: "Garrard",
		state: "Kentucky",
		countyState: "Garrard County, Kentucky",
		centroidLng: -84.5467000,
		centroidLat: 37.6514000
	},
	{
		geoid: "25021",
		name: "Norfolk",
		state: "Massachusetts",
		countyState: "Norfolk County, Massachusetts",
		centroidLng: -71.2026000,
		centroidLat: 42.1566000
	},
	{
		geoid: "18063",
		name: "Hendricks",
		state: "Indiana",
		countyState: "Hendricks County, Indiana",
		centroidLng: -86.5104000,
		centroidLat: 39.7625000
	},
	{
		geoid: "17077",
		name: "Jackson",
		state: "Illinois",
		countyState: "Jackson County, Illinois",
		centroidLng: -89.4157000,
		centroidLat: 37.7586000
	},
	{
		geoid: "45043",
		name: "Georgetown",
		state: "South Carolina",
		countyState: "Georgetown County, South Carolina",
		centroidLng: -79.3038000,
		centroidLat: 33.4148000
	},
	{
		geoid: "38041",
		name: "Hettinger",
		state: "North Dakota",
		countyState: "Hettinger County, North Dakota",
		centroidLng: -102.4630000,
		centroidLat: 46.4182000
	},
	{
		geoid: "49019",
		name: "Grand",
		state: "Utah",
		countyState: "Grand County, Utah",
		centroidLng: -109.6150000,
		centroidLat: 38.9999000
	},
	{
		geoid: "49045",
		name: "Tooele",
		state: "Utah",
		countyState: "Tooele County, Utah",
		centroidLng: -113.0960000,
		centroidLat: 40.4903000
	},
	{
		geoid: "51650",
		name: "Hampton",
		state: "Virginia",
		countyState: "Hampton County, Virginia",
		centroidLng: -76.2925000,
		centroidLat: 37.0230000
	},
	{
		geoid: "55011",
		name: "Buffalo",
		state: "Wisconsin",
		countyState: "Buffalo County, Wisconsin",
		centroidLng: -91.8064000,
		centroidLat: 44.3111000
	},
	{
		geoid: "51069",
		name: "Frederick",
		state: "Virginia",
		countyState: "Frederick County, Virginia",
		centroidLng: -78.2887000,
		centroidLat: 39.2370000
	},
	{
		geoid: "54041",
		name: "Lewis",
		state: "West Virginia",
		countyState: "Lewis County, West Virginia",
		centroidLng: -80.5145000,
		centroidLat: 38.9458000
	},
	{
		geoid: "27119",
		name: "Polk",
		state: "Minnesota",
		countyState: "Polk County, Minnesota",
		centroidLng: -96.3504000,
		centroidLat: 47.8364000
	},
	{
		geoid: "27127",
		name: "Redwood",
		state: "Minnesota",
		countyState: "Redwood County, Minnesota",
		centroidLng: -95.2302000,
		centroidLat: 44.4468000
	},
	{
		geoid: "47047",
		name: "Fayette",
		state: "Tennessee",
		countyState: "Fayette County, Tennessee",
		centroidLng: -89.4141000,
		centroidLat: 35.1993000
	},
	{
		geoid: "60040",
		name: "Swains Island",
		state: "American Samoa",
		countyState: "Swains Island County, American Samoa",
		centroidLng: -171.0770000,
		centroidLat: -11.0537000
	},
	{
		geoid: "22089",
		name: "St. Charles",
		state: "Louisiana",
		countyState: "St. Charles Parish, Louisiana",
		centroidLng: -90.3585000,
		centroidLat: 29.9277000
	},
	{
		geoid: "05111",
		name: "Poinsett",
		state: "Arkansas",
		countyState: "Poinsett County, Arkansas",
		centroidLng: -90.6627000,
		centroidLat: 35.5735000
	},
	{
		geoid: "12045",
		name: "Gulf",
		state: "Florida",
		countyState: "Gulf County, Florida",
		centroidLng: -85.2465000,
		centroidLat: 29.9025000
	},
	{
		geoid: "47063",
		name: "Hamblen",
		state: "Tennessee",
		countyState: "Hamblen County, Tennessee",
		centroidLng: -83.2752000,
		centroidLat: 36.2133000
	},
	{
		geoid: "01021",
		name: "Chilton",
		state: "Alabama",
		countyState: "Chilton County, Alabama",
		centroidLng: -86.6951000,
		centroidLat: 32.8660000
	},
	{
		geoid: "48351",
		name: "Newton",
		state: "Texas",
		countyState: "Newton County, Texas",
		centroidLng: -93.7096000,
		centroidLat: 30.7146000
	},
	{
		geoid: "48219",
		name: "Hockley",
		state: "Texas",
		countyState: "Hockley County, Texas",
		centroidLng: -102.3460000,
		centroidLat: 33.6067000
	},
	{
		geoid: "13009",
		name: "Baldwin",
		state: "Georgia",
		countyState: "Baldwin County, Georgia",
		centroidLng: -83.2367000,
		centroidLat: 33.0583000
	},
	{
		geoid: "37175",
		name: "Transylvania",
		state: "North Carolina",
		countyState: "Transylvania County, North Carolina",
		centroidLng: -82.8159000,
		centroidLat: 35.2253000
	},
	{
		geoid: "37171",
		name: "Surry",
		state: "North Carolina",
		countyState: "Surry County, North Carolina",
		centroidLng: -80.7069000,
		centroidLat: 36.3993000
	},
	{
		geoid: "01031",
		name: "Coffee",
		state: "Alabama",
		countyState: "Coffee County, Alabama",
		centroidLng: -85.9928000,
		centroidLat: 31.4007000
	},
	{
		geoid: "39149",
		name: "Shelby",
		state: "Ohio",
		countyState: "Shelby County, Ohio",
		centroidLng: -84.2183000,
		centroidLat: 40.3336000
	},
	{
		geoid: "16027",
		name: "Canyon",
		state: "Idaho",
		countyState: "Canyon County, Idaho",
		centroidLng: -116.7530000,
		centroidLat: 43.5854000
	},
	{
		geoid: "30019",
		name: "Daniels",
		state: "Montana",
		countyState: "Daniels County, Montana",
		centroidLng: -105.5630000,
		centroidLat: 48.7814000
	},
	{
		geoid: "12073",
		name: "Leon",
		state: "Florida",
		countyState: "Leon County, Florida",
		centroidLng: -84.3463000,
		centroidLat: 30.4794000
	},
	{
		geoid: "40119",
		name: "Payne",
		state: "Oklahoma",
		countyState: "Payne County, Oklahoma",
		centroidLng: -96.9876000,
		centroidLat: 36.0936000
	},
	{
		geoid: "01039",
		name: "Covington",
		state: "Alabama",
		countyState: "Covington County, Alabama",
		centroidLng: -86.4448000,
		centroidLat: 31.2609000
	},
	{
		geoid: "18093",
		name: "Lawrence",
		state: "Indiana",
		countyState: "Lawrence County, Indiana",
		centroidLng: -86.4797000,
		centroidLat: 38.8395000
	},
	{
		geoid: "20069",
		name: "Gray",
		state: "Kansas",
		countyState: "Gray County, Kansas",
		centroidLng: -100.4400000,
		centroidLat: 37.7386000
	},
	{
		geoid: "21045",
		name: "Casey",
		state: "Kentucky",
		countyState: "Casey County, Kentucky",
		centroidLng: -84.9356000,
		centroidLat: 37.3268000
	},
	{
		geoid: "21169",
		name: "Metcalfe",
		state: "Kentucky",
		countyState: "Metcalfe County, Kentucky",
		centroidLng: -85.6094000,
		centroidLat: 37.0002000
	},
	{
		geoid: "21207",
		name: "Russell",
		state: "Kentucky",
		countyState: "Russell County, Kentucky",
		centroidLng: -85.0553000,
		centroidLat: 37.0067000
	},
	{
		geoid: "22011",
		name: "Beauregard",
		state: "Louisiana",
		countyState: "Beauregard Parish, Louisiana",
		centroidLng: -93.3592000,
		centroidLat: 30.6429000
	},
	{
		geoid: "23025",
		name: "Somerset",
		state: "Maine",
		countyState: "Somerset County, Maine",
		centroidLng: -69.9098000,
		centroidLat: 45.5757000
	},
	{
		geoid: "40025",
		name: "Cimarron",
		state: "Oklahoma",
		countyState: "Cimarron County, Oklahoma",
		centroidLng: -102.5150000,
		centroidLat: 36.7502000
	},
	{
		geoid: "21163",
		name: "Meade",
		state: "Kentucky",
		countyState: "Meade County, Kentucky",
		centroidLng: -86.2334000,
		centroidLat: 37.9989000
	},
	{
		geoid: "21179",
		name: "Nelson",
		state: "Kentucky",
		countyState: "Nelson County, Kentucky",
		centroidLng: -85.4466000,
		centroidLat: 37.7568000
	},
	{
		geoid: "21187",
		name: "Owen",
		state: "Kentucky",
		countyState: "Owen County, Kentucky",
		centroidLng: -84.8283000,
		centroidLat: 38.5316000
	},
	{
		geoid: "29155",
		name: "Pemiscot",
		state: "Missouri",
		countyState: "Pemiscot County, Missouri",
		centroidLng: -89.7481000,
		centroidLat: 36.2134000
	},
	{
		geoid: "31125",
		name: "Nance",
		state: "Nebraska",
		countyState: "Nance County, Nebraska",
		centroidLng: -97.9976000,
		centroidLat: 41.3955000
	},
	{
		geoid: "55078",
		name: "Menominee",
		state: "Wisconsin",
		countyState: "Menominee County, Wisconsin",
		centroidLng: -88.7331000,
		centroidLat: 44.9867000
	},
	{
		geoid: "48365",
		name: "Panola",
		state: "Texas",
		countyState: "Panola County, Texas",
		centroidLng: -94.3087000,
		centroidLat: 32.1837000
	},
	{
		geoid: "21011",
		name: "Bath",
		state: "Kentucky",
		countyState: "Bath County, Kentucky",
		centroidLng: -83.7215000,
		centroidLat: 38.1476000
	},
	{
		geoid: "48001",
		name: "Anderson",
		state: "Texas",
		countyState: "Anderson County, Texas",
		centroidLng: -95.6618000,
		centroidLat: 31.7943000
	},
	{
		geoid: "51033",
		name: "Caroline",
		state: "Virginia",
		countyState: "Caroline County, Virginia",
		centroidLng: -77.3562000,
		centroidLat: 38.0144000
	},
	{
		geoid: "19167",
		name: "Sioux",
		state: "Iowa",
		countyState: "Sioux County, Iowa",
		centroidLng: -96.2154000,
		centroidLat: 43.0845000
	},
	{
		geoid: "06021",
		name: "Glenn",
		state: "California",
		countyState: "Glenn County, California",
		centroidLng: -122.3970000,
		centroidLat: 39.5918000
	},
	{
		geoid: "29510",
		name: "St. Louis",
		state: "Missouri",
		countyState: "St. Louis County, Missouri",
		centroidLng: -90.2435000,
		centroidLat: 38.6531000
	},
	{
		geoid: "13111",
		name: "Fannin",
		state: "Georgia",
		countyState: "Fannin County, Georgia",
		centroidLng: -84.3578000,
		centroidLat: 34.7956000
	},
	{
		geoid: "46051",
		name: "Grant",
		state: "South Dakota",
		countyState: "Grant County, South Dakota",
		centroidLng: -96.8392000,
		centroidLat: 45.1515000
	},
	{
		geoid: "46091",
		name: "Marshall",
		state: "South Dakota",
		countyState: "Marshall County, South Dakota",
		centroidLng: -97.6033000,
		centroidLat: 45.7471000
	},
	{
		geoid: "53067",
		name: "Thurston",
		state: "Washington",
		countyState: "Thurston County, Washington",
		centroidLng: -122.7030000,
		centroidLat: 46.9781000
	},
	{
		geoid: "31107",
		name: "Knox",
		state: "Nebraska",
		countyState: "Knox County, Nebraska",
		centroidLng: -97.8976000,
		centroidLat: 42.6598000
	},
	{
		geoid: "35061",
		name: "Valencia",
		state: "New Mexico",
		countyState: "Valencia County, New Mexico",
		centroidLng: -106.8080000,
		centroidLat: 34.6973000
	},
	{
		geoid: "30079",
		name: "Prairie",
		state: "Montana",
		countyState: "Prairie County, Montana",
		centroidLng: -105.3450000,
		centroidLat: 46.8610000
	},
	{
		geoid: "19005",
		name: "Allamakee",
		state: "Iowa",
		countyState: "Allamakee County, Iowa",
		centroidLng: -91.3345000,
		centroidLat: 43.2906000
	},
	{
		geoid: "48061",
		name: "Cameron",
		state: "Texas",
		countyState: "Cameron County, Texas",
		centroidLng: -97.4758000,
		centroidLat: 26.1251000
	},
	{
		geoid: "48339",
		name: "Montgomery",
		state: "Texas",
		countyState: "Montgomery County, Texas",
		centroidLng: -95.4635000,
		centroidLat: 30.3290000
	},
	{
		geoid: "08071",
		name: "Las Animas",
		state: "Colorado",
		countyState: "Las Animas County, Colorado",
		centroidLng: -104.1150000,
		centroidLat: 37.4051000
	},
	{
		geoid: "48299",
		name: "Llano",
		state: "Texas",
		countyState: "Llano County, Texas",
		centroidLng: -98.6578000,
		centroidLat: 30.7041000
	},
	{
		geoid: "48319",
		name: "Mason",
		state: "Texas",
		countyState: "Mason County, Texas",
		centroidLng: -99.2242000,
		centroidLat: 30.7196000
	},
	{
		geoid: "37043",
		name: "Clay",
		state: "North Carolina",
		countyState: "Clay County, North Carolina",
		centroidLng: -83.7444000,
		centroidLat: 35.0739000
	},
	{
		geoid: "36071",
		name: "Orange",
		state: "New York",
		countyState: "Orange County, New York",
		centroidLng: -74.3550000,
		centroidLat: 41.3879000
	},
	{
		geoid: "36093",
		name: "Schenectady",
		state: "New York",
		countyState: "Schenectady County, New York",
		centroidLng: -74.0580000,
		centroidLat: 42.8333000
	},
	{
		geoid: "54059",
		name: "Mingo",
		state: "West Virginia",
		countyState: "Mingo County, West Virginia",
		centroidLng: -82.1081000,
		centroidLat: 37.7431000
	},
	{
		geoid: "20197",
		name: "Wabaunsee",
		state: "Kansas",
		countyState: "Wabaunsee County, Kansas",
		centroidLng: -96.2238000,
		centroidLat: 38.9755000
	},
	{
		geoid: "27055",
		name: "Houston",
		state: "Minnesota",
		countyState: "Houston County, Minnesota",
		centroidLng: -91.4740000,
		centroidLat: 43.6738000
	},
	{
		geoid: "21181",
		name: "Nicholas",
		state: "Kentucky",
		countyState: "Nicholas County, Kentucky",
		centroidLng: -84.0138000,
		centroidLat: 38.3264000
	},
	{
		geoid: "25023",
		name: "Plymouth",
		state: "Massachusetts",
		countyState: "Plymouth County, Massachusetts",
		centroidLng: -70.7015000,
		centroidLat: 41.9705000
	},
	{
		geoid: "36005",
		name: "Bronx",
		state: "New York",
		countyState: "Bronx County, New York",
		centroidLng: -73.8409000,
		centroidLat: 40.8515000
	},
	{
		geoid: "37055",
		name: "Dare",
		state: "North Carolina",
		countyState: "Dare County, North Carolina",
		centroidLng: -75.7138000,
		centroidLat: 35.6928000
	},
	{
		geoid: "39103",
		name: "Medina",
		state: "Ohio",
		countyState: "Medina County, Ohio",
		centroidLng: -81.9292000,
		centroidLat: 41.1327000
	},
	{
		geoid: "42005",
		name: "Armstrong",
		state: "Pennsylvania",
		countyState: "Armstrong County, Pennsylvania",
		centroidLng: -79.4516000,
		centroidLat: 40.8478000
	},
	{
		geoid: "34001",
		name: "Atlantic",
		state: "New Jersey",
		countyState: "Atlantic County, New Jersey",
		centroidLng: -74.6087000,
		centroidLat: 39.5092000
	},
	{
		geoid: "55071",
		name: "Manitowoc",
		state: "Wisconsin",
		countyState: "Manitowoc County, Wisconsin",
		centroidLng: -87.4836000,
		centroidLat: 44.1102000
	},
	{
		geoid: "55029",
		name: "Door",
		state: "Wisconsin",
		countyState: "Door County, Wisconsin",
		centroidLng: -87.0060000,
		centroidLat: 45.0597000
	},
	{
		geoid: "37047",
		name: "Columbus",
		state: "North Carolina",
		countyState: "Columbus County, North Carolina",
		centroidLng: -78.6167000,
		centroidLat: 34.2146000
	},
	{
		geoid: "39053",
		name: "Gallia",
		state: "Ohio",
		countyState: "Gallia County, Ohio",
		centroidLng: -82.3391000,
		centroidLat: 38.8089000
	},
	{
		geoid: "48441",
		name: "Taylor",
		state: "Texas",
		countyState: "Taylor County, Texas",
		centroidLng: -99.8907000,
		centroidLat: 32.3021000
	},
	{
		geoid: "51570",
		name: "Colonial Heights",
		state: "Virginia",
		countyState: "Colonial Heights County, Virginia",
		centroidLng: -77.3969000,
		centroidLat: 37.2659000
	},
	{
		geoid: "39031",
		name: "Coshocton",
		state: "Ohio",
		countyState: "Coshocton County, Ohio",
		centroidLng: -81.9060000,
		centroidLat: 40.3036000
	},
	{
		geoid: "31135",
		name: "Perkins",
		state: "Nebraska",
		countyState: "Perkins County, Nebraska",
		centroidLng: -101.6500000,
		centroidLat: 40.8508000
	},
	{
		geoid: "48205",
		name: "Hartley",
		state: "Texas",
		countyState: "Hartley County, Texas",
		centroidLng: -102.6020000,
		centroidLat: 35.8391000
	},
	{
		geoid: "08049",
		name: "Grand",
		state: "Colorado",
		countyState: "Grand County, Colorado",
		centroidLng: -106.1400000,
		centroidLat: 40.0852000
	},
	{
		geoid: "48249",
		name: "Jim Wells",
		state: "Texas",
		countyState: "Jim Wells County, Texas",
		centroidLng: -98.0122000,
		centroidLat: 27.6595000
	},
	{
		geoid: "19189",
		name: "Winnebago",
		state: "Iowa",
		countyState: "Winnebago County, Iowa",
		centroidLng: -93.7341000,
		centroidLat: 43.3775000
	},
	{
		geoid: "06079",
		name: "San Luis Obispo",
		state: "California",
		countyState: "San Luis Obispo County, California",
		centroidLng: -120.4550000,
		centroidLat: 35.3471000
	},
	{
		geoid: "01067",
		name: "Henry",
		state: "Alabama",
		countyState: "Henry County, Alabama",
		centroidLng: -85.2297000,
		centroidLat: 31.5410000
	},
	{
		geoid: "39175",
		name: "Wyandot",
		state: "Ohio",
		countyState: "Wyandot County, Ohio",
		centroidLng: -83.3136000,
		centroidLat: 40.8402000
	},
	{
		geoid: "48505",
		name: "Zapata",
		state: "Texas",
		countyState: "Zapata County, Texas",
		centroidLng: -99.2040000,
		centroidLat: 26.9454000
	},
	{
		geoid: "13177",
		name: "Lee",
		state: "Georgia",
		countyState: "Lee County, Georgia",
		centroidLng: -84.3099000,
		centroidLat: 31.6872000
	},
	{
		geoid: "21019",
		name: "Boyd",
		state: "Kentucky",
		countyState: "Boyd County, Kentucky",
		centroidLng: -82.6946000,
		centroidLat: 38.3702000
	},
	{
		geoid: "27019",
		name: "Carver",
		state: "Minnesota",
		countyState: "Carver County, Minnesota",
		centroidLng: -93.7665000,
		centroidLat: 44.8066000
	},
	{
		geoid: "31037",
		name: "Colfax",
		state: "Nebraska",
		countyState: "Colfax County, Nebraska",
		centroidLng: -97.0797000,
		centroidLat: 41.5600000
	},
	{
		geoid: "45025",
		name: "Chesterfield",
		state: "South Carolina",
		countyState: "Chesterfield County, South Carolina",
		centroidLng: -80.1708000,
		centroidLat: 34.5918000
	},
	{
		geoid: "47101",
		name: "Lewis",
		state: "Tennessee",
		countyState: "Lewis County, Tennessee",
		centroidLng: -87.4935000,
		centroidLat: 35.5290000
	},
	{
		geoid: "41067",
		name: "Washington",
		state: "Oregon",
		countyState: "Washington County, Oregon",
		centroidLng: -123.1150000,
		centroidLat: 45.5487000
	},
	{
		geoid: "51810",
		name: "Virginia Beach",
		state: "Virginia",
		countyState: "Virginia Beach County, Virginia",
		centroidLng: -76.0128000,
		centroidLat: 36.7956000
	},
	{
		geoid: "30057",
		name: "Madison",
		state: "Montana",
		countyState: "Madison County, Montana",
		centroidLng: -112.0270000,
		centroidLat: 45.2834000
	},
	{
		geoid: "51103",
		name: "Lancaster",
		state: "Virginia",
		countyState: "Lancaster County, Virginia",
		centroidLng: -76.4100000,
		centroidLat: 37.7131000
	},
	{
		geoid: "29013",
		name: "Bates",
		state: "Missouri",
		countyState: "Bates County, Missouri",
		centroidLng: -94.3320000,
		centroidLat: 38.2518000
	},
	{
		geoid: "33001",
		name: "Belknap",
		state: "New Hampshire",
		countyState: "Belknap County, New Hampshire",
		centroidLng: -71.4455000,
		centroidLat: 43.5230000
	},
	{
		geoid: "51159",
		name: "Richmond",
		state: "Virginia",
		countyState: "Richmond County, Virginia",
		centroidLng: -76.7236000,
		centroidLat: 37.9514000
	},
	{
		geoid: "02230",
		name: "Skagway",
		state: "Alaska",
		countyState: "Skagway Borough, Alaska",
		centroidLng: -135.3540000,
		centroidLat: 59.5722000
	},
	{
		geoid: "02105",
		name: "Hoonah-Angoon",
		state: "Alaska",
		countyState: "Hoonah-Angoon Borough, Alaska",
		centroidLng: -135.8910000,
		centroidLat: 59.3981000
	},
	{
		geoid: "53021",
		name: "Franklin",
		state: "Washington",
		countyState: "Franklin County, Washington",
		centroidLng: -118.8280000,
		centroidLat: 46.4659000
	},
	{
		geoid: "19093",
		name: "Ida",
		state: "Iowa",
		countyState: "Ida County, Iowa",
		centroidLng: -95.5321000,
		centroidLat: 42.3863000
	},
	{
		geoid: "20173",
		name: "Sedgwick",
		state: "Kansas",
		countyState: "Sedgwick County, Kansas",
		centroidLng: -97.4803000,
		centroidLat: 37.6935000
	},
	{
		geoid: "48453",
		name: "Travis",
		state: "Texas",
		countyState: "Travis County, Texas",
		centroidLng: -97.7713000,
		centroidLat: 30.3264000
	},
	{
		geoid: "12057",
		name: "Hillsborough",
		state: "Florida",
		countyState: "Hillsborough County, Florida",
		centroidLng: -82.4388000,
		centroidLat: 27.8720000
	},
	{
		geoid: "34021",
		name: "Mercer",
		state: "New Jersey",
		countyState: "Mercer County, New Jersey",
		centroidLng: -74.7120000,
		centroidLat: 40.2805000
	},
	{
		geoid: "31023",
		name: "Butler",
		state: "Nebraska",
		countyState: "Butler County, Nebraska",
		centroidLng: -97.1370000,
		centroidLat: 41.2513000
	},
	{
		geoid: "42129",
		name: "Westmoreland",
		state: "Pennsylvania",
		countyState: "Westmoreland County, Pennsylvania",
		centroidLng: -79.4396000,
		centroidLat: 40.3602000
	},
	{
		geoid: "12015",
		name: "Charlotte",
		state: "Florida",
		countyState: "Charlotte County, Florida",
		centroidLng: -81.9998000,
		centroidLat: 26.9024000
	},
	{
		geoid: "19029",
		name: "Cass",
		state: "Iowa",
		countyState: "Cass County, Iowa",
		centroidLng: -94.9280000,
		centroidLat: 41.3316000
	},
	{
		geoid: "39107",
		name: "Mercer",
		state: "Ohio",
		countyState: "Mercer County, Ohio",
		centroidLng: -84.6179000,
		centroidLat: 40.5400000
	},
	{
		geoid: "60030",
		name: "Rose Island",
		state: "American Samoa",
		countyState: "Rose Island County, American Samoa",
		centroidLng: -168.1640000,
		centroidLat: -14.5398000
	},
	{
		geoid: "21115",
		name: "Johnson",
		state: "Kentucky",
		countyState: "Johnson County, Kentucky",
		centroidLng: -82.8085000,
		centroidLat: 37.8519000
	},
	{
		geoid: "44003",
		name: "Kent",
		state: "Rhode Island",
		countyState: "Kent County, Rhode Island",
		centroidLng: -71.5577000,
		centroidLat: 41.6826000
	},
	{
		geoid: "50025",
		name: "Windham",
		state: "Vermont",
		countyState: "Windham County, Vermont",
		centroidLng: -72.7201000,
		centroidLat: 42.9947000
	},
	{
		geoid: "50019",
		name: "Orleans",
		state: "Vermont",
		countyState: "Orleans County, Vermont",
		centroidLng: -72.2263000,
		centroidLat: 44.7756000
	},
	{
		geoid: "20085",
		name: "Jackson",
		state: "Kansas",
		countyState: "Jackson County, Kansas",
		centroidLng: -95.8001000,
		centroidLat: 39.4346000
	},
	{
		geoid: "22041",
		name: "Franklin",
		state: "Louisiana",
		countyState: "Franklin Parish, Louisiana",
		centroidLng: -91.6919000,
		centroidLat: 32.1389000
	},
	{
		geoid: "28155",
		name: "Webster",
		state: "Mississippi",
		countyState: "Webster County, Mississippi",
		centroidLng: -89.2627000,
		centroidLat: 33.5996000
	},
	{
		geoid: "25011",
		name: "Franklin",
		state: "Massachusetts",
		countyState: "Franklin County, Massachusetts",
		centroidLng: -72.6243000,
		centroidLat: 42.5222000
	},
	{
		geoid: "31031",
		name: "Cherry",
		state: "Nebraska",
		countyState: "Cherry County, Nebraska",
		centroidLng: -101.1260000,
		centroidLat: 42.5426000
	},
	{
		geoid: "30001",
		name: "Beaverhead",
		state: "Montana",
		countyState: "Beaverhead County, Montana",
		centroidLng: -112.7010000,
		centroidLat: 45.1497000
	},
	{
		geoid: "29143",
		name: "New Madrid",
		state: "Missouri",
		countyState: "New Madrid County, Missouri",
		centroidLng: -89.6500000,
		centroidLat: 36.6038000
	},
	{
		geoid: "05057",
		name: "Hempstead",
		state: "Arkansas",
		countyState: "Hempstead County, Arkansas",
		centroidLng: -93.7130000,
		centroidLat: 33.7425000
	},
	{
		geoid: "29029",
		name: "Camden",
		state: "Missouri",
		countyState: "Camden County, Missouri",
		centroidLng: -92.7442000,
		centroidLat: 38.0361000
	},
	{
		geoid: "51117",
		name: "Mecklenburg",
		state: "Virginia",
		countyState: "Mecklenburg County, Virginia",
		centroidLng: -78.3838000,
		centroidLat: 36.7161000
	},
	{
		geoid: "21233",
		name: "Webster",
		state: "Kentucky",
		countyState: "Webster County, Kentucky",
		centroidLng: -87.6595000,
		centroidLat: 37.4991000
	},
	{
		geoid: "40047",
		name: "Garfield",
		state: "Oklahoma",
		countyState: "Garfield County, Oklahoma",
		centroidLng: -97.7825000,
		centroidLat: 36.3791000
	},
	{
		geoid: "53059",
		name: "Skamania",
		state: "Washington",
		countyState: "Skamania County, Washington",
		centroidLng: -121.8820000,
		centroidLat: 45.9686000
	},
	{
		geoid: "26091",
		name: "Lenawee",
		state: "Michigan",
		countyState: "Lenawee County, Michigan",
		centroidLng: -84.0632000,
		centroidLat: 41.8945000
	},
	{
		geoid: "13123",
		name: "Gilmer",
		state: "Georgia",
		countyState: "Gilmer County, Georgia",
		centroidLng: -84.4253000,
		centroidLat: 34.7030000
	},
	{
		geoid: "27135",
		name: "Roseau",
		state: "Minnesota",
		countyState: "Roseau County, Minnesota",
		centroidLng: -95.7477000,
		centroidLat: 48.7693000
	},
	{
		geoid: "28089",
		name: "Madison",
		state: "Mississippi",
		countyState: "Madison County, Mississippi",
		centroidLng: -90.0907000,
		centroidLat: 32.6425000
	},
	{
		geoid: "48227",
		name: "Howard",
		state: "Texas",
		countyState: "Howard County, Texas",
		centroidLng: -101.4350000,
		centroidLat: 32.3062000
	},
	{
		geoid: "54099",
		name: "Wayne",
		state: "West Virginia",
		countyState: "Wayne County, West Virginia",
		centroidLng: -82.4182000,
		centroidLat: 38.1321000
	}
]