import React from "react";
import Axios from "axios";
import * as d3 from "d3";
import { log, format, withContext } from "kn-react";
import {
  withStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box
} from "@material-ui/core";
import AnimatedNumber from "react-animated-number";
import UnitBreakdown from "UnitBreakdown/UnitBreakdown";
import { HousingAndClimateContext } from "./HousingAndClimateProvider";
import roundToNearest from "Lib/roundToNearest";
import flame from "Icons/flame.svg";
import wind from "Icons/wind.svg";
import house from "Icons/house.svg";

class HousingAndClimate extends React.Component {
  render() {
    const {
      classes,
      totals,
      totalHeatingUnits,
      totalAcUnits,
      fuelTypesData,
      acTypesData,
    } = this.props;

    return (
      <div>

        {/* FIRST ROW */}
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            <img style={iconHomeStyle} src={house} />
          </Grid>
          <Grid item xs={12} sm={10} style={{ textAlign: "left" }}>
            <AnimatedNumber
                value={roundToNearest(totals.occupiedHousingUnits, 500)}
                formatValue={format.commas}
                component="h4"
                className="MuiTypography-h4"
                style= {{ marginTop: 0, marginBottom: 0 }}
              />{" "}
            <Typography variant="body1" style={{ color: "#061432", marginBottom: 0 }} paragraph>
              Occupied Housing Units in Your Service Area
            </Typography>
            <Typography variant="body1" paragraph>
              The U.S. Census Bureau estimates the number of housing units. A housing unit excludes large apartment buildings.
            </Typography>
          </Grid>
        </Grid>

        {/*<Grid item xs={12} style={{ padding: 20 }}>
          <Typography variant="body1" paragraph>
            In your selected area, there an estimated{" "}
            <AnimatedNumber
              value={roundToNearest(totals.occupiedHousingUnits, 500)}
              formatValue={format.commas}
              style={{ fontWeight: "bold" }}
            />{" "}
            occupied housing units. With an average expected lifetime of 15
            years,&nbsp;
            <AnimatedNumber
              value={roundToNearest((totalHeatingUnits * 1) / 15, 500)}
              formatValue={format.commas}
              style={{ fontWeight: "bold" }}
            />{" "}
            heating units and{" "}
            <AnimatedNumber
              value={roundToNearest((totals.centralAcUnits * 1) / 15, 500)}
              formatValue={format.commas}
              style={{ fontWeight: "bold" }}
            />{" "}
            central AC units need to be replaced each year.
          </Typography>
          {this.props.showDetails ? (
            <Typography variant="body1" paragraph>
              Here's the estimated breakdown by unit type:
            </Typography>
          ) : null}
        </Grid>*/}

          {/* SECOND ROW */}
          {this.props.showDetails ? (
            <Grid className="graphic-block" container spacing={7}>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ }}
              >
                {/* <Card classes={{ root: classes.cardRoot }}>
                  <CardContent> */}
                <Box>

                  <Typography variant="subheading" style={{ color: "#6E7191", fontWeight: "500", fontSize: "18px", letterSpacing: "0.44px", lineHeight: '24px' }}>
                    Heating Units
                  </Typography>

                  <Typography variant="body2" style={{ color: "black", marginTop: "13px", marginBottom: "29px", display: "flex", alignItems: "center" }}>
                    <img style={iconStyle} src={flame} />
                    <AnimatedNumber
                      value={totalHeatingUnits}
                      formatValue={format.commas}
                      component="p"
                      style= {{ marginTop: 0, marginBottom: 0, marginLeft: "10px" }}
                    />
                  </Typography>

                  <UnitBreakdown data={fuelTypesData} backgroundColor="#E76E2E" />

                </Box>
                {/* </CardContent>
                </Card> */}
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                style={{ }}
              >
                {/* <Card>
                  <CardContent> */}
                <Box>

                  <Typography variant="subheading" style={{ color: "#6E7191", fontWeight: "500", fontSize: "18px", letterSpacing: "0.44px", lineHeight: '24px' }}>
                    AC Units
                  </Typography>

                  <Typography variant="body2" style={{ color: "black", marginTop: "13px", marginBottom: "29px", display: "flex", alignItems: "center" }}>
                    <img style={iconStyle} src={wind} />
                    <AnimatedNumber
                    value={totalAcUnits}
                    formatValue={format.commas}
                    component="p"
                    style= {{ marginTop: 0, marginBottom: 0, marginLeft: "10px" }}
                  />
                  </Typography>

                  <UnitBreakdown className="heating-units" data={acTypesData} backgroundColor="#2C307D" />

                </Box>
                {/* </CardContent>
                </Card> */}
              </Grid>
            </Grid>
        ) : null}
      </div>
    );
  }
}

const styles = {
  cardRoot: { boxShadow: "none" },
};

export default withStyles(styles)(
  withContext(HousingAndClimateContext, HousingAndClimate)
);

const iconStyle = {
  height: 25,
};

const iconHomeStyle = {
  height: 100,
  position: "relative",
};