import Axios from "axios";
import { log, format } from "kn-react";
import * as d3 from "d3";
import { Typography, Grid, Link } from "@material-ui/core";
import { withContext } from "kn-react";
import Layout from "Layout/Layout";
import React from "react";
import { ReportBuilderContext } from "ReportBuilder/ReportBuilderProvider";
import ReviewsMap from "ReviewsMap/ReviewsMap";
import EnrichedPlaceHistoryModel from "Models/EnrichedPlaceHistoryModel";
import PlaceCard from "Report/PlaceCard";
import PayWall from "./PayWall";
import { TransitionContext } from "Lib/TransitionProvider";

class ReportPreview extends React.Component {
  state = {
    enrichedPlaceDetailHistories: [],
    loading: true,
    showPaywall: true,
  };

  componentDidMount = () => {
    window.document.title = "Billow - Where Should We Send Your Report?";
    this.getEnrichedPlaceDetailHistories();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.bounds !== this.props.bounds) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(
        this.getEnrichedPlaceDetailHistories,
        1000
      );
    }
  };

  getEnrichedPlaceDetailHistories = (page) => {
    return Axios.get("/api/place_detail_histories/enriched_in_bounds", {
      params: {
        ...this.props.bounds,
      },
    }).then((response) => {
      log("response.data", response.data);

      const enrichedPlaceDetailHistories =
        response.data.enriched_place_detail_histories
          .map((d) => EnrichedPlaceHistoryModel.fromJSON(d))
          .sort();

      enrichedPlaceDetailHistories.sort((a, b) => {
        return d3.descending(a.userRatingsSum, b.userRatingsSum);
      });

      this.setState({ enrichedPlaceDetailHistories });
    });
  };

  togglePaywall = () => {
    this.setState({ showPaywall: !this.state.showPaywall });
  }

  onClickEnterYourEmail = e => {
    e.preventDefault();
    this.togglePaywall();
  }

  render() {
    const { enrichedPlaceDetailHistories } = this.state;

    return (
      <>
        {this.state.showPaywall ? <PayWall onClickClose={this.togglePaywall} /> : ""}
        <Layout
          left={
            <ReviewsMap
              bounds={this.props.bounds}
              onChangeBounds={this.props.onChangeBounds}
              placeDetailHistories={enrichedPlaceDetailHistories}
            />
          }
          right={
            <div style={{ padding: 16 }}>
              <Typography variant="h6" paragraph>
                {format.commas(enrichedPlaceDetailHistories.length)} Local{" "}
                {enrichedPlaceDetailHistories.length === 1
                  ? "Company"
                  : "Companies"}
              </Typography>
              <Grid container spacing={2}>
                {enrichedPlaceDetailHistories.slice(0, 10).map((p) => {
                  return (
                    <Grid item xs={12} sm={6} key={p.id}>
                      <PlaceCard
                        enrichedPlaceDetailHistory={p}
                        onClick={this.togglePaywall}
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Typography style={{ marginTop: 20 }} variant="body2" color="textSecondary" paragraph>
                To see detailed data for all companies, <Link href="#" onClick={this.onClickEnterYourEmail}>enter you email</Link> to get your custom report emailed to you.
              </Typography>
            </div>
          }
        />
      </>
    );
  }
}

export default withContext(TransitionContext, ReportPreview);
