import React from "react";
import Axios from "axios";
import { log } from "kn-react";
import * as d3 from "d3";
import HousingAndClimateModel from "../Models/HousingAndClimateModel";


const DEFAULT_STATE = {
  totals: {},
  acTypesData: [],
  fuelTypesData: [],
  totalHeatingUnits: null,
  totalAcUnits: null,
}

export const HousingAndClimateContext = React.createContext(DEFAULT_STATE);


export default class HousingAndClimateProvider extends React.Component {
  state = DEFAULT_STATE;


  componentDidMount = () => {
    this.fetchData(this.props.includedGeographies);
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.includedGeographies !== this.props.includedGeographies) {
      this.fetchData(nextProps.includedGeographies);
    }
  };

  fetchData = (geoTypeIds) => {
    if (!geoTypeIds.length) {
      this.setState({ totals: {} });
      return;
    }

    return Axios.get("/api/housing_and_climate_geographies/totals", {
      params: { geo_type_ids: geoTypeIds },
    }).then((response) => {
      log("fetchData", response);
      const totals = HousingAndClimateModel.fromJSON(response.data.totals);
      log("totals", totals);

      const fuelTotal = totals.fuelTotal || 0;
      const totalHeatingUnits = fuelTotal - (totals.fuelNone || 0);
      const totalAcUnits = totals.acUnits || 0;

      const fuelTypes = ["fuelElectric", "fuelGas", "fuelOil", "fuelPropane"];

      const fuelTypesData = fuelTypes.map((k) => ({
        key: k.replace("fuel", ""),
        count: totals[k],
      }));
      const fuelOther = fuelTotal - d3.sum(fuelTypesData, (d) => d.count);
      fuelTypesData.push({ key: "Other", count: fuelOther });

      fuelTypesData.forEach((d) => (d.value = d.count / fuelTotal));

      const acTypes = {
        acWithoutHeatPump: "No Heat Pump",
        acWithHeatPump: "Heat Pump",
        centralAcUnits: "Central Air",
        individualAcUnits: "Individual Units",
      };

      const acTypesData = Object.keys(acTypes).map((k) => ({
        key: acTypes[k],
        count: totals[k],
      }));

      acTypesData.forEach((d) => (d.value = d.count / totalAcUnits));


      this.setState({
        totals,
        acTypesData,
        fuelTypesData,
        totalHeatingUnits,
        totalAcUnits,
      });
    });
  };


  render(){
    return (
      <HousingAndClimateContext.Provider
        value={{
          ...this.state,
        }}
      >
        { this.props.children || [] }
      </HousingAndClimateContext.Provider>
    );
  }

}




