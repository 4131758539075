import React from 'react';
import * as d3 from 'd3';
import ResponsiveSvg from 'ResponsiveSvg/ResponsiveSvg';
import { log } from 'kn-react';
import './PeriodChart.css'

class PeriodChart extends React.Component {

  constructor(props){
    super(props);
    this.responsiveSvg = React.createRef();
  }

  componentDidMount = () => {
    setTimeout(() => this.draw(this.props.periods), 250);
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.periods) {
      this.draw(nextProps.periods || []);
    }
  }

  draw = (periods) => {
    const  d3Svg  = this.responsiveSvg.current?.d3Svg;
    if(!d3Svg) return;

    const { base, hdMax, cdMin } = this.props;
    log('d3Svg', d3Svg);

    const height = this.responsiveSvg.current.innerHeight;
    const width = this.responsiveSvg.current.innerWidth;
    const rectWidth = 1.5;

    let max = d3.max(periods, d => d.avg_max);
    let min = d3.min(periods, d => d.avg_min);
    let extent = [min, max]

    log('extent', extent);
    extent = [extent[0] - base - 5, extent[1] - base + 5];
    log('extent', extent);


    const yScale = d3.scaleLinear()
      .domain(extent)
      .range([height, 0]);

    var yAxis = d3.axisLeft()
      .scale(yScale)
      .tickFormat(t => (t + base) + '°');


    d3Svg.selectAll(".y-axis")
      .transition()
        .call(yAxis);

    const monthDays = periods.map(p => p.mo_da);
    const xScale = d3.scaleBand()
        .domain(monthDays)
        .range([0, width]);


    //   var xAxis = d3.axisBottom()
    //     .scale(xScale)
    //     .tickValues(
    //       xScale.domain().filter(d => d.endsWith('-01'))
    //     );;

    //   d3Svg.selectAll('.x-axis')
    //     .transition()
    //       .attr('transform', 'translate(0,' + height + ')')
    //       .call(xAxis);


    const rectStart = (d, from) => {
      const min = Math.min(d.avg_max, from);
      const y = min - base;
      return yScale(y);
    }

    const rectEnd = (d, to) => {
      const min = Math.max(d.avg_min, to);
      const y = min - base;
      return yScale(y);
    }

    const rectHeight = (d, from, to) => {
      const start = rectStart(d, from);
      const end = rectEnd(d, to);
      return Math.max( end - start, 0)
    }


    const cdd = d3Svg.selectAll('rect.cdd')
      .data(periods, d => `${d.mo}${d.da}`);

    cdd.enter().append('rect')
        .attr('class', 'cdd')
        .style('fill', '#265DE2')
      .merge(cdd)
        .on('mousedown', (_,d) => console.log(d))
        .transition()
        .attr('x', d => xScale(d.mo_da))
        .attr('width', rectWidth)
        .attr('y', d => rectStart(d, d.avg_max))
        .attr('height', d => rectHeight(d, d.avg_max, cdMin));


    cdd.exit().remove();



    const comfyTop = d3Svg.selectAll('rect.comfyTop')
      .data(periods, d => `${d.mo}${d.da}`);

      comfyTop.enter().append('rect')
          .attr('class', 'comfyTop')
          .style('fill', '#ccc')
        .merge(comfyTop)
          .on('mousedown', (_,d) => console.log(d))
          .transition()
            .attr('x', d => xScale(d.mo_da))
            .attr('y', d => rectStart(d, cdMin))
            .attr('width', rectWidth)
            .attr('height', d => rectHeight(d, cdMin, base));

      comfyTop.exit().remove();



    const comfyBottom = d3Svg.selectAll('rect.comfyBottom')
      .data(periods, d => `${d.mo}${d.da}`);

      comfyBottom.enter().append('rect')
      .attr('class', 'comfyBottom')
          .style('fill', '#ccc')
        .merge(comfyBottom)
          .on('mousedown', (_,d) => console.log(d))
          .transition()
            .attr('x', d => xScale(d.mo_da))
            .attr('width', rectWidth)
            .attr('y', d => rectStart(d, base))
            .attr('height', d => rectHeight(d, base, hdMax));

      comfyBottom.exit().remove();


      const hdd = d3Svg.selectAll('rect.hdd')
        .data(periods, d => `${d.mo}${d.da}`);

      hdd.enter().append('rect')
          .attr('class', 'hdd')
          .style('fill', '#E76E2E')
        .merge(hdd)
          .on('mousedown', (_,d) => console.log(d))
          .transition()
            .attr('x', d => xScale(d.mo_da))
            .attr('width', rectWidth)
            .attr('y', d => rectStart(d, hdMax))
            .attr('height', d => rectHeight(d, hdMax, d.avg_min));

      hdd.exit().remove();


      // const labels = d3Svg.selectAll('text.label')
      //   .data(periods);

      // labels.enter().append('text')
      //   .attr('class', 'label')
      //     .attr('text-anchor', 'middle')
      //     .style('font-size', '7')
      //     .merge(labels)
      //       .transition()
      //         .attr('x', d => xScale(d.mo_da))
      //         .attr('dx', 5)
      //         .attr('dy', d => {
      //           const t = d.avg_temp - base;
      //           return t > 0 ? -2 : 7;
      //         })
      //         .attr('y', d => {
      //           const y = d.avg_temp - base;
      //           return yScale(y);
      //         })
      //         .text(d => this.props.format(d.avg_temp))
      //         .style('fill', d => {
      //           if(d.avg_temp < 65){
      //             return'#E76E2E'
      //           } else if (d.avg_temp > 75){
      //             return '#265DE2';
      //           } else {
      //             return 'lightgray';
      //           }
      //         })
    const monthStarts = periods.filter(p => p.mo_da.endsWith('-01'))
    const monthRects = d3Svg.select('.month-rects').selectAll('rect.month')
      .data(monthStarts);

    const daysPerMonth = {};
    periods.forEach(p => {
      daysPerMonth[p.mo] = daysPerMonth[p.mo] || 0;
      daysPerMonth[p.mo] += 1;
    });

    monthRects.enter()
      .append('rect')
        .attr('class', 'month')
        .style('fill', (_,i) => i % 2 === 0 ? '#f9f9f9' : 'none')
      .merge(monthRects)
        .transition()
          .attr('x', d => xScale(d.mo_da))
          .attr('y', 0)
          .attr('width', d => daysPerMonth[d.mo] * xScale.bandwidth())
          .attr('height', height);

      monthRects.exit().remove();

    const monthLabels = d3Svg.selectAll('text.month').data(monthStarts);

    monthLabels.enter()
      .append('text')
      .attr('class', 'month')
      .style('text-anchor', 'middle')
      .style('fill', '#777')
      .style('font-size', 9)
      .attr('dy', '1.2em')
      .merge(monthLabels)
        .attr('x', d => {
          const offset = daysPerMonth[d.mo] * xScale.bandwidth() / 2;
          return xScale(d.mo_da) + offset;
        })
        .attr('y', height)
        .text(d => monthNames[d.mo]);

    monthLabels.exit().remove();

    const avgPath = d3Svg.selectAll('path.avg')
      .data([0]);

    // const lineGenerator = d3.line()
    //   .x(d => xScale(d.mo_da))
    //   .y(d => yScale(d.avg_temp - base))
    //   .curve(d3.curveBasis);

    // avgPath.enter()
    //   .append('path')
    //     .attr('class', 'avg')
    //     .style('stroke-width', 1)
    //     .style('stroke', '#fff')
    //   .merge(avgPath)
    //     .datum(periods)
    //     .transition()
    //       .attr('d', lineGenerator)

    // avgPath.exit().remove();

  }

// Heating #E76E2E
// Cooling #265DE2

  render() {
    return (
      <ResponsiveSvg
        ref={this.responsiveSvg}
        height={350}
        margin={{ top: 5, right: 5, bottom: 20, left: 30 }}
        onResize={() => this.draw(this.props.periods || [])}
        className="PeriodChart"
      >
        <g className="month-rects"/>
        <g className="x-axis axis"/>
        <g className="y-axis axis"/>
      </ResponsiveSvg>
     );
  }
}

PeriodChart.defaultProps = {
  base: 70,
  cdMin: 75,
  hdMax: 65,
  format: d3.format('.0f'),
  periods: []
}


export default PeriodChart;



const monthNames = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};