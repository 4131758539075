import React from "react";
import * as d3 from "d3";
import {
  withStyles,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Link,
  Typography,
  CardContent,
  Divider,
} from "@material-ui/core";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { log, format } from "kn-react";
import StarsRating from "Lib/StarsRating";
import ReviewsMap from "ReviewsMap/ReviewsMap";
import handIcon from "Icons/hand.svg";


class Reviews extends React.Component {
  render() {
    const { classes, otherPlaces, myPlace } = this.props;
    const eph = this.props.myPlace;

    let higherRatingCount = 0;
    let lowerRatingCount = 0;
    let moreReviewsCount = 0;
    let fewerReviewsCount = 0;
    let domainCount = 0;
    let statusCodeCount = 0;
    let hasSslCount = 0;
    let redirectsHttpCount = 0;
    let mobileFriendlyCount = 0;

    otherPlaces.forEach((p) => {
      higherRatingCount += p.rating > myPlace.rating ? 1 : 0;
      lowerRatingCount += p.rating < myPlace.rating ? 1 : 0;
      moreReviewsCount += p.userRatingsTotal > myPlace.userRatingsTotal ? 1 : 0;
      fewerReviewsCount +=
        p.userRatingsTotal < myPlace.userRatingsTotal ? 1 : 0;

      domainCount += p.domain ? 1 : 0;
      statusCodeCount += p.statusCode === 200 ? 1 : 0;
      hasSslCount += p.hasSsl ? 1 : 0;
      redirectsHttpCount += p.redirectsHttp ? 1 : 0;
      mobileFriendlyCount += p.viewport && p.contentWidth ? 1 : 0;
    });

    let higherRatingPercent = higherRatingCount / (otherPlaces.length - 1);
    let lowerRatingPercent = lowerRatingCount / (otherPlaces.length - 1);
    let moreReviewsPercent = moreReviewsCount / (otherPlaces.length - 1);
    let fewerReviewsPercent = fewerReviewsCount / (otherPlaces.length - 1);
    let domainPercent = domainCount / otherPlaces.length;
    let statusCodePercent = statusCodeCount / otherPlaces.length;
    let hasSslPercent = hasSslCount / otherPlaces.length;
    let redirectsHttpPercent = redirectsHttpCount / otherPlaces.length;
    let mobileFriendlyPercent = mobileFriendlyCount / otherPlaces.length;

    log("fewerReviewsCount", fewerReviewsCount);

    const reviewsTableData = otherPlaces.map((p) => ({
      placeId: p.placeId,
      name: p.name,
      rating: p.rating,
      userRatingsTotal: p.userRatingsTotal,
      userRatingsSum: p.userRatingsSum,
    }));

    reviewsTableData.sort((a, b) =>
      d3.descending(a.rating, b.rating)
    );

    return (
      <>
      <Grid container>

        <Grid container>
          <Typography style={{ marginBottom: '55px' }} variant="body1" paragraph>
            What's the first thing potential customers will see? Your reviews.
          </Typography>
        </Grid>

        <Grid className="reviews-container" spacing={7} container>

          <Grid item xs={12} sm={6}>
            <Grid container style={{ textAlign: 'center', maxWidth: '350px', marginLeft: 'auto', marginRight: 'auto' }}>
              <Grid item xs={12} sm={6}>

                <Typography paragraph style={{ fontSize: '30px', color: '#14142B', marginBottom: '0px', lineHeight: '120%' }}>
                 {eph.rating}
                </Typography>
                <Typography variant="body1" style={{ color: '#000' }} paragraph>
                  Review Score
                </Typography>
                <StarsRating average={eph.rating} />

              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography paragraph  style={{ fontSize: '30px', color: '#14142B', marginBottom: '0px', lineHeight: '120%' }}>
                 {eph.userRatingsTotal}
                </Typography>
                <Typography variant="body1" style={{ color: '#000' }} paragraph>
                  Reviews
                </Typography>
                <img style={iconReviewsStyle} src={handIcon} />
              </Grid>

            </Grid>

            <div style={{ marginTop: 39, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto', maxWidth: '335px' }}>
              <Divider />
            </div>

            <Grid container>
              <Typography variant="h6" paragraph>
                 Your Average Score
              </Typography>
              <Typography variant="body1" paragraph>
                 Compared to the other {otherPlaces.length - 1} companies found in your region, your review score of <strong>{eph.rating}</strong> is higher than {format.percent(lowerRatingPercent)} ({format.commas(lowerRatingCount)}) of them and lower than {format.percent(higherRatingPercent)} ({format.commas(higherRatingCount)}) of them.
              </Typography>
            </Grid>
            <Grid container style={{ marginTop: '20px' }}>
              <Typography variant="h6" paragraph>
                 Your Total Ratings
              </Typography>
              <Typography variant="body1" paragraph>
                 A good review profile has at least 20 ratings. With <strong>{eph.userRatingsTotal}</strong> {eph.userRatingsTotal === 1 ? "review" : "reviews"}, you have more reviews than {format.percent(fewerReviewsPercent)} ({format.commas(fewerReviewsCount)}) and fewer than {format.percent(moreReviewsPercent)} ({format.commas(moreReviewsCount)}).
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>

            <Card>
              <div style={{ position: "relative", width: "100%", height: 450 }}>
              {this.props.bounds ? (
                <ReviewsMap
                    bounds={this.props.bounds}
                    placeDetailHistories={this.props.reportPlaces}
                    onClick={(d) => log(d)}
                  />
               ) : null}
               </div>
             </Card>

          </Grid>

        </Grid>

        <Grid container style={{ marginTop: 68 }}>

          <Paper style={{ width: "100%", overflow: "hidden", marginBottom: 50 }}>
            <TableContainer style={{ maxHeight: 300 }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Company</TableCell>
                    <TableCell align="right">Average Rating</TableCell>
                    <TableCell align="right">Total Ratings</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ maxHeight: 300, overflowY: "scroll" }}>
                  {reviewsTableData.map((p, i) => (
                    <TableRow key={i} className={p.placeId === myPlace.placeId ? classes.highlightRow : ''}>
                      <TableCell>{p.name}</TableCell>
                      <TableCell align="right">{p.rating}</TableCell>
                      <TableCell align="right">{p.userRatingsTotal}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>

        </Grid>

      </Grid>

      </>
    );
  }
}

Reviews.defaultProps = {
  onClick: () => {},
};

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  cardHeaderContent: {
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  mediaRoot: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    borderRadius: 5
  },
  title: {
    fontSize: "1.0rem",
    color: theme.palette.text.primary,
    marginBottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subheader: {
    fontSize: "0.7rem",
    color: theme.palette.text.secondary,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  highlightRow: {
    backgroundColor: 'lightyellow',
    fontWeight: 'bold !important',
  }
});

export default withStyles(styles)(Reviews);



export const ratingColors = {
  5: '#7dc8a2',
  4: '#afd889',
  3: '#ffd855',
  2: '#ffb24f',
  1: '#ff8c5f',
};



const DataBar = props => (
  <div style={{ backgroundColor: 'whitesmoke', borderRadius: 5, overflow: 'hidden' }}>
  <div style={{ backgroundColor: props.backgroundColor || 'steelblue', height: 30, width: format.percent(props.percent), color: 'white', textAlign: 'right', lineHeight: '30px', paddingRight: 5, fontWeight: 'bold' }}>
    {format.percent(props.percent)}
  </div>
</div>
)

const iconReviewsStyle = {
  height: 40,
  position: "relative",
};