
import KnModel from 'Lib/KnModel';
import innerProduct from 'Lib/innerProduct';

export default class HousingAndClimateModel extends KnModel{
  constructor(props) {
    super(props);

    Object.keys(acEstimates).forEach(k => {
      this[k] = innerProduct(this, acEstimates[k], Object.keys(acEstimates[k])) || 0;
    });

  }

}



const acEstimates = {
  acUnits: {
    ohuCold: 0.8518,
    ohuVeryCold: 0.8518,
    ohuSubarctic: 0.8518,
    ohuMixedHumid: 0.9433,
    ohuMixedDry: 0.7953,
    ohuHotDry: 0.7953,
    ohuHotHumid: 0.9386,
    ohuMarine: 0.5224,
  },
  acWithoutHeatPump: {
    ohuCold: 0.4871,
    ohuVeryCold: 0.4871,
    ohuSubarctic: 0.4871,
    ohuMixedHumid: 0.4507,
    ohuMixedDry: 0.5118,
    ohuHotDry: 0.5118,
    ohuHotHumid: 0.5307,
    ohuMarine: 0.1493,
  },
  acWithHeatPump: {
    ohuCold: 0.0659,
    ohuVeryCold: 0.0659,
    ohuSubarctic: 0.0659,
    ohuMixedHumid: 0.2478,
    ohuMixedDry: 0.1654,
    ohuHotDry: 0.1654,
    ohuHotHumid: 0.2763,
    ohuMarine: 0.1642,
  },
  centralAcUnits: {
    ohuCold: 0.5529,
    ohuVeryCold: 0.5529,
    ohuSubarctic: 0.5529,
    ohuMixedHumid: 0.6985,
    ohuMixedDry: 0.6772,
    ohuHotDry: 0.6772,
    ohuHotHumid: 0.8070,
    ohuMarine: 0.3284,
  },
  individualAcUnits: {
    ohuCold: 0.3365,
    ohuVeryCold: 0.3365,
    ohuSubarctic: 0.3365,
    ohuMixedHumid: 0.3075,
    ohuMixedDry: 0.1496,
    ohuHotDry: 0.1496,
    ohuHotHumid: 0.1798,
    ohuMarine: 0.2239,
  },
};