import React from 'react';
import { withStyles, Typography, LinearProgress, TextField} from '@material-ui/core';
import { withContext } from 'kn-react';
import { TransitionContext } from 'Lib/TransitionProvider';
import history from 'history.js';

class Processing extends React.Component {
  state = {
    progress: 0,
    buffer: 0,
    currentIndex: 0
  }

  steps = [
    { name: 'Gathering local market data...', duration: Math.random() * 4000 },
    { name: 'Scanning competition...', duration: Math.random() * 4000 },
    { name: 'Running analysis...', duration: Math.random() * 4000 },
    { name: 'Report compiling...', duration: Math.random() * 4000 },
    { name: 'Finished!', duration: 1000 },
  ]

  componentDidMount = () => {
    this.randomizeUpdates();
    window.document.title = "Billow - Building Report...";
  }


  randomizeUpdates = () => {
    const {currentIndex} = this.state;

    if (currentIndex == this.steps.length - 1) {
    setTimeout(this.props.next, 1000)
      return;
    }

    const currentStep = this.steps[currentIndex];
    this.setState(
      { currentIndex: currentIndex + 1, progress: (currentIndex + 2) / this.steps.length * 100 },
      () => setTimeout(this.randomizeUpdates, currentStep.duration)
    );
  }


  render(){
    const { classes } = this.props;
    const { progress, buffer, currentIndex } = this.state;
    const currentStep = this.steps[currentIndex];

    return (
      <div className={this.props.classes.root}>
        <Typography variant="h6" paragraph>{ currentStep.name }</Typography>
        <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} classes={{ root: classes.progressRoot, bar: classes.bar }} />
      </div>


    )
  }
}


const styles = {
  root: {
    margin: '30vh',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  progressRoot: {
    width: '100%',
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}

export default withStyles(styles)(
  withContext(TransitionContext, Processing)
);