import { Button, withStyles } from "@material-ui/core";
import React from "react";

const backNext = (props) => {
  const { classes } = props;
  return (
    <div className={classes.backNext}>
      <div className={classes.back}>
        {
          props.isBackHidden ? '' :
            <Button disabled={props.isBackDisabled} variant="outlined" color="primary" onClick={props.onClickBack}>
            Back
          </Button>
        }
      </div>
      <div className={classes.next}>
        <Button disabled={props.isNextDisabled} variant="contained" color="primary" onClick={props.onClickNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

backNext.defaultProps = {
  isBackHidden: false,
  onClickBack: () => {},
  onClickNext: () => {},
};

const styles = {
  backNext: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  back: {
    flex: 1,
  },
  next: {
    flex: 1,
    display: 'flex',
    justifyContent: 'end',
  }
};

export default withStyles(styles)(backNext);
