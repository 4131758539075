import React from 'react';
import * as d3 from 'd3';

export default class ResponsiveSvg extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      divWidth: 400
    };
  }

  componentDidMount() {
    this.resizeListener = window.addEventListener('resize', this.throttleResize);
    this.setState({ divWidth: this.div.clientWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  throttleResize = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(this.onResize, 250);
  }

  onResize = () => {
    if (!this.div) {
      this.throttleResize();
      return;
    }
    this.setState({ divWidth: this.div.clientWidth }, this.props.onResize);
  }

  get clientSize() {
    return [this.clientWidth, this.clientHeight];
  }

  get clientWidth() {
    return this.svg.clientWidth;
  }

  get clientHeight() {
    return this.svg.clientHeight;
  }

  get innerSize() {
    return [this.innerWidth, this.innerHeight];
  }

  get innerWidth() {
    return this.svg.clientWidth - this.props.margin.left - this.props.margin.right;
  }

  get innerHeight() {
    return this.svg.clientHeight - this.props.margin.top - this.props.margin.bottom;
  }

  findOrCreateElement(parentSelection, selector){
    const split = selector.split('.');
    const element = split[0];
    const elementClass = split[1];
    const selection = parentSelection.selectAll(selector);
    return selection.empty() ? parentSelection.append(element).attr('class', elementClass) : selection;
  }

  render(){
    const { margin } = this.props;

    return (
      <div
        ref={ div => this.div = div }
        style={{
          height: `${this.props.height}px`,
          width: '100%',
          overflow: 'hidden'
        }}
      >
        <svg
          ref={ svg => {
            this.svg = svg;
          }}
          width={this.state.divWidth}
          height={this.props.height}
          className={this.props.className || ''}
        >
          <g
            ref={ svg => {
              this.d3Svg = d3.select(svg);
            }}
            transform={`translate(${margin.left}, ${margin.top})`}
          >
            {this.props.children}
          </g>
        </svg>
      </div>
    )
  }
}

ResponsiveSvg.defaultProps = {
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  height: 400,
  onResize: () => {}
}