import React from "react";
import {
  withStyles,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Avatar,
  Typography,
  IconButton
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

class SelectedPlaceCard extends React.Component {
  render() {
    const { placeDetailHistory, enrichedPlaceHistory, classes } = this.props;

    const dominantColor = enrichedPlaceHistory?.dominantColor
      ? "rgb(" + enrichedPlaceHistory?.dominantColor.slice(1, -1) + ")"
      : "black";

    console.log("dominantColor", dominantColor);

    return (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              style={{ backgroundColor: dominantColor }}
            >
              {placeDetailHistory.name[0]}
            </Avatar>
          }
          title={placeDetailHistory.name}
          subheader={placeDetailHistory.formattedAddress}
          action={
            <IconButton aria-label="remove" onClick={this.props.onClickClear}>
              <ClearIcon />
            </IconButton>
          }
        />

        {enrichedPlaceHistory?.screenshotGcsUri ? (
          <CardMedia
            className={classes.media}
            image={`https://storage.googleapis.com/${enrichedPlaceHistory?.screenshotGcsUri}`}
            title={placeDetailHistory.name}
          />
        ) : null}
      </Card>
    );
  }
}

const styles = (theme) => ({
  root: {
    // maxWidth: 500,
    maxWidth: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
});

export default withStyles(styles)(SelectedPlaceCard);
