import React from "react";
import Axios from "axios";
import Layout from "Layout/Layout";

import { Typography, Link, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import ReviewsMap from "ReviewsMap/ReviewsMap";
import EnrichedPlaceHistoryModel from "Models/EnrichedPlaceHistoryModel";
import PlaceDetailHistoryModel from "Models/PlaceDetailHistoryModel";
import KnAutoSuggest from "Lib/KnAutoSuggest";
import BackNext from "ReportBuilder/Steps/BackNext/backNext";
import SelectedPlaceCard from "./SelectedPlaceCard";
import segment from "segment";

class FindYourCompany extends React.Component {
  state = {
    cantFindPlace: false,
  };

  componentDidMount = () => {
    this.props.getPlaceDetailHistories();
    window.document.title = "Billow - Find Your Company";
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.bounds !== this.props.bounds) {
      this.props.getPlaceDetailHistories();
    }
  };

  onSuggestionSelected = (selectedPlaceDetailHistory) => {
    const { id } = selectedPlaceDetailHistory;
    return Axios.get(
      `/api/place_detail_histories/${id}/enriched_place_history`
    ).then((response) => {
      const { enriched_place_history } = response.data;
      let enrichedPlaceHistory = enriched_place_history
        ? EnrichedPlaceHistoryModel.fromJSON(enriched_place_history)
        : null;

      segment.track('selected_company', { ...selectedPlaceDetailHistory  });
      this.props.onSelectPlaceDetailHistory({
        selectedPlaceDetailHistory,
        enrichedPlaceHistory,
      });
    });
  };

  render() {
    const { bounds } = this.props;

    const {
      placeDetailHistories,
      selectedPlaceDetailHistory,
      enrichedPlaceHistory,
    } = this.props;
    const { cantFindPlace } = this.state;

    return (
      <Layout
        left={
          <ReviewsMap
            bounds={bounds}
            onChangeBounds={this.props.onChangeBounds}
            placeDetailHistories={placeDetailHistories}
            onClick={c => this.onSuggestionSelected(c)}
          />
        }
        right={
          <div style={{ padding: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Find Your Company</Typography>
                <Typography variant="caption" paragraph>
                  To Benchmark your business against your local competition, we
                  need to know which is yours:
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="body2" paragraph>
              Found {placeDetailHistories.length} Companies
            </Typography>

            <KnAutoSuggest
              containerStyles={{
                maxHeight: 300,
                overflowY: "scroll",
              }}
              label="Search for your company"
              onSuggestionsFetchRequested={(value) => {
                segment.track('searched_for_company', { query: value });
                return Axios.get("/api/place_detail_histories/search", {
                  params: {
                    ...this.props.bounds,
                    q: value,
                  },
                }).then((response) => {
                  return response.data.place_detail_histories.map((d) =>
                    PlaceDetailHistoryModel.fromJSON(d)
                  );
                })
              }}
              getSuggestionPrimaryValue={(s) => s.name}
              getSuggestionSecondaryValue={(s) => s.formattedAddress}
              onSuggestionSelected={this.onSuggestionSelected}
            />

            <Typography variant="caption">
              <Link
                href="#"
                id="toggle-cant-find-company"
                onClick={this.onCantFindPlace}
              >
                {cantFindPlace
                  ? "Search for your company"
                  : `Can't find your company?`}
              </Link>
            </Typography>

            {selectedPlaceDetailHistory ? (
              <SelectedPlaceCard
                placeDetailHistory={selectedPlaceDetailHistory}
                enrichedPlaceHistory={enrichedPlaceHistory}
                onClickClear={() =>{
                  segment.track('cleared_company', { ...selectedPlaceDetailHistory })
                  this.setState({
                    selectedPlaceDetailHistory: null,
                    enrichedPlaceHistory: null,
                  })
                }}
              />
            ) : null}

            <BackNext
              onClickBack={this.props.back}
              onClickNext={this.props.next}
              isNextDisabled={!selectedPlaceDetailHistory}
            />
          </div>
        }
      />
    );
  }
}

const styles = {};

export default withStyles(styles)(FindYourCompany);
