import { JsConvertCase } from 'kn-react';

export default class KnModel {

  constructor(props){
    for(let k in props){
      this[k] = props[k];
    }
  }

  static fromJSON(json){
    const props = {};

    Object.keys(json).forEach(k => {
      let v = json[k];

      if (k.endsWith('_at') && v) {
        v = new Date(v);
      }

      props[ JsConvertCase.toCamelCase(k) ] = v;
    });
    return new this(props);
  }


  toJSON(){
    const skipKeys = this.constructor.skipKeys || [];
    const json = {};

    Object.keys(this).forEach(k => {
      if (skipKeys.includes(k)) { return; }

      const v = this[k];
      if (typeof this === "function") { return; }

      json[ JsConvertCase.toSnakeCase(k) ] = this[k];
    });

    return json;
  }

}

// class NewModel extends KnModel {
//   constructor(props) {
//     super(props);
//     console.log('NewModel Constructor');
//   }

//   static skipKeys = ['skipMe'];

//   static sayGoodbye(){
//     console.log('Goodbye!')
//   }

//   sayHello(){
//     console.log('Hello!')
//   }

//   toJSON(){
//     const json = KnModel.prototype.toJSON.call(this);
//     console.log('newModel.toJSON()', json);
//     return json
//   }

// }


// let newModel = NewModel.fromJSON({ camel_case: 'Hey', skip_me: 'SkipMe' })
// newModel.sayHello()
// newModel.constructor.sayGoodbye();
// newModel.toJSON();

