import React from "react";
import * as d3 from "d3";
import {
  withStyles,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Link,
  Typography,
  CardContent,
  Divider,
} from "@material-ui/core";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardActions,
  Collapse
} from "@material-ui/core";
import { log, format } from "kn-react";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import StarsRating from "Lib/StarsRating";
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ZAxis } from 'recharts';
import checkIcon from "Icons/check.svg";
import closeIcon from "Icons/close.svg";


class MyWebsite extends React.Component {

  state = {
    expandCard: false,
    expandCardMobile: false
  }

  toggleCard = () => {
    this.setState({ expandCard: !this.state.expandCard });
  }

  onClickExpandCard = e => {
    e.preventDefault();
    this.toggleCard();
  }

  toggleCardMobile = () => {
    this.setState({ expandCardMobile: !this.state.expandCardMobile });
  }

  onClickExpandCardMobile = e => {
    e.preventDefault();
    this.toggleCardMobile();
  }

  render() {

    const { classes, otherPlaces, myPlace } = this.props;
    const eph = this.props.myPlace;

    let higherRatingCount = 0;
    let lowerRatingCount = 0;
    let moreReviewsCount = 0;
    let fewerReviewsCount = 0;
    let domainCount = 0;
    let statusCodeCount = 0;
    let hasSslCount = 0;
    let redirectsHttpCount = 0;
    let mobileFriendlyCount = 0;

    otherPlaces.forEach((p) => {
      higherRatingCount += p.rating > myPlace.rating ? 1 : 0;
      lowerRatingCount += p.rating < myPlace.rating ? 1 : 0;
      moreReviewsCount += p.userRatingsTotal > myPlace.userRatingsTotal ? 1 : 0;
      fewerReviewsCount +=
        p.userRatingsTotal < myPlace.userRatingsTotal ? 1 : 0;

      domainCount += p.domain ? 1 : 0;
      statusCodeCount += p.statusCode === 200 ? 1 : 0;
      hasSslCount += p.hasSsl ? 1 : 0;
      redirectsHttpCount += p.redirectsHttp ? 1 : 0;
      mobileFriendlyCount += p.viewport && p.contentWidth ? 1 : 0;
    });

    let higherRatingPercent = higherRatingCount / (otherPlaces.length - 1);
    let lowerRatingPercent = lowerRatingCount / (otherPlaces.length - 1);
    let moreReviewsPercent = moreReviewsCount / (otherPlaces.length - 1);
    let fewerReviewsPercent = fewerReviewsCount / (otherPlaces.length - 1);
    let domainPercent = domainCount / otherPlaces.length;
    let statusCodePercent = statusCodeCount / otherPlaces.length;
    let hasSslPercent = hasSslCount / otherPlaces.length;
    let redirectsHttpPercent = redirectsHttpCount / otherPlaces.length;
    let mobileFriendlyPercent = mobileFriendlyCount / otherPlaces.length;

    console.log("fewerReviewsCount", fewerReviewsCount);

    const reviewsTableData = otherPlaces.map((p) => ({
      placeId: p.placeId,
      name: p.name,
      rating: p.rating,
      userRatingsTotal: p.userRatingsTotal,
      userRatingsSum: p.userRatingsSum,
    }));

    reviewsTableData.sort((a, b) =>
      d3.descending(a.rating, b.rating)
    );

    return (
      <>

      <Grid container>

        <Grid container style={{ marginBottom: 50 }}>

            <Card style={{ width: "100%" }}>
              <CardMedia
                className={classes.media}
                classes={{ root: classes.mediaRoot }}
                image={
                  eph.hasScreenshot
                    ? `https://storage.googleapis.com/${eph?.screenshotGcsUri}`
                    : "/missing_site.jpg"
                }
                title={eph.name}
              />
                <CardHeader
                classes={{content: classes.cardHeaderContent}}
                disableTypography
                title={

                    eph.domain ?
                    <div className={classes.title}>
                      <Link href={eph.website} target="_blank">{eph.domain}</Link>
                    </div>
                    :
                      <Typography style={{ marginTop: 20 }} variant="body1" paragraph>
                        We couldn{"'"}t find a website for your company.
                      </Typography>

                }
              />
            </Card>

        </Grid>

        <Grid container style={{ marginBottom: 50 }} spacing={7}>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h6" paragraph>
                      Has SSL Certificate
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Have you ever noticed that some URLs start with http://, while others start with https://
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  {
                    eph.isOnHttps ? <img style={iconMyWebsiteStyle} src={closeIcon} />
                    : <img style={iconMyWebsiteStyle} src={closeIcon} />
                  }
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing>
                <Link
                  href="#"
                  expand={this.state.expandCard}
                  onClick={this.onClickExpandCard}
                  aria-expanded={this.state.expandCard}
                  aria-label="show more"
                >
                  {
                    !this.state.expandCard ? "Expand" : "Collapse"
                  }
                </Link>
              </CardActions>
              <Collapse in={this.state.expandCard} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    SSL information
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs={12} sm={9}>
                    <Typography variant="h6" paragraph>
                      Works on Mobile
                    </Typography>
                    <Typography variant="body1" paragraph>
                      More than {Math.round(mobileFriendlyPercent*100)}% of searches for HVAC services happen on a mobile device. 
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  {
                    (eph.viewport && eph.contentWidth) ? <img style={iconMyWebsiteStyle} src={checkIcon} />
                    : <img style={iconMyWebsiteStyle} src={closeIcon} />
                  }
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing>
                <Link
                  href="#"
                  expand={this.state.expandCardMobile}
                  onClick={this.onClickExpandCardMobile}
                  aria-expanded={this.state.expandCardMobile}
                  aria-label="show more"
                >
                  {
                    !this.state.expandCardMobile ? "Expand" : "Collapse"
                  }
                </Link>
              </CardActions>
              <Collapse in={this.state.expandCardMobile} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography variant="body1" paragraph>
                    Mobile information
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>

        </Grid>

      </Grid>

      </>
    );
  }
}

MyWebsite.defaultProps = {
  onClick: () => {},
};

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  cardHeaderContent: {
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  mediaRoot: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    borderRadius: 5
  },
  title: {
    fontSize: "1.0rem",
    color: theme.palette.text.primary,
    marginBottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subheader: {
    fontSize: "0.7rem",
    color: theme.palette.text.secondary,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  highlightRow: {
    backgroundColor: 'lightyellow',
    fontWeight: 'bold !important',
  }
});

export default withStyles(styles)(MyWebsite);



export const ratingColors = {
  5: '#7dc8a2',
  4: '#afd889',
  3: '#ffd855',
  2: '#ffb24f',
  1: '#ff8c5f',
};



const DataBar = props => (
  <div style={{ backgroundColor: 'whitesmoke', borderRadius: 5, overflow: 'hidden' }}>
  <div style={{ backgroundColor: props.backgroundColor || 'steelblue', height: 30, width: format.percent(props.percent), color: 'white', textAlign: 'right', lineHeight: '30px', paddingRight: 5, fontWeight: 'bold' }}>
    {format.percent(props.percent)}
  </div>
</div>
)

const iconMyWebsiteStyle = {
  height: 70,
  position: "relative",
};