import React from "react";
import Axios from "axios";
import * as d3 from "d3";
import { log, format, withContext } from "kn-react";
import {
  withStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box
} from "@material-ui/core";
import AnimatedNumber from "react-animated-number";
import UnitBreakdown from "UnitBreakdown/UnitBreakdown";
import { HousingAndClimateContext } from 'HousingAndClimate/HousingAndClimateProvider';
import roundToNearest from "Lib/roundToNearest";
import warehouse from "Icons/warehouse.svg";
import airConditioner from "Icons/air-conditioner.svg";

class SwapOut extends React.Component {
  render() {
    const {
      classes,
      totals,
      totalHeatingUnits,
      totalAcUnits,
      fuelTypesData,
      acTypesData,
    } = this.props;

    return (
      <Grid container>

        {/* FIRST ROW */}
        <Grid container alignItems="center">
          <Grid item xs={12} sm={2}>
            <img style={iconWarehouseStyle} src={warehouse} />
          </Grid>
          <Grid item xs={12} sm={10} style={{ }}>
            <AnimatedNumber
                value={(roundToNearest((totalHeatingUnits * 1) / 15, 500) + roundToNearest((totals.centralAcUnits * 1) / 15, 500))}
                formatValue={format.commas}
                component="h4"
                className="MuiTypography-h4"
                style= {{ marginTop: 0, marginBottom: 0 }}
              />{" "}
            <Typography variant="body1" style={{ color: "#061432", marginBottom: 0 }} paragraph>
              Swap Outs Annually
            </Typography>
            <Typography variant="body1" paragraph>
              Based on a 15 year equipment lifetime, this is our estimate of the change out market in your service area.
            </Typography>
          </Grid>
        </Grid>

        {/* SECOND ROW */}
        <Grid container className="info-block" spacing={5}>
          <Grid item xs={12} sm={6}>

            <Box>
              <Grid container alignItems="center" spacing={5}>
                <Grid item xs={12} sm={4}>
                  <img style={iconWarehouseStyle} src={airConditioner} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <AnimatedNumber
                      value={roundToNearest((totalHeatingUnits * 1) / 15, 500)}
                      formatValue={format.commas}
                      style={{ fontWeight: "bold" }}
                    />
                  <Typography variant="body2" style={{ color: "black" }}>
                    AC Units
                  </Typography>
                  <Typography variant="body2" style={{ color: "black" }}>
                    Swapped out annually.
                  </Typography>
                </Grid>
              </Grid>
            </Box>

          </Grid>
          <Grid item xs={12} sm={6}>

            <Box>
              <Grid container alignItems="center" spacing={5}>
                <Grid item xs={12} sm={4}>
                  <img style={iconWarehouseStyle} src={airConditioner} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <AnimatedNumber
                      value={roundToNearest((totals.centralAcUnits * 1) / 15, 500)}
                      formatValue={format.commas}
                      style={{ fontWeight: "bold" }}
                    />
                  <Typography variant="body2" style={{ color: "black" }}>
                    Heating Units
                  </Typography>
                  <Typography variant="body2" style={{ color: "black" }}>
                    Swapped out annually.
                  </Typography>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>

        {/* THIRD ROW */}
        <Grid container justifyContent="center">
          <Typography variant="body1" style={{ }}>
            {this.props.consumerPercent}% of consumers go online to find and research local products and services.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const styles = {
  cardRoot: { boxShadow: "none" },
};

export default withStyles(styles)(
  withContext(HousingAndClimateContext, SwapOut)
);

const iconWarehouseStyle = {
  height: 100,
};