import React from 'react';
import history from 'history.js';

const home = props => {
  if (window.location.href.includes("localhost") ) {
    history.push('/build/choose-your-county')
  } else {
    window.location.href = "https://www.goboost.com";
  }
  return '';
}


export default home;
