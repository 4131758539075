import React from 'react';
import * as d3 from 'd3';
import { format } from 'kn-react';
import './UnitBreakdown.css';

class UnitBreakdown extends React.PureComponent {

  render() {
    const {data} = this.props;
    const sortedData = data.sort( (a,b) => d3.descending( a.value, b.value ) );

    return(
      <div style={{ textAlign: 'left', fontSize: 13 }}>
        {
          sortedData.map(d => {
            const width = format.percent(d.value)
            return (
              <div className="UnitBreakdown" key={d.key} style={{ marginTop: 10, color: '#555' }}>
                <div>{d.key} { width }</div>
                <div style={{ position: 'relative', height: 20, backgroundColor: 'whitesmoke' }}>
                  <div className="rect" style={{ height: 20, backgroundColor: this.props.backgroundColor, width: width, float: 'left' }}></div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

UnitBreakdown.defaultProps = {
  backgroundColor: 'gray',
  data: []
}

export default UnitBreakdown;