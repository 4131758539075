import React from "react";
import {
  withStyles,
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Link
} from "@material-ui/core";
import { log, format } from 'kn-react';
import { Lock } from "@material-ui/icons";


class PlaceCard extends React.Component {

  onClick = () => {
    const { enrichedPlaceDetailHistory } = this.props;
    this.props.onClick(enrichedPlaceDetailHistory);
  }


  render() {
    const { classes } = this.props;
    const eph = this.props.enrichedPlaceDetailHistory;

    return (
      <Card className={classes.root}>

        <CardActionArea onClick={this.onClick}>
            <CardMedia
              className={classes.media}
              classes={{ root: classes.mediaRoot }}
              image={
                eph.hasScreenshot ?
                  `https://storage.googleapis.com/${eph?.screenshotGcsUri}`
                : '/missing_site.jpg'
              }
              title={eph.name}
            />

          <CardHeader
            classes={{content: classes.cardHeaderContent}}
            disableTypography
            title={
              <div className={classes.title}>
                {eph.name}
              </div>
            }
            subheader={
              <div className={classes.subheader}>
                <div style={{ marginBottom: 3 }}>
                  { format.commas(eph.totalClicksPerMonth || 0) } monthly clicks &nbsp;
                  { format.commas(eph.userRatingsTotal || 0) } { eph.userRatingsTotal === 1 ? 'review' : 'reviews' } &nbsp;
                  { format.commas(eph.rating || 0, { decimals: 1 }) } rating
                </div>
                <div style={{ marginBottom: 3 }}>
                  {
                    eph.website ?
                      <>
                        {
                          eph.hasSsl ? <Lock className={classes.lockIcon}/>
                          :
                          ''
                        }
                        <Link href={ eph.website } target="_blank">{eph.domain}</Link>
                      </>
                      :
                      'No website found.'
                  }

                </div>
              </div>
            }
          />
        </CardActionArea>
      </Card>
    );
  }
}


PlaceCard.defaultProps = {
  onClick: () => {}
}

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  cardHeaderContent: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  mediaRoot: {
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
  },
  title: {
    fontSize: '1.0rem',
    color: theme.palette.text.primary,
    marginBottom: 5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  subheader: {
    fontSize: '0.7rem',
    color: theme.palette.text.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  lockIcon: {
    width: '0.5em',
    height: '0.5em',
    position: 'relative',
    top: 2,
    marginRight: 3,
    fill: '#aaa',
  }
});

export default withStyles(styles)(PlaceCard);
