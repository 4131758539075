import React from "react";
import Axios from "axios";
import * as d3 from "d3";
import { log, format, MaxWidth } from "kn-react";
import { Grid, Typography } from "@material-ui/core";
import HousingAndClimate from "HousingAndClimate/HousingAndClimate";
import SwapOut from "SwapOut/SwapOut";
import CountyAvgTemp from "CountyAvgTemp/CountyAvgTemp";
import EnrichedPlaceHistoryModel from "Models/EnrichedPlaceHistoryModel";
import Reviews from "Report/Reviews";
import MyWebsite from "Report/MyWebsite";
import Recap from "Report/Recap";
import KnModel from "Lib/KnModel";
import HousingAndClimateProvider from "HousingAndClimate/HousingAndClimateProvider";
import Section from "./Section";
import Competition from "./Competition";
import segment from 'segment';
import SectionHeader from "./SectionHeader";
import introduction from "Icons/introduction.svg";
import marketdata from "Icons/market-data.svg";
import swap from "Icons/swap.svg";
import climate from "Icons/climate.svg";
import competition from "Icons/competition.svg";
import review from "Icons/review.svg";
import website from "Icons/website.svg";
import recap from "Icons/recap.svg";


export default class ReportViewer extends React.Component {
  state = {
    tab: 0,
    includedGeographies: [],
    reportPlaces: [],
    bounds: null,
    myPlace: null,
    selectedPlace: null,
  };

  componentDidMount = () => {
    window.document.title = 'HVAC Report';
    this.fetchReport()
      .then(this.fetchEnrichedPlaceDetailHistoryForReport)
      .then(this.fetchEnrichedPlaceDetailHistories);
  };

  fetchReport = () => {
    const { uuid } = this.props.match.params;
    log("uuid", uuid);


    return Axios.get(`/api/reports/${uuid}`).then((response) => {
      console.log(response);
      const report = KnModel.fromJSON(response.data.report);
      segment.track('viewed_report', { report });

      this.setState({ ...report });
    });
  };


  fetchEnrichedPlaceDetailHistoryForReport = () => {
    return Axios.get(`/api/place_detail_histories/enriched_for_place_id`, {
      params: { place_id: this.state.placeId },
    }).then((response) => {
      log("response.data", response.data);

      const myPlace = EnrichedPlaceHistoryModel.fromJSON(
        response.data.enriched_place_history
      );

      log("myPlace", myPlace);

      this.setState({ myPlace });
    });
  };

  fetchEnrichedPlaceDetailHistories = () => {
    return Axios.get("/api/place_detail_histories/enriched_in_bounds", {
      params: { ...this.state.bounds },
    }).then((response) => {
      log("response.data", response.data);

      const reportPlaces = response.data.enriched_place_detail_histories
        .map((d) => EnrichedPlaceHistoryModel.fromJSON(d))
        .sort();

      reportPlaces.filter(p => (
        p.placeId !== this.state.placeId
      )).sort((a, b) => {
        return d3.descending(a.userRatingsSum, b.userRatingsSum);
      });



      this.setState({
        reportPlaces: reportPlaces.concat([this.state.myPlace])
      });
    });
  };

  onSelect = (p) => {
    this.setState({ selectedPlace: p });
  };

  onClearSelection = () => {
    this.setState({ selectedPlace: null });
  };

  scrollToElement = (event, scrollElementId) => {
    event.preventDefault();

    const scrollTo = window.document.getElementById(scrollElementId);
    if(scrollTo) {
      scrollTo.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  render() {
    const { tab, reportPlaces, bounds, selectedPlace, myPlace } = this.state;

    return (
      <HousingAndClimateProvider
        includedGeographies={this.state.includedGeographies}
      >
        <MaxWidth
          maxWidth={1440}
          style={{ backgroundColor: "white", paddingTop: 40 }}
        >


        <div className="header-name">
          <Typography variant="h4">{myPlace?.name}</Typography>
          <Typography variant="body1" paragraph>
            {myPlace?.formattedAddress}
          </Typography>
        </div>

        <Grid container
          style= {{ marginTop: 40 }}
        >
          <Grid
            item
            sm={12}
            md={3}
            className="report-sidebar"
          >
              <div className="report-sidebar-logo-container">
                <a href="/" aria-current="page" className="brand w-nav-brand w--current" aria-label="home">
                  <img src="/goboost_logo.png" alt="" className="header-logo"/>
                </a>
              </div>
              <div className="report-sidebar-navigation-container">
                <ul className="report-sidebar-navigation">
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'intro_section')}>
                      <img style={iconStyle} src={introduction} />
                      <div>Intro</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'market_data_section')}>
                      <img style={iconStyle} src={marketdata} />
                      <div>Market Data</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'swap_out_section')}>
                      <img style={iconStyle} src={swap} />
                      <div>Swap Outs</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'climate_section')}>
                      <img style={iconStyle} src={climate} />
                      <div>Climate</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'competition_section')}>
                      <img style={iconStyle} src={competition} />
                      <div>Competition</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'reviews_section')}>
                      <img style={iconStyle} src={review} />
                      <div>Reviews</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'website_section')}>
                      <img style={iconStyle} src={website} />
                      <div>Website</div>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={e => this.scrollToElement(e, 'recap_section')}>
                      <img style={iconStyle} src={recap} />
                      <div>Recap</div>
                    </a>
                  </li>
                </ul>
              </div>
          </Grid>


          <Grid
            item
            sm={12}
            md={9}
            className="report-content"
          >

          {/* INTRO SECTION */}
          <SectionHeader id="intro_section">
            <Typography variant="h2">
              Intro
            </Typography>
          </SectionHeader>

          <Section className="section-intro">
            <Typography variant="body1" paragraph>
              The GoBoost platform provides owner operators with a website, reviews tool, and the ability to deploy internet ads.
            </Typography>
            <Typography variant="body1" paragraph>
              The project was born out of the frustration that the internet has become a necessary evil for small businesses owners like us.
            </Typography>
            <Typography variant="body1" paragraph>
              We use technology and automation to simplify internet tasks for our customers.
            </Typography>
            <Typography variant="body1" paragraph>
              This free report is an example of how millions of data points and thousands of lines of code can simplify things.
            </Typography>

            <Typography variant="body2" className="disclaimer" paragraph>
              Disclaimer: This free report your about to review was constructed using millions of data points and thousands of lines of code.
              The information contained within this report should be reviewed for accuracy prior to making business decisions.
            </Typography>
          </Section>
          {/* INTRO SECTION */}


          {/* MARKET DATA SECTION */}
          <SectionHeader id="market_data_section">
            <Typography variant="h2">
              Market Data
            </Typography>
          </SectionHeader>

          <Section className="section-market-data">
            <HousingAndClimate
              showDetails={true}
              includedGeographies={this.state.includedGeographies}
            />
            {/* <Box textAlign='center'>
              <Button variant="contained" href="#" onClick={e => this.scrollToElement(e, 'swap_out_section')} endIcon={<img style={btnIconStyle} src={arrowIcon} />}>Continue</Button>
            </Box> */}
          </Section>
          {/* MARKET DATA SECTION */}

          {/* SWAP OUT SECTION */}
          <SectionHeader id="swap_out_section">
            <Typography variant="h2">
              Swap Outs
            </Typography>
          </SectionHeader>

          <Section className="section-swapout">
            <SwapOut
              showDetails={true}
              includedGeographies={this.state.includedGeographies}
              reportPlaces={this.state.reportPlaces}
              consumerPercent={97}
            />
            {/* <Box textAlign='center'>
              <Button variant="contained" href="#" onClick={e => this.scrollToElement(e, 'climate_section')} endIcon={<img style={btnIconStyle} src={arrowIcon} />}>Continue</Button>
            </Box> */}
          </Section>
          {/* SWAP OUT SECTION */}


          {/* CLIMATE SECTION */}
          <SectionHeader id="climate_section">
            <Typography variant="h2">
              Climate
            </Typography>
          </SectionHeader>

          <Section>
            <CountyAvgTemp
              includedGeographies={this.state.includedGeographies}
            />
            {/* <Box textAlign='center'>
              <Button variant="contained" href="#" onClick={e => this.scrollToElement(e, 'competition_section')} endIcon={<img style={btnIconStyle} src={arrowIcon} />}>Continue</Button>
            </Box> */}
          </Section>
          {/* CLIMATE SECTION */}


          {/* COMPETITION SECTION */}
          <SectionHeader id="competition_section">
            <Typography variant="h2">
              Competition
            </Typography>
          </SectionHeader>

          <Section className="section-competition">
            <Competition
              {...this.props}
              bounds={this.state.bounds}
              reportPlaces={this.state.reportPlaces}
              otherPlaces={reportPlaces}
            />

            {/* <Box textAlign='center'>
              <Button variant="contained" href="#" onClick={e => this.scrollToElement(e, 'reviews_section')} endIcon={<img style={btnIconStyle} src={arrowIcon} />}>Continue</Button>
            </Box> */}
          </Section>
          {/* COMPETITION SECTION */}


          {/* REVIEWS SECTION */}
          <SectionHeader id="reviews_section">
            <Typography variant="h2">
              Reviews
            </Typography>
          </SectionHeader>

          <Section className="section-reviews">
            {myPlace ? (
              <>
                <Reviews bounds={this.state.bounds} myPlace={myPlace} otherPlaces={reportPlaces} reportPlaces={this.state.reportPlaces} />
              </>
            ) : null}
          </Section>
          {/* REVIEWS SECTION */}


          {/* WEBSITE SECTION */}
          <SectionHeader id="website_section">
            <Typography variant="h2">
              Website
            </Typography>
          </SectionHeader>

          <Section className="section-website">
            {myPlace ? (
              <>
                <MyWebsite myPlace={myPlace} otherPlaces={reportPlaces} />
              </>
            ) : null}
          </Section>
          {/* WEBSITE SECTION */}


          {/* RECAP SECTION */}
          <SectionHeader id="recap_section">
            <Typography variant="h2">
              Recap
            </Typography>
          </SectionHeader>

          <Section>
            {myPlace ? (
              <>
                <Recap
                  myPlace={myPlace}
                  otherPlaces={reportPlaces}
                  includedGeographies={this.state.includedGeographies}
                />
              </>
            ) : null}
          </Section>
          {/* RECAP SECTION */}

          </Grid>

          </Grid>

        </MaxWidth>
      </HousingAndClimateProvider>
    );
  }
}




const iconStyle = {
  width: 33,
  height: 33,
};

const btnIconStyle = {
  height: 17,
};