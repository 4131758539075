import { Avatar, Typography, withStyles } from "@material-ui/core";



const avatarHeader = props => {
  const { classes } = props;
  const Icon = props.icon;
  return (
    <div className={classes.root}>
      <Avatar classes={{ root: classes.avatar }}>
        <Icon />
      </Avatar>
      <Typography variant="h6">{props.title}</Typography>
    </div>
  )
}

const avatarStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fill: "white",
    marginRight: 10
  },
});

export default withStyles(avatarStyles)(avatarHeader);