import React from "react";
import { log, withContext } from "kn-react";
import Layout from "Layout/Layout";
import CountyAvgTemp from "CountyAvgTemp/CountyAvgTemp";
import HousingAndClimate from "HousingAndClimate/HousingAndClimate";
import BackNext from "ReportBuilder/Steps/BackNext/backNext";
import { withStyles } from "@material-ui/core";
import ToggleMap from "ToggleMap/ToggleMap";
import { Typography, Card, CardContent, Grid, Avatar } from "@material-ui/core";
import { Home, Cloud, WbSunny } from "@material-ui/icons";
import AvatarHeader from "AvatarHeader/AvatarHeader";
import HousingAndClimateProvider from "HousingAndClimate/HousingAndClimateProvider";
import segment from "segment.js";
import history from 'history.js';

class SelectServiceArea extends React.Component {
  componentDidMount = () => {
    if (!this.props.county) {
      history.push('/build/choose-your-county')
    }

    window.document.title = "GoBoost - Select Your Service Area";
  };


  render() {
    const { county } = this.props;

    return (
      <HousingAndClimateProvider
        includedGeographies={this.props.includedGeographies}
      >
        <Layout
          left={
            <ToggleMap
              geoTypeIdsUrl="/api/census_geographies/geo_type_ids_in_bounds"
              showPositionIcon={true}
              position={[county.centroidLat, county.centroidLng]}
              includedGeographies={this.props.includedGeographies}
              onAddIncludedGeography={ geoTypeId => segment.track('added_geography', { geo_type_id: geoTypeId }) }
              onRemoveIncludedGeography={ geoTypeId => segment.track('removed_geography', { geo_type_id: geoTypeId }) }
              onChangeIncludedGeographies={
                this.props.onChangeIncludedGeographies
              }
              onChangeMap={(bounds) => {
                this.props.onChangeBounds(bounds);
              }}
              geoTypeStyles={{
                fontWeight: "bold",
              }}
              includedFill="#85E6D2"
              includedStrokeColor="#3AC2A4"
              excludedFill="#EE551D"
              excludedStrokeColor="#EE551D"
              canEdit={true}
            />
          }
          right={
            <div style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Select Your Service Area
                  </Typography>
                  <Typography variant="caption" paragraph>
                    We'll use your selections to estimate your market size and
                    summarize recent climate data.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <AvatarHeader icon={Home} title="Market" />
                      <HousingAndClimate
                        showDetails={false}
                        includedGeographies={this.props.includedGeographies}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <AvatarHeader icon={WbSunny} title="Climate" />
                      <CountyAvgTemp
                        includedGeographies={this.props.includedGeographies}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <BackNext
                    isBackHidden={window.location.search.includes('source=webflow')}
                    onClickBack={this.props.back}
                    onClickNext={this.props.next}
                    isNextDisabled={!this.props.includedGeographies.length}
                  />
                </Grid>
              </Grid>
            </div>
          }
        />
      </HousingAndClimateProvider>
    );
  }
}

const styles = (theme) => ({
  backNext: {
    display: "flex",
    flexDirection: "row",
  },
  back: {
    flex: 1,
  },
  next: {},
});

export default withStyles(styles)(SelectServiceArea);
