import React from 'react';
import Axios from 'axios';
import { log } from 'kn-react'
import PeriodChart from './PeriodChart';
import { Typography } from '@material-ui/core';


class CountyAvgTemp extends React.Component {

  state = {
    averages: [],
    hdDays: 0,
    cdDays: 0,
  }

  componentDidMount = () => {
    this.fetchData(this.props.includedGeographies);
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.includedGeographies !== this.props.includedGeographies) {
      this.fetchData(nextProps.includedGeographies);
    }
  }


  fetchData = geoTypeIds => {
    if (!geoTypeIds.length) {
      this.setState({ averages: [] });
      return;
    }

    return Axios.get(
      '/api/county_avg_temps/averages',
      { params: { geo_type_ids: geoTypeIds } }
    ).then(response => {
      log("fetchData", response);

      const { averages } = response.data;

      const { hdMax, cdMin } = this.props;

      let hdDays = 0;
      let cdDays = 0;
      averages.forEach(a => {
        a.avg_temp = Math.round(a.avg_temp);
        a.avg_min = Math.round(a.avg_min);
        a.avg_max = Math.round(a.avg_max);

        hdDays += a.avg_min < hdMax ? 1 : 0;
        cdDays += a.avg_max > cdMin ? 1 : 0;
      })


      this.setState({ averages, hdDays, cdDays });
    })
  }

  render(){
    const { hdMax, cdMin } = this.props;
    const { averages, hdDays, cdDays } = this.state;
    return (
      <>
        <Typography variant="body1" style={{marginBottom: "30px"}} paragraph>
          Your selected regions had <span className="hdd">{ hdDays }</span > days with lows below {hdMax}° and <span className="cdd">{ cdDays }</span> days with highs over {cdMin}° on average during the past five years.
        </Typography>
        <PeriodChart
          periods={averages}
          cdMin={this.props.cdMin}
          hdMax={this.props.hdMax}
        />
      </>
    );
  }
}

CountyAvgTemp.defaultProps = {
  cdMin: 78,
  hdMax: 62,
  includedGeographies: [],
}



export default CountyAvgTemp;