import React from "react";
import Axios from 'axios';
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import { machineStates } from "ReportBuilder/ReportBuilderProcess";
import KnAutoSuggest from "Lib/KnAutoSuggest";
import KnModel from "Lib/KnModel";

class ChooseYourCounty extends React.Component {
  componentDidMount = () => {
    window.document.title = "Billow";
  };


  onSuggestionSelected = (county) => {
    this.props.onSelectCounty(county);
    this.props.next();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.sizeUp}>
          <div className={classes.backgroundContainer}>
            <div className={classes.backgroundImage}/>
            <div className={classes.backgroundFilter}/>
          </div>
          <div className={classes.backgroundContent}>

            <Typography variant="h3">
              Built for Owner Operators in the heating & cooling biz.
            </Typography>

            <div>
              <KnAutoSuggest
                containerStyles={{
                  maxHeight: 300,
                  overflowY: "scroll",
                }}
                label=""
                placeholder="Find you County"
                onSuggestionsFetchRequested={(value) => {
                  // segment.track('searched_for_county', { query: value });
                  return Axios.get('/api/counties/search', { params: { q: value } })
                    .then(response => {
                      return response.data.counties.map(c => (
                        KnModel.fromJSON(c)
                      ));
                    })
                }}
                getSuggestionPrimaryValue={(s) => s.countyState}
                getSuggestionSecondaryValue={(s) => ""}
                onSuggestionSelected={this.onSuggestionSelected}
              />

              <Button
                color="primary"
                variant="contained"
                component={RouterLink}
                to={machineStates[0].path}
                style={{ width: 200, marginTop: 30 }}
                size="large"
              >
                Fetch Data
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  sizeUp: {
    position: 'relative',
    height: 400,
    backgroundColor: "whitesmoke",
    textAlign: "center",
    padding: 40,
    maxWidth: 1200,
    marginTop: 50,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  backgroundContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: 20,
  },
  backgroundImage: {
    position: 'absolute',
    background: `url('https://assets.website-files.com/617859a21633846786c11d96/61785cf016eec8dc2db5dea5_HVAC-Service-Area-GoBoost.jpg')`,
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
  },
  backgroundFilter: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    backgroundColor: 'black',
    opacity: 0.5,
  },
  backgroundContent: {
    position: 'absolute',
    left: '0%',
    top: '0%',
    right: '0%',
    bottom: '0%',
    color: 'white',
    padding: 50
  },
  textField: {
    backgroundColor: "white",
    width: 400,
  },
  busyPlace: {
    textAlign: "center",
    padding: 40,
  },
  feature: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
  },
  textCenter: {
    textAlign: "center",
  },
  footer: {
    marginTop: 200,
    padding: 50,
  },
  homeHeroImage: {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
  },
};

export default withStyles(styles)(ChooseYourCounty);
