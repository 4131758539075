import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

export default Analytics({
  app: 'billow-react',
  plugins: [
    segmentPlugin({
      writeKey: process.env.NODE_ENV !== 'production' ? 'rtXraBYykpgFCMEMXTYxsYDrqsIdfjV1' : 'Eg4rIKND8Wdql2WFPega4Z955mRiKxE8'
    })
  ]
});
